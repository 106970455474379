import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import breakpoints from "@/styles/breakpoints";
import ProductName from '@/components/common/ProductName';


interface QuestionTextProps {
  isOpen: boolean;
}

interface QAWrapperProps {
  isLast: boolean;
}

const QAWrapper = styled.div<QAWrapperProps>`
  width: 100%;
  border-bottom: ${props => props.isLast ? 'none' : '1px solid #141414'};
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;

  * {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 20px;
  position: relative;
  flex-shrink: 0
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 2px;
  background-color: #6D6D6D;

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #6D6D6D;
    transform: rotate(90deg);
  }
`;


const QuestionText = styled.p<QuestionTextProps>`
  margin: 0;
  font-size: 16px;
  letter-spacing: -.01rem;
  color: ${props => props.isOpen ? '#6D6D6D' : 'white'};
  flex-grow: 1
`;

const AnswerContainer = styled.div`
  overflow: hidden;
  height: 0;
  width: 100%;
`;

const AnswerText = styled.p`
  margin: 0;
  font-size: 16px;
  letter-spacing: -.01rem;
  padding: 0 20px 20px 56px;
  color: white;
`;

const ProductNameWrapper = styled.span`
  display: inline-flex;
  align-items: baseline;
  vertical-align: baseline;
`;


interface QAComponentProps {
  question: string;
  answer: string;
  isLast: boolean;
}

const QAComponent: React.FC<QAComponentProps> = ({ question, answer, isLast }) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);
  const answerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (answerRef.current) {
      gsap.set(answerRef.current, { height: 0 });
    }
  }, []);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
    
    if (!isOpen) {
      if (iconRef.current) gsap.to(iconRef.current, { rotation: 45, duration: 0.3 });
      if (answerRef.current) gsap.to(answerRef.current, { height: 'auto', duration: 0.3, ease: 'power2.out' });
    } else {
      if (iconRef.current) gsap.to(iconRef.current, { rotation: 0, duration: 0.3 });
      if (answerRef.current) gsap.to(answerRef.current, { height: 0, duration: 0.3, ease: 'power2.in' });
    }
  };

  const renderText = (text: string) => {
    const productNames = [
      'idVisor Pro',
      'idVisor Flex',
      'idVisor',
      'ageVisor',
      'idWedge',
      'identifake'
    ];
    
    const regex = new RegExp(`\\b(${productNames.join('|')}|id(?!s\\b)\\w+)\\b`, 'gi');
    
    const parts = text.split(regex);
    
    return parts.map((part, index) => {
      if (regex.test(part) && part.toLowerCase() !== 'id') {
        return (
          <ProductNameWrapper key={index}>
            <ProductName
              name={part}
              fontSize={{ base: 16 }}
              helveticaWeight="inherit"
              as="span"
            />
          </ProductNameWrapper>
        );
      }
      return part;
    });
  };

  return (
    <QAWrapper isLast={isLast}>
      <QuestionContainer onClick={toggleAnswer}>
        <IconContainer>
          <Icon ref={iconRef} />
        </IconContainer>
        <QuestionText isOpen={isOpen}>{renderText(question)}</QuestionText>
      </QuestionContainer>
      <AnswerContainer ref={answerRef}>
        <AnswerText>{renderText(answer)}</AnswerText>
      </AnswerContainer>
    </QAWrapper>
  );
};

export default QAComponent;