import React,{ useState } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { ButtonColors, ButtonDisabledStyles, ButtonLayouts, ButtonSizes, buttonStyles } from './buttonStyles';
import { css } from '@emotion/react';

interface ButtonStyleProps extends
    Pick<ButtonProps, 'variant' | 'buttonColor' | 'size' | 'disabled' | 'layout' | 'disabledStyle'>,
    React.ButtonHTMLAttributes<HTMLButtonElement> {}

const StyledButton = styled.button<ButtonStyleProps>`
    position: relative;
    font-family: "PP Neue Montreal", sans-serif;
    font-style: normal;
    font-weight: normal;
    border: none;
    cursor: pointer;
    overflow: visible;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    /* for inner shadow */
    ::after {
        content: '';
        position: absolute;
        inset: 0;
        pointer-events: none;
    }

    /* shared disabled props */
    ${props => props.disabled && css`
        cursor: not-allowed;
        * {
            cursor: not-allowed;
        }
    `}

    //If variant, map to styles
    ${props => props.variant ? (
        props.variant === 'nav' && css`
            ${buttonStyles.colors['blue']}
            ${buttonStyles.sizes['xs']}
            ${props.disabled && buttonStyles.disabled['dark']}
        ` ||
        props.variant === 'checkout' && css`
             ${buttonStyles.colors['blue']}
             ${buttonStyles.sizes['sm']}
             ${buttonStyles.layouts['fill']}
             ${props.disabled && buttonStyles.disabled['glowing']}
        ` ||
        props.variant === 'mega' && css`
            ${buttonStyles.colors['blue']}
            ${buttonStyles.sizes['xxxl']}
            ${props.disabled && buttonStyles.disabled['dark']}
        ` ||
        props.variant === 'hero' && css`
            ${buttonStyles.colors['blue']}
            ${buttonStyles.sizes['lg']}
            ${props.disabled && buttonStyles.disabled['dark']}`
    ) : css` //If no variant specified, use styles / defaults
        ${buttonStyles.colors[props.buttonColor || 'blue']}
        ${buttonStyles.sizes[props.size || 'md']}
        ${props.layout && buttonStyles.layouts[props.layout]}
        ${props.disabled && buttonStyles.disabled[props.disabledStyle || 'dark']}
    `}    
`

interface ButtonProps {
    children?: React.ReactNode;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    type?: 'button' | 'submit' | 'reset' ;
    text?: string;
    target?: string | null | undefined;
    variant?: 'mega' | 'nav' | 'checkout' | 'hero';
    buttonColor?: ButtonColors;
    size?: ButtonSizes;
    disabled?: boolean;
    disabledStyle?: ButtonDisabledStyles;
    layout?: ButtonLayouts;
}

const Button = ({ children, href, onClick, type = 'button', variant, text, disabled, target, buttonColor, size, layout, disabledStyle }: ButtonProps) => {
    const effectiveTarget = target ?? "_self";
    const content = text || children;
    const [isPressed, setIsPressed] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) {
            e.preventDefault();
            // Add a subtle shake animation to indicate the button is disabled
            e.currentTarget.animate([
                { transform: 'translateX(-2px)' },
                { transform: 'translateX(2px)' },
                { transform: 'translateX(-2px)' },
            ], { duration: 200, iterations: 1 });
            return;
        }

        setIsPressed(true);
        setTimeout(() => setIsPressed(false), 200);
        onClick?.(e);
    };

    const buttonContent = (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            {typeof content === 'string' ? (
                <>
                    <span>{content}</span>
                </>
            ) : (
                content
            )}
        </span>
    );

    if (href && !disabled) {
        return (
            <Link
                href={href}
                target={effectiveTarget}
                rel={effectiveTarget === "_blank" ? "noopener noreferrer" : undefined}
                passHref
            >
                <StyledButton
                    onClick={(e) => e.stopPropagation()} //prevent scroll behavior
                    variant={variant}
                    buttonColor={buttonColor}
                    size={size}
                    layout={layout}
                    disabledStyle={disabledStyle}
                >{buttonContent}</StyledButton>
            </Link>
        );
    }
    
    return (
        <StyledButton 
            onClick={handleClick} 
            type={type} 
            variant={variant} 
            disabled={disabled}
            style={isPressed ? { transform: 'scale(0.98)' } : {}}
            buttonColor={buttonColor}
            size={size}
            layout={layout}
            disabledStyle={disabledStyle}
        >
            {buttonContent}
        </StyledButton>
    );
};


export default Button;