// @/components/landing/Hero/Content
import React from 'react'; 
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Container from '@/components/layout/Base/Container';
import Section from '@/components/layout/Base/Section';
import Image from 'next/image';
import ValueLine from './ValueLine';
import Button from '@/components/common/Button';
import StarsMiniProof from './StarsMiniProof';
import { PopupButton } from '@typeform/embed-react'
import { sendGAEvent } from '@next/third-parties/google'

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;

    @media (min-width: ${breakpoints.md}px) {
        width: 50%;
    }
`;

const Heading = styled.h1`
    font-size: 56px;
    line-height: 110%; 


    @media (min-width: ${breakpoints.md}px) {
        font-size: 64px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 86px;
    }

`;

const ItalicText = styled.span`
    font-style: italic;
`

const ValuePropsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px 0px 0px 0px;
`;

const ButtonContainer = styled.div`
    width: 100%; 

    > button {
        min-width: 100% !important;
    }

    @media (min-width: ${breakpoints.md}px) {
        margin-right: auto;  

    }
`;

const TypeformButton = styled(PopupButton)`
    background: none;
    border: none;
    margin-right: auto;

    @media (max-width: ${breakpoints.md}px) {
        width: 100%;
        display: block;
        
        > button {
            width: 100% !important;
        }
    }
`


const Content = ( ) => {

    return (
        <ContentContainer>
            <Heading>ID Scanning<br/> Pays <ItalicText>for</ItalicText> Itself</Heading>
            <ValuePropsContainer>
                <ValueLine text='Age Verification'></ValueLine>
                <ValueLine text='Fake ID Detection'></ValueLine>
                <ValueLine text='Data Automation'></ValueLine>
            </ValuePropsContainer>
            <StarsMiniProof/>
            <ButtonContainer>
            {/* <TypeformButton 
                id="urXbR95w"
                size={90}
                onReady={({ formId }) => {
                console.log('form ready');
                sendGAEvent({ event: 'typeform_ready', form_id: formId });
                }}
                onStarted={({ formId, responseId }) => {
                console.log(`Form ${formId} started with response ID ${responseId}`);
                sendGAEvent({ event: 'typeform_started', form_id: formId, response_id: responseId });
                }}
                onSubmit={({ formId, responseId }) => {
                console.log(`Form ${formId} submitted, response id: ${responseId}`);
                sendGAEvent({ event: 'typeform_submitted', form_id: formId, response_id: responseId });
                }}
            > */}
                        <Button variant="hero" href="#openWizard">
                            Find Your Perfect ID Scanner
                        </Button>
                {/* </TypeformButton> */}
            </ButtonContainer>
        </ContentContainer>
    );
  };
  
  export default Content;