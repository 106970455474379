// src/components/landing/MostPopularProducts/SectionTitle.tsx

import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints'
import { UnderlineRed } from '@/components/common/UnderlineRed';

const MobileOnlyWrapper = styled.div`
  display: inline-block;
  position: relative;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const DesktopOnlyWrapper = styled.span`
  display: none;

  @media (min-width: ${breakpoints.md}px) {
    display: inline;
  }
`;

const SectionTitleContainer = styled.div`
    text-align: center;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 90px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`;

const TitleTextWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
`;

const MobileUnderlineWrapper = styled.div`
  position: absolute;
  bottom: 10px; // Adjust this value to reduce the space between text and underline
  left: 0;
  right: 0;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const Title = styled.h1`
    font-family: 'PP Editorial New';
    font-weight: normal;
    line-height: 1.2;
    z-index: 2;

        @media (min-width: ${breakpoints.md}px) {
         line-height: .7; 
    }
`;

const Light = styled.span`
    font-weight: 200;
`;


const Id = styled.span`
    font-weight: normal;
    font-style: italic;
    margin-right: .4rem;
`;

const TitleBlur = styled.svg`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    fill: rgba(215, 36, 34, 0.22);
    width: 258px;
    height: 156px;
    margin-top: -55px;
    z-index: -1;
    filter: blur(50px);
`;

const Subtitle = styled.h5`
    margin-top: 38px;
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
    }
`;

export const SectionTitle = () =>  {
    return (
    <>
    <SectionTitleContainer>
        <TitleWrapper>
            <TitleTextWrapper>
                <Title>
                    Most Popular <Light>
                        <MobileOnlyWrapper>
                            <Id>id</Id>Scanners
                            <MobileUnderlineWrapper>
                                <UnderlineRed isHovered={true} />
                            </MobileUnderlineWrapper>
                        </MobileOnlyWrapper>
                        <DesktopOnlyWrapper><Id>id</Id>Scanners</DesktopOnlyWrapper>
                    </Light>
                </Title>
                <DesktopOnlyWrapper>
                    <UnderlineRed isHovered={true} />
                </DesktopOnlyWrapper>
                {/* <TitleBlur>
                    <path d="M258 106.131C258 138.263 200.245 156 129 156C57.7553 156 0 138.263 0 106.131C0 73.999 57.7553 0 129 0C200.245 0 258 73.999 258 106.131Z" />
                </TitleBlur> */}
            </TitleTextWrapper>
        </TitleWrapper>
        <Subtitle className="sans">If you already know what you&nbsp;need.</Subtitle>
    </SectionTitleContainer>
    </>
    );
};

export default SectionTitle;