export async function processPostContent(content: string): Promise<string> {
  const cheerioModule = await import('cheerio');
  const $ = cheerioModule.default.load(content);

  // Process only images that are not already inside a figure
  $('img').not('figure img').each((_, img) => {
    const $img = $(img);
    const src = $img.attr('src') || '';
    const alt = $img.attr('alt') || '';
    const width = $img.attr('width');
    const height = $img.attr('height');

    if (src && width && height) {
      const imgElement = `<Image src="${src}" alt="${alt}" width={${width}} height={${height}} layout="responsive" />`;
      const $figure = $('<figure>').html(imgElement);
      
      // If there's a figcaption, move it inside the figure
      const $nextEl = $img.next();
      if ($nextEl.is('figcaption')) {
        $figure.append($nextEl);
      }

      $img.replaceWith($figure);
    }
  });

  return $.html();
}