// components/BuySection/CheckoutSection.tsx
import React from 'react';
import styled from '@emotion/styled';
import Button from '@/components/common/Button';
import breakpoints from '@/styles/breakpoints';

const CheckoutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  padding-top: 16px;
  margin-top: 4px; // Add this to reduce space between last addon and line

  @media (min-width: ${breakpoints.lg}px) {
    padding-top: 20px;
  }
`;

const Subtotal = styled.div`
  font-size: 14px;
  color: #FFFFFF;
  font-family: 'PP Neue Montreal', sans-serif;
`;

const SubtotalNumber = styled.span`
  font-size: 26px;
`;

const ErrorMessage = styled.div`
  color: #D72422;
  margin-top: 10px;
  font-size: 14px;
`;

interface CheckoutSectionProps {
  totalPrice: number;
  isLoading: boolean;
  isCartReady: boolean;
  onAddToCart: () => void;
  error: string | null;
}

export const CheckoutSection: React.FC<CheckoutSectionProps> = ({
  totalPrice,
  isLoading,
  isCartReady,
  onAddToCart,
  error
}) => (
  <>
    <CheckoutContainer>
      <Subtotal>
        Subtotal:<br />
        <SubtotalNumber>${totalPrice.toFixed(2)}</SubtotalNumber>
      </Subtotal>
      <Button
        variant="nav"
        onClick={onAddToCart}
        disabled={isLoading || !isCartReady}
      >
        {!isCartReady ? 'Loading...' : isLoading ? 'Adding...' : 'Add to Cart'}
      </Button>
    </CheckoutContainer>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </>
);