//  @/components/layout/NavBar/DesktopMenu.tsx
import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { ProductCarousel } from '@/components/common/ProductCarousel';
import { IndicatorClosed } from '@/components/icons/IndicatorClosed';
import { IndicatorOpen } from '@/components/icons/IndicatorOpen';
import breakpoints from '@/styles/breakpoints';
import { gsap } from 'gsap';
import { NavDataType } from '@/utils/hooks/useNavData';
import { ProcessedNavGroup } from '@/utils/hooks/useNavData';
import { useNav } from '@/providers/NavProvider';

interface DesktopMenuProps {
  isOpen: boolean;
  activeCategory: string;
  setActiveCategory: (category: string) => void;
}

const MenuContainer = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(21px);
  padding: 60px 0 55px 0;
  z-index: 99;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  @media (min-width: ${breakpoints.md}px) {
    display: block;
  }
`;

const MenuContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 40px;

  @media (min-width: ${breakpoints.lg}px) {
    display: absolute;
    transform: translateX(25%);
    justfiy-content: center;
    padding-left: 0;
  }

`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 60px;

  @media (min-width: ${breakpoints.lg}px) {
    margin-right: 70px;
  }

`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
`;

const IndicatorWrapper = styled.div<{ isSelected: boolean }>`
  width: 4px;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: ${({ isSelected }) => isSelected
    ? 'drop-shadow(0px 0px 2px rgba(215, 36, 34, 0.6)) drop-shadow(0px 2px 6px rgba(237, 0, 0, 1))'
    : 'drop-shadow(0px 0px 2px rgba(215, 36, 34, 0.12))'
  };
  transition: filter 0.3s ease;
`;

const CategoryText = styled.span<{ isSelected: boolean }>`
  color: ${({ isSelected }) => isSelected ? '#FFF' : 'rgba(255, 255, 255, 0.66)'};
  font-family: "PP Editorial New", sans-serif;
  font-size: 18px;
  font-weight: 400;
  transition: color 0.3s ease;
  width: 128px;
`;

const CarouselContainer = styled.div`
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const CarouselInner = styled.div`
  opacity: 0;
`;



export const DesktopMenu: React.FC<DesktopMenuProps> = ({ 
  isOpen, 
  activeCategory, 
  setActiveCategory,
}) => {  
  const { navData } = useNav();
  const [isInitialized, setIsInitialized] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      // Reset carousel animations
      if (carouselRef.current) {
        gsap.set(carouselRef.current, { clearProps: "all" });
      }
      if (innerRef.current) {
        gsap.set(innerRef.current, { clearProps: "all" });
      }
      setIsInitialized(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && navData && navData.length > 0) {
      if (!isInitialized) {
        setActiveCategory(navData[0].name || 'Most Popular');
        setIsInitialized(true);
      }

      // Show the carousel when the menu opens
      if (innerRef.current) {
        gsap.to(innerRef.current, {
          opacity: 1,
          duration: 0.3,
          ease: 'power2.out',
        });
      }
    } else {
      // Reset initialization when menu closes
      setIsInitialized(false);
    }
  }, [isOpen, isInitialized, setActiveCategory, navData]);

  useEffect(() => {
    if (isInitialized && carouselRef.current && innerRef.current) {
      gsap.to(innerRef.current, {
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
      });
    }
  }, [isInitialized, activeCategory]);

  if (!isOpen || !navData) return null;

  const handleCategoryClick = (category: string) => {
    if (category !== activeCategory) {
      gsap.to(innerRef.current, {
        opacity: 0,
        duration: 0.2,
        ease: 'power2.out',
        onComplete: () => {
          setActiveCategory(category);
          gsap.to(innerRef.current, {
            opacity: 1,
            duration: 0.3,
            ease: 'power2.out',
          });
        },
      });
    }
  };

  return (
    <MenuContainer isOpen={isOpen}>
      <MenuContent>
        <CategoriesContainer>
          {navData.map((group) => (
            <CategoryItem 
              key={group.slug || group.databaseId} 
              onClick={() => group.name && handleCategoryClick(group.name)}
            >
              <IndicatorWrapper isSelected={group.name === activeCategory}>
                {group.name === activeCategory ? <IndicatorOpen /> : <IndicatorClosed />}
              </IndicatorWrapper>
              <CategoryText isSelected={group.name === activeCategory}>
                {group.name || 'Unnamed Category'}
              </CategoryText>
            </CategoryItem>
          ))}
        </CategoriesContainer>
        <CarouselContainer ref={carouselRef}>
          <CarouselInner ref={innerRef}>
            {activeCategory && (
              <ProductCarousel 
                products={navData.find(group => group.name === activeCategory)?.products}
                groupName={activeCategory}
                cardWidth={153.821} 
                cardHeight={271.448} 
              />
            )}
          </CarouselInner>
        </CarouselContainer>
      </MenuContent>
    </MenuContainer>
  );
};