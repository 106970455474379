// WooSessionManagerPlugin/index.ts
import { FaustHooks, FaustPlugin } from '@faustwp/core';
import { ApolloClientOptions, NormalizedCacheObject, from } from '@apollo/client';
import { createSessionLink } from './createSessionLink';
import { createErrorLink } from './createErrorLink';
import { createUpdateLink } from './createUpdateLink';

export class WooSessionManagerPlugin implements FaustPlugin {
    apply(hooks: FaustHooks) {        

        const { addAction, addFilter } = hooks;

        addFilter(
        'apolloClientOptions',
        'blueprint',
            (
                apolloClientOptions: ApolloClientOptions<NormalizedCacheObject>,
                context: Record<string, never>,
            ) => {
                
                // Only run client side
                if (typeof window === 'undefined') {
                    return apolloClientOptions;
                }
                
                const existingLink = apolloClientOptions.link;

                const newLink = from([
                    createSessionLink(),
                    createErrorLink(),
                    createUpdateLink(),
                    ...(Array.isArray(existingLink) ? existingLink : [existingLink]),
                ]);

                return {
                    ...apolloClientOptions,
                    link: newLink,
                };
            },
        );
    }
}