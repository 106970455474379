import Section from '@/components/layout/Base/Section';
import styled from '@emotion/styled'
import Image from 'next/image';
import SideImage from './idvisor_side_full_hero.jpg';
import AngleImage from './idvisor_45-up_full_hero.jpg';
import MobileImage from './MobileImage.png';
import breakpoints from '@/styles/breakpoints';
import Container from "@/components/layout/Base/Container";
import { blurDataUrlSoft } from '@/styles/blurDataUrl';
import useViewport from '@/utils/hooks/useViewport';

const StyledSection = styled(Section)`
    overflow: hidden;
    background-color: black;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0px;
    max-width: 100%;
`

const HeroContainer = styled(Container)`
    position: relative;
    height: 90svh;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4%;
    padding-bottom: 10px;

    @media (min-width: ${breakpoints.md}px) {
        gap: 10%;
        padding-bottom: 0px;
    }
`

const Textbox = styled.div`
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    z-index: 1;

    @media (min-width: ${breakpoints.md}px) {
        padding: 0px;
    }
`

const Title = styled.h1`
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 72px;
    }
`


const Description = styled.p`
    font-size: 18px;
    line-height: 150%;
    color: rgb(var(--token-gray));
    text-align: center;
    max-width: 550px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 20px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 26px;
        max-width: 700px;
    }
`

const SidePic = styled.div`
    position: relative;
    height: 35%;
    width: 100%;
`

const AnglePic = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`

const EasyUseHeroSection = () => {
    const { width } = useViewport();

    return (
        <StyledSection>
            <HeroContainer>
                <Textbox>
                    <Title>Top-Tier Security Measures</Title>
                    <Description>Protect your venue with advanced security features that prevent unauthorized entry and ensure compliance with regulations.</Description>
                </Textbox>
                {width >= breakpoints.md && (
                    <SidePic>
                        <Image
                            src={SideImage}
                            alt=''
                            fill={true}
                            sizes="100vw"
                            style={{objectFit: "contain"}}
                            placeholder='blur'
                            blurDataURL={blurDataUrlSoft}
                        />
                    </SidePic>
                )}
                {width <= breakpoints.md && (
                    <AnglePic>
                        <Image
                            src={MobileImage}
                            alt=''
                            fill={true}
                            sizes="100vw"
                            style={{objectFit: "contain"}}
                            placeholder='blur'
                            blurDataURL={blurDataUrlSoft}
                        />
                    </AnglePic>
                )}
            </HeroContainer>
        </StyledSection>
    )
}

export default EasyUseHeroSection;