import { FaustTemplate } from "@faustwp/core";
import { GetSolutionPageDocument, GetSolutionPageQuery } from '../generated/graphql';
import FeatureSolutionPage from "@/components/featureSolutionPage";
import { mapSolutionPageToCommonData } from "@/components/featureSolutionPage/types";

const Component: FaustTemplate<GetSolutionPageQuery> = (props) => {
    
    if (props.loading) return <>Loading...</>; // Loading state for previews
    if (!props.data) return <>No data found</>;
    const data = mapSolutionPageToCommonData(props.data);

    return (
        <FeatureSolutionPage data={data}/>
    );
}

Component.query = GetSolutionPageDocument;

Component.variables = (seedQuery, context) => {
  return {
    id: seedQuery?.databaseId,
    asPreview: context?.asPreview,
  };
};

export default Component;