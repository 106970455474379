import { CartData } from "./types";
import { useEffect, useState } from "react";

export type ShippingSelectionReturn = ReturnType<typeof getShippingSelection>;

export default function getShippingSelection(data: CartData) {
    const [selectedShippingDetails, setSelectedShippingDetails] = useState<{ label: string, cost: string, id: string } | null>(null);

    useEffect(() => {
        const chosenMethod = data?.cart?.chosenShippingMethods?.[0] || null;
        if (chosenMethod) {
            // Find the details of the chosen method
            data?.cart?.availableShippingMethods?.forEach(shippingPackage => {
                shippingPackage?.rates?.forEach(rate => {
                    if (rate?.id === chosenMethod) {
                        setSelectedShippingDetails({
                            id: rate.id || '',
                            label: rate.label || '',
                            cost: rate.cost || ''
                        });
                    }
                });
            });
        }
    }, [data]);
    
    return selectedShippingDetails;
}