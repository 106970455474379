// src/components/landing/IndustriesSection/index.tsx
import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import Section from '@/components/layout/Base/Section';
import Container from "@/components/layout/Base/Container";
import IndustriesSectionTitle from "./IndustriesSectionTitle"
import IndustryItem from './Industryitem';
import useViewport from '@/utils/hooks/useViewport';
import breakpoints from '@/styles/breakpoints';

const IndustryList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin-top: 20px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;


interface Industry {
  title: React.ReactNode;
  description: React.ReactNode;
  href: string;
}

interface IndustriesSectionProps {
    industries: Industry[];
  }
  
  const IndustriesSection: React.FC<IndustriesSectionProps> = ({ industries }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { width } = useViewport();
    const isMobile = width < breakpoints.sm;
  
    const handleItemIntersect = useCallback((index: number, isIntersecting: boolean) => {
      if (isMobile) {
        if (isIntersecting) {
          setActiveIndex(index);
        } else if (activeIndex === index) {
          setActiveIndex(null);
        }
      } else {
        setActiveIndex(isIntersecting ? index : null);
      }
    }, [isMobile, activeIndex]);
  
    return (
      <Section>
        <Container>
          <IndustriesSectionTitle />
          <IndustryList>
            {industries.map((industry, index) => (
              <IndustryItem
                key={index}
                title={industry.title}
                description={industry.description}
                href={industry.href}
                onItemIntersect={handleItemIntersect}
                index={index}
                isActive={activeIndex === index}
              />
            ))}
          </IndustryList>
        </Container>
      </Section>
    )
  }
  
  export default IndustriesSection;