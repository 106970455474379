// src/utils/useViewport.ts
import breakpoints from '@/styles/breakpoints';
import { useState, useEffect } from 'react';

const useViewport = () => {
  const [width, setWidth] = useState(0);
  const isMobile = width > 0 && width < breakpoints.md;

  useEffect(() => {
    // Check if the code is running on the client-side
    if (typeof window !== 'undefined') {
      const handleResize = () => setWidth(window.innerWidth);
      setWidth(window.innerWidth); // Set initial width
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { width, isMobile };
};

export default useViewport;