// ./wp-templates/index.js
import category from './category';
import tag from './tag';
import frontPage from './front-page.tsx';
import page from './page';
import single from './single';
import singleFeaturePage from './single-feature-page';
import singleSolutionPage from './single-solution-page';
import singleSpotlight from './single-spotlight'


export default {
  category,
  tag,
  'front-page': frontPage,
  'single-spotlight' : singleSpotlight,
  'single-feature-page' : singleFeaturePage,
  'single-solution-page' : singleSolutionPage,
  page,
  single,
};