// src/components/landing/FakeIdSection/SolutionsStack.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import SolutionItem from './SolutionItem';

const FullWidthContainer = styled(Container)`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
`;

interface Solution {
    color: string;
    name: string;
    description: string;
    backgroundImage: string;
  }
  
  interface SolutionsStackSectionProps {
    solutions: Solution[];
  }
  

  const SolutionsStackSection: React.FC<SolutionsStackSectionProps> = ({ solutions }) => {
    return (
      <FullWidthContainer>
        {solutions.map((solution, index) => (
          <SolutionItem
            key={index}
            color={solution.color}
            name={solution.name}
            description={solution.description}
            showBottomBorder={index === solutions.length - 1}
            backgroundImage={solution.backgroundImage}
          />
        ))}
      </FullWidthContainer>
    );
  };
  
  export default SolutionsStackSection;