// src/components/spotlight/sections/CaseStudySection/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import { CaseStudyCarousel } from './CaseStudyCarousel';
import { SectionTitle } from './SectionTitle'

interface CaseStudy {
  id: string;
  image: string;
  company: string;
  location: string;
  previewText: string;
  caseStudyUrl: string;
}

interface CaseStudiesSectionProps {
  caseStudies: CaseStudy[];
  title?: string;
  subtitle?: string;
}

const FullWidthSection = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
`;

export const CaseStudiesSection: React.FC<CaseStudiesSectionProps> = ({ 
  caseStudies, 
  title,
  subtitle
}) => {
  return (
    <FullWidthSection>
      <SectionTitle 
        title={title}
        subtitle={subtitle}
      />
      <CaseStudyCarousel caseStudies={caseStudies} />
    </FullWidthSection>
  );
};

export default CaseStudiesSection;