// src/components/icons/ShieldIcon.tsx
import React from 'react';

interface ShieldIconProps {
  color: string;
}

const ShieldIcon: React.FC<ShieldIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="78" viewBox="0 0 64 78" fill="none" style={{ overflow: 'visible' }}>
    <g filter="url(#filter0_di_5429_39646)">
      <path d="M32 0L0 14.1818V35.4545C0 55.1318 13.6533 73.5327 32 78C50.3467 73.5327 64 55.1318 64 35.4545V14.1818L32 0Z" fill={color}/>
      <path d="M1 35.4545V14.8324L32 1.09375L63 14.8324V35.4545C63 54.6252 49.7426 72.5205 32 76.9704C14.2574 72.5205 1 54.6252 1 35.4545Z" stroke="url(#paint0_linear_5429_39646)" strokeWidth="2"/>
    </g>
    <defs>
      <filter id="filter0_di_5429_39646" x="-25" y="-25" width="114" height="128" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="12.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.33 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5429_39646"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5429_39646" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_innerShadow_5429_39646"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="6"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"/>
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5429_39646"/>
      </filter>
      <linearGradient id="paint0_linear_5429_39646" x1="32" y1="0" x2="32" y2="78" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.88"/>
        <stop offset="1" stopColor="white" stopOpacity="0.33"/>
      </linearGradient>
    </defs>
  </svg>
);

export default ShieldIcon;