//components/layout/NavBar/NavLinkWrapper
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { NavLink } from '@/components/layout/NavBar/NavLink';
import breakpoints from '@/styles/breakpoints';
import useViewport from '@/utils/hooks/useViewport';

const Wrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 0 18px 0;
    white-space: nowrap;
  }

  @media (min-width: ${breakpoints.lg}px) {
    gap: 80px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
  }

  & > * {
    outline: none;
    text-decoration: none;
  }
`;

interface NavLinkWrapperProps {
  onIdScannersClick: () => void;
  isDesktopMenuOpen: boolean;
}

export const NavLinkWrapper: React.FC<NavLinkWrapperProps> = ({ onIdScannersClick, isDesktopMenuOpen }) => {
  const router = useRouter();

  return (
    <Wrapper>
      <div onClick={onIdScannersClick}>
        <NavLink
          prefix="id"
          suffix="Scanners"
          isActive={isDesktopMenuOpen} 
        />
      </div>
      <StyledLink href="/idinsights" passHref>
        <NavLink
          prefix="id"
          suffix="Insights"
          isActive={router.pathname === '/idinsights'}
        />
      </StyledLink>
      <StyledLink href="/solutions" passHref>
        <NavLink
          prefix="id"
          suffix="Solutions"
          isActive={router.pathname === '/solutions'}
        />
      </StyledLink>
    </Wrapper>
  );
};