import React, { useCallback, useEffect } from 'react';
import { sendGAEvent } from '@next/third-parties/google';
import { createPopup } from '@typeform/embed';

export default function WizardProvider() {

    const popupRef = React.useRef<HTMLElement | null>(null);

    const { open, close } = createPopup('urXbR95w', {
        respectOpenModals: 'all',
        onReady: ({ formId }) => {
            sendGAEvent({ event: 'typeform_ready', form_id: formId });
        },
        onStarted: ({ formId, responseId }) => {
            history.pushState({ modal: true }, '');
            sendGAEvent({ event: 'typeform_started', form_id: formId, response_id: responseId });
            // Store the popup element
            popupRef.current = document.querySelector('.tf-v1-popup');
        },
        onSubmit: ({ formId, responseId }) => {
            sendGAEvent({ event: 'typeform_submitted', form_id: formId, response_id: responseId });
        },
        onClose: () => {
            removeHash();
        }
    });

    const forceCleanup = useCallback(() => {
        // Call the normal close
        close();
        
        // Force remove any popup elements if they exist
        const popup = popupRef.current || document.querySelector('.tf-v1-popup');
        if (popup) {
            popup.remove();
        }
        
        // Reset body styles that might have been set
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
    }, [close]);

    const removeHash = useCallback(() => {
        history.pushState("", document.title, window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            if (event.state?.modal) {
                // Do nothing on forward
            } else {
                console.log('Closing modal from back button');
                forceCleanup();
            }
        };

        const handleUrlChange = () => {
            if (window.location.hash === '#openWizard') {
                open();
            }
        };

        handleUrlChange();
        window.addEventListener('popstate', handlePopState);
        window.addEventListener('hashchange', handleUrlChange);
        
        return () => {
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('hashchange', handleUrlChange);
        };
    }, [open, forceCleanup]);

    return <></>
};