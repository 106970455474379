// src/constants/navigation.ts

export const CATEGORY_ORDER = [
    'Most Popular',
    'Mobile',
    'Stationary',
    'Data Automation',
    'Accessories'
  ] as const;
  
  // Type helper for category names
  export type CategoryName = typeof CATEGORY_ORDER[number];
  
  // Helper function to get category index
  export const getCategoryIndex = (categoryName: string | null | undefined): number => {
    if (!categoryName) return Infinity;
    const index = CATEGORY_ORDER.indexOf(categoryName as CategoryName);
    return index === -1 ? Infinity : index;
  };