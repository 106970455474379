// @/providers/ProductNavProvider/index.tsx
import React, { createContext, useContext, useState } from 'react';

interface ProductNavContextType {
  productNav: {
    productName: string;
    buyButtonText: string;
    onBuyClick: () => void;
  } | null;
  setProductNav: (nav: ProductNavContextType['productNav']) => void;
}

const ProductNavContext = createContext<ProductNavContextType | undefined>(undefined);

export function ProductNavProvider({ children }: { children: React.ReactNode }) {
  const [productNav, setProductNav] = useState<ProductNavContextType['productNav']>(null);

  return (
    <ProductNavContext.Provider value={{ productNav, setProductNav }}>
      {children}
    </ProductNavContext.Provider>
  );
}

export function useProductNav() {
  const context = useContext(ProductNavContext);
  if (context === undefined) {
    throw new Error('useProductNav must be used within a ProductNavProvider');
  }
  return context;
}