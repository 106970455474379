import { blurDataURL } from '@/styles/blurDataUrl';
import styled from '@emotion/styled';
import Image from 'next/image';

interface ImageContainerProps {
    aspectRatio: number;
}

const ImageContainer = styled.div<ImageContainerProps>`
    position: relative;
    width: 100%;
    padding-top: ${props => `${(1 / props.aspectRatio) * 100}%`};
`

interface PostImageProps {
    src: string;
    alt?: string;
    width: number;
    height: number;
}

export default function({ src, alt = '', width, height }: PostImageProps) {
    const aspectRatio = width / height;

    return (
        <ImageContainer aspectRatio={aspectRatio}>
            <Image
                src={src}
                alt={alt}
                fill
                sizes="(max-width: 800px) 100vw, 800px"
                placeholder='blur'
                blurDataURL={blurDataURL}
                priority
            />
        </ImageContainer>
    );
}