// src/components/landing/IdInsightsPreviewSection/Intro.tsx


import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints'
import Section from '@/components/layout/Base/Section';

const SectionTitleContainer = styled(Section)`
    text-align: center;
    flex-shrink: 0;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const TitleTextWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
`;

const Title = styled.h1`
    font-family: 'PP Editorial New';
    line-height: 1.3;
`;

const TitleBlur = styled.svg`
    position: absolute;
    top: 70%;
    left: 20%;
    fill: rgba(255, 255, 255, 0.66);    
    width: 138px;
    height: 138px;
    margin-top: -55px;
    z-index: -1;
    filter: blur(150px);
`;

const Subtitle = styled.h3`
    margin-bottom: 40px;
    font-family: 'PP Editorial New';
    color: #86868B;
`;

const Paragraph = styled.p`
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
`;

export const Intro = () =>  {
    return (
    <>
    <SectionTitleContainer>
        <TitleWrapper>
            <TitleTextWrapper>
                <Title>Stay Informed, Stay Ahead</Title>
                <TitleBlur>
                    <circle cx="69" cy="69" r="69" />
                </TitleBlur>
            </TitleTextWrapper>
        </TitleWrapper>
        <Subtitle>Want to Dive Deeper?</Subtitle>
        <Paragraph>Our comprehensive library of resources will keep you informed about the latest in ID verification and fraud prevention. Whether you're new to ID scanning or a seasoned user, we've got something for you.</Paragraph>
    </SectionTitleContainer>
    </>
    );
  };
  
  export default Intro;