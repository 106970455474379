//@ utils/navDataCache.ts

import { NavDataType } from '@/utils/hooks/useNavData';
import { getNavData } from '@/utils/rendering/nav/getNavData';
import { TWENTY_FOUR_HOURS } from '@/constants/revalidationInterval';

let cachedNavData: NavDataType | null = null;
let lastFetchTime = 0;

export async function getNavDataWithCache(): Promise<NavDataType> {
  const now = Date.now();
  if (!cachedNavData || now - lastFetchTime > TWENTY_FOUR_HOURS) {
    cachedNavData = await getNavData();
    lastFetchTime = now;
  }
  return cachedNavData;
}