// providers/AddOnsProvider.tsx
import React, { createContext, useContext, useReducer, useMemo, useCallback } from 'react';
import { ProductAddonFieldEnum, ProductAddonPriceAdjustEnum } from '@/generated/graphql';
import { FEATURE_MAP, FeatureName } from '@/services/qlm';
import { Addon } from '@/services/woocommerce/product/types';

export interface AddOnsState {
  selectedAddOns: Set<string>;
  basePrice: number;
  // addOns: AddOn[];
  addOns: Addon[];
  freeAddOns: Set<string>;
}

type AddOnsAction = 
  | { type: 'TOGGLE_ADDON'; payload: string }
  // | { type: 'SET_ADDONS'; payload: AddOn[] }
  | { type: 'SET_ADDONS'; payload: Addon[] }
  | { type: 'SET_BASE_PRICE'; payload: number };

  const addOnsReducer = (state: AddOnsState, action: AddOnsAction): AddOnsState => {
    switch (action.type) {
    case 'TOGGLE_ADDON': {
      const newSelected = new Set(state.selectedAddOns);
      if (newSelected.has(action.payload)) {
        newSelected.delete(action.payload);
      } else {
        newSelected.add(action.payload);
      }
      return { ...state, selectedAddOns: newSelected };
    }
    case 'SET_ADDONS': {
      const freeAddOns = new Set(
        action.payload
          .filter(addon => addon.price === 0 || addon.price === null)
          .map(addon => addon.name)
      );
      return { ...state, addOns: action.payload, freeAddOns };
    }
    case 'SET_BASE_PRICE':
      return { ...state, basePrice: action.payload };
    default:
      return state;
  }
};

interface AddOnsContextType {
    state: AddOnsState;
    toggleAddOn: (name: string) => void;
    toggleBasePrice?: (price: number) => void;
    totalPrice: number;
    isSelected: (name: string) => boolean;
    isFree: (name: string) => boolean;
    isAlreadyPurchased: (name: string) => boolean;
}

const AddOnsContext = createContext<AddOnsContextType | null>(null);


export const AddOnsProvider: React.FC<{
    children: React.ReactNode;
    initialAddOns: Addon[];  // This should be the processed addons
    basePrice: number;
    qlmFeatures?: number;
    }> = ({ children, initialAddOns, basePrice, qlmFeatures }) => {
    // console.log('AddOnsProvider Init:', {
    //   initialAddOns,
    //   basePrice
    // });

    const isAlreadyPurchased = useCallback((addonName: string) => {
      if (!qlmFeatures) return false;

      if (addonName in FEATURE_MAP) {
        return (qlmFeatures & FEATURE_MAP[addonName as FeatureName]) !== 0;
      }
      return false;
    }, [qlmFeatures]);

    // Remove any processing here - use the addons as provided
    const [state, dispatch] = useReducer<React.Reducer<AddOnsState, AddOnsAction>>(
      addOnsReducer,
      {
        selectedAddOns: new Set(),
        basePrice,
        addOns: initialAddOns,  // Use the processed addons directly
        freeAddOns: new Set(
          initialAddOns
            .filter(addon => addon.price === 0 || addon.price === null)
            .map(addon => addon.name)
        )
      }
    );

 const totalPrice = useMemo(() => {
    return state.basePrice + Array.from(state.selectedAddOns).reduce((total, name) => {
      const addon = state.addOns.find(a => a.name === name);
      return total + (addon?.price || 0);
    }, 0);
  }, [state.selectedAddOns, state.basePrice, state.addOns]);

// Then in the provider, update the value memo to include the new function
const value = useMemo<AddOnsContextType>(() => ({
    state,
    toggleAddOn: (name: string) => dispatch({ type: 'TOGGLE_ADDON', payload: name }),
    toggleBasePrice: (price: number) => dispatch({ type: 'SET_BASE_PRICE', payload: price }),
    totalPrice,
    isSelected: (name: string) => state.selectedAddOns.has(name) || state.freeAddOns.has(name),
    isFree: (name: string) => state.freeAddOns.has(name),
    isAlreadyPurchased // Add the callback function
}), [state, totalPrice, isAlreadyPurchased]); // Add isAlreadyPurchased to dependencies

return (
    <AddOnsContext.Provider value={value}>
        {children}
    </AddOnsContext.Provider>
);
};

export const useAddOns = () => {
  const context = useContext(AddOnsContext);
  if (!context) {
    throw new Error('useAddOns must be used within an AddOnsProvider');
  }
  return context;
};