//pages/spotlight/components/BuySection/index
import React, { forwardRef, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Container"
// import SectionTitle from './SectionTitle'
import breakpoints from '@/styles/breakpoints';
import { ProductAddonPriceAdjustEnum, ProductAddonFieldEnum } from '@/generated/graphql';

import { ProductImage } from './ProductImage';
import { ProductDetails } from './ProductDetails';
import SupportCards from '@/components/common/SupportCards';
import { BuyProps } from './types';
// import ProductSchema from './ProductSchema';


const StyledSection = styled(Section)`
    max-width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    gap: 40px;
  }
`;


const BuySection = forwardRef<HTMLDivElement, BuyProps>(({ product }, ref) => {
  const cleanPrice = (price: string | null | undefined): number => {
    if (typeof price !== 'string') return 0;
    return parseFloat(price.replace(/[^0-9.-]+/g, "")) || 0;
  };

  const basePrice = cleanPrice(product.price);

  const supportCards = [
    { 
      ...product.supportCards?.firstSupportCard, 
      iconSrc: '/icons/financing-icon.svg',
      description: product.supportCards?.firstSupportCard?.description || '',
      title: product.supportCards?.firstSupportCard?.title || ''
    },
    { 
      ...product.supportCards?.secondSupportCard, 
      iconSrc: '/icons/warranty-icon.svg',
      description: product.supportCards?.secondSupportCard?.description || '',
      title: product.supportCards?.secondSupportCard?.title || ''
    },
    { 
      ...product.supportCards?.thirdSupportCard, 
      iconSrc: '/icons/support-icon.svg',
      description: product.supportCards?.thirdSupportCard?.description || '',
      title: product.supportCards?.thirdSupportCard?.title || ''
    },
    { 
      ...product.supportCards?.fourthSupportCard, 
      iconSrc: '/icons/satisfaction-icon.svg',
      description: product.supportCards?.fourthSupportCard?.description || '',
      title: product.supportCards?.fourthSupportCard?.title || ''
    },
  ];


return (
  <>
    {/* <ProductSchema product={product}/> */}
    <StyledSection>
      <Container>
          <ContentWrapper ref={ref}>
            <ProductImage 
              src={product.featuredImage?.node?.mediaItemUrl}
              alt={product.featuredImage?.node?.altText}
            />
            <ProductDetails
              name={product.name}
              price={product.price}
              regularPrice={product.regularPrice}
              salePrice={product.salePrice}
              onSale={product.onSale}
              bentoAddonMapping={product.bentoAddonMapping}
              productId={product.id}
            />
          </ContentWrapper>
          <SupportCards cards={supportCards} variant="default" />
      </Container>
    </StyledSection>
  </>
);
});

BuySection.displayName = 'BuySection';

export default BuySection;