import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

interface LightningIconProps {
  isOn: boolean;
  size?: {
    base: number;
    md?: number;
  };
}

const IconSVG = styled.svg<LightningIconProps>`
  fill: #01010E;
  transition: filter 0.3s ease;
  
  width: ${props => props.size?.base || 50}px;
  height: ${props => (props.size?.base || 50) * (200/127)}px;

  @media (min-width: ${breakpoints.md}px) {
    width: ${props => props.size?.md || 127}px;
    height: ${props => (props.size?.md || 127) * (200/127)}px;
  }
  
  ${({ isOn }) => isOn ? `
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.33));
  ` : `
    filter: none;
  `}

  path {
    stroke-width: 2px;
    transition: stroke 0.3s ease;
  }
`;

export const LightningIcon: React.FC<LightningIconProps> = ({ isOn, size }) => {
  return (
    <IconSVG 
      isOn={isOn}
      size={size}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 127 200"
    >
      <defs>
        <linearGradient id="lightningStrokeGradientOn" x1="63.5" y1="0" x2="63.5" y2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.88" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient id="lightningStrokeGradientOff" x1="63.5" y1="0" x2="63.5" y2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.16" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.13" />
        </linearGradient>
      </defs>
      <path 
        d="M93.708 7.47461L70.9497 79.9642L116.85 79.0582C120.95 78.9773 123.356 83.6398 120.915 86.9345L39.3967 196.953C37.4101 199.634 33.1954 197.495 34.1878 194.308L56.9627 121.189H9.89488C5.84196 121.189 3.51512 116.576 5.9241 113.316L86.7627 3.94355C89.4119 0.359159 95.0431 3.22209 93.708 7.47461Z"
        stroke={isOn ? "url(#lightningStrokeGradientOn)" : "url(#lightningStrokeGradientOff)"}
      />
    </IconSVG>
  );
};