// WooSessionManagerPlugin/createErrorLink
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import { getSessionToken } from './createSessionLink/getSessionToken';
import { removeWooSessionToken } from './utils';

export function createErrorLink() {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    const authErrorMessages = [
      'Wrong number of segments',
      'The iss do not match with this server',
      'invalid-secret-key | Expired token',
      'invalid-secret-key | Signature verification failed',
      'Expired token',
    ];

    if (graphQLErrors) {
      for (let err of graphQLErrors) {

        // Suppress the "No items in cart to remove" error
        if (err.message === "No items in cart to remove.") {
          continue;
        }

        console.log('GraphQL error:', err);
        
        if (authErrorMessages.some(msg => err.message.includes(msg))) {
          console.log('Authentication error detected:', err.message);
          
          // Return Observable that handles the async operations in Promise chain
          return new Observable(observer => {
            Promise.resolve()
              .then(() => removeWooSessionToken())  // Clear the invalid token
              .then(() => getSessionToken(true))  // Force fetch a new token
              .then(newToken => {
                if (newToken) {
                  // Update the operation context with the new token
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      'woocommerce-session': `Session ${newToken}`,
                    },
                  }));
                  
                  // Retry the operation
                  forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                  });
                } else {
                  observer.error(new Error('Failed to get new session token'));
                }
              })
              .catch(error => {
                console.error('Error fetching new session token:', error);
                observer.error(error);
              });
          });
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
}