// NavLink.tsx
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { UnderlineRed } from '@/components/common/UnderlineRed';


interface NavLinkProps {
  prefix: string;
  suffix: string;
  onClick?: () => void;
  isActive?: boolean;
}

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.713px;
  * {
    cursor: pointer;
  }
`;

const LinkTextContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
  height: 18px;
  position: relative;
`;

const LinkText = styled.span`
  color: #fff;
  font-family: "PP Editorial New";
  font-size: 1.4375rem;
  line-height: 1; // Remove the default line-height
`;

const LinkPrefix = styled(LinkText)`
  font-style: italic;
  font-weight: 400;
`;

const LinkSuffix = styled(LinkText)`
  font-style: normal;
  font-weight: 200;
`;

const IdInsightsPrefix = styled(LinkPrefix)`
  margin-right: 0.10063rem;
`;

export const NavLink: React.FC<NavLinkProps> = ({ prefix, suffix, onClick, isActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  
  const renderPrefix = () => {
    if (prefix === 'id' && suffix === 'Insights') {
      return <IdInsightsPrefix>{prefix}</IdInsightsPrefix>;
    }
    return <LinkPrefix>{prefix}</LinkPrefix>;
  };

  return (
    <LinkWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <LinkTextContainer>
        {renderPrefix()}
        <LinkSuffix>{suffix}</LinkSuffix>
      </LinkTextContainer>
      <UnderlineRed isHovered={isHovered} isActive={isActive} />
    </LinkWrapper>
  );
};