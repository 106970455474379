import { CategoryToPostConnectionPageInfo, GetPostsByCategoryQuery, GetPostsByTagQuery, PageInfo, Post, TagToPostConnectionPageInfo, TaxonomySeoFieldsFragment } from "@/generated/graphql"

export interface CategoryTagPageData {
    name: string;
    slug: string;
    posts: Partial<Post>[] | null | undefined;
    seo?: TaxonomySeoFieldsFragment | null | undefined;
}

export function transformCategoryData(categoryData: GetPostsByCategoryQuery): CategoryTagPageData {
    return {
        name: categoryData?.category?.name ?? '',
        slug: categoryData?.category?.slug ?? '',
        posts: categoryData?.category?.posts?.nodes?.map(node => node as Partial<Post>) ?? [],
        seo: categoryData?.category?.seo ?? null,
    };
}

export function transformTagData(tagData: GetPostsByTagQuery): CategoryTagPageData {
    return {
        name: tagData?.tag?.name ?? '',
        slug: tagData?.tag?.slug ?? '',
        posts: tagData?.tag?.posts?.nodes?.map(node => node as Partial<Post>) ?? [],
        seo: tagData?.tag?.seo ?? null,
    };
}