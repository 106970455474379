import styled from '@emotion/styled';

interface TextGradientProps {
  opacity?: number;
}

const GradientSpan = styled.span<TextGradientProps>`
  && {
        background-image: ${props => {
            const greyValue = Math.round(255 * (1 - (props.opacity || 1)));
            return `linear-gradient(to bottom, rgba(255,255,255), rgb(${greyValue},${greyValue},${greyValue}))`;
    }};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
`;

const TextGradient = ({ opacity = 1, children }: { opacity: number, children: React.ReactNode }) => {
    return (
    <GradientSpan opacity={opacity}>
      {children}
    </GradientSpan>
  );
};

export default TextGradient;