import { FEATURE_MAP, FeatureName } from ".";

// Convert feature strings to number
export function encodeFeatures(features: FeatureName[]): number {
    return features.reduce((acc, feature) => {
        return acc | (FEATURE_MAP[feature] || 0);
    }, 0);
}

// Convert number to feature strings
export function decodeFeatures(value: number): FeatureName[] {
    return Object.entries(FEATURE_MAP)
        .filter(([_, bitValue]) => (value & bitValue) !== 0)
        .map(([feature]) => feature as FeatureName);
}