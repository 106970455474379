import { css } from '@emotion/react';
import breakpoints from './breakpoints';

const globalStyles = css`

    /* Base formating */

    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        cursor: default;
    }

    html {
        max-width: 100vw;
        font-size: 16px; /* This sets 1rem = 16px */
        scroll-behavior: smooth;
        scroll-padding-top: 100px;
    }
   

    body {
        margin: auto;
        overflow-x: hidden;
        min-height: 100vh; /* Sets a height and positioning context for child elements */
        position: relative;
        background-color: rgb(var(--background-blue)); /* Prevent seeing white when scrolling beyond bounds */
        color: white;
        -webkit-font-smoothing: antialiased; /* Makes type crisp */
        -moz-osx-font-smoothing: grayscale; /* Makes type crisp */
        -webkit-overflow-scrolling: touch; /* For iOS momentum scrolling */
        padding-top: env(safe-area-inset-top); /* For iphone notch */
    }
    

    /* Brand colors! */

    :root {
        --background-blue: 1, 1, 14;
        --token-blue: 58, 113, 255;
        --token-red: 215, 36, 34;
        --token-green: 30, 157, 56;
        --button-hover: 39, 99, 255;
        --token-gray: 134, 134, 139;
        --dark-gray: 35, 35, 35;
    }

    /* Text Styles */
    
    h1 {
        font-family: 'PP Editorial New', serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 160%;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 72px;
        }
    }

    h2 {
        font-family: 'PP Editorial New', serif;
        font-size: 38px;
        font-weight: 400;
        line-height: 150%;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 60px;
        }
    }

    h3 {
        font-family: 'PP Editorial New';
        font-weight: 400;
        font-size: 32px;
        line-height: 150%;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 42px;
        }
    }

    h4 {
        font-family: 'PP Editorial New';
        font-size: 28px;
        font-weight: 400;
        line-height: 150%;
    }

    h5 {
        font-family: 'PP Editorial New';
        font-size: 26px;
        font-weight: 400;
        color: rgb(var(--token-gray));
        line-height: 150%;
    }

    h1.sans {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 44px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.02em;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 72px;
        }
    }

    h2.sans {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 40px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.02em;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 60px;
        }
    }

    h3.sans {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 32px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.02em;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 42px;
        }
    }

    h4.sans {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 28px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.02em;
    }

    h5.sans {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 26px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: rgb(var(--token-gray));
    }

    p {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 26px;
        font-weight: regular;
        line-height: 150%;
        letter-spacing: -0.01em;
    }

    p.small {
        font-size: 16px;
        letter-spacing: -0.01em;
    }

    p.caption {
        font-size: 12px;
        letter-spacing: -0.01em;
        color: rgb(var(--token-gray));
    }

    a {
        color: rgb(var(--token-blue));
        text-decoration: none;
        cursor: pointer;
        
        &:hover {
            text-decoration: underline;
        }
    }

    button * {
        cursor: pointer;
    }

    *[style*="italic"], i, em {
        font-family: 'PP Editorial New', Helvetica, Arial, sans-serif;
    }

    figcaption {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 13.5px;
        color: #808086;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.03em;
        margin-top: 8px;
    }

    ul, ol {
        padding-left: 20px;
    }

    input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        width: 100%;
    }


    @font-face {
        font-family: 'PP Editorial New';
        src: url('/fonts/PPEditorialNew-Regular.woff2') format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap
    }

    @font-face {
        font-family: 'PP Editorial New';
        src: url('/fonts/PPEditorialNew-Italic.woff2') format('woff2');
        font-weight: 400;
        font-style: italic;
        font-display: swap
    }

    @font-face {
        font-family: 'PP Editorial New';
        src: url('/fonts/PPEditorialNew-Ultralight.woff2') format('woff2');
        font-weight: 200;
        font-style: normal;
        font-display: swap
    }

    @font-face {
        font-family: 'PP Neue Montreal';
        src: url('/fonts/PPNeueMontreal-Regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: swap
    }
`;

export default globalStyles;

export const outlineStyles = {
    glosscard: css`
        position: relative;
        overflow: hidden;
        border-radius: 20px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            border-radius: 20px;
            padding: 1px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.13));
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask-composite: exclude;
            pointer-events: none;
        }
    `
};