//BentoText.tsx holds the bento's title and caption
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import TextGradient from '@/styles/TextGradient';
import Glow from '@/styles/Glow';
import breakpoints from '@/styles/breakpoints';
import { BentoVariantType } from './BentoItem';

interface Variant {
    variant: 'mini' | 'square';
}

// BentoText holds the bento title and caption
const Textbox = styled.div<Variant>`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    pointer-events: none;

    ${props => props.variant === 'mini' && css`
        top: 0;
        left: 20px;
        align-items: flex-start;
        max-width: 45%;
    `}

    ${props => props.variant === 'square' && css`
        top: auto;
        left: 0;
        align-items: center;
        height: 45%;
        max-height: 150px;
        max-width: 100%;
    `}
`

const Title = styled.h4<Variant>`
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.02em;

    ${props => props.variant === 'mini' && css`
        font-size: 18px;
    `}

    ${props => props.variant === 'square' && css`
        font-size: 28px;
    `}
`

const Caption = styled.p<Variant>`
    font-size: 12px;
    letter-spacing: -0.01em;
    color: rgb(var(--token-gray));
    z-index: 1;

    ${props => props.variant === 'mini' && css`
        /* none */
    `}

    ${props => props.variant === 'square' && css`
        text-align: center;
        max-width: 225px;
    `}
`

const DarkGradient = styled.div<Variant>`
    position: absolute;
    top: 0;
    left: -15%;
    right: -15%;
    bottom: 0;
    background: linear-gradient(to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 0) 100%
        );

    ${props => props.variant === 'mini' && css`
        /* none */
    `}

    ${props => props.variant === 'square' && css`
        display: none;
    `}
`

interface BentoTextProps {
    title: string;
    caption: string;
    variant: BentoVariantType;
}

const BentoText = ({ title, caption, variant }: BentoTextProps) => {

    const variantFallback: BentoVariantType = variant === 'squareCentered' ? 'square' : variant;

    return (
        <Textbox variant={variantFallback}>
            <DarkGradient variant={variantFallback}/>
            <Glow opacity={0.22} blur={55}>
                <TextGradient opacity={0.08}>
                    <Title dangerouslySetInnerHTML={{ __html: title }} variant={variantFallback}/>
                </TextGradient>
            </Glow>
            <Caption dangerouslySetInnerHTML={{ __html: caption }} variant={variantFallback}/>
        </Textbox>
    );
}

export default BentoText;