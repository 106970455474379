// components/BuySection/utils.ts
import { ProductAddonFieldEnum, ProductAddonPriceAdjustEnum } from '@/generated/graphql';
import { Addon } from '@/services/woocommerce/product/types';

interface AddonOption {
  label?: string | null;
  price?: number | null;
  priceType?: ProductAddonPriceAdjustEnum | null;
}

interface AddonInput {
  __typename?: 'AddonCheckbox';
  description?: string | null;
  name?: string | null;
  price?: number | null;
  type?: ProductAddonFieldEnum | null;
  fieldName: string;
  priceType?: ProductAddonPriceAdjustEnum | null;
  options?: Array<AddonOption | null> | null;
}

export const processAddOns = (addons: Array<AddonInput | null> | null | undefined): Addon[] => {
  return addons?.flatMap(addon => {
    if (addon?.__typename === 'AddonCheckbox' && Array.isArray(addon.options)) {
      return addon.options.map((option: AddonOption | null) => ({
        id: `${addon.fieldName}-${option?.label ?? ''}`,
        name: option?.label ?? '',
        price: option?.price ?? null,
        info: addon.description ?? '',
        type: addon.type ?? ProductAddonFieldEnum.Checkbox,
      }));
    }
    return [];
  }) ?? [];
};

