import Container from "@/components/layout/Base/Container";
import Section from "@/components/layout/Base/Section";
import SectionSpacer from "@/components/layout/Base/SectionSpacer";
import { blurDataURL } from "@/styles/blurDataUrl";
import breakpoints from "@/styles/breakpoints";
import { outlineStyles } from "@/styles/globalStyles";
import { ProcessedSubHeroImagesSection } from "@/utils/spotlightDataProcessor";
import styled from "@emotion/styled";
import Image from "next/image";

const Layout = styled.div`
    display: flex;
    gap: 20px;

    @media (min-width: ${breakpoints.md}px) {
        gap: 56px;
    }
`

const StyledImage = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    ${outlineStyles.glosscard}

    @media (min-width: ${breakpoints.md}px) {
        aspect-ratio: 16 / 9;
    }
`

interface SubHeroImagesProps {
    images: ProcessedSubHeroImagesSection
}

export default function SubHeroImages({ images }: SubHeroImagesProps) {
    if (!images.leftImage.src && !images.rightImage.src) return <></>

    return (
        <>
            <Section>
                <Container>
                    <Layout>
                        {images.leftImage.src && (
                            <StyledImage>
                                <Image
                                    src={images.leftImage.src}
                                    alt={images.leftImage.altText}
                                    placeholder="blur"
                                    blurDataURL={blurDataURL}
                                    fill
                                    style={{ objectFit: 'cover' }}
                                />
                            </StyledImage>
                        )}
                        {images.rightImage.src && (
                            <StyledImage>
                                <Image
                                    src={images.rightImage.src}
                                    alt={images.rightImage.altText}
                                    placeholder="blur"
                                    blurDataURL={blurDataURL}
                                    fill
                                    style={{ objectFit: 'cover' }}
                                />
                            </StyledImage>
                        )}
                    </Layout>
                </Container>
            </Section>
            <SectionSpacer size="small" />
        </>
    )
}