import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

interface ContainerProps {
  variant?: 'default' | 'cart';
}

const SupportCardsContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;

  ${({ variant }) =>
    variant === 'default' &&
    `
    @media (min-width: ${breakpoints.md}px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 40px;
    }

    @media (min-width: ${breakpoints.lg}px) {
      gap: 40px;
      flex-wrap: nowrap;
    }

    @media (min-width: ${breakpoints.xl}px) {
      gap: 80px;
    }
  `}
`;

const SupportCard = styled.div<ContainerProps>`
  width: 100%;
  background: #000;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;

  ${({ variant }) =>
    variant === 'default' &&
    `
    @media (min-width: ${breakpoints.md}px) {
      width: 48%;
    }

    @media (min-width: ${breakpoints.lg}px) {
      width: 100%;
    }
  `}
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const IconImage = styled.img`
  width: 20px;
  height: 20px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const CardTitle = styled.h3`
  color: #FFF;
  font-size: 14px;
  margin: 0;
  font-family: 'PP Neue Montreal', sans-serif;
`;

const CardDescription = styled.p`
  color: #86868B;
  font-size: 12px;
  margin: 0;
`;

interface SupportCardProps {
  iconSrc: string;
  title: string;
  description: string;
}

interface SupportCardComponentProps extends SupportCardProps {
  variant?: 'default' | 'cart';
}

const SupportCardComponent: React.FC<SupportCardComponentProps> = ({ iconSrc, title, description, variant }) => (
  <SupportCard variant={variant}>
    <CardContent>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContent>
    <IconWrapper>
      <IconImage src={iconSrc} alt={title} />
    </IconWrapper>
  </SupportCard>
);

interface SupportCardsProps {
  cards: SupportCardProps[];
  variant?: 'default' | 'cart';
}

const SupportCards: React.FC<SupportCardsProps> = ({ cards, variant = 'default' }) => {
  return (
    <SupportCardsContainer variant={variant}>
      {cards.map((card, index) => (
        <SupportCardComponent
          key={index}
          iconSrc={card.iconSrc}
          title={card.title}
          description={card.description}
          variant={variant}
        />
      ))}
    </SupportCardsContainer>
  );
};

export default SupportCards;