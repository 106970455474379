// src/components/spotlight/sections/ReviewsSection/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import { ReviewsCarousel } from './ReviewsCarousel';
import { SectionTitle } from './SectionTitle'

const FullWidthSection = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
`;

interface Review {
  id: string;
  image: string;
  reviewText: string;
  rating: number;
  author: string;
}

interface ReviewsSectionProps {
  reviews: Review[];
  title?: string;
  subtitle?: string;
}


export const ReviewsSection: React.FC<ReviewsSectionProps> = ({ 
  reviews, 
  title,
  subtitle 
}) => {
  return (
    <FullWidthSection>
      <SectionTitle title={title} subtitle={subtitle} />
      <ReviewsCarousel reviews={reviews} />
    </FullWidthSection>
  );
};

export default ReviewsSection;
