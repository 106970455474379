// spotlightDataProcessor.ts

import { GetSpotlightQuery, Spotlight, SpotlightProductDetails, WithAcfSpotlightProductDetails, ComparisonGroup, SimpleProduct, Maybe } from '../generated/graphql';

//Main Processed Spotlight Product Page Interface 
export interface ProcessedSpotlightProductPage {
  title: string;
  uri: string;
  productDisplayName: string;
  processedHeroSection: ProcessedHeroSection;
  processedSubHeroImagesSection: ProcessedSubHeroImagesSection;
  processedStreamingTextSection: ProcessedStreamingTextSection;
  processedReviews: ProcessedReview[];
  processedAnalogySection: string;
  processedCaseStudiesSection: ProcessedCaseStudiesSection;
  processedFormulaSections: {
    first: ProcessedFormulaSection;
    second: ProcessedFormulaSection;
    third: ProcessedFormulaSection;
  };
  processedBentoSections: {
    first: ProcessedBentoSection;
    second: ProcessedBentoSection;
    third: ProcessedBentoSection;
  };
  processedHowItWorksSection: ProcessedHowItWorksSection;
  processedBuySection: ProcessedProduct;
  processedAddonsForInTheBox: ProcessedAddon[];
  processedInTheBoxSection: ProcessedInTheBoxSection;
  processedTechSpecsSection: ProcessedTechSpecsSection;
  processedFaqSection: ProcessedFaqSection;
  processedCompareSection: ProcessedCompareSection | null;
  processedWizardCtaSection: ProcessedWizardCtaSection;
  processedNavSection: {
    productName: string;
    buyButtonText: string;
  };
}

//Section Parts of Interface for Processed Data
interface ProcessedHeroSection {
  mediaType: 'video' | 'image';  
  mediaSrc: string;             
  subheading: string;
}

interface ProcessedStreamingTextSection {
  sentences: string[];
}

interface ProcessedReview {
  id: string;
  image: string;
  reviewText: string;
  rating: number;
  author: string;
}

interface ProcessedCaseStudy {
  id: string;
  image: string;
  company: string;
  location: string;
  previewText: string;
  caseStudyUrl: string;
}

interface ProcessedCaseStudiesSection {
  title: string;
  subtitle: string;
  caseStudies: ProcessedCaseStudy[];
}

type FormulaIcon = "shield" | "lightning" | "person";


interface FormulaItem {
  icon: FormulaIcon;
  isOn: boolean;
  text: string;
}

interface ProcessedHowItWorksVideo {
  id: string;
  src: string;
  text: string;
  editorialParts: string[];
}

interface ProcessedHowItWorksSection {
  videos: ProcessedHowItWorksVideo[];
}

interface ProcessedProduct {
  id: string; // added for cart mutation
  name: string;
  price: string;
  regularPrice: string;
  salePrice: string;
  onSale: boolean;
  featuredImage: {
    node: {
      altText: string;
      mediaItemUrl: string;
    }
  };
  addons: any[]; // Keep this as is for now
  bentoAddonMapping: BentoAddonMapping; // Add this new field
  supportCards: {
    firstSupportCard: { description: string; title: string };
    secondSupportCard: { description: string; title: string };
    thirdSupportCard: { description: string; title: string };
    fourthSupportCard: { description: string; title: string };
  };
}

interface ProcessedInTheBoxItem {
  text: string;
  src: string;
  rowSpan: number;
  addon: boolean;
  columnSpan: number;
}
// Used by 'What's in the box'
interface ProcessedAddon {
  name: string;
  price: number | null;
}

interface ProcessedInTheBoxSection {
  items: ProcessedInTheBoxItem[];
}

interface ProcessedTechSpec {
  label: string;
  value: string;
}

interface ProcessedSpecCategory {
  title: string;
  specs: ProcessedTechSpec[];
  fullWidth?: boolean;
  image?: string;
}

interface ProcessedTechSpecsSection {
  specCategories: ProcessedSpecCategory[];
}

interface ProcessedFaqItem {
  question: string;
  answer: string;
}

interface ProcessedFaqSection {
  faqData: ProcessedFaqItem[];
}

interface ProcessedCompareProduct {
  id: string;
  name: string;
  price: string;
  image: string;
  specs: {
    [key: string]: {
      value: string | null;
      name: string;
    };
  };
  slug: string;
}

interface ProcessedCompareSection {
  products: ProcessedCompareProduct[];
  categories: string[];
  currentProduct: string;
}

interface ProcessedWizardCtaSection {
  bigText: string;
  buttonText: string;
  smallText: string;
  bigTextWidth: {
    sm: number;
    md: number;
  };
}

// For mapping bento popups to product add ons
interface BentoAddonMapping {
  [addonName: string]: {
    title: string;
    caption: string;
    animationName: string | null;
    bentoPopup: {
      body: string;
      heading: string;
    };
  };
}


// Section Specific Exported interfaces

export interface ProcessedNavData {
  productName: string;
  buyButtonText: string;
}

export interface ProcessedFormulaSection {
  productName: string;
  titleFontSize: { base: number; md: number };
  titleHelveticaWeight: number;
  titleCustomStyles: { color: string };
  items: FormulaItem[];
  showTitle: boolean;
}


export interface ProcessedBentoItem {
  addonName: string | null;
  animationName: string | null;
  caption: string;
  isAddon: boolean;
  popup: {
    body: string;
    heading: string;
    pageLink: {
      nodes: Array<{
        id: string;
        title: string;
        slug: string;
      }>;
    } | null;
  };
  span: number;
  title: string;
}

export interface ProcessedBentoSection {
  heroSectionName: string | null;
  bentoItems: ProcessedBentoItem[];
}

interface AddonOption {
  __typename?: string;
  price?: number | null;
  label?: string | null;
  priceType?: string | null;
}

interface Addon {
  __typename?: string;
  description?: string | null;
  name?: string | null;
  price?: number | null;
  options?: Maybe<AddonOption[]>;
  type?: string | null;
  fieldName?: string;
  priceType?: string | null;
}

//Main Spotlight Product Page Data Processing  
export function processSpotlightData(data: GetSpotlightQuery): ProcessedSpotlightProductPage {
  if (!data.spotlight) throw new Error("No spotlight data found");
  
  const spotlight = data.spotlight as unknown as Spotlight & WithAcfSpotlightProductDetails;
  
  if (!spotlight.spotlightProductDetails) throw new Error("No spotlight product details found");

  const productDisplayName = spotlight.title || "Spotlight";
  const product = spotlight.spotlightProductDetails.associatedProduct?.nodes?.[0] as SimpleProduct;

  if (!product) throw new Error("No associated product found");

  // Doing bentos before the others so that we can map the popups to the addons in the buy section
  const processedBentoSections = {
    first: processBentoSection(spotlight.spotlightProductDetails.firstBentoSection as SpotlightProductDetails['firstBentoSection']),
    second: processBentoSection(spotlight.spotlightProductDetails.secondBentoSection as SpotlightProductDetails['secondBentoSection']),
    third: processBentoSection(spotlight.spotlightProductDetails.thirdBentoSection as SpotlightProductDetails['thirdBentoSection']),
  };

  const bentoSectionsArray = [
    processedBentoSections.first,
    processedBentoSections.second,
    processedBentoSections.third
  ];

  const processedBuySection = processBuySection(product, bentoSectionsArray);
  const processedAddonsForInTheBox = processAddonsForInTheBox(product.addons);

  // console.log("we got the id!:", product.id)
  return {
    title: spotlight.title || "",
    uri: spotlight.uri || "",
    productDisplayName,
    processedHeroSection: processHeroSection(spotlight.spotlightProductDetails.heroSection as SpotlightProductDetails['heroSection']),
    processedSubHeroImagesSection: processSubHeroImagesSection(spotlight.spotlightProductDetails.subHeroImagesSection),
    processedStreamingTextSection: processStreamingTextSection(spotlight.spotlightProductDetails.streamingTextSection as SpotlightProductDetails['streamingTextSection']),
    processedReviews: processReviews(spotlight.spotlightProductDetails.reviews as SpotlightProductDetails['reviews']),
    processedAnalogySection: processAnalogySection(spotlight.spotlightProductDetails.analogySection as SpotlightProductDetails['analogySection']),
    processedCaseStudiesSection: processCaseStudiesSection(spotlight.spotlightProductDetails.caseStudiesSection as SpotlightProductDetails['caseStudiesSection'], productDisplayName),
    processedFormulaSections: {
      first: processFormulaSection(spotlight.spotlightProductDetails.formulaSection as SpotlightProductDetails['formulaSection'], productDisplayName),
      second: processFormulaSection(spotlight.spotlightProductDetails.secondFormulaSection as SpotlightProductDetails['secondFormulaSection'], productDisplayName),
      third: processFormulaSection(spotlight.spotlightProductDetails.thirdFormulaSection as SpotlightProductDetails['thirdFormulaSection'], productDisplayName),
    },
    processedBentoSections,
    processedHowItWorksSection: processHowItWorksSection(spotlight.spotlightProductDetails.howItWorksVideos as SpotlightProductDetails['howItWorksVideos']),
    processedBuySection,
    processedAddonsForInTheBox,
    processedInTheBoxSection: processInTheBoxSection(product),
    processedTechSpecsSection: processTechSpecsSection(product),
    processedFaqSection: processFaqSection(product),
    processedCompareSection: processCompareSection(product, productDisplayName),
    processedWizardCtaSection: processWizardCtaSection(spotlight.spotlightProductDetails.wizardCtaSection as SpotlightProductDetails['wizardCtaSection']),
    processedNavSection: {
      productName: productDisplayName,
      buyButtonText: "Buy Now"
    }
  };
}

//Functions for each section's data processing 
function processHeroSection(heroSection: any): ProcessedHeroSection {
  const mediaSrc = heroSection?.video?.node?.mediaItemUrl || '';
  const mediaType = getMediaType(mediaSrc);
  
  return {
    mediaType,
    mediaSrc,
    subheading: heroSection?.subheading || "Missing Subheading",
  };
}

function getMediaType(src: string): 'video' | 'image' {
  if (!src) return 'image'; // Default to image if no source
  const extension = src.split('.').pop()?.toLowerCase();
  return extension === 'mp4' ? 'video' : 'image';
}

interface Image {
  src: string;
  altText: string;
}

export interface ProcessedSubHeroImagesSection {
  leftImage: Image;
  rightImage: Image;
}

function processSubHeroImagesSection(subHeroImages: SpotlightProductDetails['subHeroImagesSection']): ProcessedSubHeroImagesSection {
  return {
    leftImage: {
      src: subHeroImages?.leftImage?.node.mediaItemUrl ?? '',
      altText: subHeroImages?.leftImage?.node.altText ?? ''
    },
    rightImage: {
      src: subHeroImages?.rightImage?.node.mediaItemUrl ?? '',
      altText: subHeroImages?.rightImage?.node.altText ?? ''
    }
  }
}

function processStreamingTextSection(streamingTextSection: SpotlightProductDetails['streamingTextSection']): ProcessedStreamingTextSection {
  return {
    sentences: streamingTextSection?.streamingTextSentence
      ?.map(item => item?.sentance)
      .filter((sentence): sentence is string => sentence !== null && sentence !== undefined) || [],
  };
}

function processReviews(reviews: SpotlightProductDetails['reviews']): ProcessedReview[] {
  return reviews?.map((review, index) => ({
    id: `review-${index}`,
    image: review?.image?.node?.mediaItemUrl || '',
    reviewText: review?.reviewText || '',
    rating: review?.rating || 0,
    author: review?.author || '',
  })) || [];
}

function processAnalogySection(analogySection: SpotlightProductDetails['analogySection']): string {
  return analogySection?.analogyText || '';
}

function processCaseStudiesSection(caseStudiesSection: SpotlightProductDetails['caseStudiesSection'], productDisplayName: string): ProcessedCaseStudiesSection {
  return {
    title: caseStudiesSection?.title || "Success Stories",
    subtitle: `See the ${productDisplayName} in action`,
    caseStudies: caseStudiesSection?.caseStudies?.map((study, index) => ({
      id: `case-study-${index}`,
      image: study?.image?.node?.mediaItemUrl || '',
      company: study?.company || '',
      location: study?.location || '',
      previewText: study?.previewText || '',
      caseStudyUrl: study?.caseStudyUrl || '',
    })) || [],
  };
}

function mapIcon(icon: string | undefined | null): FormulaIcon {
  switch (icon) {
    case "shield":
    case "lightning":
    case "person":
      return icon;
    default:
      return "shield"; // Default fallback
  }
}

function processFormulaSection(
  formulaSection: SpotlightProductDetails['formulaSection'] | SpotlightProductDetails['secondFormulaSection'] | SpotlightProductDetails['thirdFormulaSection'] | null | undefined,
  productName: string
): ProcessedFormulaSection {
  return {
    productName,
    titleFontSize: { base: 57.14, md: 82.29 },
    titleHelveticaWeight: 500,
    titleCustomStyles: { color: '#someColor' },
    items: [
      { icon: mapIcon(formulaSection?.firstItem?.icon), isOn: formulaSection?.firstItem?.isOn || false, text: formulaSection?.firstItem?.text || 'Protection' },
      { icon: mapIcon(formulaSection?.secondItem?.icon), isOn: formulaSection?.secondItem?.isOn || false, text: formulaSection?.secondItem?.text || 'Performance' },
      { icon: mapIcon(formulaSection?.thirdItem?.icon), isOn: formulaSection?.thirdItem?.isOn || false, text: formulaSection?.thirdItem?.text || 'Easy Use' },
    ],
    showTitle: formulaSection
      ? 'showtitle' in formulaSection 
        ? formulaSection.showtitle || false 
        : 'showTitle' in formulaSection 
          ? formulaSection.showTitle || false 
          : false
      : false,
  };
}

function addNbsp(text: string): string {
  // First, replace any existing &nbsp; with a unique placeholder
  const placeholder = '___NBSP___';
  text = text.replace(/&nbsp;/g, placeholder);

  // Split the text into words
  const words = text.split(/\s+/);
  
  // If there are at least two words, add &nbsp; between the last two
  if (words.length >= 2) {
    const lastWord = words.pop();
    text = words.join(' ') + placeholder + lastWord;
  }
  
  // Replace all placeholders with &nbsp;
  return text.replace(new RegExp(placeholder, 'g'), '&nbsp;');
}

function processBentoSection(bentoSection: SpotlightProductDetails['firstBentoSection'] | SpotlightProductDetails['secondBentoSection'] | SpotlightProductDetails['thirdBentoSection']): ProcessedBentoSection {
  return {
    heroSectionName: bentoSection?.heroSectionName || null,
    bentoItems: bentoSection?.bentoItems?.map(item => item ? {
      addonName: item.addonName || null,
      animationName: item.animationName?.[0] || null, // Take the first animation name
      caption: addNbsp(item.caption || ''),
      isAddon: item.isAddon || false,
      popup: item.popup ? {
        body: item.popup.body || null,
        heading: item.popup.heading || null,
        pageLink: item.popup.pageLink ? {
          nodes: item.popup.pageLink.nodes?.map(node => node ? {
            id: node.id,
            title: (node as any).title || null, // Use type assertion
            slug: (node as any).slug || null, // Use type assertion
          } : null) || null
        } : null
      } : null,
      span: item.span || 1, // Default to 1 if not provided
      title: item.title || null,
    } : null).filter((item): item is ProcessedBentoItem => item !== null) || []
  };
}

function processHowItWorksSection(howItWorksVideos: SpotlightProductDetails['howItWorksVideos']): ProcessedHowItWorksSection {
  return {
    videos: howItWorksVideos?.map((video, index) => ({
      id: `step-${index + 1}`,
      src: video?.video?.node?.mediaItemUrl || "",
      text: video?.text || `Step ${index + 1}`,
      editorialParts: video?.part?.filter((part): part is string => part !== null) || [`${index + 1}.`],
    })) || []
  };
}

function processBuySection(product: any, bentoSections: ProcessedBentoSection[]): ProcessedProduct {
  const bentoAddonMapping = createBentoAddonMapping(bentoSections);
  
  return {
    id: product?.databaseId?.toString() || '', // Use databaseId instead of id
    name: product?.name || '',
    price: product?.price || '',
    regularPrice: product?.regularPrice || '',
    salePrice: product?.salePrice || '',
    onSale: product?.onSale || false,
    featuredImage: {
      node: {
        altText: product?.featuredImage?.node?.altText || '',
        mediaItemUrl: product?.featuredImage?.node?.mediaItemUrl || '',
      }
    },
    addons: product?.addons?.filter((addon: any) => addon !== null) || [],
    bentoAddonMapping: bentoAddonMapping,
    supportCards: {
      firstSupportCard: {
        description: product?.supportCards?.firstSupportCard?.description || '',
        title: product?.supportCards?.firstSupportCard?.title || ''
      },
      secondSupportCard: {
        description: product?.supportCards?.secondSupportCard?.description || '',
        title: product?.supportCards?.secondSupportCard?.title || ''
      },
      thirdSupportCard: {
        description: product?.supportCards?.thirdSupportCard?.description || '',
        title: product?.supportCards?.thirdSupportCard?.title || ''
      },
      fourthSupportCard: {
        description: product?.supportCards?.fourthSupportCard?.description || '',
        title: product?.supportCards?.fourthSupportCard?.title || ''
      }
    }
  };
}

function processInTheBoxSection(product: any): ProcessedInTheBoxSection {
  return {
    items: product?.whatsinthebox?.item?.map((item: any) => ({
      text: item?.text || '',
      src: item?.image?.node?.mediaItemUrl || '',
      rowSpan: item?.rowSpan || 1,
      columnSpan: item?.columnSpan || 1,
      addon: item?.addon || false,
    })) || []
  };
}

function processTechSpecsSection(product: any): ProcessedTechSpecsSection {
  const techSpecs = product?.techSpecs?.ca || [];
  return {
    specCategories: techSpecs.map((category: any) => ({
      title: category?.title || '',
      specs: category?.specs?.filter((spec: any) => spec !== null) || [],
      fullWidth: category?.fullWidth || false,
      image: category?.image?.node?.mediaItemUrl || undefined
    }))
  };
}

function processFaqSection(product: SimpleProduct): ProcessedFaqSection {
  return {
    faqData: product.frequentlyAskedQuestions?.question?.map(item => ({
      question: item?.question || '',
      answer: item?.answer || ''
    })) || []
  };
}

function processCompareSection(product: SimpleProduct, productDisplayName: string): ProcessedCompareSection | null {
  const compareData = product.compareData?.comparisonGroup?.nodes?.[0] as ComparisonGroup | undefined;
  
  if (!compareData || !compareData.products || compareData.products.nodes.length === 0) {
    return null;
  }

  const compareProducts: ProcessedCompareProduct[] = compareData.products.nodes.map((node) => {
    const compareProduct = node as SimpleProduct;
    return {
      id: compareProduct.id || '',
      name: compareProduct.name || 'Unnamed Product',
      price: compareProduct.price || 'N/A',
      image: compareProduct.compareData?.image?.node?.mediaItemUrl || '',
      specs: {
        ...compareProduct.compareData?.specs?.accessoryItem?.reduce((acc, item) => {
          if (item?.name) {
            acc[`accessory_${item.name}`] = { value: item.value || null, name: item.name };
          }
          return acc;
        }, {} as Record<string, { value: string | null; name: string }>),
        ...compareProduct.compareData?.specs?.overviewItem?.reduce((acc, item) => {
          if (item?.name) {
            acc[`overview_${item.name}`] = { value: item.value || null, name: item.name };
          }
          return acc;
        }, {} as Record<string, { value: string | null; name: string }>),
        ...compareProduct.compareData?.specs?.upgradeItem?.reduce((acc, item) => {
          if (item?.name) {
            acc[`upgrade_${item.name}`] = { value: item.value || null, name: item.name };
          }
          return acc;
        }, {} as Record<string, { value: string | null; name: string }>),
      },
      slug: compareProduct.compareData?.name?.edges?.[0]?.node?.slug || '',
    };
  }) || [];

  return {
    products: compareProducts,
    categories: ['Overview', 'Accessory', 'Upgrade'],
    currentProduct: productDisplayName
  };
}

function processWizardCtaSection(wizardCtaSection: SpotlightProductDetails['wizardCtaSection']): ProcessedWizardCtaSection {
  return {
    bigText: wizardCtaSection?.bigText || '',
    buttonText: wizardCtaSection?.buttonText || 'Learn More',
    smallText: wizardCtaSection?.smallText || '',
    bigTextWidth: {
      sm: wizardCtaSection?.smbigtextwidth || 300,
      md: wizardCtaSection?.bigtextwidth || 630
    }
  };
}

function createBentoAddonMapping(bentoSections: ProcessedBentoSection[]): BentoAddonMapping {
  const mapping: BentoAddonMapping = {};

  bentoSections.forEach(section => {
    section.bentoItems.forEach(item => {
      if (item.isAddon && item.addonName) {
        mapping[item.addonName] = {
          title: item.title,
          caption: item.caption,
          animationName: item.animationName,
          bentoPopup: item.popup
        };
      }
    });
  });

  return mapping;
}

function processAddonsForInTheBox(addons: Maybe<Maybe<Addon>[]> | undefined): ProcessedAddon[] {
  // console.log('Addons:', JSON.stringify(addons, null, 2));
  // I think this is old and there's one processing for the addons that gets passed to this and buy section now
  
  return addons?.flatMap(addon => {
    if (addon && addon.__typename === 'AddonCheckbox' && Array.isArray(addon.options)) {
      return addon.options.flatMap(option => {
        if (option && typeof option.label === 'string') {
          return {
            name: option.label,
            price: typeof option.price === 'number' ? option.price : null
          };
        }
        return [];
      });
    }
    return [];
  }) ?? [];
}