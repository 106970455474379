import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

interface PersonIconProps {
  isOn: boolean;
  size?: {
    base: number;
    md?: number;
  };
}

const IconSVG = styled.svg<PersonIconProps>`
  fill: #01010E;
  transition: filter 0.3s ease;
  
  width: ${props => props.size?.base || 50}px;
  height: ${props => (props.size?.base || 50) * (200/166)}px;

  @media (min-width: ${breakpoints.md}px) {
    width: ${props => props.size?.md || 166}px;
    height: ${props => (props.size?.md || 166) * (200/166)}px;
  }
  
  ${({ isOn }) => isOn ? `
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.33));
  ` : `
    filter: none;
  `}

  path, circle {
    stroke-width: 2px;
    transition: stroke 0.3s ease;
  }
`;

export const PersonIcon: React.FC<PersonIconProps> = ({ isOn, size }) => {
  return (
    <IconSVG 
      isOn={isOn}
      size={size}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 166 200"
    >
      <defs>
        <linearGradient id="personStrokeGradientOn" x1="83" y1="0" x2="83" y2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.88" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient id="personStrokeGradientOff" x1="83" y1="0" x2="83" y2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.16" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.13" />
        </linearGradient>
      </defs>
      <path 
        d="M164.22 142.292C164.22 157.446 128.234 198 82.61 198C36.9858 198 1 157.446 1 142.292C1 127.138 36.9858 114.043 82.61 114.043C128.234 114.043 164.22 127.138 164.22 142.292Z"
        stroke={isOn ? "url(#personStrokeGradientOn)" : "url(#personStrokeGradientOff)"}
      />
      <circle 
        cx="83.2303" 
        cy="49.6894" 
        r="48.6894"
        stroke={isOn ? "url(#personStrokeGradientOn)" : "url(#personStrokeGradientOff)"}
      />
    </IconSVG>
  );
};