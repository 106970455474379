import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Image from 'next/image';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const LoadingBar = styled.div`
  width: 100%;
  max-width: 200px;
  height: 4px;
  background: #1a1a1a;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  margin: 12px 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: ${shimmer} 1.5s infinite;
  }
`;

const LoadingText = styled.p`
  color: #FFF;
  font-family: Arial, sans-serif;
  font-size: 14px;
  animation: ${pulse} 1.5s infinite ease-in-out;
  text-align: center;
`;

interface LoadingProps {
    message?: string;
}

export default function Loading({ message = 'Loading...'}: LoadingProps) {
  return (
    <LoadingWrapper>
      <Image
        src="/icons/tokenworks-logo.svg" 
        alt="TokenWorks Logo"
        width={157}
        height={26.283}
      />
      <LoadingBar />
      <LoadingText>{message}</LoadingText>
    </LoadingWrapper>
  );
}