// src/components/landing/FakeIDSection/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import Section from '@/components/layout/Base/Section';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints';
import SectionTitle from './SectionTitle';
import CrisisIntroSection from './CrisisIntro'
import SolutionsStackSection from './SolutionsStack';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import Students from '@/components/landing/FakeIdSections/Students'
import NewspaperSection from './NewspaperSection';
import SegwayTextSection from './SegwayTextSection'
import Id from './Id';


// TODO:
//  1. Fix: svg for newspaper just kinda gets cut-off instead of scaleing
//  2. Add intersection observer to solutions hover (probably needs mobile images first)

const FakeIdSections: React.FC = () => {
  const solutions = [
    {
      color: "#01010E",
      name: "Barcode Detective",
      description: "Over the past 25 years, through rigorous innovation and iteration, we've built barcode detective - a sophisticated algorithm that catches fakes.",
      backgroundImage: "/images/landing-barcode-detective-background.jpg"
    },
    {
      color: "#3A71FF",
      name: "DMV Check",
      description: "The ability to cross reference an id with state DMV databases with the tap of a button.",
      backgroundImage: "/images/landing-dmv-check-background.jpg"
    },
    {
      color: "#ED0000",
      name: "Multiple Forensic Checks",
      description: "And with our most advanced system, identifake, multiple forensic checks across thousands of datapoints.",
      backgroundImage: "/images/landing-multiple-forensic-checks-background.jpg"
    }
  ];


  return (
    <>
      <SectionTitle
        title="What about Fake&nbsp;IDs?"
        subtitle="Unfortunately, there's a $400 Million Per Year Black Market Fake&nbsp;ID&nbsp;Industry"
      />
        <Id/>

        <CrisisIntroSection/>

        <SectionSpacer size='medium' />

        <Students/>

        <SectionSpacer size='medium' />

        <NewspaperSection></NewspaperSection>

        <SectionSpacer size='medium' />

        <SegwayTextSection></SegwayTextSection>

        <SolutionsStackSection solutions={solutions} />
      
        <SectionSpacer size='small' />
    </>
  )
}

export default FakeIdSections;