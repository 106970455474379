// src/components/ReviewsSection/SectionTitle.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'
import QAComponent from './QAComponent';  // Import the new component


const Title = styled.h1`
    line-height: 1.3;
    padding-bottom: 23px;
    
    @media (min-width: ${breakpoints.md}px) {
    padding-bottom: 20px;
    }
`

const SectionTitleContainer = styled(Container)`
    text-align: center;
    margin-bottom: 80px;
    width: 100%;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 116px;
    }
`

const TitleBlur = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(20px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 230.96%);
    width: 250px;
    height: 130px;
    z-index: -1;

    @media (min-width: ${breakpoints.md}px) {
        width: 600px;
        height: 80px;
        filter: blur(40px);
    }
`
export const SectionTitle: React.FC = () => {
    return (
      <SectionTitleContainer>
        <Title >Common Questions</Title>
        <TitleBlur></TitleBlur>
        <h5 className="sans">We have you covered</h5>
      </SectionTitleContainer>
    );
  };
  
  export default SectionTitle;