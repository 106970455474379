// ./wp-templates/page.tsx
import { useState, useEffect } from 'react';
import { FaustTemplate, useFaustQuery } from "@faustwp/core";
import { gql } from '@apollo/client';
import { SeedNode } from "@faustwp/core/dist/cjs/queries/seedQuery";
import Section from '@/components/layout/Base/Section';
import Container from '@/components/layout/Base/Container';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import { GetPageDocument, GetPageQuery } from '@/generated/graphql';
import { Seo } from '@/components/layout/Base/Seo';

const Title = styled.h1`
    margin-bottom: 4rem;
`

const StyledArticle = styled.article`
  max-width: 800px;
  margin: 0 auto;
  letter-spacing: 1;


  h1 {
    margin-bottom: 2rem;
    letter-spacing: 1;
    font-size: 30px;
  }

  h2, h3, h4, h5, h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
      letter-spacing: 1;
  }

  h2 {
      font-size: 24px;
    }

  h3 {
      font-size: 18px;
    }

  p {
    margin-bottom: 1.5rem;
    letter-spacing: 1;
    font-size: 16px;
  }

  ul, ol {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    font-size: 16px;
    font-family: 'PP Neue Montreal';
      letter-spacing: 1;
  }

  li {
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-family: 'PP Neue Montreal';
      letter-spacing: 1;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 2rem 0;
    border-radius: 8px;
      letter-spacing: 1;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid rgb(var(--token-blue));
    padding-left: 1rem;
    margin: 2rem 0;
    color: rgb(var(--token-gray));
      letter-spacing: 1;
  }

  pre {
    background-color: rgba(var(--dark-gray), 0.8);
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin: 2rem 0;
      letter-spacing: 1;
  }

  code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.9em;
      letter-spacing: 1;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    letter-spacing: 1;
    font-family: 'PP Neue Montreal';
    font-size: 16px
  }

  th, td {
    border: 1px solid rgba(var(--token-gray), 0.3);
    padding: 0.5rem;
    text-align: left;
    letter-spacing: 1;
    font-family: 'PP Neue Montreal';
  }

  th {
    background-color: rgba(var(--token-blue), 0.1);
  }

  a {
    color: white;
  }
`;

interface ComponentProps {
  loading?: boolean;
  databaseId: string;
}

const Component: FaustTemplate<GetPageQuery> = (props) => {
  if (props.loading) return <>Loading...</>; // Loading state for previews
  if (!props.data) return <>No data found</>;

  const content = props.data.page?.content;

  return (
    <>
      <Seo data={props.data.page?.seo}/>
      <Section>
          <Container>
              <SectionSpacer size='small'/>
                  <StyledArticle>
                      <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
                  </StyledArticle>
              <SectionSpacer size='large'/>
          </Container>
      </Section>
    </>
  );
}


Component.query = GetPageDocument;

Component.variables = (seedQuery, context) => {
  return {
    databaseId: seedQuery?.databaseId,
    asPreview: context?.asPreview,
  };
};

export default Component;