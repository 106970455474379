import dynamic from 'next/dynamic';
import BentoItem, { BentoVariantType } from '../spotlight/bento/BentoItem';
import { ProcessedBentoItem } from '@/utils/spotlightDataProcessor';

const AnimationComponents: { [key: string]: React.ComponentType<any> } = {
    AntiPassback: dynamic(() => import('@/components/spotlight/bento/AntiPassback/Animation')),
    BatteryLife:  dynamic(() => import('@/components/spotlight/bento/BatteryLife/Animation')),
    ClearAlerts: dynamic(() => import('@/components/spotlight/bento/ClearAlerts/Animation')),
    ClearMobileInterface: dynamic(() => import('@/components/spotlight/bento/ClearMobileInterface/Animation')),
    Cradle: dynamic(() => import('@/components/spotlight/bento/Cradle/Animation')),
    CRM: dynamic(() => import('@/components/spotlight/bento/CRM/Animation')),
    Durable: dynamic(() => import('@/components/spotlight/bento/Durable/Animation')),
    ExpiredIdFlags: dynamic(() => import('@/components/spotlight/bento/ExpiredIdFlags/Animation')),
    ExtendedWarrentyThree: dynamic(() => import('@/components/spotlight/bento/ExtendedWarrentyThree/Animation')),
    ExtendedWarrentyFour:dynamic(() => import('@/components/spotlight/bento/ExtendedWarrentyFour/Animation')),
    ExtraBattery: dynamic(() => import('@/components/spotlight/bento/ExtraBattery/Animation')),
    FakeDetection: dynamic(() => import('@/components/spotlight/bento/FakeDetection/Animation')),
    FindMyDevice: dynamic(() => import('@/components/spotlight/bento/FindMyDevice/Animation')),
    FreeUpdates: dynamic(() => import('@/components/spotlight/bento/FreeUpdates/Animation')),
    HapticAlerts: dynamic(() => import('@/components/spotlight/bento/HapticAlerts/Animation')),
    InstantScans: dynamic(() => import('@/components/spotlight/bento/InstantScans/Animation')),
    MultiDeviceSync: dynamic(() => import('@/components/spotlight/bento/MultiDeviceSync/Animation')),
    Passports: dynamic(() => import('@/components/spotlight/bento/Passports/Animation')),
    PhotoCapture: dynamic(() => import('@/components/spotlight/bento/PhotoCapture/Animation')),
    UnderageAlerts: dynamic(() => import('@/components/spotlight/bento/UnderageAlerts/Animation')),
    VIPBanned: dynamic(() => import('@/components/spotlight/bento/VIPBanned/Animation')),
    Placeholder: dynamic(() => import('@/components/spotlight/bento/PlaceholderAnimation/Animation')),
    Stand: dynamic(() => import('@/components/spotlight/bento/Stand/Animation')),
    AvMagStripe: dynamic(() => import('@/components/spotlight/bento/AvMagStripe/Animation')),
    Large7Touchscreen: dynamic(() => import('@/components/spotlight/bento/Large7Touchscreen/Animation')),
};

interface FlexibleBentoItem {
    isAddon?: boolean;
    addonName?: string;
    animationName?: string;
    caption?: string;
    popup?: {
      body: string;
      heading: string;
      pageLink: {
        nodes: Array<{
          id: string;
          title: string;
          slug: string;
        }>;
      } | null;
    };
    span?: number;
    title?: string;
}

interface RenderBentoProps {
    bentoName: string;
    data?: FlexibleBentoItem;
    format?: BentoVariantType;
    popup?: boolean;
}

export default function RenderBento({ bentoName, data, format, popup = false }: RenderBentoProps) {
    const AnimationComponent = AnimationComponents[bentoName];

    //Must use rigid ProcessedBentoItem type, but put fallbacks here :')
    const flexibleBentoData: ProcessedBentoItem = {
        isAddon: data?.isAddon || false,
        addonName: data?.addonName || '',
        animationName: data?.animationName || '',
        caption: data?.caption || '',
        popup: {
            body: data?.popup?.body || '',
            heading: data?.popup?.heading || '',
            pageLink: data?.popup?.pageLink || null,
        },
        span: data?.span || 1,
        title: data?.title || '',
    };
    
    return (
        <BentoItem
            data={flexibleBentoData}
            AnimationComponent={AnimationComponent}
            format={format}
            popup={popup}
        />
    );
}