// components/animations/FadeShrinkScroll.tsx
import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { NAV_HEIGHT } from '../layout/Layout';
import { css } from '@emotion/react';
import breakpoints from '@/styles/breakpoints';

gsap.registerPlugin(ScrollTrigger);

const ScrollContainer = styled.div<VariantProps>`
  margin-top: calc(-1 * ${NAV_HEIGHT});
  position: relative;
  height: 150vh;
  margin-bottom: -50vh;
  /* background-color: blue; */

  ${props => props.variant === 'compact' && css`
    margin-top: -10vh;
    margin-bottom: -60vh;

    @media (min-width: ${breakpoints.md}px) {
      margin-bottom: -70vh;
    }
  `}
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* background-color: red; */
`;

//full is more spatious, compact has more overlap
// both are designed to be used with 100(s)vh sections
type Variant = 'full' | 'compact';

interface VariantProps {
  variant: Variant;
}

interface ScrollEffectProps {
    children: React.ReactNode;
    variant?: Variant;
}

export default function({ children, variant = 'full' }: ScrollEffectProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const coverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && coverRef.current) {

      // wait for apge to load
      window.addEventListener('load', () => {
        ScrollTrigger.refresh();
      });

      const animation = gsap.to(coverRef.current, {
        opacity: 0,
        scale: 0.9,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top top',
          // end: variant === 'compact' ? '35% top' : '50% top',
          end: '50% top',
          scrub: 0.5,
        },
      });

      // Neccisary for gsap to place the correct scroll triggers after load
      setTimeout(() => ScrollTrigger.refresh(), 100);

      return () => {
        animation.kill();
        animation.scrollTrigger?.kill();
      };
    }
  }, []);

  return (
    <ScrollContainer ref={containerRef} variant={variant}>
      <StickyContainer ref={coverRef}>
        {children}
      </StickyContainer>
    </ScrollContainer>
  );
};