// src/components/spotlight/sections/ReviewsSection/ReviewsCard/ReviewStars.tsx
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints'


interface ReviewStarsProps {
  rating: number;
}

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StarSvg = styled.svg`
  width: 14.72px;
  height: 14px;
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 21px;
    height: 21px;
    margin-right: 3px;
  }
`;

const Star: React.FC<{ filled: boolean }> = ({ filled }) => (
  <StarSvg viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 1.33624L12.4647 7.38292L12.5574 7.66824H12.8574H19.2153L14.0716 11.4053L13.8289 11.5816L13.9216 11.867L15.8863 17.9136L10.7427 14.1766L10.5 14.0002L10.2573 14.1766L5.11369 17.9136L7.07837 11.867L7.17108 11.5816L6.92837 11.4053L1.78476 7.66824H8.14261H8.44262L8.53532 7.38292L10.5 1.33624Z"
      fill={filled ? "#D72422" : "none"}
      stroke="#D72422"
      strokeWidth="0.825843"
    />
  </StarSvg>
);

export const ReviewStars: React.FC<ReviewStarsProps> = ({ rating }) => {
  return (
    <StarsContainer>
      {[1, 2, 3, 4, 5].map((starNumber) => (
        <Star key={starNumber} filled={starNumber <= rating} />
      ))}
    </StarsContainer>
  );
};