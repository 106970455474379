// NavIconsContainer.tsx
import React from 'react';
import styled from '@emotion/styled';
import { NavIcon } from '@/components/layout/NavBar/NavIcon';
import breakpoints from '@/styles/breakpoints';
import { CartIcon } from './CartIcon';
import useViewport from '@/utils/hooks/useViewport';

const Wrapper = styled.div`
  display: inline-flex;
  height: 60px;
  padding: 0 20px 0 0;
  justify-content: flex-end;
  align-items: center;
  gap: 17px;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.md}px) {
    gap: 20px;
    padding: 0 20px 0 0;  // Remove top padding
    align-items: flex-start;  // Align items to the top
    padding-top: 21px;  // Add top padding to align icons
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (min-width: ${breakpoints.md}px) {
    height: auto;  // Remove full height on desktop
  }

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
  }
`;

export const NavIconsContainer: React.FC = () => {
  const { isMobile } = useViewport();

  return (
    <Wrapper>
      {!isMobile && (
        <IconWrapper>
          <NavIcon icon="/icons/wrench-icon.svg" alt="Support" href="https://support.tokenworks.com/portal/en/kb/tokenworks" />
        </IconWrapper>
      )}
      <IconWrapper>
        <NavIcon icon={CartIcon} alt="Cart" href="/cart" isCart={true} />
      </IconWrapper>
      <IconWrapper>
        <NavIcon icon="/icons/account-icon.svg" alt="Account" href="/my-account" />
      </IconWrapper>
    </Wrapper>
  );
};