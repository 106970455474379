// utils/imageUtils.ts

// Define an array of default images
const defaultImages = [
    '/images/default-post-1.png',
    '/images/default-post-2.png',
    '/images/default-post-3.png',
    '/images/default-post-4.png',
    '/images/default-post-5.png'
  ];
  
  // Function to choose a default image based on the post ID
  export const getDefaultImageForPost = (postId: string): string => {
    // Create a simple hash of the post ID
    const hash = postId.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
  
    // Use the hash to select an image from the array
    return defaultImages[Math.abs(hash) % defaultImages.length];
  };

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export { shimmer, toBase64 };