//components/spotlight/sections/CompareSection/DesktopCompareSection.tsx
import React from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import ProductName from '@/components/common/ProductName';
import Button from '@/components/common/Button';
import breakpoints from "@/styles/breakpoints";
import Container from "@/components/layout/Base/Section";
import { blurDataURL } from '@/styles/blurDataUrl';


// Interfaces
interface Product {
  id: string;
  name: string;
  price: string;
  image: string;
  specs: {
    [key: string]: {
      value: string | null;
      name: string;
    };
  };
  slug: string;
}

interface CompareSectionProps {
  products: Product[];
  categories: string[];
  currentProduct: string;
  onBuyClick: () => void;
  onLearnMoreClick: (slug: string) => void; 
}

const CompareContainer = styled.div`
  display: none;
  color: #fff;
  
  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.16);
    background: #000;
    width: 900px;
    padding: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-shrink: 0;
  }
`;

const Title = styled.h4`
  font-family: 'PP Neue Montreal', sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.92);
  line-height: 1.26;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(3, 1fr);
  width: 100%;
`;

const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 10px;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;
`;

const ProductImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const ButtonWrapper = styled.div`
`;

const CategoriesContainer = styled.div`
  width: 100%;
`;

const CategoryTitle = styled.h5`
  color: rgba(255, 255, 255, 0.92);
  font-family: 'PP Neue Montreal', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.36px;
  margin-top: 40px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  grid-column: 1 / -1;
`;

const SpecRow = styled.div`
  display: contents;

  &:last-child > div {
    border-bottom: none;
  }
`;

const SpecCell = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;  
`;

const SpecNameCell = styled(SpecCell)`
  justify-content: flex-start;
`;

const SpecValue = styled.div<{ isDefault: boolean }>`
  color: ${props => props.isDefault ? '#5E5E5E' : 'rgba(255, 255, 255, 0.92)'};
  font-family: 'PP Neue Montreal', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.32px;
`;

const SpecName = styled.div`
  color: #5E5E5E;
  font-family: 'PP Neue Montreal', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.24px;
  text-align: left;
`;

// Main component
const DesktopCompareSection: React.FC<CompareSectionProps> = ({ products, categories, currentProduct, onBuyClick, onLearnMoreClick }) => {
  const displayedProducts = products.slice(0, 3);

  return (
    <Container>
    <CompareContainer>
      <GridContainer>
        <Title>ID Scanners</Title>
        {displayedProducts.map((product) => (
          <GridCell key={product.name}>
            <ProductName 
              name={product.name}
              fontSize={{ base: 28 }}
              helveticaWeight={500}
              as={'h4'}
            />
          </GridCell>
        ))}

          <GridCell /> {/* Empty cell for alignment */}
          {displayedProducts.map((product) => (
            <GridCell key={`${product.name}-image`}>
              <ImageContainer>
                <ProductImage 
                  src={product.image} 
                  alt={product.name} 
                  width={300} 
                  height={300} 
                  placeholder='blur'
                  blurDataURL={blurDataURL}
                />
              </ImageContainer>
            </GridCell>
          ))}

          <GridCell /> {/* Empty cell for alignment */}
          {displayedProducts.map((product) => (
          <GridCell key={`${product.name}-image`}>
          <ButtonWrapper>
                <Button 
                  variant="nav"
                  onClick={
                    product.name === currentProduct 
                      ? onBuyClick 
                      : () => onLearnMoreClick(product.id)
                  }
                >
                  {product.name === currentProduct ? "Buy Now" : "Learn More"}
                </Button>
              </ButtonWrapper>
          </GridCell>
          ))}


        {categories.map((category) => (
          <React.Fragment key={category}>
            <CategoryTitle>{category}</CategoryTitle>
            {Object.entries(displayedProducts[0].specs)
              .filter(([key]) => key.startsWith(category.toLowerCase()))
              .map(([key, spec]) => (
                <SpecRow key={key}>
                  <SpecNameCell>
                    <SpecName>{spec.name}</SpecName>
                  </SpecNameCell>
                  {displayedProducts.map((product) => (
                    <SpecCell key={`${product.name}-${key}`}>
                      <SpecValue isDefault={!product.specs[key].value}>
                        {product.specs[key].value || 'X'}
                      </SpecValue>
                    </SpecCell>
                  ))}
                </SpecRow>
              ))}
          </React.Fragment>
        ))}
      </GridContainer>
    </CompareContainer>
    </Container>
  );
};

export default DesktopCompareSection;