// @/components/landing/Hero
import React from 'react'; 
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Container from '@/components/layout/Base/Container';
import Section from '@/components/layout/Base/Section';
import Image from 'next/image';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';

import Content from './Content';
import Images from './Images';
import SocialProof from './SocialProof'

// TODO
// 3.  Add animations to Images (Images.tsx)
// 4.  Make stars accept props and size based on breakpoint (StarsMiniProof.tsx)
// 5.  Make checkmarks accept props and size based on breakpoint (ValueLine.tsx)

// const White = styled.div`
//     background: white;
// `;

interface HeroProps {
    isLight?: boolean;
}

interface ThemeProps {
    isLight?: boolean;
  }
  
const HybridSection = styled(Section)<ThemeProps>`
    background: ${props => props.isLight ? 'white' : 'none'};
    color: ${props => props.isLight ? 'black' : 'white'};

    h1, h2, h3, p {
        color: ${props => props.isLight ? 'black' : 'white'};
    }
`;


const HeroContainer = styled.div`
    gap: 20px;
    height: 100%;
    padding-top: 10vh;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;

    @media (min-width: ${breakpoints.md}px) {
        gap: 40px;
        flex-direction: row;
        align-items: center;
    }
`;


const Hero: React.FC<HeroProps> = ({ isLight }) => {

  return (
    <HybridSection isLight={isLight}>
         <Container>
                <HeroContainer>
                    <Content/>
                    <Images/>
                </HeroContainer>
                <SocialProof isLight={isLight}/>
        </Container>
      </HybridSection>
  );
};

export default Hero;