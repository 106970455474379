import React from 'react';
import PlaceholderAnimation from '@/components/spotlight/bento/PlaceholderAnimation/Animation';

interface AnimationWrapperProps {
  AnimationComponent: React.ComponentType<any>;
  isAnimating: boolean;
}

const AnimationWrapper: React.FC<AnimationWrapperProps> = ({ AnimationComponent, isAnimating }) => {
  if (AnimationComponent === PlaceholderAnimation) {
    return <PlaceholderAnimation />;
  }
  return <AnimationComponent isAnimating={isAnimating} />;
};

export default AnimationWrapper;