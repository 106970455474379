// src/components/landing/IdInsightsPreviewSection/LibraryTitle.tsx

import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints'
import { UnderlineRed } from '@/components/common/UnderlineRed';


const SectionTitleContainer = styled.div`
    text-align: center;
    margin-bottom: 20px;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 90px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const TitleTextWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
`;

const Title = styled.h1`
    font-family: 'PP Editorial New';
    font-weight: 200;
    line-height: .7; // Remove the default line-height
    z-index: 2;
`;


const Id = styled.span`
    font-weight: normal;
    font-style: italic;
    margin-right: .4rem;
`;

const TitleBlur = styled.svg`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    fill: rgba(215, 36, 34, 0.22);
    width: 258px;
    height: 156px;
    margin-top: -55px;
    z-index: -1;
    filter: blur(50px);
`;

const Subtitle = styled.h5`
    margin-top: 38px;
    margin-bottom: 60px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
    }
`;

export const LibraryTitle = () =>  {
    return (
    <>
    <SectionTitleContainer>
        <TitleWrapper>
            <TitleTextWrapper>
                <Title><Id>id</Id>Insights</Title>
                <UnderlineRed isHovered={true} />
                <TitleBlur>
                    <path d="M258 106.131C258 138.263 200.245 156 129 156C57.7553 156 0 138.263 0 106.131C0 73.999 57.7553 0 129 0C200.245 0 258 73.999 258 106.131Z" />
                </TitleBlur>
            </TitleTextWrapper>
        </TitleWrapper>
            <Subtitle className="sans">Dependable Source of Identity Verification Information</Subtitle>
    </SectionTitleContainer>
    </>
    );
  };
  
  export default LibraryTitle;