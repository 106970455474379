// components/BuySection/AddOnsList.tsx
import React, { useState, Suspense } from 'react';
import styled from '@emotion/styled';
import { useAddOns } from '@/providers/AddOnsProvider';
import { AddOn } from './AddOn';
import BentoPopup from '@/components/spotlight/bento/BentoPopup';
import BuyBentoPopupItem  from './BuyBentoPopupItem';
import { BentoAddonMapping } from './types';

const AddOnsContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  font-family: 'PP Neue Montreal', sans-serif;
`;

const AddOnsAndPrice = styled.div`
  width: 100%;
`;

interface AddOnsListProps {
  bentoAddonMapping: BentoAddonMapping;
}

export const AddOnsList: React.FC<AddOnsListProps> = ({ bentoAddonMapping }) => {
  const { state } = useAddOns();
  const [openPopup, setOpenPopup] = useState<string | null>(null);
  const [hoveredAddOn, setHoveredAddOn] = useState<string | null>(null);
  const [hoveredInfo, setHoveredInfo] = useState<string | null>(null);

  const handleInfoClick = (addonName: string) => {
    if (bentoAddonMapping[addonName]) {
      setOpenPopup(addonName);
    } else {
      console.log("Info for:", addonName);
    }
  };

  return (
    <>
      <AddOnsAndPrice>
        <AddOnsContainer>
          {state.addOns.map((addon) => (
            <AddOn
              key={addon.id}
              addon={addon}
              onInfoClick={() => handleInfoClick(addon.name)}
              hoveredAddOn={hoveredAddOn}
              hoveredInfo={hoveredInfo}
              onHoverAddOn={setHoveredAddOn}
              onHoverInfo={setHoveredInfo}
            />
          ))}
        </AddOnsContainer>
      </AddOnsAndPrice>

      {openPopup && bentoAddonMapping[openPopup] && (
        <BentoPopup
          content={bentoAddonMapping[openPopup].bentoPopup}
          isOpen={true}
          onClose={() => setOpenPopup(null)}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <BuyBentoPopupItem
              data={{
                title: bentoAddonMapping[openPopup].title,
                caption: bentoAddonMapping[openPopup].caption,
                isAddon: true,
                addonName: openPopup,
              }}
              animationName={bentoAddonMapping[openPopup].animationName}
            />
          </Suspense>
        </BentoPopup>
      )}
    </>
  );
};