// components/common/Checkbox/CheckboxSvg.tsx
import React from 'react';
import styled from '@emotion/styled';

interface CheckboxSvgProps {
  checked: boolean;
  included: boolean;
  isHovered?: boolean;
  className?: string;
}

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
`;

const CheckboxSvg: React.FC<CheckboxSvgProps> = ({ 
  checked, 
  included, 
  isHovered,
  className 
}) => {
  const rectX = (5.99219 / 32) * 100;
  const rectWidth = (20.0158 / 32) * 100;
  const rectRadius = (4 / 32) * 100;
  const strokeWidth = (1.77303 / 32) * 100;

  const checkmarkPath = "M14.7837 19.2091C14.692 19.1955 14.6038 19.1535 14.5331 19.0832L11.494 16.0596C11.318 15.8845 11.3172 15.5998 11.4924 15.4237L11.8469 15.0673C12.0221 14.8913 12.3068 14.8906 12.4828 15.0657L14.9113 17.4818L19.3864 13.0067C19.562 12.8311 19.8467 12.8311 20.0223 13.0067L20.3267 13.3111C20.5023 13.4867 20.5023 13.7714 20.3267 13.947L15.1879 19.0857C15.0781 19.1956 14.9255 19.2367 14.7837 19.2091Z";

  let fillColor, strokeColor, checkmarkColor;

  if (included) {
    fillColor = "rgba(58, 113, 255, 0.5)";
    strokeColor = "#3A71FF";
    checkmarkColor = "white";
  } else if (checked) {
    fillColor = "#3A71FF";
    strokeColor = "white";
    checkmarkColor = "white";
  } else {
    fillColor = "rgba(255, 255, 255, 0.92)";
    strokeColor = "#3A71FF";
    checkmarkColor = "rgba(58, 113, 255, 0.33)";
  }

  return (
    <StyledSvg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x={`${rectX}%`}
        y={`${rectX}%`}
        width={`${rectWidth}%`}
        height={`${rectWidth}%`}
        rx={`${rectRadius}%`}
        fill={fillColor}
      />
      <rect
        x={`${rectX}%`}
        y={`${rectX}%`}
        width={`${rectWidth}%`}
        height={`${rectWidth}%`}
        rx={`${rectRadius}%`}
        stroke={strokeColor}
        strokeWidth={`${strokeWidth}%`}
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={checkmarkPath}
        fill={checkmarkColor}
      />
    </StyledSvg>
  );
};

export default CheckboxSvg;