// src/components/landing/FakeIdSection/SegwayTextSection.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'
import Section from '@/components/layout/Base/Section';

const Title = styled.h1`
    font-size: 32px;
    line-height: 1.3;
    padding-bottom: 23px;
    
    @media (min-width: ${breakpoints.md}px) {
      font-size: 72px;
      padding-bottom: 20px;
    }
`

const SectionTitleContainer = styled(Container)`
    text-align: center;
    margin-bottom: 80px;
    width: 100%;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 116px;
    }
`

const TitleBlur = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(20px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 230.96%);
    width: 250px;
    height: 130px;
    z-index: -1;

    @media (min-width: ${breakpoints.md}px) {
        width: 600px;
        height: 80px;
        filter: blur(40px);
    }
`

export const EditorialLight = styled.span`
    font-family: 'PP Editorial New';
    font-weight: 200;
    margin-right: -.1rem;

`

export const Editorialitalics = styled.span`
    font-family: 'PP Editorial New';
    font-style: italic; 
`


  export const SegwayTextSection: React.FC = () => {
    return (
    <Section>
      <SectionTitleContainer>
        <Title>Don't Let Fake ID's Become&nbsp;Real&nbsp;Problems</Title>
        <TitleBlur></TitleBlur>
        <h5 className="sans">Here's how to stop them, before they stop&nbsp;you.</h5>
      </SectionTitleContainer>
      </Section>
    );
  };
  
  export default SegwayTextSection;