// components/BuySection/AddOn.tsx
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { useAddOns } from '@/providers/AddOnsProvider';
import breakpoints from '@/styles/breakpoints';
import Checkbox from '@/components/common/Checkbox';
import { Addon as AddOnType } from '@/services/woocommerce/product/types';

const AddOnItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;
`;

const AddOnText = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.92);
`;

const AddOnPrice = styled.span`
  font-size: 14px;
  color: #86868B;
  margin-left: 10px;
  margin-right: 40px;
`;

const InfoIconContainer = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  * {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 20px;
    height: 20px;
  }
`;

const InfoIconSvg: React.FC<{ isHovered: boolean }> = ({ isHovered }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" width="100%" height="100%">
    <circle cx="10" cy="10" r="9.625" fill="#0F0F0F" stroke={isHovered ? "white" : "#86868B"} strokeWidth="0.75"/>
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fill={isHovered ? "white" : "#86868B"}
      fontSize="14"
      fontFamily="Arial, sans-serif"
    >
      i
    </text>
  </svg>
);

interface AddOnProps {
  addon: AddOnType;
  onInfoClick: () => void;
  hoveredAddOn: string | null;
  hoveredInfo: string | null;
  onHoverAddOn: (id: string | null) => void;
  onHoverInfo: (id: string | null) => void;
}

export const AddOn = memo(({ 
  addon,
  onInfoClick,
  hoveredAddOn,
  hoveredInfo,
  onHoverAddOn,
  onHoverInfo
}: AddOnProps) => {
  const { isSelected, isFree, toggleAddOn } = useAddOns();
  
  const handleChange = React.useCallback(() => {
    if (!isFree(addon.name)) {
      toggleAddOn(addon.name);
    }
  }, [addon.name, isFree, toggleAddOn]);

  const isAddonSelected = isSelected(addon.name);
  const isAddonFree = isFree(addon.name);

  return (
    <AddOnItem>
      <CheckboxContainer
        onMouseEnter={() => onHoverAddOn(addon.id)}
        onMouseLeave={() => onHoverAddOn(null)}
      >
        <Checkbox
          checked={isAddonSelected}
          onChange={handleChange}
          included={isAddonFree}
          isHovered={hoveredAddOn === addon.id}
          name={addon.name}
        />
      </CheckboxContainer>
      <AddOnText>{addon.name}</AddOnText>
      {!isAddonSelected && addon.price !== null && (
        <AddOnPrice>+ ${addon.price.toFixed(2)}</AddOnPrice>
      )}
      <InfoIconContainer
        onClick={onInfoClick}
        onMouseEnter={() => onHoverInfo(addon.id)}
        onMouseLeave={() => onHoverInfo(null)}
      >
        <InfoIconSvg isHovered={hoveredInfo === addon.id} />
      </InfoIconContainer>
    </AddOnItem>
  );
});

AddOn.displayName = 'AddOn';