// @/faust-plugins/WooSessionManagerPlugin/utils.ts
import { WooSessionCookieResponse } from "@/pages/api/wooSessionCookie";

const ENDPOINT = '/api/wooSessionCookie'

export async function getWooSessionToken(): Promise<WooSessionCookieResponse> {
    return fetch(ENDPOINT).then(r => r.json());
}

export async function setWooSessionToken(token: string): Promise<WooSessionCookieResponse> {
    return fetch(ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
    }).then(r => r.json());
}

export async function removeWooSessionToken(): Promise<WooSessionCookieResponse> {
    return fetch(ENDPOINT, {
        method: 'DELETE'
    }).then(r => r.json());
}