import { GetCartQuery } from "@/generated/graphql";
import { QlmCreds } from "@/services/qlm";

export type CartData = GetCartQuery;

export const emptyCart = {
    cart: {
        contents: {
            itemCount: 0,
            nodes: []
        },
        totalTax: "$0.00",
        total: "$0.00",
        subtotalTax: "$0.00",
        subtotal: "$0.00",
        shippingTotal: "$0.00",
        shippingTax: "$0.00",
    }
}

//This is the type for cartItems from processCartItems
export interface LocalCartItem {
    id: string;
    name: string;
    price: number;
    quantity: number;
    imageSrc: string;
    maxQuantity: number;
    variationName?: string;
    addOns?: AddonItem[];
    qlmCreds?: QlmCreds;
}

interface AddonItem {
  name: string;
  value: string;
  price: number;
  field_name: string;
  field_type: string;
  id: string;
  price_type: string;
}