// UnderlineRed.tsx
import React from 'react';
import styled from '@emotion/styled';

interface UnderlineRedProps {
  isHovered: boolean;
  isActive?: boolean;
}

const UnderlineRedWrapper = styled.div<UnderlineRedProps>`
  width: 100%;
  height: 2px;
  flex-shrink: 0;
  border-radius: 999px;
  background: ${({ isHovered, isActive }) => 
    (isHovered || isActive) ? '#D72422' : 'rgba(215, 36, 34, 0.2)'};
  box-shadow: ${({ isHovered, isActive }) =>
    (isHovered || isActive)
      ? '0px 2px 6px 0px #ED0000, 0px -3px 6px 1px rgba(237, 0, 0, 0.33), 0px 0px 0px 1px rgba(237, 0, 0, 0.33)'
      : '0px 0px 0px 2px rgba(237, 0, 0, 0.12)'};
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
`;

export const UnderlineRed: React.FC<UnderlineRedProps> = ({ isHovered, isActive }) => {
  return <UnderlineRedWrapper isHovered={isHovered} isActive={isActive} />;
};