import { debugGTM } from "./gtm-debug";
import { sendGTMEvent } from "@next/third-parties/google";
// types/gtm-events.ts
export interface GTMItem {
  item_id: string;
  item_name: string;
  price: number;
  currency: string;
  item_brand: string; 
  quantity?: number;
  item_category?: string;
  item_variant?: string;
}

// Base ecommerce interface
export interface GTMEcommerceBase {
  items: GTMItem[];
  currency?: string;
  value?: number;
  page_type?: 'spotlight' | 'generic';
}

// Purchase-specific ecommerce interface
export interface GTMEcommercePurchase extends GTMEcommerceBase {
  transaction_id: string;
  tax?: number;
  shipping?: number;
}

// Union type for all ecommerce event types
export type GTMEcommerceData = GTMEcommerceBase | GTMEcommercePurchase;

export interface GTMEcommerceEvent {
  event: GTMEventName;
  ecommerce: GTMEcommerceData;
}

export type GTMEventName = 
  | 'view_item'
  | 'view_item_list'
  | 'select_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'add_shipping_info'
  | 'add_payment_info'
  | 'purchase';

// Type-safe helper for purchase events specifically
export function createPurchaseEvent(
  ecommerceData: GTMEcommercePurchase
): GTMEcommerceEvent {
  return {
      event: 'purchase' as GTMEventName,
      ecommerce: ecommerceData
  };
}

// Keep the generic helper for other events
export function createGTMEvent<T extends GTMEventName>(
  eventName: T, 
  ecommerceData: GTMEcommerceBase
): GTMEcommerceEvent {
  const event = {
      event: eventName,
      ecommerce: ecommerceData
  };
  
  debugGTM(event);
  return event;
}

export const sendGTMEventWithDebug = (event: GTMEcommerceEvent) => {
  // Clear ecommerce first
  sendGTMEvent({ ecommerce: null });

  // Debug logging
  debugGTM(event);
  
  // Clear and send in single operation
  sendGTMEvent({
      ...event,
      ecommerce: {
          ...event.ecommerce,
      }
  });
};