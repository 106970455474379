import breakpoints from "@/styles/breakpoints";
import styled from "@emotion/styled";
import { getDefaultImageForPost as getDefaultImage } from '@/utils/imageUtils';
import { Post } from "@/generated/graphql";
import { Seo } from "@/components/layout/Base/Seo";
import SectionSpacer from "@/components/layout/Base/SectionSpacer";
import CategoryCarousel from "../CategoryCarousel";
import { CategoryTagPageData } from "./types";

//  Note: some urls are /categort/[slug] and some are just /[slug] - not sure why yet - see linear issue BLU-110 

// TODO:
// ____ Probably the line for v1
// 2. Add load more function that is already made for idInsights 
// 3. Create full page UI with more than one row
// 4. Navigation and other categories on side (like docs / digital publication)

const PostsLibrary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${breakpoints.md}px){
    gap: 40px;
  }
`;

const CategoryTitle = styled.h1`
  text-align: center;
`;

export default function CategoryTagTemplate({ data }: { data: CategoryTagPageData }) {
  const getDefaultImageForPost = (post: Post) => getDefaultImage(post.id);

  const formatName = (name: string) => {
    return name
      .split('-')  // Split by dashes
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // Capitalize each word
      .join(' ');  // Join words with spaces
  };
  
  return (
    <>
      <Seo data={data?.seo}/>
      
      <SectionSpacer size='small' />
      <PostsLibrary>
        <CategoryTitle>{formatName(data.name) || "Tag"}</CategoryTitle>
        <CategoryCarousel
            category={''}
            categorySlug={data?.slug || 'idinsights'}
            posts={data.posts as Partial<Post>[] || []}
            getDefaultImageForPost={getDefaultImageForPost}
        />
      </PostsLibrary>
      <SectionSpacer size='small' />
    </>
  );
}