import styled from '@emotion/styled'
import Image from 'next/image';
import DeviceImage from './idvisor-flex_opening-stand_black.png';
import breakpoints from '@/styles/breakpoints';
import Section from '@/components/layout/Base/Section';
import Container from "@/components/layout/Base/Container";
import { blurDataUrlSoft } from '@/styles/blurDataUrl';

const StyledSection = styled(Section)`
    overflow: hidden;
    background-color: black;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0px;
    max-width: 100%;
`

const HeroContainer = styled(Container)`
    position: relative;
    height: 90svh;
    max-height: 800px;
`

const Textbox = styled.div`
    padding: 20px;
    height: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 1;

    @media (min-width: ${breakpoints.md}px) {
        padding-top: 48px;
        padding-left: 20px;
        height: 100%;
        max-width: 35%;
        padding-right: 0px;
        margin-left: auto;
        padding-bottom: 15%;
        align-items: flex-end;
        justify-content: center;
    }
`

const Title = styled.h1`
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 72px;
        text-align: start;
    }
`

const Description = styled.p`
    font-size: 18px;
    line-height: 150%;
    color: rgb(var(--token-gray));
    text-align: center;
    max-width: 500px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 20px;
        text-align: start;
    }

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 26px;
    }
`

const ProductImage = styled.div`
    position: absolute;
    height: 70%;
    width: 100%;
    bottom: 0;

    @media (min-width: ${breakpoints.md}px) {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        width: 75%;
        margin-right: auto;
        right: auto;
    }
`

const FlexProtectionHeroSection = () => {
    return (
        <StyledSection>
            <HeroContainer>
                <Textbox>
                    <Title>Secure & Compliant</Title>
                    <Description>Safeguard your venue with cutting-edge security features that prevent underage entry and keep you compliant with&nbsp;all&nbsp;regulations.</Description>
                </Textbox>
                <ProductImage>
                    <Image
                        src={DeviceImage}
                        alt=''
                        fill={true}
                        sizes="100vw"
                        style={{objectFit: "contain"}}
                        placeholder='blur'
                        blurDataURL={blurDataUrlSoft}
                    />
                </ProductImage>
            </HeroContainer>
        </StyledSection>
    )
}

export default FlexProtectionHeroSection;