import styled from '@emotion/styled';
import Section from '@/components/layout/Base/Section';
import BlogContainer from '@/components/blog/BlogContainer';
import breakpoints from '@/styles/breakpoints';
import PostImage from './PostImage'; //Keep for rendering reasons I think
import useViewport from '@/utils/hooks/useViewport';
import { useEffect } from 'react';

const PostContent = styled.div<{contentWidth: number}>`
  display: flex;
  flex-direction: column;
  font-family: 'PP Neue Montreal';

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 40px auto;
    align-items: center;
  }

  hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'PP Editorial New', serif;
    line-height: 150%;
    margin: 32px 0px;
  }

  h1 {
    font-size: 32px;
    @media (min-width: ${breakpoints.md}px) {
      font-size: 38px;
      margin: 48px 0px;
    }
  }

  h2 {
    font-size: 28px;
    @media (min-width: ${breakpoints.md}px) {
      font-size: 34px;
      margin: 44px 0px;
    }
  }

  h3 {
    font-size: 24px;
    @media (min-width: ${breakpoints.md}px) {
      font-size: 30px;
      margin: 40px 0px;
    }
  }

  h4, h5, h6 {
    font-size: 20px;
    font-family: 'PP Neue Montreal';

    @media (min-width: ${breakpoints.md}px) {
      font-size: 26px;
      margin: 20px 0px;
    }
  }

  p {
    font-size: 16px;
    line-height: 160%;
    margin: 12px 0px 16px 0px;
    font-family: 'PP Neue Montreal';

    @media (min-width: ${breakpoints.md}px) {
      font-size: 20px;
    }
  }

  figure {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-family: 'PP Neue Montreal';
    padding-bottom: 24px;
    margin: 0 auto;
  }

  figure figcaption {
    font-size: 12px;
    color: rgb(var(--token-gray));
    font-family: 'PP Neue Montreal';
    text-align: center;
    font-family: 'PP Neue Montreal';
    align-items: center;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;

    @media (min-width: ${breakpoints.md}px) {
      font-size: 20px;
    }
  }

  iframe, object, embed {
    position: relative;
    width: ${props => `${props.contentWidth}px`};
    height: ${props => `${props.contentWidth * 9/16}px`};
  }

  ol {
    list-style-type: decimal;
    margin-left: 20px;

    @media (min-width: ${breakpoints.md}px) {
      font-size: 20px;
    }
  }

  li {
    margin-bottom: 5px;
  }

  a {
    color: #3A71FF;
  }

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
  }
`;

const calculateContentWidth = (viewportWidth: number): number => {
  // BlogContainer max-width is 800px
  const BLOG_MAX_WIDTH = 800;
  const { sm, md, lg, xl } = breakpoints;

  // Calculate available width after padding
  let availableWidth: number;

  if (viewportWidth >= xl) {
    availableWidth = viewportWidth - (2 * 120); // 120px padding each side
  } else if (viewportWidth >= lg) {
    availableWidth = viewportWidth - (2 * 80);  // 80px padding each side
  } else if (viewportWidth >= md) {
    availableWidth = viewportWidth - (2 * 40);  // 40px padding each side
  } else {
    availableWidth = viewportWidth - (2 * 20);  // 20px padding each side
  }

  return Math.min(availableWidth, BLOG_MAX_WIDTH);
};

export default function BlogContent({ content }: { content: string }) {
  const { width } = useViewport();

  return (
    <Section>
      <BlogContainer>
        <PostContent dangerouslySetInnerHTML={{ __html: content }} contentWidth={calculateContentWidth(width)} />
      </BlogContainer>
    </Section>
  );
}