import React, { useState, useRef, Suspense } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import breakpoints from '@/styles/breakpoints';
import BentoText from '@/components/spotlight/bento/BentoText';
import useIntersectionObserver from '@/utils/hooks/useIntersectionObserver';
import useViewport from '@/utils/hooks/useViewport';
import { outlineStyles } from '@/styles/globalStyles';
import PlaceholderAnimation from '@/components/spotlight/bento/PlaceholderAnimation/Animation';
import AnimationWrapper from './AnimationWrapper';


const AnimationComponents: { [key: string]: React.LazyExoticComponent<React.ComponentType<any>> } = {
    AntiPassback: React.lazy(() => import('@/components/spotlight/bento/AntiPassback/Animation')),
    BatteryLife:  React.lazy(() => import('@/components/spotlight/bento/BatteryLife/Animation')),
    ClearAlerts: React.lazy(() => import('@/components/spotlight/bento/ClearAlerts/Animation')),
    ClearMobileInterface: React.lazy(() => import('@/components/spotlight/bento/ClearMobileInterface/Animation')),
    Cradle: React.lazy(() => import('@/components/spotlight/bento/Cradle/Animation')),
    CRM: React.lazy(() => import('@/components/spotlight/bento/CRM/Animation')),
    Durable: React.lazy(() => import('@/components/spotlight/bento/Durable/Animation')),
    ExpiredIdFlags: React.lazy(() => import('@/components/spotlight/bento/ExpiredIdFlags/Animation')),
    ExtendedWarrentyTwo: React.lazy(() => import('@/components/spotlight/bento/ExtendedWarrentyTwo/Animation')),
    ExtendedWarrentyThree: React.lazy(() => import('@/components/spotlight/bento/ExtendedWarrentyThree/Animation')),
    ExtendedWarrentyFour: React.lazy(() => import('@/components/spotlight/bento/ExtendedWarrentyFour/Animation')),
    ExtraBattery: React.lazy(() => import('@/components/spotlight/bento/ExtraBattery/Animation')),
    FakeDetection: React.lazy(() => import('@/components/spotlight/bento/FakeDetection/Animation')),
    FindMyDevice: React.lazy(() => import('@/components/spotlight/bento/FindMyDevice/Animation')),
    FreeUpdates: React.lazy(() => import('@/components/spotlight/bento/FreeUpdates/Animation')),
    HapticAlerts: React.lazy(() => import('@/components/spotlight/bento/HapticAlerts/Animation')),
    InstantScans: React.lazy(() => import('@/components/spotlight/bento/InstantScans/Animation')),
    MultiDeviceSync: React.lazy(() => import('@/components/spotlight/bento/MultiDeviceSync/Animation')),
    Passports: React.lazy(() => import('@/components/spotlight/bento/Passports/Animation')),
    PhotoCapture: React.lazy(() => import('@/components/spotlight/bento/PhotoCapture/Animation')),
    UnderageAlerts: React.lazy(() => import('@/components/spotlight/bento/UnderageAlerts/Animation')),
    VIPBanned: React.lazy(() => import('@/components/spotlight/bento/VIPBanned/Animation')),
    Stand: React.lazy(() => import('@/components/spotlight/bento/Stand/Animation')),
    AvMagStripe: React.lazy(() => import('@/components/spotlight/bento/AvMagStripe/Animation')),
    Large7Touchscreen:  React.lazy(() => import('@/components/spotlight/bento/Large7Touchscreen/Animation')),
  };

  
interface Variant {
    variant: 'square' | 'mini';
    span?: number;
}

const GridItem = styled.div<Variant>`
    width: 100%;
    position: relative;
    background: black;
    ${outlineStyles.glosscard}
    
    ${props => props.variant === 'square' && css`
        &::before {
            content: '';
            display: block;
            padding-top: calc(${100 / (props.span || 1)}%);
        }  
    `}
`

const GridItemContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const BentoAnimation = styled.div<Variant>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const AddonBadge = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-family: 'PP Neue Montreal';
`

interface BuyBentoPopupItemProps {
    data: {
        title: string;
        caption: string;
        isAddon: boolean;
        addonName?: string;
    };
    animationName: string | null;
}

interface BuyBentoPopupItemProps {
    data: {
        title: string;
        caption: string;
        isAddon: boolean;
        addonName?: string;
    };
    animationName: string | null;
}

const BuyBentoPopupItem: React.FC<BuyBentoPopupItemProps> = ({ data, animationName }) => {
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef(null);
    const isInCenter = useIntersectionObserver(ref, { rootMargin: '-49% 0px -49% 0px' });
    const { width } = useViewport();
    const isMobile = width > 0 && width < breakpoints.md;
    const shouldAnimate = isMobile ? isInCenter : isHovered;
    
    const AnimationComponent = animationName && AnimationComponents[animationName] 
        ? AnimationComponents[animationName] 
        : PlaceholderAnimation;

    return (
        <GridItem
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={ref}
            variant={'square'}
        >
            <GridItemContent>
                <BentoAnimation variant={'square'}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <AnimationWrapper 
                          AnimationComponent={AnimationComponent} 
                          isAnimating={shouldAnimate} 
                        />
                    </Suspense>
                </BentoAnimation>
                <BentoText variant={'square'} title={data.title} caption={data.caption}/>
                {data.isAddon && data.addonName && <AddonBadge>{data.addonName}</AddonBadge>}
            </GridItemContent>
        </GridItem>
    );
};

export default BuyBentoPopupItem;