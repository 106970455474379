export const QLM_METADATA_KEY = '_qlm_creds'

export interface UpgradeType {
    name: string;
    databaseId: number;
    qlmProductId: number;
    queryParam: string;
}

export const softwareUpgrades = [
    {
        name: 'IDVisor Software Upgrade',
        databaseId: 48640,
        qlmProductId: 13,
        queryParam: 'qlm_activation_key'
    },
    {
        name: 'AgeVisor Software Upgrade',
        databaseId: 48827,
        qlmProductId: 25,
        queryParam: 'avk'
    }
] as UpgradeType[];

export type FeatureName = 'Anti-PassBack' | 'Photo Capture' | 'Passport Scanning' | 'VIP/Banned List';
// VIP/Banned List & Tagging are the same feature

// Mapping of features to their bit values
export const FEATURE_MAP: Record<FeatureName, number> = {
    'Anti-PassBack': 1,
    'VIP/Banned List': 2,
    'Photo Capture': 4,
    'Passport Scanning': 8
} as const;

export const QLM_ENDPOINT = 'https://licenseserver.tokenworks.net/qlmservice.asmx';