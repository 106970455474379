//@/components/layout/Base/Container.ts
import styled from '@emotion/styled';

const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
`;

export default Container;