// @/components/landing/Hero/ValueLine
import React from 'react'; 
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Container from '@/components/layout/Base/Container';
import Section from '@/components/layout/Base/Section';
import Image from 'next/image';

// TODO
// 1.  Make checkmarks accept props and size based on breakpoint


const ValueLineContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

const CheckmarkContainer = styled.div`

`;

const ValuePropText = styled.h2`
    color: rgba(255, 255, 255, 0.92);
    font-family: "PP Neue Montreal";
    font-size: 19.579px;

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 22px;
    }


`;

interface ValueLineProps {
  text: string;
}

const ValueLine: React.FC<ValueLineProps> = ({ text }) => {
    return (
        <ValueLineContainer>
            <CheckmarkContainer>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="21"
                fill="none"
                viewBox="0 0 22 21"
            >
                <g id="305615 1" clipPath="url(#clip0_7037_8133)">
                <g id="Group">
                    <path
                    id="Vector"
                    fill="#3A71FF"
                    d="M21.387.51c-1.796 1.048-3.826 2.55-5.735 4.242a59 59 0 0 0-4.699 4.709 71 71 0 0 0-3.2 3.87c-.355.462-1.273 1.702-1.562 2.112q-.222.315-.247.307c-.014-.003-1.313-.743-2.886-1.64l-2.86-1.637-.064.075c-.035.041-.072.086-.08.101-.016.028.226.315 6.409 7.568l.497.584h.136l.388-.783c1.914-3.853 3.794-7.026 5.926-10.004A60.4 60.4 0 0 1 21.418.93c.213-.199.387-.367.387-.373 0-.01-.117-.178-.14-.2a5 5 0 0 0-.278.153"
                    ></path>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_7037_8133">
                    <path fill="#fff" d="M0 .33h21.855V20.8H0z"></path>
                </clipPath>
                </defs>
            </svg>
            </CheckmarkContainer>
            <ValuePropText>{text}</ValuePropText>
        </ValueLineContainer>
    );
};

export default ValueLine;