// components/sections/FormulaSection/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import Section from "@/components/layout/Base/Section";
import breakpoints from '@/styles/breakpoints'
import { PopupButton } from '@typeform/embed-react'
import { sendGAEvent } from '@next/third-parties/google';
import Button from './Button';

// Typeform info:
// Remember the Typeform popup is also on the landing hero section, landing Fake ID section, & in Footer
// if you make any changes on it here then replicate them there too ^^^
// Integreating it inside button component it would stil be a wrapper and that component is already fairly complex so this was un-intuitivly the simpilier solution
// added basic google analytics events based on typeform events but on v2 we can connect via typeform plugin or throught api via Google Tag Manager shareGaInstance="YOUR_GOOGLE_ANALYTICS_ID" prop on typeform instance

const WizardSection = styled(Section)`
  overflow: hidden;
  max-width: 100%;
  z-index: 2;
`
const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  border-radius: 30px;
  background: #000;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 27px;
    border: 2px solid rgba(255, 255, 255, 0.33);
    pointer-events: none;
  }

  @media (min-width: ${breakpoints.md}px){
    max-width: 900px;
}

`

const BigText = styled.h2<{ width?: { sm?: string | number | undefined; md?: string | number | undefined } }>`
    text-align: center;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: -0.38px;
    /* text-wrap: pretty; */
    padding: 44px 36px 40px 36px;
    line-height: normal;
    width: ${props => props.width?.sm ? 
        (typeof props.width.sm === 'number' ? `${props.width.sm}px` : props.width.sm) 
        : '300px'};

    @media (min-width: ${breakpoints.md}px) {
        width: ${props => props.width?.md ? 
            (typeof props.width.md === 'number' ? `${props.width.md}px` : props.width.md) 
            : '630px'};
        padding: 64px 36px 49px 36px;
    }
`;

export const CtaItalicText = styled.span`
    font-style: italic;
`

export const CtaLightText = styled.span`
    font-weight: 200;
`

const SmallText = styled.p`
  color: #A8A8A8;
  text-align: center;
  font-family: 'PP Neue Montreal', sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.01rem;
  padding: 38px;
  width: 320px;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 26px;
    width: 390px;
    padding: 38px 0px 68px 0px;
    letter-spacing: -0.02rem;
  }

`
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const TypeformButton = styled(PopupButton)`
  
  /* This is a carbon copy of the Button component's 'mega' variant: */

  position: relative;
  padding: 12px 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background-color: rgb(var(--token-blue));
  overflow: hidden;
  box-shadow: 0px 0px 3.8px 1.8px rgba(var(--token-blue), 0.25);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: "PP Neue Montreal", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.92);

  /* Gradient stroke */
  ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      padding: 1px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(225, 225, 225, 0.05));
      pointer-events: none;
      -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
      mask-composite: exclude;
  }

  /* unchanging inset shadow */
  ::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      box-shadow: 0px 0px 2.5px 2px rgba(0, 0, 0, 0.1) inset;
      pointer-events: none;
  }

  &:hover {
        background-color: rgb(var(--button-hover));
        box-shadow: 0px 0px 50px 12px rgba(var(--token-blue), 0.35);
  }

  /* Mega variant props */
  padding: 30px 40px;
  border-radius: 16px;
  box-shadow: 0px 2px 30px 10px rgba(var(--token-blue), 0.33);
  font-size: 32px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 1);

  // linear stroke
  &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px;
      padding: 2px;
  }

  // inset shadow
  &::after {
      box-shadow: 0px 0px 7px 6px rgba(0, 0, 0, 0.1) inset;
  }

  &:hover {
      box-shadow: 0px 2px 60px 30px rgba(var(--token-blue), 0.33);
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 50px 60px;
    border-radius: 20px;
    box-shadow: 0px 2px 50px 17px rgba(var(--token-blue), 0.33);
    font-size: 42px;

    // linear stroke
    &::before {
        border-radius: 20px;
    }

    // inset shadow
    &::after {
        box-shadow: 0px 0px 9.7px 8px rgba(0, 0, 0, 0.1) inset;
    }

    &:hover {
        box-shadow: 0px 2px 100px 50px rgba(var(--token-blue), 0.33);
    }
  }
`

interface WizardCtaSectionProps {
  bigText: string | null | undefined;
  buttonText: string | null | undefined;
  smallText: string | null | undefined;
  bigTextWidth?: {
    sm?: string | number;
    md?: string | number;
  };
}

  
const renderBigText = (text: string): React.ReactNode => {
  const parts = text.split(/(<CtaItalicText>.*?<\/CtaItalicText>|<CtaLightText>.*?<\/CtaLightText>)/);
  return parts.map((part, index) => {
    if (part.startsWith('<CtaItalicText>')) {
      return <CtaItalicText key={index}>{part.replace(/<\/?CtaItalicText>/g, '')}</CtaItalicText>;
    }
    if (part.startsWith('<CtaLightText>')) {
      return <CtaLightText key={index}>{part.replace(/<\/?CtaLightText>/g, '')}</CtaLightText>;
    }
    return part;
  });
};

export const WizardCtaSection: React.FC<WizardCtaSectionProps> = ({ 
  bigText, 
  buttonText, 
  smallText, 
  bigTextWidth
}) => {
  const processedBigText = bigText ? renderBigText(bigText) : null;

  return (
    <WizardSection>
      <Card>
        <BigText width={bigTextWidth}>
          {processedBigText}
        </BigText>
        <ButtonWrapper>
          <TypeformButton
              id="urXbR95w"
              size={90}
              onReady={({ formId }) => {
                console.log('form ready');
                sendGAEvent({ event: 'typeform_ready', form_id: formId });
              }}
              onStarted={({ formId, responseId }) => {
                console.log(`Form ${formId} started with response ID ${responseId}`);
                sendGAEvent({ event: 'typeform_started', form_id: formId, response_id: responseId });
              }}
              onSubmit={({ formId, responseId }) => {
                console.log(`Form ${formId} submitted, response id: ${responseId}`);
                sendGAEvent({ event: 'typeform_submitted', form_id: formId, response_id: responseId });
              }}
                >
              {buttonText || 'Learn More'}
          </TypeformButton>
        </ButtonWrapper>
        <SmallText>
          {smallText || ''}
        </SmallText>
      </Card>
    </WizardSection>
  );
}