// @/components/layout/Layout/index.tsx
import { Global } from '@emotion/react';
import globalStyles from '@/styles/globalStyles';
import NavBar from '@/components/layout/NavBar/';
import DynamicFooter from '@/components/layout/DynamicFooter';
import PageBackground from './PageBackground';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { delayFooter } from '@/utils/delayFooter';
import Head from 'next/head';
import Script from 'next/script';
import ZohoChat from './ZohoChat';

interface LayoutProps {
    children: React.ReactNode;
    navProps?: {
      productName?: string;
      buyButtonText?: string;
      onBuyClick?: () => void;
    } | undefined;
  }

const Main = styled.main`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

const Page = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const NAV_HEIGHT = '60px'; //exported for other 100vh sections

const Layout = ({ children, navProps }: LayoutProps) => {
    
    useEffect(() => {
        delayFooter();
    }, []);

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0 maximum-scale=1.0, user-scalable=no" />
                <link rel="icon" href="/favicon.ico" />
                {/* <meta httpEquiv="content-language" content="en-US" />
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"></meta> */}
                <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
                <link rel="icon" href="/favicon/favicon.ico" />
                <link rel="manifest" href="/favicon/site.webmanifest" />
            </Head>
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
                strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
                `}
            </Script>
            <Script id="google-ads" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS_ID}');
                `}
            </Script>

            <ZohoChat/>
            
            <Global styles={globalStyles} />
            <PageBackground/>
            <Main>
                <div style={{ height: NAV_HEIGHT }}/>
                <NavBar {...navProps}/>
                <Page>
                    {children}
                </Page>
                <div id="footer-container">
                    <DynamicFooter />
                </div>
            </Main>
        </>
    );
}

export default Layout;