// Hero/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import HeroMedia from './HeroMedia';
import ProductName from '@/components/common/ProductName';
import Button from '@/components/common/Button';
import breakpoints from '@/styles/breakpoints'
import { css } from '@emotion/react';


const HeroContainer = styled.div`
  position: relative; 
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  height: 100vh;
  margin-top: -60px;


  @media (min-width: ${breakpoints.md}px) {

  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 3; 
  margin-top: -52px;

  @media (min-width: ${breakpoints.md}px) {
    margin-top: -78px;
  }

`;

const gradientOverlay = css`
  position: relative;

  &::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 100%, transparent 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    z-index: 1;
  }

  &::before {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #FFF;
    z-index: 0;
  }

`;


const Subheading = styled.h3`
${gradientOverlay}
  width: 260px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-family: "PP Editorial New", serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 21px;
  margin-bottom: 18px;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 42px;
    margin-top: 28px;
    margin-bottom: 20px;
    width: 800px;
  }
`;

const BlurredGradient = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 130px; // Adjust this value to position it correctly
  width: 240px;
  height: 68px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 212.96%);
  z-index: 2;
  filter: blur(15px);
  -webkit-filter: blur(15px);
  will-change: filter;

  @media (min-width: ${breakpoints.md}px) {
    width: 354px;
    height: 98px;
    bottom: 160px; // Adjust this value for larger screens
  }
`;

interface HeroProps {
  mediaSrc: string;    
  mediaType: 'video' | 'image';
  productName: string;
  subheading: string;
  onBuyClick: () => void;  
}

const Hero: React.FC<HeroProps> = ({ 
  mediaSrc,  
  mediaType,
  productName, 
  subheading, 
  onBuyClick 
}) => {
  return (
    <HeroContainer>
      <HeroMedia mediaSrc={mediaSrc} mediaType={mediaType} /> 
      <ContentContainer>
        <ProductName 
          name={productName} 
          fontSize={{
            base: 72,
            md: 108,
            lg: 108,
            xl: 108
          }}
          helveticaWeight={500} 
          helveticaLetterSpacing="-1.44px" 
          as="h1"
        />
        <Subheading data-text={subheading}>{subheading}</Subheading>
        <Button text="Buy Now" onClick={onBuyClick}/> 
      </ContentContainer>
    </HeroContainer>
  );
};

export default Hero;