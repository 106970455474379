import React from "react";
import styled from '@emotion/styled';
import Tag from "@/components/common/Tag";
import breakpoints from "@/styles/breakpoints";

const SmallText = styled.p`
    font-size: 14px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 16px;
    }
`

const TagGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`

interface DateTagsAuthorProps {
    date: string;
    categories?: {
        nodes: Array<{
            name?: string | null;
            link?: string | null;
            slug?: string | null;
            databaseId: number;
        }>
    } | null;
    author?: {
        node: {
            name?: string | null | undefined;
            uri?: string | null | undefined;
            databaseId: number;
        };
    } | null | undefined;
    tags?: {
        nodes: Array<{
            name?: string | null;
            link?: string | null;
            slug?: string | null;
            databaseId: number;
        }>;
    } | null | undefined;
}

export default function DateTagsAuthor({ date, categories, tags, author }: DateTagsAuthorProps) {
    
    const categoryLinks = categories?.nodes?.map((cat, index) => (
        <React.Fragment key={cat.databaseId}>
            <Tag text={cat.name} href={cat.link} variant="mini"/>
        </React.Fragment>
    ));

    const tagLinks = tags?.nodes?.map((tag, index) => (
        <React.Fragment key={tag.databaseId}>
            <Tag text={tag.name} href={tag.link} variant="mini"/>
        </React.Fragment>
    ))

    return (
        <>
            <SmallText>
                {date}
                {author?.node?.name && (
                    <span> | By&nbsp;
                        {/* <Link href={author.node.uri || '#'} passHref> */}
                            <span>{author.node.name}</span>
                        {/* </Link> */}
                    </span>
                )}
            </SmallText>
            <TagGrid>
                {categoryLinks}
                {tagLinks}
            </TagGrid>
        </>
    );
}