// @/components/landing/Hero/SocialProof
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Image from 'next/image';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';

interface Logo {
    src: string;
    alt: string;
  }

interface ThemeProps {
    isLight?: boolean;
}

const SocialProofContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 120px 0px 40px 0px;

  @media (min-width: ${breakpoints.md}px) {
      padding: 160px 0px 40px 0px;

  }
`;

const SocialProofHeading = styled.h3<ThemeProps>`
  text-align: center;
  font-family: "PP Neue Montreal";
  font-size: 23.301px;
  line-height: 92%;
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  
  && {
    background: ${props => props.isLight 
      ? 'linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0.8) 100%)'
      : 'linear-gradient(to bottom, #F7F7F7 0%, rgba(247, 247, 247, 0.8) 100%)'
    };
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
  }
`;


const LogoCarousel = styled.div`
  height: 33px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
`;

const LogoTrack = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
  width: fit-content;
  animation: scroll 40s linear infinite;
  padding-left: 40px; 
  will-change: transform;

  @keyframes scroll {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-50%, 0, 0);
    }
  }

`;


const ReverseLogoTrack = styled(LogoTrack)`
  animation: scroll 40s linear infinite reverse;
`;

const LogoWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 33px;
  position: relative;
`;

const SocialProof: React.FC<ThemeProps> = ({ isLight }) => {
    const logos = Array.from({ length: 24 }, (_, i) => ({
      src: `/images/logos/logo${i + 1}.png`,
      alt: `Logo ${i + 1}`
    }));
  
    const firstRowLogos = logos.slice(0, 12);
    const secondRowLogos = logos.slice(12);
  
    // Add type to the function parameter
    const createLogoGroup = (logosArray: Logo[]): Logo[] => {
      return [...logosArray, ...logosArray, ...logosArray, ...logosArray];
    };
  
  
  return (
    
    <SocialProofContainer>

      <LogoCarousel>
        <LogoTrack>
          {createLogoGroup(firstRowLogos).map((logo, index) => (
            <LogoWrapper key={`logo-1-${index}`}>
              <Image
                src={logo.src}
                alt={logo.alt}
                fill
                style={{ objectFit: 'contain' }}
                loading="eager" // Add this to prevent loading delay
              />
            </LogoWrapper>
          ))}
        </LogoTrack>
      </LogoCarousel>

      <SocialProofHeading isLight={isLight}>
        From fortune 500 companies,<br/>to the bar next-door.
      </SocialProofHeading>

      <LogoCarousel>
        <ReverseLogoTrack>
          {createLogoGroup(secondRowLogos).map((logo, index) => (
            <LogoWrapper key={`logo-2-${index}`}>
              <Image
                src={logo.src}
                alt={logo.alt}
                fill
                style={{ objectFit: 'contain' }}
                loading="eager"
              />
            </LogoWrapper>
          ))}
        </ReverseLogoTrack>
      </LogoCarousel>
    </SocialProofContainer>
  );
};

export default SocialProof;