import React, { Suspense, lazy } from 'react';
import styled from '@emotion/styled';
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Container";
import BentoGrid from "@/components/spotlight/bento/BentoGrid";
import BentoItem from "@/components/spotlight/bento/BentoItem";
import { SectionTitle } from "./SectionTitle";
import { ProcessedBentoItem as BentoItemType } from '@/utils/spotlightDataProcessor';
import PlaceholderAnimation from '@/components/spotlight/bento/PlaceholderAnimation/Animation';
import dynamic from 'next/dynamic';

const AnimationComponents: { [key: string]: React.ComponentType<any> } = {
    InstantScans: dynamic(() => import('@/components/spotlight/bento/InstantScans/Animation')),
    CRM: dynamic(() => import('@/components/spotlight/bento/CRM/Animation')),
    MultiDeviceSync: dynamic(() => import('@/components/spotlight/bento/MultiDeviceSync/Animation')),
    VIPBanned: dynamic(() => import('@/components/spotlight/bento/VIPBanned/Animation')),
    Placeholder: dynamic(() => import('@/components/spotlight/bento/PlaceholderAnimation/Animation')),
    UnderageAlerts: dynamic(() => import('@/components/spotlight/bento/UnderageAlerts/Animation')),
  };

interface ThreeFeaturesSectionProps {
  title: string;
  subtitle: React.ReactNode;
  items: BentoItemType[];
}

const StyledSection = styled(Section)`
    max-width: 100%;
`;

const ThreeFeaturesSection: React.FC<ThreeFeaturesSectionProps> = ({ 
    title, 
    subtitle, 
    items 
  }) => {
    return (
      <StyledSection>
        <Container>
          <SectionTitle 
            title={title} 
            subtitle={subtitle} 
          />
          <BentoGrid>
            {items.map((item, index) => {
              const animationName = item.animationName || 'Placeholder';
              const AnimationComponent = AnimationComponents[animationName];
              
              if (!AnimationComponent) {
                console.error(`Animation component not found for: ${animationName}`);
                return null; // or return a fallback component
              }

              return (
                <BentoItem
                  key={index}
                  data={item}
                  AnimationComponent={AnimationComponent}
                />
              );
            })}
          </BentoGrid>
        </Container>
      </StyledSection>
    )
  }
  
  export default ThreeFeaturesSection;