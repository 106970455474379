// src/components/FormulaSection/SectionTitle.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'
import ProductName from '@/components/common/ProductName';

interface SectionTitleProps {
  productName: string;
  fontSize?: {
    base: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  helveticaWeight?: number | string;
  customStyles?: React.CSSProperties;
}

const SectionTitleContainer = styled(Container)`
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    flex-shrink: 0;
    max-width: 100%;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 90px;
    }
`;

const TitleBlur = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 212.96%);
    width: 150px;
    height: 48px;
    margin-top: 10px;
    z-index: -1;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 0;
        width: 670px;
        height: 100px;
        filter: blur(35px);
    }
`;

const ProductNameWrapper = styled.div`
  display: inline-block;
`;

export const SectionTitle: React.FC<SectionTitleProps> = ({
  productName,
  fontSize = { base: 50, md: 72 },
  helveticaWeight = 500,
  customStyles = {}
}) => {
  return (
    <SectionTitleContainer>
      <h1 style={customStyles}>
        The <ProductNameWrapper>
          <ProductName 
            name={productName}
            fontSize={fontSize}
            helveticaWeight={helveticaWeight}
            as={'h1'} 
          />
        </ProductNameWrapper> Formula
      </h1>
      <TitleBlur></TitleBlur>
    </SectionTitleContainer>
  );
};

export default SectionTitle;