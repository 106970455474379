import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Image from 'next/image';
import { blurDataURL } from '@/styles/blurDataUrl';

const MediaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 66vh;
  min-height: 400px;
  overflow: hidden;
  background: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  max-width: 100%;
  z-index: 1; 
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (min-width: ${breakpoints.md}px) {
  object-fit: contain;
  }
`;

const StyledImage = styled(Image)`
  padding-top: 80px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

interface HeroMediaProps {
  mediaSrc: string;
  mediaType: 'video' | 'image';
}

const HeroMedia: React.FC<HeroMediaProps> = ({ mediaSrc, mediaType }) => {
  return (
    <MediaContainer>
      {mediaType === 'video' ? (
        <StyledVideo autoPlay loop muted playsInline>
          <source src={mediaSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      ) : (
        <StyledImage
          src={mediaSrc}
          alt="Hero Image"
          fill
          priority
          sizes="(max-width: 1200px) 100vw, 1200px"
          placeholder='blur'
          blurDataURL={blurDataURL}
        />
      )}
    </MediaContainer>
  );
};

export default HeroMedia;