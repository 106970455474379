// components/layout/NavBar/MobileMenu.tsx
import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { MobileMenuLink } from '@/components/layout/NavBar/MobileMenuLink';
import { SubMenu } from '@/components/layout/NavBar/SubMenu';
import { gsap } from 'gsap';

interface MobileMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px); 
  padding: 40px 20px 40px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(21px);
  position: fixed;
  top: 60px; 
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const SearchBar = styled.div`
  display: flex;
  width: 100%; // Full width
  padding: 10px 10px 10px 8px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12) inset;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  color: #767676;
  font-family: 'PP Neue Montreal', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 60%;
  letter-spacing: -0.32px;

  &::placeholder {
    color: #767676;
  }

  &:focus {
    outline: none;
    color: #000;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
`;


export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, toggleMenu }) => {
  const menuRef = useRef(null);
  /* const searchBarRef = useRef(null); */
  const linksContainerRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      // Reset all GSAP animations
      gsap.set(menuRef.current, { clearProps: "all" });
      /* gsap.set(searchBarRef.current, { clearProps: "all" }); */
      gsap.set(linksContainerRef.current, { clearProps: "all" });
    }
  }, [isOpen]);
  
  useEffect(() => {
    const menu = menuRef.current;
    /* const searchBar = searchBarRef.current; */
    const linksContainer = linksContainerRef.current;
  
    if (isOpen) {
      // Open animation
      gsap.set(menu, { visibility: 'visible' });
      gsap.to(menu, { opacity: 1, duration: 0.2, ease: 'power2.out' });
      /* gsap.from(searchBar, { y: -20, opacity: 0, duration: 0.3, delay: 0.2, ease: 'power2.out' }); */
      gsap.from(linksContainer, { y: -20, opacity: 0, duration: 0.5, delay: 0.3, ease: 'power2.out' });
    } else {
      // Close animation
      gsap.to(menu, { 
        opacity: 0, 
        duration: 0.5, 
        ease: 'power2.in',
        onComplete: () => {
          gsap.set(menu, { visibility: 'hidden' });
        }
      });
    }
  }, [isOpen]);

  return (
    <MobileMenuContainer ref={menuRef} isOpen={isOpen}>
      {/* <SearchBar ref={searchBarRef}>
        <Image src="/icons/mobile-search-icon.svg" alt="Search" width={14} height={14} />
        <SearchInput placeholder="Search" />
      </SearchBar> */}
      <LinksContainer ref={linksContainerRef}>
        <MobileMenuLink 
          prefix="id" 
          suffix="Scanners" 
          hasSubmenu={true}
          toggleMenu={toggleMenu}
          isMenuOpen={isOpen}
        >
          <SubMenu isMenuOpen={isOpen} />
        </MobileMenuLink>
        <MobileMenuLink 
          prefix="id" 
          suffix="Insights" 
          hasSubmenu={false} 
          href="/idinsights" 
          toggleMenu={toggleMenu}
          isMenuOpen={isOpen}
        />
        <MobileMenuLink 
          prefix="id" 
          suffix="Solutions" 
          hasSubmenu={false} 
          href="/solutions" 
          toggleMenu={toggleMenu}
          isMenuOpen={isOpen}
        />
        <MobileMenuLink 
          prefix="id" 
          suffix="Support" 
          hasSubmenu={false} 
          href="https://support.tokenworks.com/portal/en/kb/tokenworks" 
          toggleMenu={toggleMenu}
          isMenuOpen={isOpen}
        />
      </LinksContainer>
    </MobileMenuContainer>
  );
};