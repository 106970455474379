import React from "react";

function NewspaperBackground() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="856"
      height="598"
      fill="none"
      viewBox="0 0 856 598"
    >
      <g clipPath="url(#clip0_5965_22100)">
        <path fill="#E6D9C3" d="M7 0H856V586H7z"></path>
        <path
          fill="#E6D9C3"
          d="M856 586c-1 9.5-4.5 12-7 12v-12l4-14c1 .667 3.057 13.461 3 14z"
        ></path>
        <path
          fill="url(#paint0_linear_5965_22100)"
          fillOpacity="0.33"
          d="M182 440H1031V730H182z"
        ></path>
        <path
          fill="#F7F4EF"
          fillRule="evenodd"
          d="M849 12H0v586h849c1.223 0 2.259-.623 2.994-1.298.329-.204.667-.593 1.006-1.202a5.71 5.71 0 01-1.006 1.202c-1.614.999-2.994-2.464-2.994-6.202V12z"
          clipRule="evenodd"
        ></path>
        <mask
          id="mask0_5965_22100"
          style={{ maskType: "alpha" }}
          width="853"
          height="586"
          x="0"
          y="12"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#F7F4EF"
            fillRule="evenodd"
            d="M849 12H0v586h849c1.223 0 2.259-.623 2.994-1.298.329-.204.667-.593 1.006-1.202a5.71 5.71 0 01-1.006 1.202c-1.614.999-2.994-2.464-2.994-6.202V12z"
            clipRule="evenodd"
          ></path>
        </mask>
        <g mask="url(#mask0_5965_22100)">
          <path
            fill="url(#paint1_linear_5965_22100)"
            fillOpacity="0.66"
            d="M0 549h861v49H2a2 2 0 01-2-2v-47z"
          ></path>
        </g>
        <path
          fill="#01010E"
          stroke="#01010E"
          d="M7 598.5c-5.5 0-7.5-8.167-7.5-10l-2.5 11c3.667-.333 18-1 10-1z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5965_22100"
          x1="604.388"
          x2="604.388"
          y1="440"
          y2="755.893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0"></stop>
          <stop offset="0.5"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_5965_22100"
          x1="442.67"
          x2="442.67"
          y1="598"
          y2="549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A6050"></stop>
          <stop offset="1" stopColor="#A28F71" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0_5965_22100">
          <path fill="#fff" d="M0 0H856V598H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NewspaperBackground;