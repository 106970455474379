//components/spotlight/sections/CompareSection/index

import React from 'react';
import MobileCompareSection from './MobileCompareSection';
import DesktopCompareSection from './DesktopCompareSection';
import SectionTitle from "./SectionTitle";
import Section from '@/components/layout/Base/Section'
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

// Interfaces
interface Product {
  id: string;
  name: string;
  price: string;
  image: string;
  specs: {
    [key: string]: {
      value: string | null;
      name: string;
    };
  };
  slug: string;
}
  
interface CompareSectionProps {
  products: Product[];
  categories: string[];
  currentProduct: string;
  onBuyClick: () => void;
  onLearnMoreClick: (slug: string) => void; 
}

const StyledSection = styled(Section)`
    max-width: 100%;
`

const CompareSection: React.FC<CompareSectionProps> = (props) => {
  return (
    <StyledSection>
      <SectionTitle />
      <MobileCompareSection {...props} />
      <DesktopCompareSection {...props} />
    </StyledSection>
  );
};

export default CompareSection;