import React, { useState, useEffect } from 'react';
import { FaustTemplate } from "@faustwp/core";
import { GetCategoriesPreviewDocument, GetCategoriesPreviewQuery, PostSeoFieldsFragment } from '@/generated/graphql';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import NewspaperHeroSection from '@/components/landing/NewspaperHeroSection';
import IdInsightsPreviewSection from '@/components/landing/IdInsightsPreviewSection';
import { WizardCtaSection, CtaItalicText, CtaLightText } from '@/components/common/WizardCTA';
import CaseStudiesSection from '@/components/common/CaseStudiesSection';
import ReviewsSection from '@/components/common/ReviewsSection';
import IconQuoteSection, { ItalicText } from '@/components/landing/IconQuoteSection';
import LandingVideoSection from '@/components/landing/LandingVideoSection'
import MissionSection from '@/components/landing/MissionSection/Index';
import ThreeFeaturesSection from '@/components/landing/ThreeFeaturesSection';
import { Editorialitalics, EditorialLight } from '@/components/landing/ThreeFeaturesSection/SectionTitle';
import IndustriesSection from '@/components/landing/IndustriesSection';
import FakeIdSections from '@/components/landing/FakeIdSections';
import { ProcessedBentoItem } from '@/utils/spotlightDataProcessor';
import MostPopularProductsSection from '@/components/landing/MostPopularProductsSection';
import {LgBr, MdBr, SmBr}  from '@/components/common/Br';
import breakpoints from '@/styles/breakpoints';
import useViewport from '@/utils/hooks/useViewport';
import { Seo } from "@/components/layout/Base/Seo";
import Hero from '@/components/landing/Hero';

// TODO
// 1. Fake ID Section
// 3. Review Mobile and Fix
//    b. intesection observer on fake id solutions for hover effect
//    c. properly position title blurs 

//Wizard CTA Data (Heading is in JSX)
const wizardCTA1ButtonText = "Find ID Scanner"; 
const wizardCTA1SmallText = "Take a quick survey & get shown the best solution for you";

const wizardCTA2ButtonText = "Instant Security"; 
const wizardCTA2SmallText = "We'll ask you a few questions then recommend the perfect product for your specific needs.";

const wizardCTA3ButtonText = "Perfect Match"; 
const wizardCTA3SmallText = "Tell us about your business and we'll tailor a solution for you.";

const wizardCTA4ButtonText = "Perfect Match"; 
const wizardCTA4SmallText = "Take our quick assessment to find the perfect match.";

 //Reviews Section Content
 const reviews = [
  {
    id: '1',
    image: '/images/landing-review-image-1.png',
    reviewText: "I had someone brag about getting served in my establishment as a minor. Never again this takes all the math and guess work out of it. I love the product. I would never knowingly serve a minor and now I know without a doubt.",
    rating: 5,
    author: "Doogie B."
  },
  {
    id: '2',
    image: '/images/landing-review-image-2.jpg',
    reviewText: "This tool is invaluable to our operation. We need to track how many drinks are served to a guest across multiple bar locations, and these scanners do a great job for us. Tokenworks customer support is also fantastic, always a quick response to any questions or issues.",
    rating: 5,
    author: "Jason"
  },
  {
    id: '3',
    image: '/images/landing-review-image-3.jpg',
    reviewText: "Saved us a fine during sting operation 3 days after getting unit. We had new security working the front door and 4 undercover operatives tried to get into our business. This thing paid for itself already and the local police appreciate that we are checking every ID.",
    rating: 5,
    author: "Kevin K."
  },
  {
    id: '4',
    image: '/images/landing-review-image-4.png',
    reviewText: "Overall an easy process.",
    rating: 5,
    author: "Jessie B."
  },
  {
    id: '5',
    image: '/images/landing-review-image-5.png',
    reviewText: "Easy to use handheld unit. Kids with fake ID's just walk away before being scanned they don't want to get caught. Great database we can ban unruly customers.",
    rating: 5,
    author: "Alex's B."
  },
  {
    id: '6',
    image: '/images/landing-review-image-6.png',
    reviewText: "I work for a company that has sites all over the U.S. This device has aided in preventing bad actors from stealing our product. Customer support is A+.",
    rating: 5,
    author: "Anonymous Customer"
  },
  {
    id: '7',
    image: '/images/landing-review-image-7.png',
    reviewText: "Excellent product, easy to use and install. Helping me to safe my business from unlawful sales or customers. Thank you",
    rating: 5,
    author: "Saima K."
  },
  {
    id: '8',
    image: '/images/landing-review-image-8.png',
    reviewText: "Indispensable id scanner for the liquor industry. Keeps a log of id's scanned for looking back because of trouble patrons.",
    rating: 5,
    author: "Mark R."
  },
  {
    id: '9',
    image: '/images/landing-review-image-9.png',
    reviewText: "So far the process has been very simple and easy. I tried out one of these scanners from a neighboring establishment and was very impressed with the speed and accuracy. I caught multiple pass back IDs that we never would have caught previously",
    rating: 5,
    author: "Majestic F."
  },
  {
    id: '10',
    image: '/images/landing-review-image-10.png',
    reviewText: "I had a great experience dealing with customer service when I called to ask a few questions. Once I received the scanner I was pleasantly surprised at how easy to setup/operate it was. Definitely will make further purchases and recommend their products to others!",
    rating: 5,
    author: "Blake"
  },
];


// //Stop Mistakes at the Door Content
const firstThreeFeaturesSectionData: ProcessedBentoItem[] = [
  {
    title: "Instant Scans",
    caption: "Scan IDs in less than a second.",
    animationName: "InstantScans",
    addonName: null,
    isAddon: false,
    popup: {
      heading: "Instant Scans",
      body: `
        <p>Experience unparalleled efficiency with our cutting-edge instant ID scanning technology. Powered by a high-quality Honeywell scanner, this feature revolutionizes the way you verify identities and ages.</p>

        <h4>Blazing Speed Meets Precision</h4>
        <p>Our advanced system reads all types of IDs in less than one second, dramatically reducing wait times and enhancing customer experiences. Whether you're managing a busy nightclub, running a high-traffic retail store, or operating a security-sensitive venue, this rapid scanning capability ensures swift and accurate verification.</p>

        <h4>Versatile and Reliable</h4>
        <p>From driver's licenses to passports, our scanner effortlessly processes a wide range of identification documents. The state-of-the-art Honeywell technology guarantees consistent performance, even with worn or slightly damaged IDs, minimizing false rejections and keeping your operations smooth.</p>

        <h4>Streamlined Operations</h4>
        <p>By significantly reducing the time spent on ID checks, you can serve more customers, improve queue management, and boost overall operational efficiency. This instant scanning feature seamlessly integrates with our comprehensive ID verification system, providing you with a powerful tool for age verification, fraud prevention, and customer data management.</p>

        <p>Elevate your business's security and efficiency with lightning-fast ID scanning - because in today's fast-paced world, every second counts.</p>
      `,
      pageLink: null
    },
    span: 1
  },
  {
    title: "Real-Time CRM",
    caption: "Track customer loyalty, visit counts, and more.",
    animationName: "CRM",
    addonName: null,
    isAddon: false,
    popup: {
      heading: "Built-In, Real-Time CRM",
      body: `
        <p>Harness the full potential of your ID scanner with our integrated real-time CRM feature. This sophisticated system transforms standard age verification into a comprehensive venue management solution, offering instant access to vital data and actionable insights.</p>
        <h4>Precision Occupancy Tracking</h4>
        <p>Monitor your venue's capacity with pinpoint accuracy using live occupancy statistics. This real-time data ensures compliance with local regulations while optimizing the safety and comfort of your patrons. Make informed decisions about entry management and space utilization on the fly.</p>
        <h4>Effortless Customer Data Acquisition</h4>
        <p>Capture valuable customer information seamlessly during the ID verification process. Our system intelligently organizes and stores data, from contact details to visit history, providing a rich database for analysis and personalized marketing efforts. Leverage this wealth of information to refine your business strategies and enhance customer experiences.</p>
        <h4>Proactive Relationship Management</h4>
        <p>Elevate your customer interactions with data-driven insights. Instantly recognize VIP guests, track visit patterns, and tailor your service to individual preferences. This level of personalization fosters stronger customer loyalty, increases repeat visits, and sets your venue apart in a competitive market.</p>
        <p>By integrating real-time CRM capabilities into your ID scanning workflow, you'll streamline operations, enhance security, and create memorable experiences for your guests. Embrace this powerful, all-in-one solution to unlock new levels of efficiency and customer satisfaction in your venue management.</p>
      `,
      pageLink: null
    },
    span: 1
  },
  {
    title: "Underage Alerts",
    caption: "Get instant alerts for underage attempts.",
    animationName: "UnderageAlerts",
    addonName: null,
    isAddon: false,
    popup: {
      heading: "Underage Alerts",
      body: `
        <p>Safeguard your establishment and ensure compliance with our cutting-edge Underage Alerts. This powerful tool helps businesses in age-restricted industries prevent underage sales and maintain legal compliance effortlessly.</p>
        <h4>Instant Age Verification</h4>
        <p>Our ID scanner provides real-time underage warnings, instantly notifying staff when an underage ID is presented. The system quickly calculates the customer's age and displays clear, color-coded alerts, making it virtually impossible to mistakenly serve minors.</p>
        <h4>Multi-Sensory Notifications</h4>
        <p>Experience unparalleled alert functionality with our multi-sensory warning system. Visual cues, audible alarms, and vibration notifications ensure that your staff never misses an underage warning, even in busy or noisy environments.</p>
        <h4>Customizable Age Thresholds</h4>
        <p>Tailor Underage Alerts to your specific needs. Set custom age thresholds for various products or services, from alcohol and tobacco to casino entry or equipment rentals. Our flexible system adapts to your unique business requirements.</p>
        <p>Protect your business, your customers, and your community with advanced Underage Alerts. Ensure compliance, reduce risks, and operate with confidence in any age-restricted industry.</p>
              `,
      pageLink: null
    },
    span: 1
  },
];

 // Case Studies Section Content
 const caseStudies = [
  {
    id: '1',
    image: '/images/idvisor-casestudy-avant-gardener.jpg',
    company: 'Avant Gardner',
    location: 'Brooklyn, NY',
    previewText: "See how Avant Gardner improved entry efficiency and enhanced guest safety with TokenWorks' advanced ID scanning solution.",
    caseStudyUrl: 'https://www.avant-gardner.com/'
  },
  {
    id: '2',
    image: '/images/Berkshire-Roots-Pittsfield-Massachusetts-Dispensary-1-3466860910.png',
    company: 'Berkshire Roots',
    location: 'Pittsfield, MA',
    previewText: "See how Berkshire Roots streamlined compliance and improved customer experience with TokenWorks' ID scanning technology.",
    caseStudyUrl: '/cannabis/how-identifake-helps-massachusetts-cannabis-dispensary'
  },
];


const enhanceEverydayOperationsData: ProcessedBentoItem[] = [
  {
    title: "Real-Time CRM",
    caption: "Track customer loyalty, visit counts, and more.",
    animationName: "CRM",
    addonName: null,
    isAddon: false,
    popup: {
      heading: "Built-In, Real-Time CRM",
      body: `
        <p>Harness the full potential of your ID scanner with our integrated real-time CRM feature. This sophisticated system transforms standard age verification into a comprehensive venue management solution, offering instant access to vital data and actionable insights.</p>
        <h4>Precision Occupancy Tracking</h4>
        <p>Monitor your venue's capacity with pinpoint accuracy using live occupancy statistics. This real-time data ensures compliance with local regulations while optimizing the safety and comfort of your patrons. Make informed decisions about entry management and space utilization on the fly.</p>
        <h4>Effortless Customer Data Acquisition</h4>
        <p>Capture valuable customer information seamlessly during the ID verification process. Our system intelligently organizes and stores data, from contact details to visit history, providing a rich database for analysis and personalized marketing efforts. Leverage this wealth of information to refine your business strategies and enhance customer experiences.</p>
        <h4>Proactive Relationship Management</h4>
        <p>Elevate your customer interactions with data-driven insights. Instantly recognize VIP guests, track visit patterns, and tailor your service to individual preferences. This level of personalization fosters stronger customer loyalty, increases repeat visits, and sets your venue apart in a competitive market.</p>
        <p>By integrating real-time CRM capabilities into your ID scanning workflow, you'll streamline operations, enhance security, and create memorable experiences for your guests. Embrace this powerful, all-in-one solution to unlock new levels of efficiency and customer satisfaction in your venue management.</p>
      `,
      pageLink: null
    },
    span: 1
  },
  {
    title: "Multi-Device Sync",
    caption: "Keep all your devices up-to-date",
    animationName: "MultiDeviceSync",
    addonName: null,
    isAddon: false,
    popup: {
      heading: "Multi-Device Synchronization",
      body: `
        <p>idVisor Sync revolutionizes ID verification processes by enabling real-time data sharing between multiple ID scanning devices. This advanced multi-device synchronization feature creates a unified network of ID scanners, perfect for businesses with multiple entry points or separate venues.</p>
        <p>At the core of idVisor Sync is its ability to instantly share critical information across all connected scanners. Customer data, VIP lists, and banned individual records are updated in real-time, ensuring consistency and accuracy at every checkpoint. This seamless integration significantly enhances security measures and streamlines customer management across locations.</p>
        <p>The centralized customer database maintained by idVisor Sync keeps visitor history, tags, and other important details current on all devices. Whether a customer enters through the main entrance or a VIP access point, their information is immediately available to all connected scanners. This real-time synchronization not only improves operational efficiency but also elevates the overall customer experience.</p>
        <p>For large venues, multi-location businesses, or establishments requiring coordinated ID verification, idVisor Sync offers unparalleled control and consistency. The networked age verification system ensures that all entry points operate with the same up-to-date information, reducing errors and enhancing security protocols.</p>
        <p>By upgrading to idVisor Sync, businesses transform their individual ID scanners into a powerful, interconnected verification network. This synchronized system adapts to complex business needs, providing a scalable solution for comprehensive ID verification and customer management across multiple devices and locations.</p>
      `,
      pageLink: null
    },
    span: 1
  },
  {
    title: "VIP & Banned List",
    caption: "Manage special guests and restricted individuals",
    animationName: "VIPBanned",
    addonName: null,
    isAddon: false,
    popup: {
      heading: "VIP & Banned List Management",
      body: `
        <p>In the fast-paced world of venue management, split-second decisions can make or break your customer experience. Enter the game-changing VIP/Banned List feature - your secret weapon for elevating security, personalizing service, and maximizing operational efficiency.</p>
        <h4>Instant Recognition, Tailored Experiences</h4>
        <p>Imagine a world where your staff instantly knows how to treat each patron. With lightning-fast ID scans, this powerful tagging system transforms every interaction. VIPs receive red-carpet treatment from the moment they arrive, while potential troublemakers are discreetly managed before issues arise. It's not just identification; it's customer experience optimization at its finest.</p>
        <h4>Beyond Binary: Customization is King</h4>
        <p>Forget one-size-fits-all solutions. This feature empowers you to create a tagging ecosystem as unique as your venue. From loyalty tiers to special accommodations, the only limit is your imagination. Craft tags that speak your business's language, ensuring every staff member is on the same page, every time.</p>
        <h4>Security Meets Sophistication</h4>
        <p>In today's climate, robust security isn't just preferable - it's essential. This system acts as your silent sentinel, alerting staff to banned individuals without causing a scene. It's peace of mind wrapped in discretion, allowing you to maintain a welcoming atmosphere while keeping potential risks at bay.</p>
        <p>Don't just manage your venue - master it. The VIP/Banned List feature isn't merely an upgrade; it's a revolution in patron management. Elevate every interaction, fortify your security, and watch as your venue transforms into the efficiently run, customer-centric operation you've always envisioned.</p>
      `,
      pageLink: null
    },
    span: 1
  }
];

//Industries Section Content
const industries = [
  { title: <>Bars & Clubs</>, description: <>Keep the party going. Ease the burden on your staff. <MdBr/>Maintain ban lists across&nbsp;locations.</>, href: "/solutions/bar-id-scanner" },
  { title: <>Dispensaries</>, description: <>Intuitive compliance. Empower your staff. <MdBr/>Protect your&nbsp;investment.</>, href: "/solutions/dispensary-id-scanner/" },
  { title: <>Vape Shops</>, description: <>Eliminate risk. Simplify age verification. <MdBr/>In a solution that fits your&nbsp;business.</>, href: "/solutions/vape-shop-id-scanner/" },
  { title: <>Liquor Stores</>, description: <>Affordable compliance. Effortless operation. <MdBr/>Peace of&nbsp;mind.</>, href: "/solutions/liquor-store-id-scanner/" },
  { title: <>Convenience Stores</>, description: <>Streamline your processes. Prevent mis-carding. <MdBr/>Maintain rigorous&nbsp;compliance.</>, href: "/solutions/convenience-store-id-scanner/" },
  { title: <>Casinos</>, description: <>Uncompromising accuracy. Seamless integration. <MdBr/>Effortlessly manage&nbsp;exclusions.</>, href: "/solutions/casino-id-scanner-solutions" },
  { title: <>Hotels & Hospitality</>, description: <>Simplify data collection. Eliminate Errors. <MdBr/>Enhance the guest&nbsp;experience.</>, href: "/solutions/hospitality" },
  { title: <>Car Dealerships</>, description: <>Instant fraud detection. Empower your sales team. <MdBr/>Safeguard your&nbsp;reputation.</>, href: "/solutions/car-dealership-id-scanner/" },
];

const Component: FaustTemplate<GetCategoriesPreviewQuery> = (props) => {
  if (props.loading) {
    return <>Loading...</>;
  }

  if (!props.data) {
    return <>No data found</>;
  }


  return (
    <div style={{ overflowX: 'hidden' }}>

        <Seo data={
          (props.data?.nodeByUri as { seo?: PostSeoFieldsFragment })?.seo ?? null
        } />

        <Hero isLight={true}/>

          <SectionSpacer size="small" />

        <ThreeFeaturesSection
            title="Stop Mistakes at the Door"
            subtitle={
              <>
                The <Editorialitalics>id</Editorialitalics>
                <EditorialLight>Scanner</EditorialLight> Advantage
              </>
            }
            items={firstThreeFeaturesSectionData}
          />

          <SectionSpacer size="medium" />
        
        <div id="most-popular-products">
          <MostPopularProductsSection/>
        </div>

          <SectionSpacer size="medium" />

        <WizardCtaSection 
            bigText='Not sure <CtaItalicText>which</CtaItalicText> scanner is right for you?'
            buttonText={wizardCTA4ButtonText}
            smallText={wizardCTA4SmallText}
            bigTextWidth={{ sm: '300px', md: '630px' }}
          />

          <SectionSpacer size="medium" />
     
        <ReviewsSection 
          reviews={reviews}
          title="Why Our ID Scanners?"
          subtitle="In our customer's own words"
        />

          <SectionSpacer size="small" />

        <IconQuoteSection
            iconType="lightning"
            iconSize={{ base: 300, md: 405 }}
            text={<>"Know <ItalicText>exactly</ItalicText> who you're dealing with in less than second."</>}
            textboxWidth={{ base: '350px', md: '500px' }}
            lightUpOnHover={true}
        />

          <SectionSpacer size="medium" />

        <WizardCtaSection 
            bigText="<CtaItalicText>Experience</CtaItalicText> Instant Security"
            buttonText={wizardCTA2ButtonText}
            smallText={wizardCTA2SmallText}
            bigTextWidth={{ sm: '350px', md: '800px' }}
        />

          <SectionSpacer size="medium" />

        <FakeIdSections/>

          <SectionSpacer size="small" />

        <CaseStudiesSection 
          caseStudies={caseStudies}
        />

          <SectionSpacer size="medium" />

        <IndustriesSection industries={industries} />

          <SectionSpacer size="medium" />

        <WizardCtaSection 
            bigText="Don't see <CtaItalicText>your</CtaItalicText> industry?"
            buttonText={wizardCTA3ButtonText}
            smallText={wizardCTA3SmallText}
            bigTextWidth={{ sm: '300px', md: '800px' }}
          />

          <SectionSpacer size='medium' />

        <ThreeFeaturesSection
          title="Enhance Everyday Operations"
          subtitle="Run a more efficient and effective&nbsp;business"
          items={enhanceEverydayOperationsData}
        />
              
          <SectionSpacer size='medium' />

        <IdInsightsPreviewSection categories={props.data.categories} />

        <SectionSpacer size='large' />
    </div>
  );
};

// Define the query
Component.query = GetCategoriesPreviewDocument;

Component.variables = (seedQuery, context) => {
  return {
    id: seedQuery?.databaseId,
    asPreview: context?.asPreview,
  };
};

export default Component;