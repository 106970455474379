// NavLogo.tsx
import React from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import breakpoints from '@/styles/breakpoints';
import Link from 'next/link';


const LogoWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  * {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.md}px) {
    display: inline-flex;
    padding: 19.36px 0px 14.357px 29px;
    justify-content: flex-end;
    align-items: center;
    position: static; /* Revert to static positioning */
    transform: none; /* Remove transform */
  }
`;

const Logo = styled.div`
  height: 18.45px;
  width: auto;
  margin-top: -8px;

  @media (min-width: ${breakpoints.md}px) {
    height: 26.283px;
  }
`;

export const NavLogo: React.FC = () => {
  return (
    <LogoWrapper>
      <Link href="/" passHref>
        <Logo>
          <Image  
            src="/icons/tokenworks-logo.svg" 
            alt="TokenWorks Logo"
            width={157} 
            height={26.283}
          />
        </Logo>
      </Link>
    </LogoWrapper>
  );
};

export default NavLogo;