// src/components/spotlight/sections/CaseStudySection/CaseStudyCarousel.tsx
import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { CaseStudyCard } from './CaseStudyCard';
import breakpoints from '@/styles/breakpoints'
import CircleArrowButton from '@/components/icons/CircleArrowButton';

interface CaseStudy {
  id: string;
  image: string;
  company: string;
  location: string;
  previewText: string;
  caseStudyUrl: string;
}

interface CaseStudyCarouselProps {
  caseStudies: CaseStudy[];
}

const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const CarouselList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 335px;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 20px;
  padding: 0 20px 10px 20px;
  margin: 0 0 60px 0; // Added bottom margin for navigation buttons
  list-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }


  @media (min-width: ${breakpoints.md}px) {
    grid-auto-columns: 335px;
    scroll-padding: 0 40px;
    padding: 0 40px 20px 40px;

  }


  @media (min-width: ${breakpoints.lg}px) {
    grid-auto-columns: 795px;
    scroll-padding: 0 80px;
    padding: 0 80px 20px 80px;

  }

  @media (min-width: ${breakpoints.xl}px) {
    grid-auto-columns: 795px;
    scroll-padding: 0 calc(120px + ((100vw - 1440px) / 2));
    padding-left: calc(120px + ((100vw - 1440px) / 2));
    padding-right: calc(120px + ((100vw - 1440px) / 2));
  }
`;



const CarouselItem = styled.li`
  scroll-snap-align: start;
  margin: 0 auto; // centers the card if it hits the max-width

`;

const NavigationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  gap: 10px;
  max-width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    right: 40px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    right: calc(120px + ((100vw - 1440px) / 2));
  }
`;

interface CaseStudyCarouselProps {
  caseStudies: CaseStudy[];
}

export const CaseStudyCarousel: React.FC<CaseStudyCarouselProps> = ({ caseStudies }) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [cardWidth, setCardWidth] = useState(335);


  const handleScroll = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const cardWidth = listRef.current.querySelector('li')?.offsetWidth || 0;
      const gap = 20; // gap between cards
      const scrollPadding = window.innerWidth >= breakpoints.lg ? 40 : 20;
      const scrollAmount = cardWidth + gap;
      
      const currentScroll = listRef.current.scrollLeft;
      const targetScroll = direction === 'left' 
        ? Math.max(currentScroll - scrollAmount, 0)
        : currentScroll + scrollAmount;
      
      listRef.current.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const checkScrollable = () => {
    if (listRef.current) {
      setCanScrollLeft(listRef.current.scrollLeft > 0);
      setCanScrollRight(
        listRef.current.scrollLeft < listRef.current.scrollWidth - listRef.current.clientWidth - 1
      );
    }
  };

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', checkScrollable);
      checkScrollable();
    }

    const handleResize = () => {
      setCardWidth(window.innerWidth >= breakpoints.lg ? 795 : 335);
      checkScrollable();
    };

    window.addEventListener('resize', handleResize);
    handleResize();


    return () => {
      if (list) {
        list.removeEventListener('scroll', checkScrollable);
      }
    window.removeEventListener('resize', handleResize);
  };
}, []);

  // Touch swiping logic
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe && canScrollRight) {
      handleScroll('right');
    } else if (isRightSwipe && canScrollLeft) {
      handleScroll('left');
    }
    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <CarouselWrapper>
      <CarouselList
        ref={listRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {caseStudies.map((caseStudy) => (
          <CarouselItem key={caseStudy.id}>
            <CaseStudyCard {...caseStudy} />
          </CarouselItem>
        ))}
      </CarouselList>
      <NavigationWrapper>
      <CircleArrowButton 
              direction="left" 
              onClick={() => handleScroll('left')} 
              disabled={!canScrollLeft} 
              ariaLabel="Scroll left"
            />
            <CircleArrowButton 
              direction="right" 
              onClick={() => handleScroll('right')} 
              disabled={!canScrollRight} 
              ariaLabel="Scroll right"
            />
      </NavigationWrapper>
    </CarouselWrapper>
  );
};
