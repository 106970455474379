import { getQlmCreds } from "@/services/qlm";
import { CartData, LocalCartItem } from "./types";

//This is the input types from server
export type CartContentsNode = NonNullable<NonNullable<NonNullable<CartData['cart']>['contents']>['nodes'][number]>;

export type ProcessCartItemsReturn = LocalCartItem[];


const parseCurrency = (value: string): number => {
    return parseFloat(value.replace(/[$,]/g, ''));
};

const processAddons = (extraData: CartContentsNode['extraData']): LocalCartItem['addOns'] => {
  if (!extraData) return undefined;  
  
  const addonsData = extraData.find(data => data?.key === "addons");
    if (addonsData?.value) {
      try {
        const parsedAddons = JSON.parse(addonsData.value);
        return Array.isArray(parsedAddons) ? parsedAddons.map((addon: any) => ({
          ...addon,
          price: parseFloat(addon.price) || 0,
        })) : [];
      } catch (error) {
        console.error('Error parsing addons:', error);
        return [];
      }
    }
    return [];
};

export function getCartItems(data: CartData): LocalCartItem[] {
    if (!data?.cart?.contents?.nodes) {
        return [];
    }

    const cartItems: LocalCartItem[] = data.cart.contents.nodes.map((node: CartContentsNode) => {
        const addOns = processAddons(node.extraData);
        const subtotalPrice = parseCurrency(node.subtotal ?? '0');
        const pricePerItem = subtotalPrice / (node.quantity ?? 1);
        const qlmCreds = getQlmCreds(node.extraData);

        return {
          id: node.key,
          name: node.product?.node.name ?? '',
          price: pricePerItem,
          subtotalPrice: subtotalPrice,
          quantity: node.quantity ?? 0,
          imageSrc: node.variation?.node.image?.mediaItemUrl ?? 
                      node.product?.node.featuredImage?.node.mediaItemUrl ?? 
                      '/placeholder-image.jpg',
          maxQuantity: 
            (node.product?.node.__typename === 'SimpleProduct' || node.product?.node.__typename === 'VariableProduct')
              ? node.product.node.stockQuantity ?? 100
              : 100, //lol put 100 if null
          variationName: node.variation?.node.name?.replace(/\s-\s\$\d+(\.\d{2})?$/, '') || undefined,
          addOns: addOns,
          qlmCreds: qlmCreds
        };
    });

    return cartItems;
}