// components/layout/NavBar/index.tsx
import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { NavIconsContainer } from '@/components/layout/NavBar/NavIconsContainer';
import { NavLinkWrapper } from '@/components/layout/NavBar/NavLinkWrapper';
import NavLogo from '@/components/layout/NavBar/NavLogo';
import { HamburgerMenuIcon } from '@/components/layout/NavBar/HamburgerMenuIcon';
import { MobileMenu } from '@/components/layout/NavBar/MobileMenu';
import { DesktopMenu } from '@/components/layout/NavBar/DesktopMenu';
import ScrollLock from '@/components/layout/NavBar/ScrollLock';
import ProductName from '@/components/common/ProductName';
import Button from '@/components/common/Button';
import breakpoints from '@/styles/breakpoints';
import { useRouter } from 'next/router';
import { useProductNav } from '@/providers/ProductNavProvider';

const NavBarContainer = styled.nav<{ isTransparent: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ isTransparent }) => isTransparent ? 'transparent' : 'rgba(0, 0, 0, 0.70)'};
  position: fixed;
  height: 60px;
  padding: 0;
  top: 0;
  left: 0; 
  right: 0;
  z-index: 100;
  backdrop-filter: ${({ isTransparent }) => isTransparent ? 'none' : 'blur(21px)'};
  will-change: backdrop-filter, background;
  transition: background 0.3s ease, backdrop-filter 0.3s ease;
  max-width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px; 
    height: 1px; 
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    pointer-events: none;
    opacity: ${({ isTransparent }) => isTransparent ? 0 : 1};
    will-change: opacity;
    transition: opacity 0.3s ease;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
`;

const ProductNameWrapper = styled.div`
  color: #FFF;
  margin-top: 3px;
`;

const DefaultNavContent = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};
  will-change: opacity, visibility;
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ProductNavContent = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};
  position: absolute;
  top: 0;
  padding: 0 20px 0 20px;
  will-change: opacity, visibility;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 40px 0 40px;
  }
`;

interface NavBarProps {
  productName?: string;
  buyButtonText?: string;
  onBuyClick?: () => void;
}

export const NavBar: React.FC<NavBarProps> = ({ 
  productName, 
  buyButtonText = "Buy Now", 
  onBuyClick 
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('Most Popular');
  const [isTransparent, setIsTransparent] = useState(true);
  const [showProductNav, setShowProductNav] = useState(false);
  const [isDefaultNavVisible, setIsDefaultNavVisible] = useState(true);
  const { productNav } = useProductNav();

  const isProductPage = !!productNav;
  const router = useRouter();

  // Move resetNavigationState to the top, after state declarations
  const resetNavigationState = useCallback(() => {
    setIsMobileMenuOpen(false);
    setIsDesktopMenuOpen(false);
    setShowProductNav(false);
    setIsTransparent(window.scrollY <= 100); // Use window.scrollY instead of isScrolled
    setIsDefaultNavVisible(true);
    setActiveCategory('Most Popular');
  }, []); // Remove isScrolled from dependencies

  const closeNav = useCallback(() => {
    setIsMobileMenuOpen(false);
    setIsDesktopMenuOpen(false);
    setIsTransparent(window.scrollY <= 100);
    setShowProductNav(false);
    setIsDefaultNavVisible(true);
  }, []);

  // Update toggleMobileMenu
  const toggleMobileMenu = useCallback(() => {
    if (isMobileMenuOpen) {
      resetNavigationState();
    } else {
      setIsMobileMenuOpen(true);
      setIsDesktopMenuOpen(false);
      setIsTransparent(false);
      setShowProductNav(false);
      setIsDefaultNavVisible(false);
    }
  }, [isMobileMenuOpen, resetNavigationState]);


  useEffect(() => {
    const handleRouteChangeStart = () => {
      console.log('Route change start');
      closeNav();
    };
  
    const handleRouteChangeComplete = (url: string) => {
      console.log('Route change complete:', url);

      // Don't scroll if:
      // It's exactly the same URL
      if (
        url === router.asPath
      ) {
        return;
      }
      
      window.scrollTo(0, 0);
    };
  
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
  
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router, closeNav]);

  // for switching to product nav on spotlight pages
  const handleScroll = useCallback(() => {
    if (isMobileMenuOpen || isDesktopMenuOpen) return;

    const scrollPosition = window.scrollY;
    const streamingTextSection = document.querySelector('.streaming-text-section');
    
    if (streamingTextSection) {
      const sectionTop = streamingTextSection.getBoundingClientRect().top + window.scrollY;
      setShowProductNav(scrollPosition >= sectionTop - 60);
    }

    setIsTransparent(scrollPosition <= 100);
  }, [isMobileMenuOpen, isDesktopMenuOpen]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleDesktopMenu = useCallback(() => {
    setIsDesktopMenuOpen(prev => !prev);
    if (!isDesktopMenuOpen) {
      setIsMobileMenuOpen(false);
      setIsTransparent(false);
      setShowProductNav(false);
    } else {
      // When closing the desktop menu
      setIsTransparent(window.scrollY <= 100);
      // Optionally, you might want to restore product nav if applicable
      if (isProductPage) {
        const streamingTextSection = document.querySelector('.streaming-text-section');
        if (streamingTextSection) {
          const sectionTop = streamingTextSection.getBoundingClientRect().top + window.scrollY;
          setShowProductNav(window.scrollY >= sectionTop - 60);
        }
      }
    }
  }, [isDesktopMenuOpen, isProductPage]);

  return (
    <>
      <NavBarContainer isTransparent={isTransparent && !isMobileMenuOpen && !isDesktopMenuOpen}>
      <NavContent>
          <DefaultNavContent isVisible={!showProductNav || isMobileMenuOpen || isDesktopMenuOpen}>
            <HamburgerMenuIcon onClick={toggleMobileMenu} isOpen={isMobileMenuOpen} />
            <NavLogo/>
            <NavLinkWrapper 
              onIdScannersClick={toggleDesktopMenu} 
              isDesktopMenuOpen={isDesktopMenuOpen}
            />
            <NavIconsContainer />
          </DefaultNavContent>
          {isProductPage && (
            <ProductNavContent isVisible={showProductNav && !isMobileMenuOpen && !isDesktopMenuOpen}>
              <ProductNameWrapper>
                <ProductName 
                  name={productNav.productName} 
                  fontSize={{
                    base: 38.25,
                    md: 38.25
                  }}
                  helveticaWeight={400}
                  helveticaLetterSpacing="-0.02em"
                />
              </ProductNameWrapper>
              <Button 
                onClick={productNav.onBuyClick}
                variant='nav'
              >
                {productNav.buyButtonText}
              </Button>
            </ProductNavContent>
          )}
        </NavContent>
        </NavBarContainer>
        <MobileMenu
          isOpen={isMobileMenuOpen} 
          toggleMenu={toggleMobileMenu}
        />
        <DesktopMenu
          isOpen={isDesktopMenuOpen} 
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
    <ScrollLock isLocked={isMobileMenuOpen} />
  </>
);
};

export default NavBar;