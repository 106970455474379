import React from 'react'
import styled from '@emotion/styled'

interface BadgeProps {
  scale: number;
}

const BadgeContainer = styled.div<BadgeProps>`
    width: ${props => 45 * props.scale}px;
    height: ${props => 45 * props.scale}px;
    flex-shrink: 0;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: ${props => 8 * props.scale}px;
    right: ${props => 8 * props.scale}px;
`;

const Badge = styled.svg<BadgeProps>`
    max-width: ${props => 45 * props.scale}px;
    max-height: ${props => 45 * props.scale}px;
    width: 100%;
    height: 100%;
    fill: #D72522;
    filter: drop-shadow(0px ${props => 1.81 * props.scale}px ${props => 4.524 * props.scale}px rgba(0, 0, 0, 0.66)) drop-shadow(0px 0px ${props => 5 * props.scale}px rgba(237, 0, 0, 0.66));
`;

const PopularText = styled.text<BadgeProps>`
    font-family: "PP Neue Montreal";
    font-size: 4.52px;
    font-style: normal;
    line-height: 70%;
    fill: #FFF;
    text-shadow: 0px 0px 0.45px rgba(0, 0, 0, 0.12);
`;

const CategoryDiv = styled.div<BadgeProps>`
    font-family: "PP Editorial New";
    font-size: 7.239px;
    font-style: normal;
    font-weight: 200;
    text-align: center;
    line-height: normal;
    color: #FFF;
    text-shadow: 0px 0px 0.452px rgba(0, 0, 0, 0.25);
    max-width: 31px;
    margin: 0 auto;
    word-wrap: break-word;
`;

interface ProductBadgeProps {
  category: string;
  scale: number;
}

const ProductBadge: React.FC<ProductBadgeProps> = ({ category, scale }) => {
    return (
        <BadgeContainer scale={scale}>
            <Badge xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" scale={scale}>
                <path d="M20.8235 1.18797C21.6505 0.47773 22.8722 0.47773 23.6992 1.18797L26.0167 3.17831C26.5487 3.63522 27.2662 3.81207 27.9496 3.65474L30.9267 2.96939C31.989 2.72483 33.0708 3.29258 33.473 4.30579L34.6001 7.14516C34.8588 7.79697 35.412 8.28701 36.0902 8.4653L39.0447 9.24195C40.099 9.51909 40.793 10.5245 40.6783 11.6086L40.3568 14.6465C40.283 15.3439 40.545 16.0349 41.0628 16.508L43.3179 18.5687C44.1227 19.304 44.2699 20.5168 43.6645 21.4234L41.9681 23.9639C41.5786 24.5472 41.4895 25.2808 41.7281 25.9402L42.7673 28.8129C43.1381 29.838 42.7049 30.9803 41.7476 31.5018L39.0648 32.9629C38.4489 33.2983 38.0291 33.9065 37.9339 34.6013L37.519 37.6279C37.371 38.7079 36.4565 39.518 35.3665 39.5348L32.312 39.5818C31.6108 39.5926 30.9564 39.9361 30.5492 40.507L28.7754 42.9941C28.1424 43.8816 26.9562 44.174 25.9832 43.6823L23.2567 42.3044C22.6308 41.9881 21.8918 41.9881 21.2659 42.3044L18.5394 43.6823C17.5665 44.174 16.3803 43.8816 15.7473 42.9941L13.9734 40.507C13.5662 39.9361 12.9119 39.5926 12.2107 39.5818L9.15612 39.5348C8.06613 39.518 7.15168 38.7079 7.00364 37.6279L6.58878 34.6013C6.49354 33.9065 6.07375 33.2983 5.45788 32.9629L2.77507 31.5018C1.81773 30.9803 1.38451 29.838 1.75534 28.8129L2.79453 25.9402C3.03309 25.2808 2.94401 24.5472 2.55457 23.9639L0.858088 21.4234C0.252706 20.5168 0.399965 19.304 1.20471 18.5687L3.45989 16.508C3.97759 16.0349 4.23964 15.3439 4.16583 14.6465L3.84432 11.6086C3.7296 10.5245 4.42359 9.51909 5.4779 9.24195L8.43242 8.4653C9.11067 8.28701 9.66381 7.79697 9.92256 7.14516L11.0497 4.30579C11.4519 3.29258 12.5336 2.72483 13.596 2.96939L16.573 3.65474C17.2564 3.81207 17.9739 3.63522 18.5059 3.17831L20.8235 1.18797Z" />
                <PopularText x="50%" y="13" textAnchor="middle" scale={scale}>Popular</PopularText>
                <foreignObject x="7" y="18" width="31" height="18">
                    <CategoryDiv scale={scale}>{category}</CategoryDiv>
                </foreignObject>
            </Badge>
        </BadgeContainer>
    )
}

export default ProductBadge;