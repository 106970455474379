//pages/spotlight/components/sections/TechSpecsSection
import React, { useState } from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Section";
import breakpoints from "@/styles/breakpoints";
import SectionTitle from "./SectionTitle"

const TsSection = styled(Section)`
    padding: 0;
    max-width: 100%;
`
const SpecGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  max-width: 566px;

  @media (min-width: ${breakpoints.md}px) {
    grid-gap: 0;
    grid-row-gap: 20px;
    grid-column-gap: 80px;
  }
`

const FullWidthCategory = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.md}px) {
   grid-column: 1;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 30%;
  height: 160px; 
  transform: translateX(-50%);
  left: 50%;

  @media (min-width: ${breakpoints.md}px) {
    height: 194px;
    transform: translateX(-75%);
  }
`;

const SpecCategory = styled.div`
  margin-bottom: 12px;
`;

const CategoryTitle = styled.h5`
    font-size: 18px;
    color: rgba(255, 255, 255, 0.92);
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 2px solid #666; // Placeholder gray, adjust as needed

    @media (min-width: ${breakpoints.md}px) {
        font-size: 26px;
    }
`;

const SpecList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SpecItem = styled.li`
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: normal;
    font-size: 12px;
    margin-bottom:12px;
    color: #86868B;
    letter-spacing: .01rem;
  
    @media (min-width: ${breakpoints.md}px) {
      font-size: 16px;
  }

`;

const SpecLabel = styled.span`
  color: rgba(255, 255, 255, 0.92);
`;


interface Spec {
  label: string;
  value: string;
}

interface SpecCategory {
  title: string;
  specs: Spec[];
  fullWidth?: boolean;
  image?: string;
}

interface TechSpecsSectionProps {
  specCategories: SpecCategory[];
}
  
export const TechSpecsSection: React.FC<TechSpecsSectionProps> = ({ specCategories }) => {
  return (
    <TsSection>
      <Container>
        <SectionTitle />
        <SpecGrid>
          {specCategories.map((category, index) => (
            category.fullWidth ? (
              <FullWidthCategory key={index}>
                <CategoryTitle>{category.title}</CategoryTitle>
                {category.image && (
                  <ImageContainer>
                    <Image 
                      src={category.image} 
                      alt={category.title}
                      fill={true}
                      style={{
                        objectFit: "contain",
                        filter: "grayscale(100%)"
                      }}
                      sizes={`218px`}
                    />
                  </ImageContainer>
                )}
                <SpecList>
                  {category.specs.map((spec, specIndex) => (
                    <SpecItem key={specIndex}>
                      <SpecLabel>{spec.label}<br /></SpecLabel> 
                      {spec.value}
                    </SpecItem>
                  ))}
                </SpecList>
              </FullWidthCategory>
            ) : (
              <SpecCategory key={index}>
                <CategoryTitle>{category.title}</CategoryTitle>
                <SpecList>
                  {category.specs.map((spec, specIndex) => (
                    <SpecItem key={specIndex}>
                      <SpecLabel>{spec.label}<br /></SpecLabel> {spec.value}
                    </SpecItem>
                  ))}
                </SpecList>
              </SpecCategory>
            )
          ))}
        </SpecGrid>
      </Container>
    </TsSection>
  );
};

export default TechSpecsSection;