//InTheBoxSection/index.tsx
import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Container";
import { useAddOns } from '@/providers/AddOnsProvider';
import breakpoints from '@/styles/breakpoints';
import GridItem from './GridItem';
import SectionTitle from './SectionTitle';

const StyledSection = styled(Section)`
    max-width: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 20px;
  
  @media (min-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

interface GridItemData {
  text: string;
  src: string;
  columnSpan: number;
  rowSpan: number;
  addon: boolean;
}


interface InTheBoxData {
  items: GridItemData[];
}

interface InTheBoxSectionProps {
  data: InTheBoxData;
}

// InTheBoxSection/index.tsx
export const InTheBoxSection = memo(({ data }: InTheBoxSectionProps) => {
  const { state, isSelected, toggleAddOn } = useAddOns();
  const maxColumns = Math.max(...data.items.map(item => item.columnSpan));


  return (
    <StyledSection>
      <Container>
        <SectionTitle/>
        <Grid>
          {data.items.map((item) => {
            const matchingAddon = state.addOns.find(
              addon => addon.name === item.text
            );

            const addonPrice = matchingAddon?.price != null 
              ? `$${Math.floor(matchingAddon.price)}` 
              : undefined;

            return (
              <GridItem
                key={item.text}
                text={item.text}
                src={item.src}
                columnSpan={item.columnSpan}
                rowSpan={item.rowSpan}
                maxColumns={maxColumns}
                addon={item.addon}
                price={addonPrice}
                checked={isSelected(item.text)}
                onToggle={() => {
                  if (item.addon) {
                    toggleAddOn(item.text);
                  }
                }}
              />
            );
          })}
        </Grid>
      </Container>
    </StyledSection>
  );
});

InTheBoxSection.displayName = 'InTheBox';

export default InTheBoxSection;