import CircleButton from "./CircleButton";

const CloseButton = ({...props}) => {
    return (
        <CircleButton label="Close" {...props}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 16 16" 
                width="16" 
                height="16" 
                aria-hidden="true" 
                focusable="false"
            >
                <path d="M1 1L15 15" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                <path d="M15 1L1 15" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </CircleButton>
    );
}

export default CloseButton;