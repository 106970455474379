// src/constants/productNames.ts

export const PRODUCT_NAMES = [
    'idVisor',
    'idVisor Pro',
    'idVisor Wedge',
    'idVisor Flex',
    'idWedge',
    'identifake',
    'idStand',
  ];