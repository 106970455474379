import { getNextStaticProps } from '@faustwp/core';
import { GetNavGroupsDocument, GetNavGroupsQuery, GetNavGroupProductsDocument, GetNavGroupProductsQuery } from '@/generated/graphql';
import { NavDataType, ProcessedNavGroup, ProcessedNavGroupProduct, NavGroupProduct } from '@/utils/hooks/useNavData';

const extractPathAndSlug = (link: string | null | undefined): { path: string, slug: string } => {
  if (!link) return { path: '/product', slug: '' };

  try {
    const url = new URL(link);
    const pathParts = url.pathname.split('/').filter(Boolean);

    if (pathParts.length >= 2) {
      return { path: `/${pathParts[pathParts.length - 2]}`, slug: pathParts[pathParts.length - 1] };
    } else if (pathParts.length === 1) {
      return { path: '/product', slug: pathParts[0] };
    }
  } catch (error) {
    console.error('Error parsing URL:', error);
  }

  return { path: '/product', slug: '' };
};

function isValidProduct(product: NavGroupProduct): product is (NavGroupProduct & { __typename: 'SimpleProduct' | 'VariableProduct' }) {
  return product.__typename === 'SimpleProduct' || product.__typename === 'VariableProduct';
}

function processProduct(product: NavGroupProduct & { __typename: 'SimpleProduct' | 'VariableProduct' }): ProcessedNavGroupProduct {
  const previewData = product.productPreviewData;
  
  const getProductLink = (): string => {
    if (previewData?.link?.nodes?.[0]?.link) {
      const link = previewData.link.nodes[0].link;
      try {
        const url = new URL(link);
        return url.pathname;
      } catch (error) {
        console.error('Error parsing URL:', error);
      }
    }
    return `/product/${product.slug || ''}`;
  };

  return {
    __typename: product.__typename,
    databaseId: product.databaseId,
    name: product.name ?? null,
    slug: product.slug ?? null,
    onSale: product.onSale ?? null,
    price: product.price ?? null,
    regularPrice: product.regularPrice ?? null,
    salePrice: product.salePrice ?? null,
    featuredImage: product.featuredImage ? {
      node: {
        altText: product.featuredImage.node?.altText ?? null,
        sourceUrl: product.featuredImage.node?.sourceUrl ?? null,
      }
    } : null,
    navGroups: product.navGroups ? {
      nodes: product.navGroups.nodes.map(node => ({
        name: node.name ?? null
      }))
    } : null,
    productPreviewData: previewData ? {
      mostPopular: previewData.mostPopular ?? null,
      categoryForMostPopularBadge: previewData.categoryForMostPopularBadge ?? null,
      displayName: previewData.displayName ?? null,
      defaultImage: previewData.defaultImage ? {
        node: {
          altText: previewData.defaultImage.node?.altText ?? null,
          sourceUrl: previewData.defaultImage.node?.sourceUrl ?? null,
        }
      } : null,
      hoverImage: previewData.hoverImage ? {
        node: {
          altText: previewData.hoverImage.node?.altText ?? null,
          sourceUrl: previewData.hoverImage.node?.sourceUrl ?? null,
        }
      } : null,
      link: previewData.link ? {
        nodes: previewData.link.nodes?.map(node => ({
          link: node.link ?? null,
          slug: node.slug ?? null
        })) ?? null
      } : null
    } : null,
    isMostPopular: previewData?.mostPopular ?? false,
    categoryBadge: previewData?.mostPopular 
      ? (previewData.categoryForMostPopularBadge || product.navGroups?.nodes[0]?.name || null)
      : null,
    displayName: previewData?.displayName || product.name || '',
    defaultImage: previewData?.defaultImage?.node?.sourceUrl || product.featuredImage?.node?.sourceUrl || '',
    hoverImage: previewData?.hoverImage?.node?.sourceUrl || product.featuredImage?.node?.sourceUrl || '',
    link: getProductLink(),
  };
}

export async function getNavData(): Promise<NavDataType> {
  const navGroupResult = await getNextStaticProps<{
    data: GetNavGroupsQuery;
  }>({} as any, {
    Page: {
      query: GetNavGroupsDocument,
    },
  });

  const navGroupProps = (navGroupResult as any).props;

  const navGroups = navGroupProps.data.navGroups?.nodes ?? [];
  const sortedNavGroups = [...navGroups].sort((a, b) => {
    const order = ['most-popular', 'mobile', 'stationary', 'software', 'accessories'];
    return order.indexOf(a?.slug ?? '') - order.indexOf(b?.slug ?? '');
  });

  const fullNavData = await Promise.all(sortedNavGroups.map(async (group) => {
    if (!group?.slug) return null;

    const productResult = await getNextStaticProps<{
      data: GetNavGroupProductsQuery;
      variables: { slug: string };
    }>({} as any, {
      Page: { 
        query: GetNavGroupProductsDocument,
        variables: () => ({ slug: group.slug as string }),
      },
    });

    const productProps = (productResult as any).props;

    const processedProducts = (productProps.data.navGroup?.contentNodes?.nodes ?? [])
      .filter(isValidProduct)
      .map(processProduct);

    return {
      ...group,
      products: processedProducts,
    };
  }));

  const processedNavData = fullNavData.filter((item): item is ProcessedNavGroup => item !== null);

  // Create "Most Popular" group
  const mostPopularProducts = processedNavData.flatMap(group => 
    group.products.filter(product => product.isMostPopular)
  );

  if (mostPopularProducts.length > 0) {
    const mostPopularGroup: ProcessedNavGroup = {
      name: 'Most Popular',
      slug: 'most-popular',
      databaseId: 0,
      count: mostPopularProducts.length,
      products: mostPopularProducts,
    };

    processedNavData.unshift(mostPopularGroup);
  }

  return processedNavData;
}