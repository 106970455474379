import { useZohoChat } from "@/providers/ZohoChatProvider";
import { css, Global } from "@emotion/react";
import Script from "next/script";

export default function ZohoChat() {
    // In the future, the ZohoChatProvider can be used to configure
    // more than margin. We can also disable the bot on different pages.
    
    const { bottomMargin } = useZohoChat();

    return (
        <>
            <Global
                styles={css`
                    #zsiq_float {
                        font-family: 'PP Neue Montreal' !important;
                        position: absolute;
                        bottom: ${bottomMargin}px !important;
                        transition: bottom 0.5s cubic-bezier(0.4, 0, 0.2, 1) !important;
                    }
                `}
            />
            <Script id="zoho-salesiq" strategy="afterInteractive">
                {`
                    var $zoho=$zoho || {};
                    $zoho.salesiq = $zoho.salesiq || {
                        widgetcode: "siqd549edbd16dd1fe8dfb655e71b4043247a5ed329e67adc1391050a27d73e4e8d",
                        values:{},
                        ready:function(){
                            $zoho.salesiq.floatbutton.position("bottomright");
                        }
                    };
                    var d=document;s=d.createElement("script");
                    s.type="text/javascript";
                    s.id="zsiqscript";
                    s.defer=true;
                    s.src="https://salesiq.zohopublic.com/widget";
                    t=d.getElementsByTagName("script")[0];
                    t.parentNode.insertBefore(s,t);
                `}
            </Script>
        </>
    )
}