import styled from '@emotion/styled'
import Image from 'next/image';
import Section from '@/components/layout/Base/Section';
import ScanImage from './generic_license-scan_full_hero.png';
import breakpoints from '@/styles/breakpoints';
import Container from "@/components/layout/Base/Container";
import { blurDataUrlSoft } from '@/styles/blurDataUrl';

const StyledSection = styled(Section)`
    overflow: hidden;
    background-color: black;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0px;
    max-width: 100%;
`

const HeroContainer = styled(Container)`
    position: relative;
    height: 90svh;
    max-height: 800px;
`

const Textbox = styled.div`
    padding: 20px;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 1;
`

const Title = styled.h1`
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 72px;
    }
`

const Description = styled.p`
    font-size: 18px;
    line-height: 150%;
    color: rgb(var(--token-gray));
    text-align: center;
    max-width: 500px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 20px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 26px;
    }
`

const ProductImage = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
`

const EasyUseHeroSection = () => {
    return (
        <StyledSection>
            <HeroContainer>
                <Textbox>
                    <Title>Blazing Fast,<br/>Accurate Scans</Title>
                    <Description>The idVisor delivers quick, dependable ID checks, ensuring your operations run smoothly without long lines or errors.</Description>
                </Textbox>
                <ProductImage>
                    <Image
                        src={ScanImage}
                        alt=''
                        fill={true}
                        sizes="100vw"
                        style={{objectFit: "cover"}}	
                        placeholder='blur'
                        blurDataURL={blurDataUrlSoft}
                    />
                </ProductImage>
            </HeroContainer>
        </StyledSection>
    )
}

export default EasyUseHeroSection;