// ./wp-templates/single.tsx
import { FaustTemplate } from "@faustwp/core";
import { GetPostQuery, GetPostDocument } from '../generated/graphql';
import { format } from 'date-fns';
import PostCover from "@/components/blog/PostCover";
import PostContent from '@/components/blog/PostContent';
import PostInfo from '@/components/blog/PostInfo';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import FadeShrinkScroll from "@/components/animations/FadeShrinkScroll";
import FeaturedImage from "@/components/blog/FeaturedImage";
import { Seo } from "@/components/layout/Base/Seo";
import { useEffect, useState } from "react";
import { processPostContent } from "@/components/blog/processPostContent";


const Component: FaustTemplate<GetPostQuery> = (props) => {
  if (props.loading) return <>Loading...</>; // Loading state for previews
  
  const post = props.data?.post as GetPostQuery['post'];
  const [processedContent, setProcessedContent] = useState<string>('');

  if (!post) return <div>No post found</div>;
  
  const { title, content, featuredImage, date, author, categories, tags, commentCount } = post;
  
  const emptyNodeArray = { nodes: [] };

  const dateFormatted = date 
    ? format(new Date(date), 'MMMM d, yyyy')
    : 'Date not available';

  useEffect(() => {
    if (content) {
      processPostContent(content)
        .then(processed => setProcessedContent(processed))
        .catch(error => {
          console.error('Error processing content:', error);
          setProcessedContent(content); // Fallback to unprocessed content
        });
    }
  }, [content]);

  return (
    <>
      <Seo data={props.data?.post?.seo} />
      <article>
        <FadeShrinkScroll>
          <PostCover title={title ?? 'Untitled'} date={dateFormatted} categories={categories ?? emptyNodeArray}/>
        </FadeShrinkScroll>
        
        <div style={{ zIndex: "1", position: "relative" }}>
          <FeaturedImage 
            src={featuredImage?.node?.mediaItemUrl}
            alt={featuredImage?.node?.altText ?? ''}
            width={featuredImage?.node?.mediaDetails?.width}
            height={featuredImage?.node?.mediaDetails?.height}
          />
          <PostContent content={processedContent ?? ''}/>
          <SectionSpacer size="small"/>
        </div>

        <PostInfo 
          date={dateFormatted} 
          categories={categories ?? emptyNodeArray}
          tags={tags ?? emptyNodeArray}
          author={author}
          commentCount={commentCount ?? 0}
        />
        <SectionSpacer size="large"/>
      </article>
    </>
  );
}


Component.query = GetPostDocument;

Component.variables = (seedQuery, context) => {
  return {
    id: seedQuery?.databaseId,
    asPreview: context?.asPreview,
  };
};

export default Component;