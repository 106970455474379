import styled from "@emotion/styled";
import breakpoints from "@/styles/breakpoints";

const BackgroundWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
`

const Background = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%; /* to force height beyond 100vh */
    z-index: -1;
    background-color: rgb(var(--background-blue));
    display: flex;
    justify-content: space-between;
    padding: 0 9px;
    margin: auto;


    @media (min-width: ${breakpoints.md}px) {
        padding: 0 19px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        width: 1440px; /* Fixed width to match .xl breakpoint */
        left: 50%;
        transform: translateX(-50%);
        padding: 0 79px;
    }

    @media (min-width: ${breakpoints.xl}px) {
        left: 0;
        transform: none;
        padding: 0 79px;
    }
`;

const DashedLine = styled.div`
    width: 2px;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%, transparent 100%);
    background-size: 100% 10px; /* Adjust the second value (10px) to control the dash size */

    &.hidden-mobile {
    @media (max-width: ${breakpoints.lg - 1}px) {
      visibility: hidden;
    }}
`;

const PageBackground = () => {
    return (
        <>
        <BackgroundWrapper>
            <Background>
                <DashedLine/>
                <DashedLine className='hidden-mobile' />
                <DashedLine/>
                <DashedLine className='hidden-mobile' />
                <DashedLine/>
            </Background>
        </BackgroundWrapper>
        </>
    );
};

export default PageBackground;
