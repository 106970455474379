//pages/spotlight/components/sections/FormulaSection/icons/ShieldIcon.tsx
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';


interface ShieldIconProps {
  isOn: boolean;
  size?: {
    base: number;
    md?: number;
  };
}

const IconSVG = styled.svg<ShieldIconProps>`
  fill: #01010E;
  transition: filter 0.3s ease;
  
  width: ${props => props.size?.base || 50}px;
  height: ${props => (props.size?.base || 50) * (200/164)}px;

  @media (min-width: ${breakpoints.md}px) {
    width: ${props => props.size?.md || 164}px;
    height: ${props => (props.size?.md || 164) * (200/164)}px;
  }
  
  ${({ isOn }) => isOn ? `
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.33));
  ` : `
    filter: none;
  `}

  path {
    stroke-width: 2px;
    transition: stroke 0.3s ease;
  }
`;

export const ShieldIcon: React.FC<ShieldIconProps> = ({ isOn, size }) => {
  return (
    <IconSVG 
      isOn={isOn}
      size={size}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 164 200"
    >
      <defs>
        <linearGradient id="strokeGradientOn" x1="82" y1="0" x2="82" y2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.88" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient id="strokeGradientOff" x1="82" y1="0" x2="82" y2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.16" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.13" />
        </linearGradient>
      </defs>
      <path 
        d="M81.8182 1L1 37.3636V91.9091C1 142.364 35.9091 189.545 81.8182 199C127.727 189.545 162.636 142.364 162.636 91.9091V37.3636L81.8182 1Z" 
        stroke={isOn ? "url(#strokeGradientOn)" : "url(#strokeGradientOff)"}
      />
    </IconSVG>
  );
};