// src/components/spotlight/sections/CaseStudySection/CaseStudyCard/index.tsx
import React, { useState } from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints'
import Image from "next/image"
import Link from 'next/link'
import { blurDataURL } from '@/styles/blurDataUrl';


interface CaseStudyCardProps {
  image: string;
  company: string;
  location: string;
  previewText: string;
  caseStudyUrl: string;
}

const Card = styled.div`
  width: 335px;
  height: 600px;
  min-height: 600px;
  flex: 0 0 auto;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.16);
  background: #000;
  box-shadow: 0px 3.375px 13.499px 0px rgba(0, 0, 0, 0.13),
    0px 0px 0px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  @media (min-width: ${breakpoints.lg}px) {
    width: 795px;
    height: 387.33px;
    min-height: 387.33px;
    flex-direction: row;
  }

`;

const ImageContainer = styled.div`
  width: 335px;
  height: 300px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative; 

  @media (min-width: ${breakpoints.lg}px) {
    min-width: 398px;
    min-height: 387.33px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: ${breakpoints.lg}px) {
    padding: 29px 20px 20px 20px;
    justify-content: space-between;
  }
`;


const TextBox = styled.div`
  padding: 27px 16px 0 16px;
  flex-grow: 1;
  overflow: hidden;

  @media (min-width: ${breakpoints.lg}px) {
    padding: 0;
  }
`;

const Location = styled.p`
    &.caption {
        line-height: .9;
        margin-top: 5px;
        margin-bottom: 12px;

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 16px;
        margin-top: -1px;
        margin-bottom: 9px;
    }

  }
`;

const PreviewText = styled.p`
  font-size: 18px;
  line-height: 1.5;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 26px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  @media (min-width: ${breakpoints.lg}px) {
    padding: 24px 0 0 0;
  }
`;

const ReadStoryLink = styled(Link)`
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 2px solid rgba(255, 255, 255, 0.16);    
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: rgba(255, 255, 255, 0.66);
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 18px;
    line-height: 0.75;
    background-color: #000000;
    text-decoration: none;

  &:hover {
    border: 2px solid rgba(255, 255, 255, .2);
    background-color: #141414;
  }
`;


export const CaseStudyCard: React.FC<CaseStudyCardProps> = ({ image, company, location, previewText, caseStudyUrl }) => {
  return (
      <Card>
        <ImageContainer>
          <Image 
              src={image} 
              alt={`Case study for ${company}`}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 1200px) 335px, 398px"
              placeholder='blur'
              blurDataURL={blurDataURL}
            />
          </ImageContainer>
        <ContentContainer>
          <TextBox>
            <h3>{company}</h3>
            <Location className="caption">{location}</Location>
            <PreviewText>{previewText}</PreviewText>
          </TextBox>
          <ButtonContainer>
          <ReadStoryLink href={caseStudyUrl}>Read Story</ReadStoryLink>
          </ButtonContainer>
        </ContentContainer>
      </Card>
    );
  };