// src/components/landing/FakeIdSection/Students.tsx
import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'
import Section from '@/components/layout/Base/Section';
import EightMillion from './EightMillion'
import { MdBr, SmBr } from '@/components/common/Br';

const StyledSection = styled(Section)`
    max-width: 100%
    overflow-y: visible;

`

const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    width: 100%;
    overflow-y: visible;
`

const StyledContainer = styled.div`
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: visible;
`

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;
    max-width: 600px;
    margin-top: -40px;
    z-index: 1;

        @media (min-width: ${breakpoints.md}px) {
        padding-bottom: 42%;
    }
`

const MillionContainer = styled.div`
    max-width: 780px;
    width: 100%;
    margin: auto;
    z-index: 2;
`

const TopText = styled.p`
    color: rgb(var(--token-gray));
    text-align: center;
    font-family: "PP Neue Montreal";
    font-style: normal;
    margin-bottom: 20px;
    z-index: 2;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 40px;
    }
`

const Underline = styled.span`
    text-decoration: underline;
`

const BottomText = styled.p`
    font-family: "PP Neue Montreal";
    text-align: center;
    margin-top: 40px;
    z-index: 2;
`

const Blur = styled.svg`
    display: none;
    @media (min-width: ${breakpoints.md}px) {
        display: block;
        margin-top: -80px;
        margin-left: 200px;
        filter: blur(75px);
        z-index: 1;
        overflow-y: visible;
    }
`

const Students: React.FC = () => {
    return (
        <StyledSection>
            <CenterDiv>
            <StyledContainer>
                <TopText>2/3 of US Students have <Underline>reported</Underline> using a fake ID.</TopText>
                <MillionContainer>
                    <EightMillion/>
                </MillionContainer>
                <ImageContainer>
                    <Image
                    src="/images/gavel.png"
                    alt="Gavel"
                    fill
                    style={{
                        objectFit: 'contain',
                    }}        
                    />
                </ImageContainer>
                <BottomText>That&apos;s 8 million potential nightmares <MdBr/><SmBr/>of waving to your family in court.</BottomText>
                <Blur width="320" height="86" viewBox="0 0 320 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="160" cy="43" rx="160" ry="43" fill="url(#paint0_linear_5425_38529)"/>
                    <defs>
                        <linearGradient id="paint0_linear_5425_38529" x1="160" y1="0" x2="160" y2="86" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#633421"/>
                            <stop offset="1" stop-color="#C96A43"/>
                        </linearGradient>
                    </defs>
                </Blur>
            </StyledContainer>
        </CenterDiv>
      </StyledSection>
    );
  };
  
  export default Students;