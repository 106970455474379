// // hooks/useScrollLock.ts

import { useEffect } from 'react';

const useScrollLock = (isLocked: boolean) => {
  useEffect(() => {
    const originalOverflow = window.getComputedStyle(document.body).overflow;

    if (isLocked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalOverflow;
    }

    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isLocked]);

  return null;
};

export default useScrollLock;