// components/common/ProductName.tsx
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

interface StyledTextProps {
  fontSize: {
    base: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  letterSpacing?: string;
  fontWeight?: number | string;
  lineHeight?: string | number;
}

const StyledText = styled.span<StyledTextProps>`
  color: inherit;
  font-size: ${props => `${props.fontSize.base}px`};
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: ${props => props.lineHeight || '70%'};
  letter-spacing: ${props => props.letterSpacing || 'normal'};


  @media (min-width: ${breakpoints.md}px) {
    font-size: ${props => props.fontSize.md ? `${props.fontSize.md}px` : `${props.fontSize.base}px`};
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: ${props => props.fontSize.lg ? `${props.fontSize.lg}px` : `${props.fontSize.md ? `${props.fontSize.md}px` : `${props.fontSize.base}px`}`};
  }

  @media (min-width: ${breakpoints.xl}px) {
    font-size: ${props => props.fontSize.xl ? `${props.fontSize.xl}px` : `${props.fontSize.lg ? `${props.fontSize.lg}px` : `${props.fontSize.md ? `${props.fontSize.md}px` : `${props.fontSize.base}px`}`}`};
  }
`;

const ItalicText = styled(StyledText)`
  font-family: "PP Editorial New", serif;
  font-style: italic;
`;

const NormalText = styled(StyledText)`
  font-family: "PP Neue Montreal", sans-serif;
  font-style: normal;
`;

interface ProductNameProps {
  name: string;
  fontSize: {
    base: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  helveticaWeight?: number | string;
  helveticaLetterSpacing?: string;
  lineHeight?: string | number;
  as?: React.ElementType;
  align?: 'left' | 'center';
}

const ProductName: React.FC<ProductNameProps> = ({ 
  name, 
  fontSize, 
  helveticaWeight = "normal", 
  helveticaLetterSpacing,
  lineHeight,
  as: Component = 'div',
  align = 'center' 
}) => {
  if (typeof name !== 'string') {
    console.error("ProductName component received a non-string name:", name);
    return null;
  }

  if (!name) return null;

  const parts = name.match(/^(id|age)?(.+)/i);
  
  if (!parts) return null;

  const [, prefix, rest] = parts;
  const isIdFollowedByVW = prefix?.toLowerCase() === 'id' && (rest.startsWith('V') || rest.startsWith('W'));

  const getPrefixSize = (size: number) => isIdFollowedByVW ? size * 0.8726 : size;

  const prefixSize = {
    base: getPrefixSize(fontSize.base),
    md: fontSize.md ? getPrefixSize(fontSize.md) : undefined,
    lg: fontSize.lg ? getPrefixSize(fontSize.lg) : undefined,
    xl: fontSize.xl ? getPrefixSize(fontSize.xl) : undefined,
  };

  // For non-prefixed names, use the size that "Visor" would have in a processed name
  const getNonPrefixedSize = (size: number) => size * 0.8726;

  const mainTextSize = prefix ? fontSize : {
    base: getNonPrefixedSize(fontSize.base),
    md: fontSize.md ? getNonPrefixedSize(fontSize.md) : undefined,
    lg: fontSize.lg ? getNonPrefixedSize(fontSize.lg) : undefined,
    xl: fontSize.xl ? getNonPrefixedSize(fontSize.xl) : undefined,
  };

  return (
    <Component style={{ 
      display: 'flex', 
      alignItems: 'baseline',
      width: '100%',
      justifyContent: align === 'left' ? 'flex-start' : 'center'
    }}>      
      {prefix && (
        <ItalicText fontSize={prefixSize} lineHeight={lineHeight}>
          {prefix.split('').map((char, index) => (
            <span 
              key={index} 
              style={{
                letterSpacing: char.toLowerCase() === 'd' && isIdFollowedByVW ? `${fontSize.base * 0.1}px` : 'normal'
              }}
            >
              {char}
            </span>
          ))}
        </ItalicText>
      )}
      <NormalText 
        fontSize={mainTextSize} 
        fontWeight={helveticaWeight}
        letterSpacing={helveticaLetterSpacing}
        lineHeight={lineHeight}
      >
        {rest}
      </NormalText>
    </Component>
  );
};

export default ProductName;