import { useZohoChat } from "@/providers/ZohoChatProvider"
import breakpoints from "@/styles/breakpoints";
import useViewport from "@/utils/hooks/useViewport";
import styled from "@emotion/styled";
import Link from "next/link";
import { RefObject, useEffect, useState } from "react";

const SECTION_NAV_HEIGHT = 55; //pixels

const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${SECTION_NAV_HEIGHT}px;
    background-color: black;
    border-top: 1px solid rgb(var(--dark-gray));
    padding-bottom: 4px;
    z-index: 10;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: ${breakpoints.md}px) {
        border: 1px solid rgb(var(--dark-gray));
        border-radius: 12px;
        padding-bottom: 0;
        margin: 0 auto;
        margin-bottom: 20px;
        width: fit-content;
        gap: 40px;
        padding: 0 20px;
        background-color: #121212;
    }
`

const JumpLink = styled(Link)<{isActive: boolean}>`
    font-family: 'PP Neue Montreal';
    color: ${props => props.isActive ? 'white' : 'gray'};
    transition: color 0.3s ease;
    margin-top: .2rem;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: .1rem;
    }

    :hover { 
        color: white; 
        text-decoration: none; // Ensure no underline on hover
    }
`

interface SectionNavProps {
    buySectionRef: RefObject<HTMLDivElement>;
    featuresRef: RefObject<HTMLDivElement>;
    reviewsRef: RefObject<HTMLDivElement>;
    idleRef: RefObject<HTMLDivElement>;
}

const useActiveSection = (refs: {
    features: RefObject<HTMLDivElement>;
    buy: RefObject<HTMLDivElement>;
    reviews: RefObject<HTMLDivElement>;
    idle: RefObject<HTMLDivElement>;
  }) => {
    const [activeSection, setActiveSection] = useState<string | null>(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const element = entry.target;
              if (element === refs.features.current) setActiveSection('features');
              if (element === refs.buy.current) setActiveSection('buy');
              if (element === refs.reviews.current) setActiveSection('reviews');
              if (element === refs.idle.current) setActiveSection(null);
            }
          });
        },
        {
          threshold: 0.2,
          rootMargin: "-30% 0px -30% 0px"
        }
      );
  
      Object.values(refs).forEach(ref => {
        if (ref.current) observer.observe(ref.current);
      });
  
      return () => observer.disconnect();
    }, [refs]);
  
    return activeSection;
};

export default function SectionNav({ buySectionRef, featuresRef, reviewsRef, idleRef }: SectionNavProps) {

    const activeSection = useActiveSection({
        features: featuresRef,
        buy: buySectionRef,
        reviews: reviewsRef,
        idle: idleRef
    });
    
    //Make zoho widget rise
    const { setBottomMargin } = useZohoChat();
    const { isMobile } = useViewport();
    useEffect(() => {
        if (isMobile) {
            setBottomMargin(SECTION_NAV_HEIGHT)
        }
        return () => setBottomMargin(0)
    }, [setBottomMargin, isMobile])

    return (
        <Background>
            <JumpLink
                isActive={activeSection === 'features'}
                href='#features'
                onClick={(e) => {
                    e.preventDefault();
                    featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
            >Features</JumpLink>

            <JumpLink
                isActive={activeSection === 'buy'}
                href='#buy'
                onClick={(e) => {
                    e.preventDefault();
                    buySectionRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
            >Configure</JumpLink>

            <JumpLink
                isActive={activeSection === 'reviews'}
                href='#reviews'
                onClick={(e) => {
                    e.preventDefault();
                    reviewsRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
            >Reviews</JumpLink>

        </Background>
    )
}