// @/components/landing/Hero/Images
import React from 'react'; 
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Container from '@/components/layout/Base/Container';
import Section from '@/components/layout/Base/Section';
import Image from 'next/image';
import { blurDataUrlSoft } from '@/styles/blurDataUrl';

// TODO
// 1. Add animations to Images

const ImagesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
    grid-template-rows: repeat(1, minmax(0, 1fr));  
    gap: 20px;
    padding-top: 37px;
    width: 100%;
    height: 100%; 

    @media (min-width: ${breakpoints.md}px) {
        grid-template-rows: repeat(2, minmax(0, 1fr));  
        width: 50%;
        padding-top: 20px;
        padding-left: 20px;
        gap: 20px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        padding-top: 0px;
        width: 50%;
        gap: 40px;
    }
`;

const ImageBox = styled.div<{ hideOnMobile?: boolean }>`
  aspect-ratio: 1 / 1;
  background: #0E0E0E;
  position: relative;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: #0A0A0A;
  overflow: hidden;
  width: 100%;
  height: 100%; 
  min-width: 0; 
  min-height: 0; 
  ${({ hideOnMobile }) => hideOnMobile && `
    @media (max-width: ${breakpoints.md}px) {
      display: none;
    }
  `}
`;

const FillerImageBox = styled.div<{ hideOnMobile?: boolean }>`
  aspect-ratio: 1 / 1;
    ${({ hideOnMobile }) => hideOnMobile && `
    @media (max-width: ${breakpoints.md}px) {
      display: none;
    }
  `}
`;


const Images = ( ) => {

    return (
        <ImagesContainer>
            <FillerImageBox hideOnMobile={true}/>
            <ImageBox hideOnMobile={true}>
                <Image
                    src={'/images/Identifake-square.png'}
                    alt={'identifake ID scanner'}
                    sizes={`(min-width: ${breakpoints.md}px) 23vw, 47vw`}
                    fill
                    style={{objectFit: "cover"}}
                    placeholder='blur'
                    blurDataURL={blurDataUrlSoft}
                />
            </ImageBox>
            <ImageBox>
                <Image
                    src={'/images/idvisor-square.png'}
                    alt={'Bar Scene'}
                    sizes={`(min-width: ${breakpoints.md}px) 23vw, 47vw`}
                    fill
                    style={{objectFit: "cover"}}
                    placeholder='blur'
                    blurDataURL={blurDataUrlSoft}
                />
            </ImageBox>
            <ImageBox>
                <Image
                    src={'/images/bar-scene-square.png'}
                    alt={'idVisor ID scanner'}
                    sizes={`(min-width: ${breakpoints.md}px) 23vw, 47vw`}
                    fill
                    style={{objectFit: "cover"}}
                    placeholder='blur'
                    blurDataURL={blurDataUrlSoft}
                />
            </ImageBox>

        </ImagesContainer>
    );
  };
  
  export default Images;