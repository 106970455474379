import styled from "@emotion/styled";
import breakpoints from "@/styles/breakpoints";
import PostImage from "./PostImage";
import Section from "@/components/layout/Base/Section";
import BlogContainer from "@/components/blog/BlogContainer";

interface Props {
    src: string | null | undefined;
    alt: string | null | undefined;
    height: number | null | undefined;
    width: number | null | undefined;
}

const Spacer = styled.div`
    margin-bottom: 42px;
    max-width: 100%;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 52px;
    }
`

export default function({ src, alt, height, width }: Props) {
    if (!src || !width || !height) {
        return null;
    }

    return (
        <Section>
            <BlogContainer>
                <PostImage
                    src={src}
                    alt={alt || ''}
                    height={height}
                    width={width}
                />
                <Spacer/>
            </BlogContainer>
        </Section>
    );
}