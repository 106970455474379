//InTheBoxSection/GridItem.tsx
import React, { memo, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { outlineStyles } from '@/styles/globalStyles';
import Image from 'next/image';
import breakpoints from '@/styles/breakpoints';
import AddonOverlay from './AddonOverlay';
import { blurDataUrlSoft } from '@/styles/blurDataUrl';

interface BoxProps {
  rowSpan: number;
  columnSpan: number;
}

const Box = styled.div<BoxProps>`
  width: 100%;
  height: 100%;
  background: black;
  ${outlineStyles.glosscard}
  grid-column: span ${props => Math.min(props.columnSpan, 2)};
  grid-row: span ${props => props.rowSpan};
  aspect-ratio: ${props => Math.min(props.columnSpan, 2)} / ${props => props.rowSpan};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${breakpoints.md}px) {
    grid-column: span ${props => Math.min(props.columnSpan, 3)};
    aspect-ratio: ${props => Math.min(props.columnSpan, 3)} / ${props => props.rowSpan};
    padding: 28px;
    gap: 20px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    grid-column: span ${props => props.columnSpan};
    aspect-ratio: ${props => props.columnSpan} / ${props => props.rowSpan};
  }
`

const ProductImage = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

const Text = styled.p`
    text-align: center;
    font-size: 14px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 26px;
    }
`

interface GridItemProps {
  text: string;
  src: string;
  rowSpan?: number;
  columnSpan?: number;
  addon?: boolean;
  price?: string;
  checked: boolean;
  onToggle: () => void; // Changed from (isChecked: boolean) => void
  maxColumns: number;
}


const GridItem = memo(({ 
  text, 
  src, 
  rowSpan = 1, 
  columnSpan = 1, 
  addon = false, 
  price, 
  checked, 
  onToggle, 
  maxColumns 
}: GridItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  
  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);
  
  return (
    <Box
      rowSpan={rowSpan}
      columnSpan={columnSpan}
      onClick={onToggle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ProductImage>
        <Image
          src={src}
          alt={''}
          fill
          style={{objectFit: "contain"}}
          sizes={`(max-width: ${breakpoints.sm}px) 50vw,
              (max-width: ${breakpoints.md}px) 25vw)`}
          placeholder='blur'
          blurDataURL={blurDataUrlSoft}
        />
      </ProductImage>
      <Text>{text}</Text>
      {addon && <AddonOverlay
        addon={addon}
        name={text}
        checked={checked}
        onChange={onToggle} // Updated to use onToggle directly
        isHovered={isHovered}
        price={price}
      />}
    </Box>
  );
});

GridItem.displayName = 'BoxItem';

export default GridItem;