// @/components/landing/Hero/StarsMiniProof
import React from 'react';
import styled from '@emotion/styled';
import { ReviewStars } from '@/components/common/ReviewsSection/ReviewCard/ReviewStars';
import breakpoints from '@/styles/breakpoints';

// TODO
// 1.  Make stars accept props and size based on breakpoint

const SocialProofBanner = styled.div`
    display: flex; 
    align-items: row;
    justify-content: left;
    margin: 26px 0px 36px 0px;
`

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallText = styled.p`
  color: rgba(255, 255, 255, 0.92);
  text-align: start;
  font-family: "PP Editorial New", serif;
  font-size: 13.908px; 
  font-style: normal;
  font-weight: 400;
  margin-left: 16px;
  margin-top: 5px;

  @media (min-width: ${breakpoints.md}px) {
      font-size: 14px;
      margin-top: 6px;
  }

`;

const StarsMiniProof: React.FC = () => (
    <SocialProofBanner>
    <StarContainer>
      <ReviewStars rating={5} />
    </StarContainer>
    <SmallText>25+ Years of Satisfied Customers</SmallText>
    </SocialProofBanner>
);

export default StarsMiniProof;