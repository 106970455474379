// HamburgerMenuIcon.tsx
import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import breakpoints from '@/styles/breakpoints';

const HamburgerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  transition: transform 0.3s ease;
  border-radius: 1px;
`;

interface HamburgerMenuIconProps {
  onClick: () => void;
  isOpen: boolean;
}

export const HamburgerMenuIcon: React.FC<HamburgerMenuIconProps> = ({ onClick, isOpen }) => {
  const line1Ref = useRef(null);
  const line2Ref = useRef(null);
  const line3Ref = useRef(null);

  useEffect(() => {
    if (isOpen) {
      gsap.to(line1Ref.current, { rotation: 45, y: 8, duration: 0.3 });
      gsap.to(line2Ref.current, { opacity: 0, duration: 0.5 });
      gsap.to(line3Ref.current, { rotation: -45, y: -8, duration: 0.3 });
    } else {
      gsap.to(line1Ref.current, { rotation: 0, y: 0, duration: 0.3 });
      gsap.to(line2Ref.current, { opacity: 1, duration: 0.7 });
      gsap.to(line3Ref.current, { rotation: 0, y: 0, duration: 0.3 });
    }
  }, [isOpen]);

  return (
    <HamburgerWrapper onClick={onClick}>
      <HamburgerIcon>
        <Line ref={line1Ref} style={{ top: '0' }} />
        <Line ref={line2Ref} style={{ top: '50%', transform: 'translateY(-50%)' }} />
        <Line ref={line3Ref} style={{ bottom: '0' }} />
      </HamburgerIcon>
    </HamburgerWrapper>
  );
};