// src/components/layout/SectionSpacer.tsx
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

type BreakpointKey = keyof typeof breakpoints;
type SpacerSize = 'small' | 'medium' | 'large' | 'custom';

type SpacingSizes = {
  [key in BreakpointKey]: {
    small: number;
    medium: number;
    large: number;
  };
};

const defaultSpacing: SpacingSizes = {
  sm: { small: 60, medium: 120, large: 160 },
  md: { small: 80, medium: 160, large: 240 },
  lg: { small: 120, medium: 232, large: 396 },
  xl: { small: 120, medium: 232, large: 396 },
};

type CustomSizes = {
  [key in BreakpointKey]?: number;
};

interface SpacerProps {
  size: SpacerSize;
  customSizes?: CustomSizes;
}

const StyledSpacer = styled.div<SpacerProps>`
  max-width: 100%;
  ${({ size, customSizes = {} }) => {
    const breakpointEntries = Object.entries(breakpoints);
    return breakpointEntries.map(([key, value], index) => {
      const breakpointKey = key as BreakpointKey;
      let height: number;

      if (size === 'custom') {
        height = customSizes[breakpointKey] ?? defaultSpacing[breakpointKey].medium;
      } else {
        height = defaultSpacing[breakpointKey][size];
      }

      // Base style (smallest breakpoint)
      if (index === 0) {
        return `height: ${height}px;`;
      }

      // Media queries for larger breakpoints
      return `
        @media (min-width: ${value}px) {
          height: ${height}px;
        }
      `;
    }).join('\n');
  }}
`;

const SectionSpacer: React.FC<SpacerProps> = ({ size, customSizes }) => {
  if (size === 'custom' && !customSizes) {
    console.warn('Custom size specified without customSizes prop. Falling back to medium size.');
  }
  return <StyledSpacer size={size} customSizes={customSizes} />;
};

export default SectionSpacer;

// Usage example
// import SectionSpacer from '@/components/layout/SectionSpacer';
// 
// const MyComponent: React.FC = () => (
//   <>
//     <section>Content 1</section>
//     <SectionSpacer size="large" />
//     <section>Content 2</section>
//     <SectionSpacer size="medium" />
//     <section>Content 3</section>
//     <SectionSpacer size="custom" customSizes={{ sm: 50, md: 100, lg: 150, xl: 200 }} />
//     <section>Content 4</section>
//   </>
// );