import { FaustTemplate } from "@faustwp/core";
import { GetFeaturePageDocument, GetFeaturePageQuery } from '../generated/graphql';
import FeatureSolutionPage from "@/components/featureSolutionPage";
import { mapFeaturePageToCommonData } from "@/components/featureSolutionPage/types";

const Component: FaustTemplate<GetFeaturePageQuery> = (props) => {
    
    if (props.loading) return <>Loading...</>; // Loading state for previews
    if (!props.data) return <>No data found</>;
    const data = mapFeaturePageToCommonData(props.data);

    return (
        <FeatureSolutionPage data={data}/>
    );
}

Component.query = GetFeaturePageDocument;

Component.variables = (seedQuery, context) => {
  return {
    id: seedQuery?.databaseId,
    asPreview: context?.asPreview,
  };
};

export default Component;