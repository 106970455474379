// ProductCarousel.tsx
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import breakpoints from "@/styles/breakpoints";
import ProductBadge from '@/components/icons/ProductBadge'; 
import PriceDisplay from '@/components/common/PriceDisplay';
import SaleBadge from '@/components/icons/SaleBadge'; 
import Image from "next/image";
import ProductName from '@/components/common/ProductName'
import { NavGroupProduct } from '@/utils/hooks/useNavData';
import { ProcessedNavGroupProduct } from '@/utils/hooks/useNavData';
import Link from 'next/link';

interface ProductCarouselProps {
  products: ProcessedNavGroupProduct[] | undefined;
  groupName: string;
  cardWidth: number;
  cardHeight: number;
  mobileCardWidth?: number;
  mobileCardHeight?: number;
  isScrollableDesktop?: boolean;
  isScrollableMobile?: boolean;
}


interface ProductCardProps {
  width: number;
  height: number;
  mobileWidth?: number;
  mobileHeight?: number;
}

interface CarouselContainerProps {
  isScrollableDesktop: boolean;
  isScrollableMobile: boolean;
}


const CarouselContainer = styled.div<CarouselContainerProps>`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 40px 0 0 0;
  
  @media (max-width: ${breakpoints.md - 1}px) {
    ${props => props.isScrollableMobile ? `
      overflow-x: auto;
    ` : `
      justify-content: center;
      overflow-x: hidden;
    `}
  }

  @media (min-width: ${breakpoints.md}px) {
    padding-top: 0px;
    ${props => props.isScrollableDesktop ? `
      overflow-x: auto;
    ` : `
      justify-content: center;
      overflow-x: hidden;
    `}
  }
`;

const ProductCard = styled.div<ProductCardProps>`
  position: relative; 
  width: ${props => props.mobileWidth || props.width}px;
  height: ${props => props.mobileHeight || props.height}px;
  flex-shrink: 0;
  border-radius: ${props => (props.mobileWidth || props.width) * 0.0617}px;
  border: 1px solid rgba(255, 255, 255, 0.13);
  box-shadow: 0px ${props => (props.mobileWidth || props.width) * 0.0308}px ${props => (props.mobileWidth || props.width) * 0.0308}px 0px rgba(0, 0, 0, 0.25) inset;
  filter: drop-shadow(0px ${props => (props.mobileWidth || props.width) * 0.0154}px ${props => (props.mobileWidth || props.width) * 0.0617}px rgba(0, 0, 0, 0.13));
  background: #01010E;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: ${breakpoints.lg}px) {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: ${props => props.width * 0.0617}px;
    box-shadow: 0px ${props => props.width * 0.0308}px ${props => props.width * 0.0308}px 0px rgba(0, 0, 0, 0.25) inset;
    filter: drop-shadow(0px ${props => props.width * 0.0154}px ${props => props.width * 0.0617}px rgba(0, 0, 0, 0.13));
  }
`;


const ImageContainer = styled.div`
  position: relative;
  width: 101%;
  height: 101%;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductCardWrapper = styled.div`
  position: relative;
  * {
    cursor: pointer;
  }
`;

// const ProductNameWrapper = styled.div`
//   margin-top: 14px;
//   text-align: center;
// `;

const ProductNameWrapper = styled.div<{fontSize: number}>`
  margin-top: ${props => props.fontSize}px;
  text-align: center;
`;



export const ProductCarousel: React.FC<ProductCarouselProps> = ({ 
  products, 
  groupName,
  cardWidth, 
  cardHeight, 
  mobileCardWidth,
  mobileCardHeight,
  isScrollableDesktop = true,
  isScrollableMobile = true
}) => {
  const [hoveredProduct, setHoveredProduct] = useState<number | null>(null);
  const scaleFactor = cardWidth / 153.821;
  const fontSize = cardWidth * 0.0878;
  const mobileFontSize = (mobileCardWidth || cardWidth) * 0.0878;

  if (!products || products.length === 0) {
    return <div>No products available</div>;
  }

  const getBadgeCategory = (product: ProcessedNavGroupProduct): string | null => {
    if (product.categoryBadge) {
      return `${product.categoryBadge} Scanner`;
    }
    return product.categoryBadge;
  };

  return (
    <CarouselContainer 
      isScrollableDesktop={isScrollableDesktop} 
      isScrollableMobile={isScrollableMobile}
    >
      {products.map((product) => (
        <ProductWrapper key={product.databaseId}>
          <ProductCardWrapper>
            <Link href={product.link}>
              <ProductCard 
                width={cardWidth}
                height={cardHeight}
                mobileWidth={mobileCardWidth}
                mobileHeight={mobileCardHeight}
                onMouseEnter={() => setHoveredProduct(product.databaseId)}
                onMouseLeave={() => setHoveredProduct(null)}
              >
                {getBadgeCategory(product) && (
                  <ProductBadge 
                    category={getBadgeCategory(product)!}
                    scale={scaleFactor}
                  />
                )}
                {product.onSale && <SaleBadge scale={scaleFactor} />}
                <ImageContainer>
                  <Image
                    src={hoveredProduct === product.databaseId ? product.hoverImage : product.defaultImage}
                    alt={product.displayName}
                    fill
                    style={{ objectFit: "contain" }}
                    priority
                    sizes={`(max-width: ${breakpoints.md}px) ${mobileCardWidth || cardWidth}px, ${cardWidth}px`}
                  />
                </ImageContainer>
              </ProductCard>
            </Link>
          </ProductCardWrapper>
          <ProductNameWrapper fontSize={fontSize}>
            <ProductName 
              name={product.displayName} 
              fontSize={{
                base: mobileFontSize,
                md: fontSize,
                lg: fontSize,
                xl: fontSize
              }}
              helveticaWeight={400} 
              helveticaLetterSpacing={`${-fontSize * 0.0326}px`}
              as="p"
            />
            <PriceDisplay 
              regularPrice={parseFloat(product.regularPrice?.replace(/[^0-9.]/g, '') || '0')} 
              salePrice={product.onSale ? parseFloat(product.salePrice?.replace(/[^0-9.]/g, '') || '0') : undefined} 
              fontSize={16} 
              marginTop={10} 
              strikethroughHeight={1.5} 
              strikethroughTop="60%"
            />       
          </ProductNameWrapper>
        </ProductWrapper>
      ))}
    </CarouselContainer>
  );
};