//components/idinsights/CategoryCarousel.tsx

import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import styled from '@emotion/styled';
import { Post, PageInfo } from '@/generated/graphql';
import useViewport from '@/utils/hooks/useViewport';
import useIntersectionObserver from '@/utils/hooks/useIntersectionObserver';
import { useCarouselData } from '@/utils/hooks/useCarouselData';
import BlogPost from './BlogPostCard';
import breakpoints from '@/styles/breakpoints';
import { getDefaultImageForPost } from '@/utils/imageUtils';
import CircleArrowButton from '../icons/CircleArrowButton';
import { blurDataURL } from '@/styles/blurDataUrl';
import { getFirstImage } from '@/utils/getFirstImage';


interface CategoryCarouselProps {
  category: string;
  posts: Partial<Post>[] | null | undefined;
  categorySlug: string;
  getDefaultImageForPost: (post: Post) => string;
  isFirstCategory?: boolean;
  inlineFirstImage?: boolean; // Add this line
}


  const PostCarouselWrapper = styled.div`
  position: relative;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }
`;

const CategoryTitle = styled.h4`
  text-align: center;
  margin-bottom: 16px;
`;


const PostCarousel = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 250px;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 20px;
  padding: 0 20px 10px 20px;
  margin: 0 0 40px 0; // Added bottom margin for navigation buttons
  list-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.md}px) {
    margin: 0 0 60px 0; // Added bottom margin for nav buttons
    grid-auto-columns: 335px;
    scroll-padding: 0 40px;
    padding: 0 40px 20px 40px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    grid-auto-columns: 400px;
    scroll-padding: 0 80px;
    padding: 0 80px 20px 80px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    scroll-padding: 0 calc(120px + ((100vw - 1440px) / 2));
    padding-left: calc(120px + ((100vw - 1440px) / 2));
    padding-right: calc(120px + ((100vw - 1440px) / 2));
  }
`;

const NavigationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  gap: 10px;
  max-width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    right: 40px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    right: calc(120px + ((100vw - 1440px) / 2));
  }
`;

const SkeletonPost = styled.div`
  // Style this to look like a loading post
  background: #393939;
  height: 100%;
  border-radius: 4px;
  transition: opacity 0.3s ease-in-out;
`;

interface FastFirstPostProps {
  post: Partial<Post>;
  categorySlug: string;
  getDefaultImageForPost: (post: Post) => string;
}

const FastFirstPost: React.FC<FastFirstPostProps> = ({ post, categorySlug, getDefaultImageForPost }) => {
  const coverImage = post.featuredImage?.node.mediaItemUrl ??
    (post.content ? getFirstImage(post.content) : '') ?? '';
  
  return (
  <li>
    <BlogPost
      categorySlug={categorySlug}
      title={post.title || 'Untitled'}
      date={post.date || ''}
      image={coverImage}
      id={post.id || ''}
      slug={post.slug || ''}
      getDefaultImageForPost={(postId) => getDefaultImageForPost({ id: postId } as Post)}
      priority={true}
      loading="eager"
      onLoad={() => {}} // Add if required by BlogPost props
    />
  </li>
)};

const CategoryCarousel: React.FC<CategoryCarouselProps> = memo(({ 
  category, 
  posts, 
  categorySlug,
  getDefaultImageForPost,
  isFirstCategory = false,
  inlineFirstImage = false, 
}) => {  
  // Split rendering - first post renders immediately, rest defer
  const firstPost = posts?.[0];
  const remainingPosts = posts?.slice(1);
    // Defer non-critical initialization
  const [isInitialized, setIsInitialized] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const { width } = useViewport();
  const [loadedImages, setLoadedImages] = useState(0);

  // Defer loading of non-critical posts
  const [shouldLoadRest, setShouldLoadRest] = useState(!isFirstCategory);

  useEffect(() => {
    if (isFirstCategory && !shouldLoadRest) {
      // Load remaining posts after first post is visible
      const timer = setTimeout(() => {
        setShouldLoadRest(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isFirstCategory]);

  const isIntersecting = useIntersectionObserver(wrapperRef, {
    threshold: 0.5,
    rootMargin: '-20% 0px'
  });

  useEffect(() => {
    const checkScrollable = () => {
      if (listRef.current) {
        setCanScrollLeft(listRef.current.scrollLeft > 0);
        setCanScrollRight(
          listRef.current.scrollLeft < listRef.current.scrollWidth - listRef.current.clientWidth - 1
        );
      }
    };
  
    let rafId: number;
    const handleScroll = () => {
      rafId = requestAnimationFrame(checkScrollable);
    };
  
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);
      checkScrollable(); // Initial check
    }
  
    return () => {
      if (list) {
        list.removeEventListener('scroll', handleScroll);
      }
      cancelAnimationFrame(rafId);
    };
  }, [width]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const cardWidth = listRef.current.querySelector('li')?.offsetWidth || 0;
      const gap = 20;
      const scrollAmount = cardWidth + gap;
      
      const currentScroll = listRef.current.scrollLeft;
      const targetScroll = direction === 'left' 
        ? Math.max(currentScroll - scrollAmount, 0)
        : currentScroll + scrollAmount;
      
      listRef.current.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const handleImageLoad = () => {
    setLoadedImages(prev => prev + 1);
  };

  return (
    <PostCarouselWrapper ref={wrapperRef} className={isIntersecting ? 'active' : ''}>
      <CategoryTitle>{category}</CategoryTitle>
      <PostCarousel ref={listRef}>
        {/* Render first post immediately */}
        {isFirstCategory && firstPost && (
          <FastFirstPost 
            post={firstPost}
            categorySlug={categorySlug}
            getDefaultImageForPost={getDefaultImageForPost}
          />
        )}
        
        {/* Defer remaining posts */}

        {shouldLoadRest && (
          <>
            {!isFirstCategory && firstPost && (
              <FastFirstPost 
                post={firstPost}
                categorySlug={categorySlug}
                getDefaultImageForPost={getDefaultImageForPost}
              />
            )}
            {remainingPosts?.map((post) => {
              const coverImage = post.featuredImage?.node.mediaItemUrl ??
                (post.content ? getFirstImage(post.content) : '') ?? '';
              
              return (
                <li key={post.id}>
                  <BlogPost
                    categorySlug={categorySlug}
                    title={post.title || 'Untitled'}
                    date={post.date || ''}
                    image={coverImage}
                    blurDataUrl={blurDataURL}
                    id={post.id || ''}
                    slug={post.slug || ''}
                    onLoad={handleImageLoad}
                    getDefaultImageForPost={(postId) => getDefaultImageForPost({ id: postId } as Post)}
                    priority={false}
                  />
                </li>
              )
            })}
          </>
        )}
      </PostCarousel>

      {/* Only show navigation after remaining posts load */}
      {shouldLoadRest && width > 0 && (
        <NavigationWrapper>
          <CircleArrowButton 
            direction="left" 
            onClick={() => handleScroll('left')} 
            disabled={!canScrollLeft} 
            ariaLabel="Scroll left"
          />
          <CircleArrowButton 
            direction="right" 
            onClick={() => handleScroll('right')} 
            disabled={!canScrollRight} 
            ariaLabel="Scroll right"
          />
        </NavigationWrapper>
      )}
    </PostCarouselWrapper>
  );
});

export default CategoryCarousel;