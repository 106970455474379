// src/pages/_app.tsx
import '../faust.config';
import type { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { FaustProvider } from '@faustwp/core';
import Layout from '@/components/layout/Layout';
import { CartProvider } from '@/providers/CartProvider';
import { NavProvider } from '@/providers/NavProvider';
import { GoogleTagManager } from '@next/third-parties/google'
import { NavDataType } from '@/utils/hooks/useNavData';
import { ProductNavProvider } from '@/providers/ProductNavProvider';
import { getInitialAppProps } from '@/utils/rendering/nav/getInitialAppProps';
import WizardProvider from '@/providers/WizardProvider';
import ZohoChatProvider from '@/providers/ZohoChatProvider';

interface CustomAppProps extends AppProps { navData: NavDataType; }
 
function TokenWorks({ Component, navData, pageProps }: CustomAppProps) {
  const router = useRouter();

  return (
    <FaustProvider pageProps={pageProps}>
      <ZohoChatProvider>
        <WizardProvider/>
        <CartProvider>
          <NavProvider navData={navData}>
            <ProductNavProvider>
              <Layout>
                <Component {...pageProps} key={router.asPath} />
              </Layout>
            </ProductNavProvider>
          </NavProvider>
        </CartProvider>
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GA_ID as string} />
      </ZohoChatProvider>
    </FaustProvider>
  );
}

TokenWorks.getInitialProps = async (appContext: AppContext) => getInitialAppProps(appContext);

export default TokenWorks;