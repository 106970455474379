import Link from "next/link";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface TagStyleProps {
    variant: 'regular' | 'mini';
    isLink: boolean;
}

const tagStyles = css`
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 2px solid rgba(255, 255, 255, 0.16);
    background-color: #000000;
    color: rgba(255, 255, 255, 0.66);
    font-family: 'PP Neue Montreal', sans-serif;
    line-height: 0.75;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    text-decoration: none;
`;

const variantStyles = {
    regular: css`
        font-size: 18px;
        padding: 10px 14px;
    `,
    mini: css`
        font-size: 14px;
        padding: 9px 11px;
    `
};

const linkStyles = css`
    cursor: pointer;
    &:hover {
        border-color: rgba(255, 255, 255, .2);
        background-color: #141414;
    }
`;

const TagSpan = styled.span<TagStyleProps>`
    ${tagStyles}
    ${props => variantStyles[props.variant]}
    ${props => props.isLink && linkStyles}
`;

const TagLink = styled(Link)<TagStyleProps>`
    ${tagStyles}
    ${props => variantStyles[props.variant]}
    ${linkStyles}
`;

interface TagProps {
    text?: string | null;
    href?: string | null;
    variant?: 'regular' | 'mini';
}

export default function Tag({ text = "untitled", href, variant = 'regular' }: TagProps) {
    if (href) {
        return <TagLink href={href} variant={variant} isLink={true}>{text}</TagLink>;
    } else {
        return <TagSpan variant={variant} isLink={false}>{text}</TagSpan>;
    }
}