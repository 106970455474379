// src/providers/NavProvider/index.tsx

import React, { createContext, useContext, useMemo } from 'react';
import { NavDataType } from '@/utils/hooks/useNavData';
import { CATEGORY_ORDER, getCategoryIndex } from '@/constants/navigation';

type NavContextType = {
  navData: NavDataType;
};

const NavContext = createContext<NavContextType | undefined>(undefined);

export function NavProvider({ 
  children, 
  navData 
}: { 
  children: React.ReactNode; 
  navData: NavDataType 
}) {
  const sortedNavData = useMemo(() => {
    if (!navData) return [];
    
    return [...navData].sort((a, b) => {
      const aIndex = getCategoryIndex(a.name);
      const bIndex = getCategoryIndex(b.name);
      return aIndex - bIndex;
    });
  }, [navData]);

  return (
    <NavContext.Provider value={{ navData: sortedNavData }}>
      {children}
    </NavContext.Provider>
  );
}

export function useNav(): NavContextType {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNav must be used within a NavProvider');
  }

  return context;
}