// src/components/spotlight/sections/ReviewsSection/ReviewsCard/index.tsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import breakpoints from '@/styles/breakpoints';
import { ReviewStars } from './ReviewStars';
import Image from 'next/image';
import { blurDataURL } from '@/styles/blurDataUrl';

// Add this at the top of the file
const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  debounced.cancel = () => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
  };

  return debounced;
};

interface ReviewCardProps {
  image: string;
  reviewText: string;
  rating: number;
  author: string;
}

const Card = styled.div`
  width: 335px;
  height: 600px;
  flex: 0 0 auto;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.16);
  background: #000;
  box-shadow: 0px 3.375px 13.499px 0px rgba(0, 0, 0, 0.13),
    0px 0px 0px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.5s ease, height 0.5s ease;

  @media (min-width: ${breakpoints.lg}px) {
    width: 795px;
    height: 387.33px;
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  position: relative; 
  width: 335px;
  height: 300px;
  flex-shrink: 0;
  overflow: hidden;
  transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease;

  @media (min-width: ${breakpoints.lg}px) {
    width: 366px;
    height: 387px;
  }
`;

// const StyledImage = styled(Image)`
//   width: 335px;
//   height: 300px;
//   object-fit: cover;
//   transition: width 0.5s ease, height 0.5s ease;

//   @media (min-width: ${breakpoints.lg}px) {
//     width: 366px;
//     height: 387px;
//   }
// `;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 16px 16px 16px;
  justify-content: space-between;
  height: 100%; // Ensure it takes full height

  @media (min-width: ${breakpoints.lg}px) {
    padding: 30px 20px 22px 20px;
  }
`;

const ReviewTextBox = styled.div<{ isExpanded: boolean; showScrollbar: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: ${props => props.isExpanded ? 'auto' : 'hidden'};
  max-height: ${props => props.isExpanded ? 'calc(100% - 80px)' : 'none'}; // Adjust the 80px value as needed
  transition: max-height 0.5s ease;

  /* Scrollbar styles for WebKit browsers (Chrome, Safari, newer versions of Edge) */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* Scrollbar styles for Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

  /* Hide scrollbar when not expanded */
  ${props => !props.showScrollbar && `
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `}
`;

const ReviewText = styled.p<{ isExpanded: boolean; isMobile: boolean }>`
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  overflow: ${props => props.isExpanded ? 'visible' : 'hidden'};
  text-overflow: ${props => props.isExpanded ? 'clip' : 'ellipsis'};

  ${props => !props.isExpanded && `
    display: -webkit-box;
    -webkit-line-clamp: ${props.isMobile ? MAX_LINES.MOBILE : MAX_LINES.DESKTOP};
    -webkit-box-orient: vertical;
  `}

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 20px;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;


  @media (min-width: ${breakpoints.lg}px) {
    margin-top: 20px;
  }
`;

const Author = styled.h5`
  line-height: 1;
  font-size: 20px;
  color: #FFF;
  margin: 0 0 16px 0;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const ReadMoreLink = styled.span`
  color: #3A71FF;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-family: 'PP Neue Montreal';

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 16px;
  }
`;

const MAX_LINES = {
  MOBILE: 9, 
  DESKTOP: 8.5  
} as const;

const ANIMATION_DURATION = 0.5;

// Debug logger
const debug = (message: string, data?: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.debug(`[ReviewCard] ${message}`, data);
  }
};


export const ReviewCard: React.FC<ReviewCardProps> = ({ image, reviewText, rating, author }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isMobile, setIsMobile] = useState(true); 

  const cardRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const textBoxRef = useRef<HTMLDivElement>(null);

  const checkTextOverflow = useCallback(() => {
    if (!textRef.current) return;

    const element = textRef.current;
    const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
    const maxLines = window.innerWidth >= breakpoints.lg 
      ? MAX_LINES.DESKTOP 
      : MAX_LINES.MOBILE;
    
    const maxHeight = Math.floor(lineHeight * maxLines);
    const actualHeight = element.scrollHeight;
    const hasOverflow = actualHeight > maxHeight;

    debug('Overflow check', {
      lineHeight,
      maxLines,
      maxHeight,
      actualHeight,
      hasOverflow
    });

    setHasOverflow(hasOverflow);
  }, []);

  const handleResize = useCallback(() => {
    if (!cardRef.current || !imageContainerRef.current || !textBoxRef.current) {
      debug('Missing refs in handleResize');
      return;
    }

    const isDesktop = window.innerWidth >= breakpoints.lg;
    setIsMobile(!isDesktop);
    
    gsap.set(cardRef.current, {
      width: isDesktop ? '795px' : '335px',
      height: isDesktop ? '387.33px' : '650px'
    });

    gsap.set(imageContainerRef.current, {
      width: isDesktop ? (isExpanded ? 0 : '366px') : '335px',
      height: isDesktop ? '387.33px' : (isExpanded ? 0 : '300px'),
      opacity: isExpanded ? 0 : 1
    });

    checkTextOverflow();
  }, [isExpanded]);

  // Handle font loading
  useEffect(() => {
    document.fonts.ready.then(() => {
      debug('Fonts loaded, checking overflow');
      checkTextOverflow();
    });
  }, [checkTextOverflow]);

  // Handle resize events
  useEffect(() => {
    const debouncedResize = debounce(handleResize, 250);
    
    handleResize(); // Initial check
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
      debouncedResize.cancel();
    };
  }, [handleResize]);

  const handleToggle = useCallback(() => {
    const isDesktop = window.innerWidth >= breakpoints.lg;
    
    setIsExpanded(prev => !prev);

    if (!imageContainerRef.current) return;

    gsap.to(imageContainerRef.current, {
      width: isDesktop ? (isExpanded ? '366px' : 0) : '335px',
      height: isDesktop ? '387.33px' : (isExpanded ? '300px' : 0),
      opacity: isExpanded ? 1 : 0,
      duration: ANIMATION_DURATION,
      onComplete: () => {
        setShowScrollbar(!isExpanded);
        checkTextOverflow();
      }
    });
  }, [isExpanded]); // Removed checkTextOverflow dependency

  return (
    <Card ref={cardRef}>
      <ImageContainer ref={imageContainerRef}>
        <Image 
          src={image} 
          alt={`Review by ${author}`} 
          fill
          style={{ objectFit: 'cover' }}      
          sizes="(max-width: 1200px) 335px, 366px"
          placeholder='blur'
          blurDataURL={blurDataURL}
          onLoad={checkTextOverflow}
        />
      </ImageContainer>
      <ContentContainer>
        <Author>{author}</Author>
        <ReviewTextBox ref={textBoxRef} isExpanded={isExpanded} showScrollbar={showScrollbar}>
          <ReviewText 
            ref={textRef} 
            isExpanded={isExpanded}
            isMobile={isMobile}
          >
            {reviewText}
          </ReviewText>
        </ReviewTextBox>
        <DetailsContainer>
          <ReviewStars rating={rating} />
          {(hasOverflow || isExpanded) && (
            <ReadMoreLink onClick={handleToggle}>
              {isExpanded ? 'see less' : 'see more'}
            </ReadMoreLink>
          )}
        </DetailsContainer>
      </ContentContainer>
    </Card>
  );
};

