// src/components/spotlight/sections/ReviewsSection/SectionTitle.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'

interface SectionTitleProps {
  title?: string;
  subtitle?: string;
}

const SectionTitleContainer = styled(Container)`
    text-align: center;
    margin-bottom: 80px;
    min-width: 100%;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 116px;
        height: 145px;
`

const TitleBlur = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 212.96%);
    width: 327px;
    height: 48px;
    margin-top: 10px;
    z-index: -1;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 0;
        width: 500px;
        height: 100px;
        filter: blur(35px);
    }
`
export const SectionTitle: React.FC<SectionTitleProps> = ({ 
  title = "Owners Love It",
  subtitle = "Find out why"
}) => {
    return (
      <SectionTitleContainer>
        <h1>{title}</h1>
        <TitleBlur></TitleBlur>
        <h5 className="sans">{subtitle}</h5>
      </SectionTitleContainer>
    );
  };