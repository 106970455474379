//@/components/spotlight/bento/ClientSideAnimation.tsx

import React from 'react';
import { useClientSideRendering } from '@/utils/hooks/useClientSideRendering';

interface ClientSideAnimationProps {
  AnimationComponent: React.ComponentType<any>;
  [key: string]: any; // This allows passing any additional props
}

export function ClientSideAnimation({ AnimationComponent, ...props }: ClientSideAnimationProps) {
  const isClient = useClientSideRendering();

  if (!isClient) {
    return <div></div>; // Or any other placeholder you prefer
  }

  return <AnimationComponent {...props} />;
}