import Header from "@/components/featureSolutionPage/Header";
import SectionSpacer from "@/components/layout/Base/SectionSpacer";
import useViewport from "@/utils/hooks/useViewport";
import breakpoints from "@/styles/breakpoints";
import ContentBlocks from "@/components/featureSolutionPage/ContentBlocks";
import MostPopularProductsSection from "@/components/landing/MostPopularProductsSection";
import { WizardCtaSection } from "@/components/common/WizardCTA";
import { FeatureSolutionPageData } from "./types";
import { Seo } from "../layout/Base/Seo";

interface FeatureSolutionPageProps {
    data: FeatureSolutionPageData;
}

export default function FeatureSolutionPage({ data }: FeatureSolutionPageProps) {
    const { width } = useViewport();
    const isMobile = width > 0 && width < breakpoints.md;

    return (
        <>
            <Seo data={data.seo}/>
            
            {!isMobile && <SectionSpacer size={'small'}/>}

            <Header
                title={data?.title ?? ''}
                data={data.featureSolutionHeading}
            />

            <SectionSpacer size={'small'}/>

            <ContentBlocks data={data.contentBlocks}/>

            <SectionSpacer size={'small'}/>

            <div id={'products'}>
                <MostPopularProductsSection/>
            </div>
            
            <SectionSpacer size={'medium'}/>

            <WizardCtaSection 
                bigText={data.wizardCTA?.title || ''}
                buttonText={data.wizardCTA?.button?.title || 'Perfect Match'}
                smallText={data.wizardCTA?.caption || ''}
                bigTextWidth={{ sm: '350px', md: '800px' }}
            />

            <SectionSpacer size={'small'}/>

        </>
    );
}