// ./wp-templates/category.tsx
import { FaustTemplate } from "@faustwp/core";
import { GetPostsByTagDocument, GetPostsByTagQuery } from '@/generated/graphql';
import { transformTagData } from '@/components/idinsights/categoryTagPages/types';
import CategoryTagTemplate from '@/components/idinsights/categoryTagPages';


const Component: FaustTemplate<GetPostsByTagQuery> = (props) => {
  if (props.loading) return <>Loading...</>; // Loading state for previews

  if (!props.data) return <div>No data found</div>;
  
  const categoryTagData = transformTagData(props.data);

  return (
    <CategoryTagTemplate data={categoryTagData}/>
  );
}


Component.query = GetPostsByTagDocument;

Component.variables = (seedQuery, context) => {
  return {
    tagId: seedQuery?.databaseId,
    first: 50,
    asPreview: context?.asPreview,
  };
};

export default Component;