import { CartContentsNode } from "@/providers/CartProvider/processCartItems";
import { LocalCartItem } from "@/providers/CartProvider/types";
import { GetCartQuery, MetaData } from "@/generated/graphql";
import { QLM_METADATA_KEY, softwareUpgrades, QlmCreds } from ".";

// Check if product is QLM software upgrade product
export function productIsQlmUpgradeProduct(databaseId: number): boolean {
    return softwareUpgrades.some(upgrade => upgrade.databaseId === databaseId);
}

// Check if cart has any QLM items
export function cartHasQlmCreds(cartData: GetCartQuery): boolean {
    if (!cartData.cart?.contents?.nodes) return false;

    return cartData.cart.contents.nodes.some(item => !!getQlmCredsFromCartItem(item.extraData));
}

// Check if order has any QLM association
export function orderHasQlmMetadata(metaData?: (MetaData | null)[] | null): boolean {
    if (!metaData) return false;
    
    return metaData.some(item => item?.key === QLM_METADATA_KEY);
}

// Function for getting the first item with qlm metadata's creds from a woocommerce cart
export function getQlmCredsFromCart(cartData: GetCartQuery): QlmCreds | null {
    if (!cartHasQlmCreds(cartData)) return null;

    const qlmItem = cartData.cart!.contents!.nodes.find(item => 
        !!getQlmCredsFromCartItem(item.extraData)
    );

    return getQlmCredsFromCartItem(qlmItem!.extraData) || null;
}


// Function for getting qlm creds from single woocommerce cart item's metadata
export const getQlmCredsFromCartItem = (extraData: CartContentsNode['extraData']): QlmCreds | undefined => {
    if (!extraData) return undefined;
  
    const qlmCreds = extraData.find(data => data?.key === QLM_METADATA_KEY);
        if (qlmCreds?.value) {
            try {
                const parsedCreds = JSON.parse (qlmCreds.value);
                if (parsedCreds.serialNumber && parsedCreds.activationKey && parsedCreds.userEmail && typeof parsedCreds.existingFeatures === 'number') {
                    return {
                        serialNumber: parsedCreds.serialNumber,
                        activationKey: parsedCreds.activationKey,
                        userEmail: parsedCreds.userEmail,
                        existingFeatures: parsedCreds.existingFeatures
                    };
                }
            } catch (error) {
                console.error('Error parsing QLM credentials:', error);
                return undefined;
            }
        }
    return undefined;
}