import Button from "../common/Button";
import Section from "../layout/Base/Section";
import { FeatureSolutionFieldsFragment } from "@/generated/graphql";
import RenderBento from "./RenderBento";
import Image from "next/image";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import breakpoints from "@/styles/breakpoints";
import { outlineStyles } from "@/styles/globalStyles";
import useViewport from "@/utils/hooks/useViewport";
import { blurDataURL } from "@/styles/blurDataUrl";

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const HeadingOutline = styled.div`
    @media (max-width: ${breakpoints.md}px) {
        /* mobile only */
        border-radius: 16px;
        width: 100%;
        overflow: hidden;
        ${outlineStyles.glosscard}
        background-color: black;
    }

    @media (min-width: ${breakpoints.md}px) {
        /* desktop only */
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
`

const Text = styled.div`
    position: relative;
    padding: 0 20px 24px;
    z-index: 1;
`

const Title = styled.h1<{isBento: boolean}>`
    text-align: center;
    line-height: 120%;
    margin-bottom: 18px;

    @media (max-width: ${breakpoints.md}px) {
        /* mobile only */
        font-size: 42px;

        ${props => props.isBento && css`
            position: relative;
            margin-top: -30%;
            margin-bottom: 6%;
        `}
    }
`

const SubHeading = styled.h3`
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: regular;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: rgb(var(--token-gray));
    text-align: center;
    font-size: 16px;
    white-space: pre-line;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 26px;
    }

    @media (max-width: ${breakpoints.md}px) {
        max-width: 400px;
        margin: 0 auto;
    }
`

const ImageContainer = styled.div<{ aspectW: number, aspectH: number }>`
    position: relative;
    margin-bottom: 2%;
    aspect-ratio: ${props => `${props.aspectW} / ${props.aspectH}`};
    
    @media (min-width: ${breakpoints.md}px) {
        max-width: 225px;
        width: 100%;
    }
`

const ImageFade = styled.div`
    @media (max-width: ${breakpoints.md}px) {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
`

const BentoWrapper = styled.div`
    position: relative;
    z-index: 0;

    @media (min-width: ${breakpoints.md}px) {
        width: 200px;
        height: 200px;
    }
`

const BentoFade = styled.div`
    @media (max-width: ${breakpoints.md}px) {
        position: absolute;
        bottom: 0;
        height: 30%;
        width: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        z-index: 1;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10%;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 6%;
    }
`

interface HeaderProps {
    title: string;
    data: FeatureSolutionFieldsFragment | null | undefined;
}

export default function Header({ data, title }: HeaderProps) {
    if (!data) return <>No data</>;

    const { animation, image, subHeading, button, pageType } = data;

    const isFeaturePage: boolean = typeof pageType === 'string' 
        ? pageType === 'Feature' 
        : Boolean(pageType?.includes('Feature'));

    const { width } = useViewport();
    const isMobile = width > 0 && width < breakpoints.md;

    
    return (
        <Section>
            <StyledContainer>
                <HeadingOutline>
                    {isFeaturePage ? (
                        <>
                            {animation && animation[0] && (
                                <BentoWrapper>
                                    <RenderBento
                                        bentoName={animation[0]}
                                        format={'squareCentered'}
                                    />
                                    <BentoFade/>
                                </BentoWrapper>
                            )}
                        </>
                    ) : (
                        <>
                            {image && (
                                <ImageContainer
                                    aspectW={image.node.mediaDetails?.width || 1}
                                    aspectH={image.node.mediaDetails?.height || 1}
                                >
                                    <Image
                                        src={image.node.mediaItemUrl || ''}
                                        alt={image.node.altText || ''}
                                        width={image.node.mediaDetails?.width || 250}
                                        height={image.node.mediaDetails?.height || 250}
                                        sizes={`(max-width: ${breakpoints.md}px) 98vw, 225px`}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                        }}
                                        placeholder='blur'
                                        blurDataURL={blurDataURL}
                                    />
                                    <ImageFade/>
                                </ImageContainer>
                            )}
                        </>
                    )}

                    <Text>
                        <Title isBento={isFeaturePage} >{title}</Title>
                        <SubHeading
                            dangerouslySetInnerHTML={{ __html: data.subHeading ?? '' }}
                        />

                        {button && (
                            <ButtonWrapper>
                                <Button
                                    text={button.title ?? ''}
                                    href={button.url ?? ''}
                                    variant={isMobile ? 'nav' : undefined}
                                    target={button.target}
                                />
                            </ButtonWrapper>
                        )}
                    </Text>

                </HeadingOutline>
            </StyledContainer>
        </Section>
    );
}