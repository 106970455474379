// BentoItem.tsx
import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import breakpoints from '@/styles/breakpoints';
import BentoText from '@/components/spotlight/bento/BentoText';
import useIntersectionObserver from '@/utils/hooks/useIntersectionObserver';
import useViewport from '@/utils/hooks/useViewport';
import { outlineStyles } from '@/styles/globalStyles';
import dynamic from 'next/dynamic';
import { ProcessedBentoItem as BentoItemType } from '@/utils/spotlightDataProcessor';
import { ClientSideAnimation } from './ClientSideAnimation';

const BentoPopup = dynamic(() => import('@/components/spotlight/bento/BentoPopup'), { 
  ssr: true // Enable SSR for the popup
});

export type BentoVariantType = 'square' | 'mini' | 'squareCentered';

interface Variant {
    variant: BentoVariantType;
    span?: number;
}

const GridItem = styled.div<Variant>`
    width: 100%;
    position: relative;
    background: black;
    ${outlineStyles.glosscard}
    * {
      cursor: pointer;
    }
    
    ${props => props.variant === 'mini' && css`
        /* phone rectangle aspect */
        &::before {
            content: '';
            display: block;
            padding-top: calc(${45 / (props.span || 1)}%);
        }  
    `}

    ${props => (props.variant === 'square' || props.variant === 'squareCentered') && css`
        /* apply span={2} */
        grid-column: ${props.span ? `span ${props.span}` : 'span 1'};

        /* 1:1 aspect for desktop */
        &::before {
            content: '';
            display: block;
            padding-top: calc(${100 / (props.span || 1)}%);
        }  
    `}
`

const GridItemContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const BentoAnimation = styled.div<Variant>`
    position: absolute;

    ${props => props.variant === 'mini' && css`
        aspect-ratio: 1 / 1;
        top: 0%;
        left: 46%;
        right: -6.2%;
    `}

    ${props => props.variant === 'square' && css`
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `}
    
    ${props => props.variant === 'squareCentered' && css`
        top: 0%;
        left: -13%;
        right: -13%;
        bottom: -26%;
    `}
`

const AddonBadge = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-family: 'PP Neue Montreal';
`

interface BentoItemProps {
  data: BentoItemType;
  AnimationComponent: React.ComponentType<any>; // Changed from LazyExoticComponent
  format?: BentoVariantType;
  popup?: boolean;
}

const BentoItem: React.FC<BentoItemProps> = ({ data, AnimationComponent, format, popup = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);
  const isInCenter = useIntersectionObserver(ref, { rootMargin: '-49% 0px -49% 0px' });
  const { width } = useViewport();
  const isMobile = width > 0 && width < breakpoints.md;
  const shouldAnimate = isMobile ? isInCenter : isHovered;

  const variant = format || (isMobile ? "mini" : "square");
  
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (popup) {
      setIsOpen(true);
    }
  };

  return (
    <>
      <GridItem
        variant={variant}
        span={data.span}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={ref}
      >
        <GridItemContent>
          <BentoAnimation variant={variant}>
            <ClientSideAnimation 
              AnimationComponent={AnimationComponent} 
              isAnimating={shouldAnimate} 
            />
          </BentoAnimation>
          <BentoText variant={variant} title={data.title} caption={data.caption}/>
          {data.isAddon && <AddonBadge>{data.addonName}</AddonBadge>}
        </GridItemContent>
      </GridItem>
      <BentoPopup content={data.popup} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <BentoPopupItem data={data} AnimationComponent={AnimationComponent}/>
      </BentoPopup>
    </>
  );
}

const BentoPopupItem = ({ data, AnimationComponent }: BentoItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);
  const isInCenter = useIntersectionObserver(ref, { rootMargin: '-49% 0px -49% 0px' });
  const { width } = useViewport();
  const isMobile = width > 0 && width < breakpoints.md;
  const shouldAnimate = isMobile ? isInCenter : isHovered;
  
  return (
    <GridItem
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={ref}
      variant={'square'}
    >
      <BentoAnimation variant={'square'}>
        <ClientSideAnimation 
          AnimationComponent={AnimationComponent} 
          isAnimating={shouldAnimate} 
        />
      </BentoAnimation>
      <BentoText variant={'square'} title={data.title} caption={data.caption}/>
      {data.isAddon && <AddonBadge>{data.addonName}</AddonBadge>}
    </GridItem>
  );
}

export default BentoItem;