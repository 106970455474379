import {
    ContentBlocksFieldsFragment,
    FeatureSolutionFieldsFragment,
    IdScannersCarouselFieldsFragment,
    WizardCtaFieldsFragment,
    GetSolutionPageQuery,
    GetFeaturePageQuery,
    PostSeoFieldsFragment
} from "@/generated/graphql";

export interface FeatureSolutionPageData {
    title: string;
    featureSolutionHeading: FeatureSolutionFieldsFragment | null | undefined;
    contentBlocks: ContentBlocksFieldsFragment | null | undefined;
    idScannersCarousel: IdScannersCarouselFieldsFragment | null | undefined;
    wizardCTA: WizardCtaFieldsFragment | null | undefined;
    seo: PostSeoFieldsFragment | null | undefined;
}

export function mapSolutionPageToCommonData(data: GetSolutionPageQuery): FeatureSolutionPageData {
    return {
        title: data.solutionPage?.title || '',
        featureSolutionHeading: data.solutionPage?.featureSolutionHeading,
        contentBlocks: data.solutionPage?.contentBlocks,
        idScannersCarousel: data.solutionPage?.idScannersCarousel,
        wizardCTA: data.solutionPage?.wizardCTA,
        seo: data.solutionPage?.seo,
    };
}
  
export function mapFeaturePageToCommonData(data: GetFeaturePageQuery): FeatureSolutionPageData {
    return {
        title: data.featurePage?.title || '',
        featureSolutionHeading: data.featurePage?.featureSolutionHeading,
        contentBlocks: data.featurePage?.contentBlocks,
        idScannersCarousel: data.featurePage?.idScannersCarousel,
        wizardCTA: data.featurePage?.wizardCTA,
        seo: data.featurePage?.seo,
    };
}