// components/landing/IconQuoteSection/index.tsx

import React, { useRef, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { ShieldIcon } from './LandingShieldIcon';
import { LightningIcon } from './LandingLightningIcon';
import breakpoints from '@/styles/breakpoints';
import useIntersectionObserver from '@/utils/hooks/useIntersectionObserver';

interface IconWithTextProps {
  iconType: 'shield' | 'lightning';
  iconSize: {
    base: number;
    md?: number;
  };
  text: ReactNode;
  textboxWidth: {
    base: string;
    md: string;
  };
  lightUpOnHover?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: visible;

  @media (min-width: ${breakpoints.md}px) {
  }
`;

const IconWrapper = styled.div`
  position: relative;
  display: inline-block; // This ensures the wrapper only takes up as much space as needed
  overflow: visible;
`;

const StyledH1 = styled.h2<{ textboxWidth: { base: string; md: string } }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  color: white;
  z-index: 1;
  width: ${props => props.textboxWidth.base};

  @media (min-width: ${breakpoints.md}px) {
    width: ${props => props.textboxWidth.md};
  }
`;

export const ItalicText = styled.em`
  font-style: italic;
`;

const IconQuoteSection: React.FC<IconWithTextProps> = ({ 
  iconType, 
  iconSize, 
  text, 
  textboxWidth, 
  lightUpOnHover = false 
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  
  const isIntersecting = useIntersectionObserver(ref, {
    rootMargin: '-25% 0px',
    threshold: 0.1
  });

  const IconComponent = iconType === 'shield' ? ShieldIcon : LightningIcon;

  const isOn = lightUpOnHover ? isHovered : isIntersecting;

  return (
    <Wrapper ref={ref}>
      <IconWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconComponent isOn={isOn} size={iconSize} />
        <StyledH1 textboxWidth={textboxWidth}>{text}</StyledH1>
      </IconWrapper>
    </Wrapper>
  );
};

export default IconQuoteSection;