// components/icons/CartIcon.tsx
import React from 'react';

interface CartIconProps {
  fill: string;
}

export const CartIcon: React.FC<CartIconProps> = ({ fill }) => (
  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4 14.4C13.401 14.4 12.6 15.201 12.6 16.2C12.6 16.6774 12.7896 17.1352 13.1272 17.4728C13.4648 17.8104 13.9226 18 14.4 18C14.8774 18 15.3352 17.8104 15.6728 17.4728C16.0104 17.1352 16.2 16.6774 16.2 16.2C16.2 15.7226 16.0104 15.2648 15.6728 14.9272C15.3352 14.5896 14.8774 14.4 14.4 14.4ZM0 0V1.8H1.8L5.04 8.631L3.816 10.836C3.681 11.088 3.6 11.385 3.6 11.7C3.6 12.1774 3.78964 12.6352 4.12721 12.9728C4.46477 13.3104 4.92261 13.5 5.4 13.5H16.2V11.7H5.778C5.71833 11.7 5.6611 11.6763 5.6189 11.6341C5.57671 11.5919 5.553 11.5347 5.553 11.475C5.553 11.43 5.562 11.394 5.58 11.367L6.39 9.9H13.095C13.77 9.9 14.364 9.522 14.67 8.973L17.892 3.15C17.955 3.006 18 2.853 18 2.7C18 2.46131 17.9052 2.23239 17.7364 2.0636C17.5676 1.89482 17.3387 1.8 17.1 1.8H3.789L2.943 0M5.4 14.4C4.401 14.4 3.6 15.201 3.6 16.2C3.6 16.6774 3.78964 17.1352 4.12721 17.4728C4.46477 17.8104 4.92261 18 5.4 18C5.87739 18 6.33523 17.8104 6.67279 17.4728C7.01036 17.1352 7.2 16.6774 7.2 16.2C7.2 15.7226 7.01036 15.2648 6.67279 14.9272C6.33523 14.5896 5.87739 14.4 5.4 14.4Z" 
      fill={fill}
    />
  </svg>
);