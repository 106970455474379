// src/components/landing/FakeIdSection/CrisisIntro.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import Section from '@/components/layout/Base/Section';
// import Id from '@/components/landing/FakeIdSections/Id'

const CenterDiv = styled.div`
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
`

const CrisisIntroSection: React.FC = () => {
    return (
        <Section>
        <Container>
            <CenterDiv>
                {/* <Id/> */}
            </CenterDiv>
        </Container>
        </Section>
    )
  }
  
  export default CrisisIntroSection;