import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

interface ZohoContextType {
    bottomMargin: number;
    setBottomMargin: Dispatch<SetStateAction<number>>;
}

const ZohoContext = createContext<ZohoContextType | undefined>(undefined);

export default function ZohoChatProvider({ children }: { children: ReactNode }) {
    const [bottomMargin, setBottomMargin] = useState<number>(0);
    
    const value = {
        bottomMargin,
        setBottomMargin
    }
    
    return (
        <ZohoContext.Provider value={value}>
            {children}
        </ZohoContext.Provider>
    )
}

export function useZohoChat() {
    const context = useContext(ZohoContext);

    if (context === undefined) throw new Error('useZohoChat must be used within ZohoChatProvider');

    return context;
}