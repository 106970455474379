// ./wp-templates/spotlight-single.tsx
import { FaustTemplate } from "@faustwp/core";
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { sendGTMEventWithDebug } from '@/utils/analytics/gtm-events';
import { useRouter } from 'next/router';
import styled from "@emotion/styled";
import Loading from "@/components/layout/Base/loading"
import { Seo } from "@/components/layout/Base/Seo";
import { GetSpotlightQuery, GetSpotlightDocument } from '../generated/graphql';
import { processSpotlightData, ProcessedBentoSection } from '@/utils/spotlightDataProcessor';
import { useProductNav } from "@/providers/ProductNavProvider";
import { AddOnsProvider } from "@/providers/AddOnsProvider";
import { processAddOns } from '@/components/spotlight/sections/BuySection/utils';
import ProductSchema from '@/components/spotlight/sections/BuySection/ProductSchema';

// Sections 
import Hero from '@/components/spotlight/sections/HeroSection';
import StreamingTextSection from '@/components/spotlight/sections/StreamingText';
import ReviewsSection from '@/components/common/ReviewsSection';
import { Analogy, ItalicText } from '@/components/spotlight/sections/AnalogySection';
import CaseStudiesSection from '@/components/common/CaseStudiesSection';
import FormulaSection from '@/components/spotlight/sections/FormulaSection';
import { WizardCtaSection, CtaItalicText} from '@/components/common/WizardCTA';
import TechSpecsSection from '@/components/spotlight/sections/TechSpecsSection';
import FaqSection from '@/components/spotlight/sections/FaqSection';
import CompareSection from '@/components/spotlight/sections/CompareSection';
import BuySection from '@/components/spotlight/sections/BuySection';
import HowItWorksSection from '@/components/spotlight/sections/HowItWorksSection';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import InTheBoxSection from '@/components/spotlight/sections/InTheBoxSection';
import BentoSection from '@/components/spotlight/sections/BentoSection';

// Bento Hero Sections
import ProtectionHeroSection from "@/components/spotlight/idvisor/ProtectionHeroSection";
import PerformanceHeroSection from "@/components/spotlight/idvisor/PerformanceHeroSection";
import EasyUseHeroSection from "@/components/spotlight/idvisor/EasyUseHeroSection";
import AvPerformanceHeroSection from "@/components/spotlight/agevisor/AvPerformanceHeroSection"
import AvProtectionHeroSection from "@/components/spotlight/agevisor/AvProtectionHeroSection"
import AvEasyUseHeroSection from "@/components/spotlight/agevisor/AvEasyUseHeroSection"
import ProProtectionHeroSection from "@/components/spotlight/idvisor-pro/ProProtectionHeroSection";
import ProEasyUseHeroSection from "@/components/spotlight/idvisor-pro/ProEasyUseHeroSection";
import FlexEasyUseHeroSection from "@/components/spotlight/idvisor-flex/FlexEasyUseHeroSection";
import FlexProtectionHeroSection from "@/components/spotlight/idvisor-flex/FlexProtectionHeroSection";
import AvMiniProtectionHeroSection from "@/components/spotlight/agevisor-mini/AvMiniProtectionHeroSection";
import AvMiniPerformanceHeroSection from "@/components/spotlight/agevisor-mini/AvMiniPerformanceHeroSection";
import AvMiniEasyUseHeroSection from "@/components/spotlight/agevisor-mini/AvMiniEasyUseHeroSection"

import FadeShrinkScroll from "@/components/animations/FadeShrinkScroll";
import SectionNav from "@/components/spotlight/sections/SectionNav";
import SubHeroImages from "@/components/spotlight/sections/SubHeroImages";

const Component: FaustTemplate<GetSpotlightQuery> = (props) => {
  const router = useRouter();
  const { setProductNav } = useProductNav();
  const buySectionRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const idleRef = useRef<HTMLDivElement>(null);

  const handleGoToBuyClick = useCallback(() => {
    if (buySectionRef.current) {
      buySectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // Memoize the processed data
  const processedData = useMemo(() => {
    if (props.loading) return null;

    try {
      return {
        ...processSpotlightData(props.data as GetSpotlightQuery),
      };
    } catch (error) {
      console.error("Error processing spotlight data:", error);
      return null;
    }
  }, [props.data, props.loading]);

  if (props.loading) {
    return <Loading />;
  }

  if (!processedData) {
    return <div>Error loading product data</div>;
  }

  // For Compare Section 
  const handleLearnMoreClick = (id: string) => {
    //This is a gross typescripty function that would prob be best handled down in the component.
    let customProductPageUri: string | null | undefined;
    let productPageUri: string | null | undefined;

    const associatedProduct = props.data?.spotlight?.spotlightProductDetails?.associatedProduct?.nodes[0];
    if (associatedProduct?.__typename === 'SimpleProduct') {
      const comparisonGroup = associatedProduct.compareData?.comparisonGroup?.nodes[0];
      if (comparisonGroup?.__typename === 'ComparisonGroup') {
        const productNodes = comparisonGroup.products?.nodes;
        const matchingProduct = productNodes?.find(
          //Find the product that matches the id returned by handleLearnMoreClick
          product => product.__typename === "SimpleProduct" && product.id === id
        );
        if (matchingProduct?.__typename === 'SimpleProduct') {
          customProductPageUri = matchingProduct.compareData?.learnMoreButtonUri?.nodes[0].uri
          productPageUri = matchingProduct.compareData?.name?.edges?.[0]?.node.slug
        }
      }
    }
    
    if (customProductPageUri) {
      router.push(customProductPageUri);
      return;
    }

    if (productPageUri) {
      router.push(`/product/${productPageUri}`);
      return;
    }

    throw new Error('No slug or custom slug found.');
  };

  // Assembling bento sections with their heros 
  const renderBentoSection = (section: ProcessedBentoSection) => {
    if (!section.heroSectionName) {
      return null;
    }
  
    const heroComponents = {
      ProtectionHeroSection,
      PerformanceHeroSection,
      EasyUseHeroSection,
      AvPerformanceHeroSection,
      AvProtectionHeroSection,
      AvEasyUseHeroSection,
      ProProtectionHeroSection,
      ProEasyUseHeroSection,
      FlexEasyUseHeroSection,
      FlexProtectionHeroSection,
      AvMiniProtectionHeroSection,
      AvMiniPerformanceHeroSection,
      AvMiniEasyUseHeroSection,
    };
  
    const HeroComponent = heroComponents[section.heroSectionName as keyof typeof heroComponents];
  
    if (!HeroComponent) {
      return null;
    }
  
    return (
      <StopHorizontalScroll>
        <HeroComponent />
        <SectionSpacer size="small" />
        <BentoSection items={section.bentoItems} />
      </StopHorizontalScroll>
    );
  };

  // For Using Buy Section Add On Price for What's in the Box Addons
  const buySectionAddons = processedData.processedAddonsForInTheBox;

  // Should move into spotlight data processing in future but it's 2am and need brain cells for complex type errors in there
  // Process addons once for both sections
  const processedAddons = useMemo(() => 
    processAddOns(processedData.processedBuySection.addons),
    [processedData.processedBuySection.addons]
  );

  const basePrice = useMemo(() => 
    parseFloat(processedData.processedBuySection.price?.replace(/[^0-9.-]+/g, "")) || 0,
    [processedData.processedBuySection.price]
  );

  const processedProduct = useMemo(() => ({
    ...processedData.processedBuySection,
    addons: processedAddons
  }), [processedData.processedBuySection, processedAddons]);

  // Only re-render when the processed data changes
  const MemoizedBuySection = useMemo(() => (
    <BuySection
      ref={buySectionRef}
      product={processedProduct}
    />
  ), [processedProduct, buySectionRef]);

  const MemoizedInTheBoxSection = useMemo(() => (
    <InTheBoxSection 
      data={processedData.processedInTheBoxSection}
    />
  ), [processedData.processedInTheBoxSection]);

 // For Procuct Nav Data
  useEffect(() => {
    if (processedData) {
      setProductNav({
        productName: processedData.productDisplayName,
        buyButtonText: "Buy Now",
        onBuyClick: handleGoToBuyClick
      });
    }

    return () => {
      setProductNav(null); // Clean up when component unmounts
    };
  }, [processedData, handleGoToBuyClick, setProductNav]);


  // GTM Event view_item
  const hasTrackedView = useRef(false);
  const product = processedData.processedBuySection;

  useEffect(() => {
    if (!hasTrackedView.current && product) { // Add the flag check
      hasTrackedView.current = true; // Set the flag
      
      sendGTMEventWithDebug({
        event: 'view_item',
        ecommerce: {
          currency: 'USD',
          value: parseFloat(product.price?.replace(/[^0-9.-]+/g, "") || "0"), // Total value
          items: [{
            item_id: product.id.toString(),
            item_name: product.name || '',
            price: parseFloat(product.price?.replace(/[^0-9.-]+/g, "") || "0"),
            currency: 'USD',
            item_category: 'ID Scanners',
            quantity: 1,
            item_brand: 'TokenWorks',
          }],
        }
      });
    }
  }, [product]);// Dependencies ensure this only fires once when data is loaded
  
  const StopHorizontalScroll = styled.div`
    max-width: 100%; 
    width: 100%;
    /* overflow-x: clip; */
    /* overflow-y: visible; */
  `
  
  const Spacing = styled.div`
    height: 80px;
  `;

  //Main Spotlight Page JSX
  //Swtiched the large spacers to medium (can test/switch back - was concerned people might lose intrest)
  return (
    <StopHorizontalScroll>
      {processedData && (
        <ProductSchema product={processedData.processedBuySection} />
      )}
      <Seo data={props.data?.spotlight?.seo}/>
      <SectionNav
        buySectionRef={buySectionRef}
        featuresRef={featuresRef}
        reviewsRef={reviewsRef}
        idleRef={idleRef}
      />

      <main>
        <Hero 
          mediaSrc={processedData.processedHeroSection.mediaSrc}
          mediaType={processedData.processedHeroSection.mediaType}
          productName={processedData.productDisplayName}
          subheading={processedData.processedHeroSection.subheading}
          onBuyClick={handleGoToBuyClick}
        />

        <SubHeroImages images={processedData.processedSubHeroImagesSection}/>

        <Spacing/>

        <div className="streaming-text-section">
          {/* <StreamingTextSection textItems={processedData.processedStreamingTextSection.sentences} /> */}
        </div>

        {/* <SectionSpacer size="medium" /> */}

        <Analogy analogyText={processedData.processedAnalogySection} />
        <div ref={idleRef} />
        <div ref={featuresRef} style={{ scrollMarginTop: '-100px' }} />
        <SectionSpacer size="small" />


        {/* <div style={{ zIndex: "1", position: "relative" }}>
          <CaseStudiesSection 
            caseStudies={processedData.processedCaseStudiesSection.caseStudies}
            title={processedData.processedCaseStudiesSection.title}
            subtitle={processedData.processedCaseStudiesSection.subtitle}
          />
        </div> */}

        
        <FadeShrinkScroll variant="compact">
          <FormulaSection {...processedData.processedFormulaSections.first} />
        </FadeShrinkScroll>
        <SectionSpacer size="small" />
        {renderBentoSection(processedData.processedBentoSections.first)}

        <FadeShrinkScroll variant="compact">
          <FormulaSection {...processedData.processedFormulaSections.second} />
        </FadeShrinkScroll>
        <SectionSpacer size="small" />
        {renderBentoSection(processedData.processedBentoSections.second)}

        <FadeShrinkScroll variant="compact">
          <FormulaSection {...processedData.processedFormulaSections.third} />
        </FadeShrinkScroll>
        <SectionSpacer size="small" />
        {renderBentoSection(processedData.processedBentoSections.third)}
      

        <SectionSpacer size="medium" />
        {/* <HowItWorksSection videos={processedData.processedHowItWorksSection.videos} /> */}

        <AddOnsProvider
          key={processedProduct.id}
          initialAddOns={processedAddons}
          basePrice={basePrice} // Base price here refers to price with no addons - it's just the WooCommerce Price field)
        >
          {MemoizedBuySection}

            <SectionSpacer size="medium" />

          <div  ref={reviewsRef}>
            <ReviewsSection reviews={processedData.processedReviews} />
          </div>

          <div ref={idleRef} />

          <SectionSpacer size="medium" />
          {MemoizedInTheBoxSection}
        </AddOnsProvider>

        <SectionSpacer size="medium" />
        <TechSpecsSection specCategories={processedData.processedTechSpecsSection.specCategories} />

        <SectionSpacer size="medium" />
        <FaqSection faqData={processedData.processedFaqSection.faqData} />

        {processedData.processedCompareSection && (
        <>
          <SectionSpacer size="medium" />
          <CompareSection 
            products={processedData.processedCompareSection.products}
            categories={processedData.processedCompareSection.categories}
            currentProduct={processedData.processedCompareSection.currentProduct}
            onBuyClick={handleGoToBuyClick}
            onLearnMoreClick={handleLearnMoreClick}
          />
        </>
      )}

        <SectionSpacer size="medium" />
        <WizardCtaSection 
          bigText={processedData.processedWizardCtaSection.bigText}
          buttonText={processedData.processedWizardCtaSection.buttonText}
          smallText={processedData.processedWizardCtaSection.smallText}
          bigTextWidth={processedData.processedWizardCtaSection.bigTextWidth}
        />
        <SectionSpacer size="medium" /> 
      </main>
    </StopHorizontalScroll>
  );
};

Component.query = GetSpotlightDocument;

Component.variables = (seedQuery, context) => {
  return {
    id: seedQuery?.databaseId,
    asPreview: context?.asPreview,
  };
};


export default Component;