// components/BuySection/PriceDisplay.tsx
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline; 
  gap: 10px;
  margin-top: 6px;
`;

const Price = styled.p`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 18px;
  }
`;

const OriginalPrice = styled(Price)`
  color: #86868B;
  text-decoration-line: line-through;
`;

const SalePrice = styled(Price)`
  color: #D72422;
  font-size: 14px;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 16px;
  }
`;

interface PriceDisplayProps {
  onSale: boolean | null;
  regularPrice: string | null;
  salePrice: string | null;
  price: string | null;
}

export const PriceDisplay: React.FC<PriceDisplayProps> = ({
  onSale,
  regularPrice,
  salePrice,
  price
}) => (
  <>
    <PriceContainer>
      {onSale ? (
        <>
          <OriginalPrice className="product-main-price-regular">{regularPrice}</OriginalPrice>
          <SalePrice className="product-main-price-sale">{salePrice}</SalePrice>
        </>
      ) : (
        <Price  className="product-main-price-regular">{price}</Price>
      )}
    </PriceContainer>
  </>
);