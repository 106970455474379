import { GetCartQuery } from "@/generated/graphql";
import { emptyCart } from "../types";

export function flattenMutationResult(mutationResult: any): GetCartQuery {
    if (!mutationResult || !mutationResult.data) return emptyCart;
  
    const flattenedResult = Object.values(mutationResult.data)[0] as Record<string, any>;
    
    // The mutation result doesn't include tax fields (intentionally).
    // Only the cart query can trigger tax recalculation.
    // We flatten and extend the data to make it fit the shape of GetCartQuery.
    // The missing tax fields get a 'loading' state, for downstream components to consume.

    if (flattenedResult && typeof flattenedResult === 'object' && 'cart' in flattenedResult) {
        const cart = flattenedResult.cart;

        // Align __typename
        if (cart && typeof cart === 'object') {
            cart.__typename = "Cart";
        }

        // Add missing fields
        const extendedCart = {
            ...cart,
            totalTax: 'loading',
            subtotalTax: 'loading',
            shippingTotal: 'loading',
            shippingTax: 'loading',
            total: 'loading',
        };
      
        return { cart: extendedCart } as GetCartQuery;
    }
  
    return emptyCart;
}