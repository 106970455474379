// MobileMenuLink.tsx
import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import Link from 'next/link';
import { UnderlineRed } from '@/components/common/UnderlineRed';
import gsap from 'gsap';

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LinkContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LinkTextWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LinkTextContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const LinkText = styled.span`
  color: #FFF;
  font-family: "PP Editorial New", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  line-height: 70%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: block;

  &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  }
`;

const LinkPrefix = styled(LinkText)`
  font-style: italic;
  font-weight: 400;
`;

const SubmenuContainer = styled.div`
  overflow: hidden;
  height: 0;
`;

interface MobileMenuLinkProps {
  prefix: string;
  suffix: string;
  hasSubmenu?: boolean;
  href?: string;
  toggleMenu?: () => void;
  children?: React.ReactNode;
  isMenuOpen: boolean;
}

interface SubmenuItemProps {
  isMenuOpen: boolean;
}


export const MobileMenuLink: React.FC<MobileMenuLinkProps> = ({ 
  prefix, 
  suffix, 
  hasSubmenu, 
  href, 
  toggleMenu,
  children,
  isMenuOpen
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const submenuRef = useRef<HTMLDivElement>(null);
  const expandIconRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (hasSubmenu && submenuRef.current && expandIconRef.current) {
      if (isSubmenuOpen) {
        gsap.to(submenuRef.current, { height: 'auto', duration: 0.3, ease: 'power2.out' });
        gsap.to(expandIconRef.current, { rotation: -89.95, duration: 0.3, ease: 'power2.out' });
      } else {
        gsap.to(submenuRef.current, { height: 0, duration: 0.3, ease: 'power2.in' });
        gsap.to(expandIconRef.current, { rotation: 0, duration: 0.3, ease: 'power2.in' });
      }
    }
  }, [hasSubmenu, isSubmenuOpen]);

  useEffect(() => {
    if (!isMenuOpen) {
      setIsSubmenuOpen(false);
    }
  }, [isMenuOpen]);

  const handleClick = () => {
    if (hasSubmenu) {
      setIsSubmenuOpen(!isSubmenuOpen);
    } else if (href && toggleMenu) {
      toggleMenu();
    }
  };

  const linkContent = (
    <LinkContent onClick={handleClick}>
      <LinkTextWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <LinkTextContainer>
          <LinkPrefix>{prefix}</LinkPrefix>
          <LinkText>{suffix}</LinkText>
        </LinkTextContainer>
        <UnderlineRed isHovered={isHovered} isActive={isSubmenuOpen} />
      </LinkTextWrapper>
      {hasSubmenu ? (
        <div ref={expandIconRef}>
          <Image
            src="/icons/mobile-nav-carrot-icon.svg"
            alt="Expand"
            width={9}
            height={16}
          />
        </div>
      ) : (
        <Image
          src="/icons/mobile-nav-arrow.svg"
          alt="Navigate"
          width={16}
          height={16}
        />
      )}
    </LinkContent>
  );

  return (
    <LinkContainer>
      {href ? (
        <StyledLink href={href} onClick={handleClick}>
          {linkContent}
        </StyledLink>
      ) : (
        linkContent
      )}
      {hasSubmenu && (
        <SubmenuContainer ref={submenuRef}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && React.isValidElement<SubmenuItemProps>(child)) {
            return React.cloneElement(child, { isMenuOpen });
          }
          return child;
          })}
        </SubmenuContainer>
      )}
    </LinkContainer>
  );
};