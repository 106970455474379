//@/components/layout/Base/Section.ts
import styled from "@emotion/styled";
import breakpoints from "@/styles/breakpoints";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 20px;

  @media (min-width: ${breakpoints.md}px) {
  padding: 0 40px;
  }

  @media (min-width: ${breakpoints.lg}px) {
  padding: 0 80px;
  }

  @media (min-width: ${breakpoints.xl}px) {
  padding: 0 120px;
  /* margin: 0 auto; */
  }
`;

export default Section;