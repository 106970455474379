//BentoPopup.tsx
import gsap from 'gsap';
import { useRef, useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import useScrollLock from '@/utils/hooks/useScrollLock';
import { outlineStyles } from '@/styles/globalStyles';
import breakpoints from '@/styles/breakpoints';
import CloseButton from '@/components/icons/CloseButton';

// Sample code for future page link addition
// {content.pageLink && (
//     <a href={content.pageLink.url}>
//       {content.pageLink.post_title || 'Learn More'}
//     </a>
//   )}

const Overlay = styled.div`
    position: fixed;
    top: -40px;
    left: -40px;
    right: -40px;
    bottom: -40px;
    z-index: 1000;
    background-color: rgba(42, 42, 42, 0.3);
    backdrop-filter: blur(50px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    opacity: 0;
`

const PopupContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    max-height: 90svh;
    display: flex;
    flex-direction: column;
    background-color: black;
    ${outlineStyles.glosscard}
`

const PopupContent = styled.div`
    overflow: scroll;
    padding: 20px;
    padding-top: 68px; /* close button compensation */
    ::-webkit-scrollbar { display: none; }

    @media (min-width: ${breakpoints.md}px) {
        padding-top: 20px;
    }
`

const StyledCloseButton = styled(CloseButton)`
    position: absolute;
    top: 14px;
    right: 20px;
    z-index: 1;
`

const Split = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: ${breakpoints.md}px) {
        flex-direction: row;
    }
`

const Bento = styled.div`
    flex: 1;

    @media (min-width: ${breakpoints.md}px) {
        flex: 0.5;
    }
`

const TextRegion = styled.div`
    flex: 1;
    height: 100%;
    overflow: scroll;
    ::-webkit-scrollbar { display: none; }

    @media (min-width: ${breakpoints.md}px) {
        height: auto;
        position: absolute;
        width: calc(50% - 40px);
        top: 20px;
        right: 20px;
        bottom: 20px;
        padding-top: 45px; /* close button compensation */
    }
`

const Text = styled.div`
    position: relative;
    min-height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1, h2, h3, h4, h5 {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 22px;
        margin-bottom: 16px;
    }

    p {
        font-size: 16px;
        color: rgb(var(--token-gray));
        margin-bottom: 12px;
    }

    @media (min-width: ${breakpoints.md}px) {

        h1, h2, h3, h4, h5 {
            font-size: 32px;
            margin-bottom: 26px;
        }

        p {
            font-size: 18px;
            margin-bottom: 26px;
        }
    }
`

const Gradient = styled.div`
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
`

interface BentoPopupProps {
  content: {
    heading: string;
    body: string;
  }
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const BentoPopup = ({ content, isOpen, onClose, children }: BentoPopupProps) => {
    const [shouldRender, setShouldRender] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const overlay = useRef(null);
    const container = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);


    useScrollLock(isOpen);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isOpen) {
            setShouldRender(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if (shouldRender && isMounted) {
            gsap.to(overlay.current, { opacity: 1, duration: 0.3 });
            gsap.fromTo(container.current, 
                { scale: 0.95, opacity: 0 }, 
                { scale: 1, opacity: 1, duration: 0.3 }
            );
        }
    }, [shouldRender, isMounted]);

    const handleClose = useCallback(() => {
        gsap.to(overlay.current, { opacity: 0, duration: 0.3 });
        gsap.to(container.current, { 
            scale: 0.95, 
            opacity: 0, 
            duration: 0.3,
            onComplete: () => {
                setShouldRender(false);
                onClose();
            }
        });
    }, [onClose]);

    useEffect(() => {
        if (!isOpen && shouldRender) {
            handleClose();
        }
    }, [isOpen, shouldRender, handleClose]);

    if (!isMounted || !shouldRender) return null;

    return ReactDOM.createPortal(
        <>
        <Overlay ref={overlay} onClick={handleClose}> 
            <PopupContainer onClick={(e) => e.stopPropagation()} ref={container}>
                <PopupContent>
                    <StyledCloseButton onClick={handleClose}/>
                    <Split>
                        <Bento>
                            {children}
                        </Bento>
                        <TextRegion>
                            <Text>
                                <h1 className="sans">{content.heading}</h1>
                                <div dangerouslySetInnerHTML={{ __html: content.body }}/>
                            </Text>
                        </TextRegion>
                    </Split>
                    <Gradient/>
                </PopupContent>
            </PopupContainer>
        </Overlay>
        </>,
        document.body
        
    );
}

export default BentoPopup;