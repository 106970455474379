//components/spotlight/sections/CompareSection/MobileCompareSection
import React, { useState } from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import ProductName from '@/components/common/ProductName';
import Button from '@/components/common/Button';
import breakpoints from "@/styles/breakpoints";
import { blurDataURL } from '@/styles/blurDataUrl';

// Interfaces
interface Product {
  id: string;
  name: string;
  price: string;
  image: string;
  specs: {
    [key: string]: {
      value: string | null;
      name: string;
    };
  };
  slug: string;
}
  
interface CompareSectionProps {
  products: Product[];
  categories: string[];
  currentProduct: string;
  onBuyClick: () => void;
  onLearnMoreClick: (slug: string) => void; 
}
  
// Styled components
const CompareContainer = styled.div`
  color: #fff;
  padding: 0 20px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 40px
  }

  @media (min-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

const ProductsRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
`;

const ProductColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center
`;
  
const ProductSelectorWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const StyledProductName = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const CustomSelect = styled.select`
  width: 100%;
  padding: 10px;
  padding-left: 80px; // Adjust based on your ProductName component width
  background-color: rgba(0, 0, 0, 0.66);
  color: transparent; // Hide the default text
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 16px;
  appearance: none;
  background-image: url('/icons/mobile-compare-carrot.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px  5.23px; 

  &:focus {
    outline: none;
  }
`;
  
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1px;
  margin-bottom: 20px;
`;

const ProductImage = styled(Image)`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`;
  
const CategoriesContainer = styled.div`
  width: 100%;
`;

const CategoryRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`;

const CategoryTitle = styled.h3`
  color: rgba(255, 255, 255, 0.92);
  font-family:'PP Neue Montreal', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: -0.36px;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  width: 100%;
`;

const SpecColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
  
const SpecGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  width: 100%;
`;
  
const SpecItem = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  padding-bottom: 10px;
  text-align: center;
`;
  
const SpecValue = styled.div<{ isDefault: boolean }>`
  color: ${props => props.isDefault ? '#5E5E5E' : 'rgba(255, 255, 255, 0.92)'};
  font-family: 'PP Neue Montreal', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: -0.32px;
  margin-bottom: 5px; // Added margin to separate value from name
`;
  
const SpecName = styled.div`
    color: #5E5E5E;
    font-family:'PP Neue Montreal', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 140%;
    letter-spacing: -0.24px;
`;
  
const ProductNameWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;


// Main component
const MobileCompareSection: React.FC<CompareSectionProps> = ({ products, categories, currentProduct, onBuyClick, onLearnMoreClick }) => {
    const [selectedProducts, setSelectedProducts] = useState([products[0], products[1]]);
  
    const handleProductChange = (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selected = products.find(p => p.name === event.target.value);
      if (selected) {
        const newSelectedProducts = [...selectedProducts];
        newSelectedProducts[index] = selected;
        setSelectedProducts(newSelectedProducts);
      }
    };
  
      return (
        <div>
          <CompareContainer>
            <ProductsRow>
              {[0, 1].map((index) => (
                <ProductColumn key={index}>
                  <ProductSelectorWrapper>
                    <StyledProductName>
                      <ProductName 
                        name={selectedProducts[index].name}
                        fontSize={{ base: 16 }}
                        helveticaWeight={400}
                      />
                    </StyledProductName>
                    <CustomSelect onChange={handleProductChange(index)} value={selectedProducts[index].name}>
                      {products.map((product) => (
                        <option key={product.name} value={product.name}>
                          {product.name}
                        </option>
                      ))}
                    </CustomSelect>
                  </ProductSelectorWrapper>
    
                  <ImageContainer>
                    <ProductImage 
                      src={selectedProducts[index].image} 
                      alt={selectedProducts[index].name} 
                      width={300} 
                      height={300}
                      placeholder='blur'
                      blurDataURL={blurDataURL}
                    />
                  </ImageContainer>
    
                  <ProductNameWrapper>
                    <ProductName 
                      name={selectedProducts[index].name}
                      fontSize={{ base: 24 }}
                      helveticaWeight={500}
                    />
                  </ProductNameWrapper>
    
                  <Button 
                    variant='nav'
                    onClick={
                      selectedProducts[index].name === currentProduct 
                        ? onBuyClick 
                        : () => onLearnMoreClick(selectedProducts[index].id)
                    }
                  >
                    {selectedProducts[index].name === currentProduct ? "Buy Now" : "Learn More"}
                  </Button>
                </ProductColumn>
              ))}
            </ProductsRow>
    
            <CategoriesContainer>
              {categories.map((category) => (
                <div key={category}>
                  <CategoryTitle>{category}</CategoryTitle>
                  <CategoryRow>
                    {[0, 1].map((index) => (
                      <SpecColumn key={index}>
                        <SpecGrid>
                          {Object.entries(selectedProducts[index].specs)
                            .filter(([key]) => key.startsWith(category.toLowerCase()))
                            .map(([key, spec]) => (
                              <SpecItem key={key}>
                                <SpecValue isDefault={!spec.value}>
                                  {spec.value || 'X'}
                                </SpecValue>
                                <SpecName>{spec.name}</SpecName>
                              </SpecItem>
                            ))}
                        </SpecGrid>
                      </SpecColumn>
                    ))}
                  </CategoryRow>
                </div>
              ))}
            </CategoriesContainer>
          </CompareContainer>
        </div>
      );
    };
    
    export default MobileCompareSection;