//bentogrid.tsx
import styled from "@emotion/styled";
import breakpoints from "@/styles/breakpoints";

const StyledBentoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    gap: 20px;

    @media (min-width: ${breakpoints.md}px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${breakpoints.lg}px) {
        grid-template-columns: repeat(3, 1fr);
    }

`

const BentoGrid = ({ children }: {children?: React.ReactNode}) => {
    return (
        <>
        <StyledBentoGrid>
            {children}
        </StyledBentoGrid>
        </>
    );
}

export default BentoGrid;