import React, { useState } from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints'
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Container";
import { LgBr, MdBr, SmBr } from '@/components/common/Br';

const StyledSection = styled(Section)`
    max-width: 100%;
`

const TextContainer = styled(Container)`
    padding: 0;
`

const Text = styled.h2`
    text-align: center;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: -0.38px;
    text-wrap: pretty;

    @media (min-width: ${breakpoints.md}px){
        letter-spacing: 0px;
    }
`

export const ItalicText = styled.span`
    font-style: italic;
`

interface AnalogyProps {
    analogyText: string;
}

const convertText = (text: string): JSX.Element[] => {
    const parts = text.split(/(<ItalicText>.*?<\/ItalicText>|<SmBr\s*\/>|<LgBr\s*\/>|<MdBr\s*\/>)/);
    return parts.map((part, index) => {
        if (part.startsWith('<ItalicText>')) {
            return <ItalicText key={index}>{part.replace(/<\/?ItalicText>/g, '')}</ItalicText>;
        }
        if (part === '<SmBr />') {
            return <SmBr key={index} />;
        }
        if (part === '<LgBr />') {
            return <LgBr key={index} />;
        }
        if (part === '<MdBr />') {
            return <MdBr key={index} />;
        }
        // Process the text part
        const processedPart = part
            .replace(/"/g, '\u201C')
            .replace(/"/g, '\u201D')
            .replace(/'/g, '\u2019')
            .replace(/'/g, '\u2018');
        
        // Handle the non-breaking space between the last two words
        const words = processedPart.split(/\s+/);
        if (words.length > 1) {
            const lastTwoWords = words.slice(-2).join('\u00A0');
            words.splice(-2, 2, lastTwoWords);
            return <React.Fragment key={index}>{words.join(' ')}</React.Fragment>;
        }
        
        return <React.Fragment key={index}>{processedPart}</React.Fragment>;
    });
}

export const Analogy: React.FC<AnalogyProps> = ({ analogyText }) => {
    const processedText = convertText(analogyText);

    return (
        <StyledSection>
            <TextContainer>
                <Text>"{processedText}"</Text>
            </TextContainer>
        </StyledSection>
    );
}