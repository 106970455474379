// @/components/landing/MostPopularProductsSection
import React from 'react'; 
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';
import Container from '@/components/layout/Base/Container';
import Section from '@/components/layout/Base/Section';
import Image from 'next/image';
import { ProductCarousel } from '@/components/common/ProductCarousel';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import SectionTitle from './SectionTitle';
import { useNav } from '@/providers/NavProvider';

const StyledSection = styled(Section)`
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const MostPopularProductsSection = () => {
  const { navData } = useNav();

  const mostPopularGroup = navData?.find(group => group.slug === 'most-popular');

  if (!mostPopularGroup || mostPopularGroup.products.length === 0) {
    return null; // Or some fallback UI
  }

  return (
    <>
      <SectionTitle/>
      <StyledSection>
        <ProductCarousel 
          products={mostPopularGroup.products}
          groupName="most-popular" 
          cardWidth={340} 
          cardHeight={600} 
          mobileCardWidth={280}
          mobileCardHeight={500}
          isScrollableDesktop={false}  // Not scrollable on desktop
          isScrollableMobile={true}    // Scrollable on mobile
        />
      </StyledSection>
    </>
  );
};

export default MostPopularProductsSection;