import React from 'react';
import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import gsap from 'gsap';
import Section from '@/components/layout/Base/Section';
import Container from '@/components/layout/Base/Container';
import Tag from '@/components/common/Tag';
import breakpoints from '@/styles/breakpoints';
import ArrowSvg from './arrow.svg';
import { NAV_HEIGHT } from '../layout/Layout';

const Cover = styled.div`
    /* margin-top: calc(-1 * ${NAV_HEIGHT}); */ //required if used outside of PostCoverScrollEffect
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
`;

const BlogTitle = styled.h1`
    font-size: 28px;
    line-height: 128%;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 82px;
    }
`

const SmallText = styled.p`
    font-size: 14px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 16px;
    }
`

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  pointer-events: auto;
`;

const Arrow = styled.div`
    position: absolute;
    right: calc(50% - 1.75vh);
    top: 87.5vh;
    height: 3.5vh;
    width: 3.5vh;
`

interface BlogCoverProps {
    title?: string | null | undefined;
    date: string;
    categories?: {
        nodes: Array<{
            link?: string | null;
            name?: string | null;
            slug?: string | null;
            databaseId: number;
        }>;
    } | null;
}

export default function({title = 'untitled', date, categories }: BlogCoverProps) {
    const categoryLinks = categories?.nodes.map((cat, index) => (
        <React.Fragment key={cat.databaseId}>
            <Tag text={cat.name} href={cat.link} variant="mini"/>
        </React.Fragment>
    ));
    
    const arrow = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({ repeat: -1, yoyo: true });
        
        tl.to(arrow.current, { duration: 1, y: 0, ease: "none" })  // pause at top
            .to(arrow.current, { duration: 0.2, y: 10, ease: "sine.inOut" })
            .to(arrow.current, { duration: 0.2, y: 0, ease: "sine.inOut" });
    
    }, []);


    return (
        <Section>
            <Container>
                <Cover>
                    <header>
                        <SmallText><time>{date}</time></SmallText>
                        <BlogTitle>{title}</BlogTitle>
                        {categoryLinks && categoryLinks.length > 0 && (
                            <Categories>
                                {categoryLinks}
                            </Categories>
                        )}
                    </header>
                    <Arrow ref={arrow}>
                        <ArrowSvg/>
                    </Arrow>
                </Cover>
            </Container>
        </Section>
    );
}