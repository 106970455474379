// components/common/Checkbox.tsx
import React from 'react';
import styled from '@emotion/styled';
import CheckboxSvg from './CheckboxSvg';

const CheckboxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CheckboxInput = styled.input<{ included?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: ${props => props.included ? 'default' : 'pointer'};
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const StyledCheckboxSvg = styled(CheckboxSvg)<{ isHovered: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: ${props => 
    props.isHovered
      ? 'drop-shadow(0px 0px 4.223px rgba(58, 113, 255, 0.66))'
      : 'drop-shadow(0px 0px 4.227px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 4.227px rgba(58, 113, 255, 0.33))'
  };
`;

export interface CheckboxProps {
    name: string;
    label?: string;
    checked: boolean;
    included?: boolean;
    isHovered?: boolean;
    onChange?: (checked: boolean) => void;
}

const Checkbox = ({ 
    name, 
    label, 
    checked, 
    included = false,
    isHovered = false,
    onChange 
}: CheckboxProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!included && onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <label>
            <CheckboxContainer>
                <StyledCheckboxSvg 
                    checked={checked} 
                    included={included}
                    isHovered={isHovered}
                />
                <CheckboxInput
                    type="checkbox"
                    id={name}
                    name={name}
                    checked={checked}
                    included={included}
                    onChange={handleChange}
                    aria-label={label || name}
                />
            </CheckboxContainer>
        </label>
    );
};

export default Checkbox;