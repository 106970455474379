//components/idinsights/BlogPostCard.tsx
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from "next/image";
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { shimmer, toBase64, getDefaultImageForPost } from '@/utils/imageUtils';
import breakpoints from '@/styles/breakpoints';

const PostCard = styled.div`
  display: block;
  aspect-ratio: 1.59 / 1;
  background-size: cover;
  background-position: center;
  background-color: #000; // Default gray background
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  scroll-snap-align: start;
  cursor: pointer;
  opacity: 0;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  * {
    cursor: pointer;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px white;
  }

  &.loaded {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    transition: border-color 0.3s ease;
    z-index: 2;
    pointer-events: none;
  }

  &:hover::before {
    border-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
    pointer-events: none;
    z-index: 1;
  }

  @media (min-width: ${breakpoints.md}px) { 
    border-radius: 12px;
  }

  @media (min-width:  ${breakpoints.lg}px) { 
    border-radius: 15px;
  }

`;


const PostCardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  color: white;
  z-index: 3;
`;

const PostTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 8px;
  color: #FFF;
`;

const PostDate = styled.p`
  font-size: 12px;
`;

interface BlogPostCardProps {
  title: string | null | undefined;
  date: string | null | undefined;
  image: string | null | undefined;
  blurDataUrl?: string | null | undefined;
  id: string;
  categorySlug: string;
  slug: string;
  onLoad: () => void;
  getDefaultImageForPost: (postId: string) => string;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
}
  
const BlogPostCard: React.FC<BlogPostCardProps> = ({
  title,
  date,
  image,
  id,
  categorySlug,
  slug,
  onLoad,
  getDefaultImageForPost,
  blurDataUrl,
  priority = false,
}) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const defaultImageSrc = getDefaultImageForPost(id);

  useEffect(() => {
    setIsLoaded(true);
  }, []);
  
    return (
    <Link href={`/${categorySlug}/${slug}`} passHref>
        <PostCard className={isLoaded ? 'loaded' : ''}>
          <div style={{ position: 'relative', width: '100%', height: '100%',  borderRadius: 'inherit', overflow: 'hidden' }}>
            <Image
              src={image || defaultImageSrc}
              alt={title || 'Post image'}
              fill
              priority={priority}
              loading={priority ? 'eager' : 'lazy'}
              style={{ objectFit: 'cover' }}
              placeholder="blur"
              blurDataURL={blurDataUrl ?? `data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
              sizes={`(max-width: ${breakpoints.md}px) 250px, (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) 335px, (min-width: ${breakpoints.lg}px) 400px`}              
              onLoad={(event) => {
                if (event.target instanceof HTMLImageElement && event.target.complete) {
                  setIsLoaded(true);
                  onLoad();
                }
              }}
            />
          </div>
          <PostCardContent>
            <PostTitle>{title || 'Untitled'}</PostTitle>
            <PostDate>{date ? format(new Date(date), 'MMMM d, yyyy') : ''}</PostDate>
          </PostCardContent>
        </PostCard>
      </Link>
    );
  };
  
export default BlogPostCard;