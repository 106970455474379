// src/components/ReviewsSection/SectionTitle.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'


const SectionTitleContainer = styled(Container)`
    text-align: center;
    margin-bottom: 80px;
    width: 100%;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 116px;
    }
`

const TitleBlur = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 212.96%);
    width: 250px;
    height: 48px;
    margin-top: 10px;
    z-index: -1;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 0;
        width: 350px;
        height: 100px;
        filter: blur(35px);
    }
`
export const SectionTitle: React.FC = () => {
    return (
      <SectionTitleContainer>
        <h1>Tech Specs</h1>
        <TitleBlur></TitleBlur>
        <h5 className="sans">Details on the Hardware</h5>
      </SectionTitleContainer>
    );
  };
  
export default SectionTitle;