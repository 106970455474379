import { useState, useEffect } from "react";
import { getQLMDeviceStatus, QLMData } from ".";

export type QLMValidationState = {
  isLoading: boolean;
  error: string | null;
  data: QLMData | null;
}

export function useQLMValidation(activationKey: string | null): QLMValidationState {
    const [state, setState] = useState<QLMValidationState>({
      isLoading: false,
      error: null,
      data: null,
    });
  
    useEffect(() => {
      if (!activationKey) {
        setState({
          isLoading: false,
          error: null,
          data: null,
        });
        return;
      }
  
      const validateKey = async () => {
        setState((prev: QLMValidationState) => ({ ...prev, isLoading: true }));
        
        try {
          const data = await getQLMDeviceStatus(activationKey);
          
          setState({
            isLoading: false,
            error: null,
            data
          });
        } catch (error) {
          console.error('QLM Validation Error:', error);
          setState({
            isLoading: false,
            error: error instanceof Error 
              ? error.message 
              : 'Failed to validate license key',
            data: null,
          });
        }
      };

      // Add a small delay to ensure loading state is visible
      const timeoutId = setTimeout(() => {
        validateKey();
      }, 500);

      // Cleanup timeout if component unmounts or activationKey changes
      return () => clearTimeout(timeoutId);
    }, [activationKey]);

    return state;
}