// src/components/spotlight/sections/CaseStudySection/SectionTitle.tsx
import React from 'react';
import styled from '@emotion/styled';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints'
import ProductName from '@/components/common/ProductName'; 
import { PRODUCT_NAMES } from '@/constants/productNames';


const SectionTitleContainer = styled(Container)`
    text-align: center;
    margin-bottom: 80px;
    min-width: 100%;
    height: 122px;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 116px;
`

const TitleBlur = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 212.96%);
    width: 327px;
    height: 48px;
    margin-top: 10px;
    z-index: -1;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 0;
        width: 500px;
        height: 100px;
        filter: blur(35px);
    }
`

const ProductNameWrapper = styled.div`
  display: inline-block;
  color: rgb(var(--token-gray));
`;

interface SectionTitleProps {
  title?: string;
  subtitle?: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ 
  title = "Success Stories",
  subtitle = "See our products in action"
}) => {
    const renderText = (text: string) => {
      const parts = text.split(new RegExp(`(${PRODUCT_NAMES.join('|')})`, 'g'));
      return parts.map((part, index) => {
        if (PRODUCT_NAMES.includes(part)) {
          return (
            <ProductNameWrapper key={index}>
              <ProductName 
                name={part} 
                fontSize={{ base: 26}}
                helveticaWeight={500}
                helveticaLetterSpacing="-.01"
                as={'span'} 
              />
            </ProductNameWrapper>
          );
        }
        return part;
      });
    };

    return (
      <SectionTitleContainer>
        <h1>{renderText(title)}</h1>
        <TitleBlur></TitleBlur>
        <h5 className="sans">{renderText(subtitle)}</h5>
      </SectionTitleContainer>
    );
  };