// Animation.tsx

import React from 'react';
import styled from '@emotion/styled';

const AnimationContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--token-blue), 0.1);
    border-radius: 15px;
`

const QuestionMark = styled.div`
    font-size: 3rem;
    color: rgb(var(--token-blue));
    opacity: 0.5;
    font-weight: bold;
`

const Animation: React.FC = () => {
    return (
        <AnimationContainer>
            <QuestionMark>?</QuestionMark>
        </AnimationContainer>
    );
}

export default Animation;