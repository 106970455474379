export const delayFooter = () => {
    if (typeof window !== 'undefined') {
      const footer = document.getElementById('footer-container');
      if (footer) {
        footer.style.display = 'none';
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            footer.style.display = 'block';
          });
        });
      }
    }
  };