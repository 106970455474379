
// components/layout/NavBar/SubMenu.tsx
import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { ProductCarousel } from '@/components/common/ProductCarousel';
import { IndicatorClosed } from '@/components/icons/IndicatorClosed';
import { IndicatorOpen } from '@/components/icons/IndicatorOpen';
import { gsap } from 'gsap'
import { useNav } from '@/providers/NavProvider';

interface SubMenuProps {
  isMenuOpen: boolean;
}


const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CategoryItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px; // This ensures consistent spacing
  margin-bottom: 40px; // This ensures consistent spacing

  &:last-child {
    margin-bottom: 0; // Remove margin from the last item
  }
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  height: 21px;
`;

const IndicatorWrapper = styled.div<{ isSelected: boolean }>`
  width: 4px;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: ${({ isSelected }) => isSelected
    ? 'drop-shadow(0px 0px 2px rgba(215, 36, 34, 0.6)) drop-shadow(0px 2px 6px rgba(237, 0, 0, 1))'
    : 'drop-shadow(0px 0px 2px rgba(215, 36, 34, 0.12))'
  };
  transition: filter 0.3s ease;
`;

const CategoryText = styled.span<{ isSelected: boolean }>`
  color: ${({ isSelected }) => isSelected ? '#FFF' : 'rgba(255, 255, 255, 0.66)'};
  font-family: "PP Editorial New";
  font-size: 25.66px;
  font-style: normal;
  font-weight: 400;
  line-height: 60%;
  display: block;
  padding-top: 5.5px; // Fine-tune this value to adjust vertical alignment
`;

// Add this new styled component
const CategoryContentInner = styled.div`
  opacity: 0;
`;

const categories = ['Most Popular', 'Mobile', 'Stationary', 'Software', 'Accessories'];

const CategoryContent = styled.div<{ isInitialized: boolean }>`
  overflow: hidden;
  height: ${props => props.isInitialized ? 'auto' : '0px'};
  opacity: ${props => props.isInitialized ? 1 : 0};
`;

  
export const SubMenu: React.FC<SubMenuProps> = ({ isMenuOpen }) => {
  const { navData } = useNav();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const categoryRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const innerRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  
  useEffect(() => {
    if (navData) {
      navData.forEach(group => {
        if (group.name) {
          categoryRefs.current[group.name] = React.createRef<HTMLDivElement>();
          innerRefs.current[group.name] = React.createRef<HTMLDivElement>();
        }
      });
      setIsInitialized(true);
    }
  }, [navData]);

  useEffect(() => {
    if (!isMenuOpen) {
      setSelectedCategory(null);
      // Reset all GSAP animations
      Object.values(categoryRefs.current).forEach(ref => {
        if (ref.current) {
          gsap.set(ref.current, { clearProps: "all" });
        }
      });
      Object.values(innerRefs.current).forEach(ref => {
        if (ref.current) {
          gsap.set(ref.current, { clearProps: "all" });
        }
      });
    }
  }, [isMenuOpen]);


  const toggleCategory = (category: string) => {
    if (selectedCategory === category) {
      // Close animation
      gsap.to(innerRefs.current[category].current, {
        opacity: 0,
        duration: 0.2,
        ease: 'power2.out',
      });
      gsap.to(categoryRefs.current[category].current, {
        height: 0,
        duration: 0.3,
        ease: 'power2.out',
        delay: 0.1,
        onComplete: () => setSelectedCategory(null)
      });
    } else {
      // If there's a category open, close it first
      if (selectedCategory) {
        gsap.to(innerRefs.current[selectedCategory].current, {
          opacity: 0,
          duration: 0.2,
          ease: 'power2.out',
        });
        gsap.to(categoryRefs.current[selectedCategory].current, {
          height: 0,
          duration: 0.3,
          ease: 'power2.out',
          delay: 0.1,
        });
      }

      // Open animation for the new category
      setSelectedCategory(category);
      gsap.set(categoryRefs.current[category].current, { height: 'auto' });
      gsap.from(categoryRefs.current[category].current, {
        height: 0,
        duration: 0.3,
        ease: 'power2.out',
        delay: selectedCategory ? 0.3 : 0,
      });
      gsap.to(innerRefs.current[category].current, {
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
        delay: selectedCategory ? 0.5 : 0.2,
      });
    }
  };

  if (!navData) return null;

  return (
    <SubMenuContainer>
      <CategoryList>
        {navData.map((group) => (
          <CategoryItem 
            key={group.slug || group.databaseId} 
            isSelected={selectedCategory === group.name}
          >
            <CategoryHeader onClick={() => group.name && toggleCategory(group.name)}>
              <IndicatorWrapper isSelected={selectedCategory === group.name}>
                {selectedCategory === group.name ? <IndicatorOpen /> : <IndicatorClosed />}
              </IndicatorWrapper>
              <CategoryText isSelected={selectedCategory === group.name}>
                {group.name || 'Unnamed Category'}
              </CategoryText>
            </CategoryHeader>
            <CategoryContent 
              ref={categoryRefs.current[group.name || '']} 
              isInitialized={isInitialized && selectedCategory === group.name}
            >
              <CategoryContentInner ref={innerRefs.current[group.name || '']}>
                {group.name && (
                  <ProductCarousel 
                    key={group.name} 
                    products={group.products}
                    groupName={group.name}
                    cardWidth={153.821} 
                    cardHeight={271.448}
                  />
                )}
              </CategoryContentInner>
            </CategoryContent>
          </CategoryItem>
        ))}
      </CategoryList>
    </SubMenuContainer>
  );
};