import styled from '@emotion/styled';

const Wrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const Svg = styled.svg<{ $blur: number }>`
    position: absolute;
    top: 0;
    left: -25%;
    width: 150%;
    height: 100%;
    z-index: -1;
    filter: ${props => `blur(${props.$blur}px)`};
    will-change: filter; /* hint for GPU rendering */
`;

const BackgroundLight = styled.ellipse<{ $opacity: number, $color: string }>`
    fill: ${props => props.$color};
    opacity: ${props => props.$opacity};
`;

interface GlowProps {
    children: React.ReactNode;
    blur?: number;
    opacity?: number;
    height?: number;
    width?: number;
    color?: string;
}

const Glow = ({ children, blur = 20, opacity = 0.5, height = 50, width = 50, color = 'white' }: GlowProps) => {
    return (
        <Wrapper>
            <Svg $blur={blur} preserveAspectRatio="none">
                <BackgroundLight $color={color} $opacity={opacity} cx="50%" cy="50%" rx={`${width}%`} ry={`${height}%`} />
            </Svg>
            {children}
        </Wrapper>
    );
}

export default Glow;