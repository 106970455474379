import React from 'react';
import styled from '@emotion/styled';
import Section from '@/components/layout/Base/Section';
import BlogContainer from '@/components/blog/BlogContainer';
import DateTagsAuthor from '@/components/blog/PostInfo/DateTagsAuthor';
import Comments from './Comments';

const StyledBlogContainer = styled(BlogContainer)`
    margin-top: 72px;
    margin-bottom: 72px;
    gap: 32px;
`

const Line = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: white;  
`;

interface PostInfoProps {
    date: string;
    commentCount: number | null | undefined;
    categories?: {
        nodes: Array<{
            name?: string | null;
            link?: string | null;
            slug?: string | null;
            databaseId: number;
        }>
    } | null | undefined;
    author?: {
        node: {
            name?: string | null | undefined;
            uri?: string | null | undefined;
            databaseId: number;
        };
    } | null | undefined;
    tags?: {
        nodes: Array<{
            name?: string | null;
            link?: string | null;
            slug?: string | null;
            databaseId: number;
        }>;
    } | null | undefined;
}

const PostInfo = ({ categories, date, author, tags, commentCount }: PostInfoProps) => {

    return (
        <Section>
            <StyledBlogContainer>
                <Line/>
                <DateTagsAuthor date={date} categories={categories} tags={tags} author={author} />
                {/* <Comments commentCount={commentCount || 0}/> */}
            </StyledBlogContainer>
        </Section>
    );
};

export default PostInfo;