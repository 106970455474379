import { load } from 'cheerio';

function isInternalUrl(url: string): boolean {
    if (!url) return false;
    
    const baseUrl = process.env.NEXT_PUBLIC_WORDPRESS_URL || '';
    try {
        // Remove protocol (http/https) for comparison
        const urlDomain = new URL(url).hostname;
        const baseDomain = new URL(baseUrl).hostname;
        return urlDomain === baseDomain;
    } catch {
        return false;
    }
}

export function getFirstImage(htmlContent: string) {
    // Guard against undefined/null
    if (!htmlContent) return '';
  
    // Client-side
    if (typeof window !== 'undefined') {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const firstImg = doc.querySelector('img');
        const src = firstImg?.src || '';
        return isInternalUrl(src) ? src : '';
    }
  
    // Server-side
    try {
        const $ = load(htmlContent);
        const firstImg = $('img').first();
        const src = firstImg.attr('src') || '';
        return isInternalUrl(src) ? src : '';
    } catch (error) {
        console.error('Error parsing HTML:', error);
        return '';
    }
};