const getEndpoint = () => {
    const isServer = typeof window === 'undefined';
    const apiRoute = '/api/qlm/validate'

    if (isServer) {
        if (process.env.NODE_ENV === 'development') {
            return `http://localhost:3000${apiRoute}`;
        }

        if (!process.env.FRONTEND_URL) {
            throw new Error('FRONTEND_URL is not defined in environment variables');
        }
        
        return `${process.env.FRONTEND_URL + apiRoute}`;
    }

    return apiRoute;
}

export interface QLMData {
    serialNumber: string;
    userEmail: string;
    existingFeatures: number;
}

export async function getQLMDeviceStatus(activationKey: string): Promise<QLMData> {

    const endpoint = getEndpoint();

    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ activationKey }),
    });

    const data = await response.json();

    if (!response.ok) {
        console.error('QLM query failed:', data);
        throw new Error(data.error || 'Validation failed');
    }

    // Validate response structure
    if (!data.serialNumber || !data.userEmail || typeof data.features !== 'number') {
        console.error('Invalid QLM response structure:', data);
        throw new Error('Invalid response from server');
    }
    
    return {
        serialNumber: data.serialNumber,
        userEmail: data.userEmail,
        existingFeatures: data.features
    };
}