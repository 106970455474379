// src/components/landing/IdInsightsPreviewSection/index.tsx

import React from 'react';
import useViewport from '@/utils/hooks/useViewport';
import Library from '@/components/idinsights/Library';
import SectionSpacer from '@/components/layout/Base/SectionSpacer';
import { 
  GetCategoriesPreviewQuery, 
  GetCategoriesWithPostsQuery 
} from '@/generated/graphql';
import LibraryTitle from './LibraryTitle';
import Intro from './Intro';

interface IdInsightsPreviewSectionProps {
  categories: GetCategoriesPreviewQuery['categories'] | null | undefined;
}

const IdInsightsPreviewSection: React.FC<IdInsightsPreviewSectionProps> = ({ categories }) => {
  const { width } = useViewport();

  // Early return if no width or categories
  if (width === 0 || !categories?.nodes?.length) {
    return null;
  }

  // Convert the preview query type to the full query type
  const processedCategories: GetCategoriesWithPostsQuery['categories'] = {
    __typename: categories.__typename,
    nodes: categories.nodes.map(category => ({
      __typename: category.__typename,
      id: category.id,
      name: category.name,
      slug: category.slug,
      posts: category.posts ? {
        __typename: category.posts.__typename,
        nodes: category.posts.nodes?.filter((post): post is NonNullable<typeof post> => 
          post !== null && post !== undefined
        ) || [],
        pageInfo: {
          __typename: "CategoryToPostConnectionPageInfo",
          hasNextPage: false,
          endCursor: null,
          hasPreviousPage: false,
          startCursor: null
        }
      } : null
    }))
  };

  return (
    <main>
      <Intro />
      <SectionSpacer size='medium' />
      <LibraryTitle />
      <Library categoriesData={processedCategories} />
    </main>
  );
};

export default IdInsightsPreviewSection;