// src/components/common/Br.tsx
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';


export const MdBr = styled.br`
  display: none;
  @media (min-width: ${breakpoints.md}px) {
    display: inline;
  }
`

export const SmBr = styled.br`
  display: inline;
  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`
export const LgBr = styled.br`
  display: none;
  @media (min-width: ${breakpoints.lg}px) {
    display: inline;
  }
`;
