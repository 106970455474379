// components/icons/NavIcon.tsx
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { UnderlineRed } from '@/components/common/UnderlineRed';
import breakpoints from '@/styles/breakpoints';
import Link from 'next/link';
import { useCart } from '@/providers/CartProvider';
import { CartIcon } from './CartIcon';
import Image from 'next/image';

interface NavIconProps {
  icon: string | React.FC<{ fill: string }>;
  alt: string;
  href?: string;
  isCart?: boolean;
}

const NavIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  * {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div<{ isCart: boolean }>`
  width: 18px;
  height: 18px;

  @media (min-width: ${breakpoints.md}px) {
    width: 22px;
    height: 22px;
  }
`;

const UnderlineWrapper = styled.div`
  display: none;
  width: 20px;

  @media (min-width: ${breakpoints.md}px) {
    display: block;
  }
`;

const CartCount = styled.span`
  position: absolute;
  top: -8px;
  right: -6px;
  background-color: rgba(215, 36, 34, .96);
  color: white;
  border-radius: 100%;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  font-family: 'PP Neue Montreal';
  filter: drop-shadow(0px 0px 6px rgba(237, 0, 0, 0.66));
  border: solid #D72422 1px;
`;

export const NavIcon: React.FC<NavIconProps> = ({ icon, alt, href, isCart = false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { cartItems, isCartReady } = useCart();

  const itemCount = isCartReady ? cartItems.reduce((total, item) => total + item.quantity, 0) : 0;
  const hasItems = itemCount > 0;

  const iconColor = isCart && hasItems ? "#3A71FF" : "white";

  const renderIcon = () => {
    if (typeof icon === 'string') {
      return <Image src={icon} alt={alt} width={22} height={22} />;
    } else if (isCart) {
      return <CartIcon fill={iconColor} />;
    }
    return null;
  };

  const content = (
    <NavIconWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconWrapper isCart={isCart}>
        {renderIcon()}
      </IconWrapper>
      {isCart && hasItems && <CartCount>{itemCount}</CartCount>}
      <UnderlineWrapper>
        <UnderlineRed isHovered={isHovered} />
      </UnderlineWrapper>
    </NavIconWrapper>
  );

  return href ? (
    <Link href={href} passHref>
      {content}
    </Link>
  ) : content;
};