// WooSessionManager/createSessionLink/getSessionToken.ts
import { GraphQLClient } from 'graphql-request';
import { GetSessionTokenDocument, GetSessionTokenQuery } from '@/generated/graphql';
import { getWooSessionToken, setWooSessionToken } from '../utils';
import { WP_GRAPHQL_ENDPOINT } from '@/constants/wpGraphQlEndpoint';


async function fetchNewSessionToken(): Promise<string | null> {
  let sessionToken;
  try {
    const graphQLClient = new GraphQLClient(WP_GRAPHQL_ENDPOINT);

    const data = await graphQLClient.request<GetSessionTokenQuery>(GetSessionTokenDocument);

    const sessionToken = data?.customer?.sessionToken;

    if (!sessionToken) {
        throw new Error('Failed to retrieve a new session token');
    }
    
    return sessionToken; 
  } catch (err) {
    console.log('Error fetching session token:', err);
    return null;
  }
}

export async function getSessionToken(forceFetch = false): Promise<string | null> {
  const result = await getWooSessionToken();
  let sessionToken = result.token ?? null;
  
  if (!sessionToken || forceFetch) {
    sessionToken = await fetchNewSessionToken();
    if (sessionToken) {
      await setWooSessionToken(sessionToken);
    }
  }
  
  return sessionToken;
}