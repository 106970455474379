// src/components/landing/FakeIDSection/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import Section from '@/components/layout/Base/Section';
import Container from "@/components/layout/Base/Container";
import breakpoints from '@/styles/breakpoints';
import NewspaperBackground from './NewspaperBackground';
import { PopupButton } from '@typeform/embed-react'
import { sendGAEvent } from '@next/third-parties/google';

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const NewspaperWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 856px; // Match the original SVG width
  height: 0;
  padding-bottom: 250px;

    @media (min-width: ${breakpoints.sm}px) { 
  padding-bottom: 400px;
    }

  @media (min-width: ${breakpoints.md}px) { 
  padding-bottom: 598px;
    }
`;



const StyledNewspaperBackground = styled(NewspaperBackground)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

    @media (min-width: ${breakpoints.sm}px) { 
    width: 90%;
    height: 90%;
    }

  @media (min-width: ${breakpoints.md}px) { 
    width: 90%;
    height: 90%;
    }

  @media (min-width: ${breakpoints.lg}px) { 
    width: 856px;
    height: 100%;
    }
`;

const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4vw 2px 8vw 0; // Increased bottom padding
  max-height: 600px;
  z-index: 2,

@media (min-width: ${breakpoints.sm}px) { 
    padding: 60px 7px 100px 0; // Increased bottom padding

    }

@media (min-width: ${breakpoints.md}px) { 
    padding: 60px 7px 140px 0; // Increased bottom padding

    }
`;

const HeadlineWrapper = styled.div`
  text-align: center;
  margin-top: 7%;
  width: 100%;

@media (min-width: ${breakpoints.md}px) { 
    margin-top: 5%;
    }
`;

const MainHeadline = styled.h1`
  color: rgba(0, 0, 0, 0.92);
  font-family: "PP Editorial New", serif;
  font-size: 6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
  margin: 0;

  @media (min-width: ${breakpoints.md}px) {
  font-size: 5.5vw;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 60px;
  }
`;

const SubHeadline = styled.h2`
  color: rgba(0, 0, 0, 0.92);
  font-family: "PP Editorial New", serif;
  font-size: 6vw;
  font-style: italic;
  font-weight: 400;
  line-height: 1.1;
  margin: 10px 0 0 0;

  @media (min-width: ${breakpoints.md}px) {
  font-size: 5.5vw;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 60px;
  }
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CTAButton = styled(PopupButton)`
  height: 48.717px;
  width: 100%;
  max-width: 90%; 
  border-radius: 5.413px;
  border: 1.07px solid rgba(255, 255, 255, 0.08);
  background: #3A71FF;
  box-shadow: 0px 0px 6.618px 5.458px rgba(0, 0, 0, 0.10) inset;
  color: #FFF;
  font-family: "PP Editorial New";
  font-size: 17.507px;
  font-weight: 400;
  line-height: 2.9;
  letter-spacing: -0.525px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2A61EF;
  }

    @media (min-width: ${breakpoints.md}px) {
        font-size: 32px;
          height: 100%;
          max-width: 90%;
          margin-top: 80px
  }

`;

//   max-width: 276.08px;

const ProtectText = styled.p`
  color: rgba(0, 0, 0, 0.92);
  font-family: "PP Editorial New", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.162px;
  margin: 10px 0 0 0;
  text-align: center;

  @media (min-width: ${breakpoints.md}px) {
      font-size: 29px;
       margin: 40px 0 0 0;
  }
`;

const ItalicSpan = styled.span`
  font-style: italic;
`;

export const NewspaperSection: React.FC = () => {
    return (
      <Section>
        <CenterDiv>
        <NewspaperWrapper>
          <StyledNewspaperBackground />
          <ContentOverlay>
            <HeadlineWrapper>
              <MainHeadline>Your Business in the Newspaper</MainHeadline>
              <SubHeadline>Not in a good way.</SubHeadline>
            </HeadlineWrapper>
            <BottomContent>
              <CTAButton
                id="urXbR95w"
                size={90}
                onReady={({ formId }) => {
                  console.log('form ready');
                  sendGAEvent({ event: 'typeform_ready', form_id: formId });
                }}
                onStarted={({ formId, responseId }) => {
                  console.log(`Form ${formId} started with response ID ${responseId}`);
                  sendGAEvent({ event: 'typeform_started', form_id: formId, response_id: responseId });
                }}
                onSubmit={({ formId, responseId }) => {
                  console.log(`Form ${formId} submitted, response id: ${responseId}`);
                  sendGAEvent({ event: 'typeform_submitted', form_id: formId, response_id: responseId });
                }}
              >
                 Get Fake ID Protection
                 </CTAButton>
              <ProtectText>
                Protect <ItalicSpan>Your</ItalicSpan> License. Protect <ItalicSpan>Your</ItalicSpan> Business. Protect <ItalicSpan>Your</ItalicSpan> Future.
              </ProtectText>
            </BottomContent>
            </ContentOverlay>
            </NewspaperWrapper>
        </CenterDiv>
      </Section>
    );
  };
  
  export default NewspaperSection;