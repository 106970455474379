import breakpoints from "@/styles/breakpoints";
import { css } from "@emotion/react"

export type ButtonColors = keyof typeof buttonColors;

const buttonColors = {
    blue: css`
        --glow-color: var(--token-blue);
        background-color: rgb(var(--token-blue));
        color: rgba(255, 255, 255, 0.92);

        /* Gradient stroke */
        ::before {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(225, 225, 225, 0.05));
            pointer-events: none;
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask-composite: exclude;
        }

        &:hover {
            background-color: rgb(var(--button-hover));
            box-shadow: 0px 0px 50px 12px rgba(var(--glow-color), 0.35);
        }
        
    `,
    black: css`
        --glow-color: 155, 155, 155;
        background-color: black;
        color: rgba(255, 255, 255, 0.92);
        border-color: rgba(255, 255, 255, 0.3);
        border-style: solid;
        border-width: 1px;

        &:hover {
            background-color: rgb(var(--dark-gray));
        }
    `,
    gray: css`
    --glow-color: 155, 155, 155;
    background-color:  rgb(var(--dark-gray));
    color: rgba(255, 255, 255, 0.9);
    `
}

export type ButtonSizes = keyof typeof buttonSizes;

const buttonSizes = {
    xs: css`  // nav
        font-size: 16px;
        height: 39px;
        padding: 0 12px;
        border-radius: 8px;

        // linear stroke
        &::before {
            border-radius: 8px;
        }

        // inset shadow
        &::after {
            border-radius: 8px;
            box-shadow: 0px 0px 1.5px 1px rgba(var(--glow-color), 0.1) inset;
        }

    `,
    sm: css` // checkout
        font-size: 16px;
        height: 43px;
        padding: 0 14px;
        margin: 0 auto;
        border-radius: 8px;

        // linear stroke
        &::before {
            border-radius: 8px;
        }

        // inset shadow
        &::after {
            border-radius: 8px;
            box-shadow: 0px 1.208px 30.211px 10.272px rgba(var(--glow-color), 0.33), 0px 0px 5.861px 4.834px rgba(0, 0, 0, 0.10) inset;
        }

    `,
    md: css` // default
        font-size: 26px;
        padding: 12px 16px;
        border-radius: 12px;
        box-shadow: 0px 0px 3.8px 1.8px rgba(var(--glow-color), 0.25);

        ::before {
            border-radius: 12px;
            padding: 1px;
        }

        /* inner shadow */
        ::after {
            border-radius: 12px;
            box-shadow: 0px 0px 2.5px 2px rgba(0, 0, 0, 0.1) inset;
        }

    `,

    lg: css` // hero
        width: 100%;
        font-size: 18px;
        letter-spacing: -0.03em;
        padding: 15.25px 40px;
        border-radius: 6.9px;
        box-shadow: 0px 2px 30px 10px rgba(var(--glow-color), 0.33);

        // linear stroke
        &::before {
            border-radius: 6.9px;
            padding: 2px;
        }

        // inner shadow
        &::after {
            box-shadow: 0px 0px 2.045px 0.961px rgba(0, 0, 0, 0.1) inset;
            border-radius: 6.9px;
        }

        &:hover {
            box-shadow: 0px 2px 30px 16px rgba(var(--glow-color), 0.33);
            border-radius: 6.9px;
        }

        @media (min-width: ${breakpoints.md}px) {
            width: auto;
            font-size: 24px;
            letter-spacing: -0.03em;
            padding: 25.5px 34px;
            border-radius: 13px;
            box-shadow:  0px 0px 3.855px 1.811px rgba(var(--glow-color), 0.33);

            // linear stroke
            &::before {
                border-radius: 13px;
            }

            // inner shadow
            &::after {
                box-shadow: 0px 0px 2.51px 2.07px rgba(0, 0, 0, 0.1) inset;
                 border-radius: 13px;
            }

            &:hover {
                box-shadow: 0px 2px 30px 10px rgba(var(--glow-color), 0.33);
                 border-radius: 13px;
            }
        }

        @media (min-width: ${breakpoints.md+200}px) {
            font-size: 28px;
            padding: 25.5px 52px;
        }
    `,

    xxxl: css` // mega
        font-size: 32px;
        letter-spacing: -0.02em;
        padding: 30px 40px;
        border-radius: 16px;
        box-shadow: 0px 2px 30px 10px rgba(var(--glow-color), 0.33);

        // linear stroke
        &::before {
            border-radius: 16px;
            padding: 2px;
        }

        // inner shadow
        &::after {
            box-shadow: 0px 0px 7px 6px rgba(0, 0, 0, 0.1) inset;
        }

        &:hover {
            box-shadow: 0px 2px 60px 30px rgba(var(--glow-color), 0.33);
        }
        
        @media (min-width: ${breakpoints.md}px) {
            font-size: 42px;
            padding: 50px 60px;
            border-radius: 20px;
            box-shadow: 0px 2px 50px 17px rgba(var(--glow-color), 0.33);

            // linear stroke
            &::before {
                border-radius: 20px;
            }

            // inner shadow
            &::after {
                box-shadow: 0px 0px 9.7px 8px rgba(0, 0, 0, 0.1) inset;
            }

            &:hover {
                box-shadow: 0px 2px 100px 50px rgba(var(--glow-color), 0.33);
            }
        }
    `
} as const;

export type ButtonDisabledStyles = keyof typeof buttonDisabledStyles;

const buttonDisabledStyles = {
    dark: css`
        background-color: rgba(var(--glow-color), 0.15);
        color: rgba(255, 255, 255, 0.5);
        transition: all 0.3s ease;

        &:hover {
            background-color: rgba(var(--glow-color), 0.15);
            box-shadow: none;
        }

        &::before {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02));
        }

        &::after {
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.05) inset;
        }
    `,
    glowing: css`
        background-color: rgba(var(--glow-color), 0.15);
        color: rgba(255, 255, 255, 0.5);
        transition: all 0.3s ease;
        position: relative;

        @keyframes subtle-pulse {
            0% { 
                opacity: 1;
                background-color: rgba(var(--glow-color), 0.15);
            }
            50% { 
                opacity: 0.8;
                background-color: rgba(var(--glow-color), 0.12);
            }
            100% { 
                opacity: 1;
                background-color: rgba(var(--glow-color), 0.15);
            }
        }

        @keyframes glow-pulse {
            0% {
                box-shadow: 
                    0px 0px 2px 1px rgba(var(--glow-color), 0.5),
                    0px 0px 25px 8px rgba(var(--glow-color), 0.25),
                    0px 0px 45px 15px rgba(var(--glow-color), 0.15);
            }
            50% {
                box-shadow: 
                    0px 0px 2px 1px rgba(var(--glow-color), 0.6),
                    0px 0px 35px 12px rgba(var(--glow-color), 0.35),
                    0px 0px 65px 25px rgba(var(--glow-color), 0.25);
            }
            100% {
                box-shadow: 
                    0px 0px 2px 1px rgba(var(--glow-color), 0.5),
                    0px 0px 25px 8px rgba(var(--glow-color), 0.25),
                    0px 0px 45px 15px rgba(var(--glow-color), 0.15);
            }
        }

        animation: 
            subtle-pulse 3s infinite ease-in-out,
            glow-pulse 3s infinite ease-in-out;

        &::before {
            background: linear-gradient(to bottom, 
                rgba(var(--glow-color), 0.1), 
                rgba(var(--glow-color), 0.03)
            );
            opacity: 0.5;
            transition: opacity 0.3s ease;
        }

        &::after {
            box-shadow: 0px 0px 3px 2px rgba(var(--glow-color), 0.1) inset;
        }

        &:hover {
            background-color: rgba(var(--glow-color), 0.15);
            &::before {
                opacity: 0.7;
            }
        }
    `
} as const;

export type ButtonLayouts = keyof typeof buttonLayouts;

const buttonLayouts = {
    fill: css`
        width: 100%;
    `
} as const;


export const buttonStyles = {
    colors: buttonColors,
    sizes: buttonSizes,
    disabled: buttonDisabledStyles,
    layouts: buttonLayouts,
    // glow: buttonGlows
}