// createUpdateLink.ts
import { ApolloLink } from '@apollo/client';
import { getWooSessionToken, setWooSessionToken } from './utils';
import { WooSessionCookieResponse } from '@/pages/api/wooSessionCookie';
import { setContext } from '@apollo/client/link/context';

let tokenUpdatePromise: Promise<void | WooSessionCookieResponse> = Promise.resolve();

export function createUpdateLink(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext();
      const headers = context?.response?.headers;

      if (typeof window !== 'undefined') {
        const sessionToken = headers.get('woocommerce-session');
        if (sessionToken) {
          // Chain token updates to prevent race conditions
          tokenUpdatePromise = tokenUpdatePromise.then(() => 
            setWooSessionToken(sessionToken)
          );
        }
      }

      return response;
    });
  });
}