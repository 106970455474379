// components/sections/FormulaSection/index.tsx
import React from 'react';
import styled from '@emotion/styled';
import SectionTitle from './SectionTitle';
import FormulaItem from './FormulaItem';
import breakpoints from '@/styles/breakpoints'
import Section from "@/components/layout/Base/Section";


interface FormulaSectionProps {
  productName: string;
  titleFontSize?: {
    base: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  titleHelveticaWeight?: number | string;
  titleCustomStyles?: React.CSSProperties;
  items: Array<{
    icon: 'shield' | 'lightning' | 'person';
    isOn: boolean;
    text: string;
  }>;
  showTitle?: boolean; // New prop to control title visibility
}

const StyledSection = styled(Section)`
    height: 100svh;
    width: 100%;
    max-width: 100%;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 25%;

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    width: 750px;
    padding: 0;
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 895px;
  }
`;

export const FormulaSection: React.FC<FormulaSectionProps> = ({
    productName,
    titleFontSize,
    titleHelveticaWeight,
    titleCustomStyles,
    items,
    showTitle = false // Default to false if not provided
  }) => {
  
    return (
      <StyledSection>
        {showTitle && (
          <SectionTitle
            productName={productName}
            fontSize={titleFontSize}
            helveticaWeight={titleHelveticaWeight}
            customStyles={titleCustomStyles}
          />
        )}
        <ItemsContainer>
          {items.map((item, index) => (
            <FormulaItem key={index} {...item} />
          ))}
        </ItemsContainer>
      </StyledSection>
    );
  };

  export default FormulaSection;