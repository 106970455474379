import React from 'react'
import styled from '@emotion/styled'

interface BadgeProps {
  scale: number;
}

const BadgeContainer = styled.div<BadgeProps>`
    width: ${props => 45.241 * props.scale}px;
    height: ${props => 13.572 * props.scale}px;
    position: absolute;
    z-index: 10;
    bottom: ${props => 11 * props.scale}px;
    right: ${props => 7.5 * props.scale}px;
`;

const Badge = styled.svg<BadgeProps>`
    width: 100%;
    height: 100%;
    fill: #D72422;
    filter: drop-shadow(0px ${props => 0.905 * props.scale}px ${props => 2.714 * props.scale}px rgba(0, 0, 0, 0.66)) drop-shadow(0px 0px ${props => 2.5 * props.scale}px rgba(237, 0, 0, 0.66));
`;

const Text = styled.text<BadgeProps>`
    fill: #FFF;
    font-family: "PP Editorial New";
    font-size: 7.239px;
    font-style: normal;
    font-weight: 400;
    line-height: 70%;
    text-shadow: 0px 0px 0.452px rgba(0, 0, 0, 0.25);
`;

interface SaleBadgeProps {
  scale: number;
}

const SaleBadge: React.FC<SaleBadgeProps> = ({ scale }) => {
    return (
        <BadgeContainer scale={scale}>
            <Badge xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 15" scale={scale}>
                <path d="M1.29115 1.20379C1.05417 0.907561 1.26508 0.46875 1.64443 0.46875L45.0032 0.46875C45.3826 0.46875 45.5935 0.90756 45.3565 1.20378L44.8134 1.88268C44.6812 2.04791 44.6812 2.28269 44.8134 2.44792L45.7184 3.57923C45.8506 3.74446 45.8506 3.97924 45.7184 4.14447L44.8134 5.27579C44.6812 5.44102 44.6812 5.6758 44.8134 5.84103L45.7184 6.97234C45.8506 7.13757 45.8506 7.37235 45.7184 7.53758L44.8134 8.66889C44.6812 8.83412 44.6812 9.0689 44.8134 9.23413L45.7184 10.3654C45.8506 10.5307 45.8506 10.7655 45.7184 10.9307L44.8134 12.062C44.6812 12.2272 44.6812 12.462 44.8134 12.6272L45.3565 13.3061C45.5935 13.6024 45.3826 14.0412 45.0032 14.0412L1.64443 14.0412C1.26508 14.0412 1.05417 13.6024 1.29115 13.3061L1.83427 12.6272C1.96645 12.462 1.96645 12.2272 1.83427 12.062L0.929222 10.9307C0.797038 10.7655 0.797038 10.5307 0.929222 10.3654L1.83427 9.23413C1.96645 9.0689 1.96645 8.83412 1.83427 8.66889L0.929222 7.53758C0.797038 7.37235 0.797038 7.13757 0.929222 6.97234L1.83427 5.84103C1.96645 5.6758 1.96645 5.44102 1.83427 5.27579L0.929222 4.14447C0.797038 3.97924 0.797038 3.74446 0.929222 3.57923L1.83427 2.44792C1.96645 2.28269 1.96645 2.04791 1.83427 1.88268L1.29115 1.20379Z" />
                <Text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" scale={scale}>On Sale!</Text>
            </Badge>
        </BadgeContainer>
    )
}

export default SaleBadge;