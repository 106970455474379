//components/idinsights/Library.tsx
import React from 'react';
import styled from '@emotion/styled';
import CategoryCarousel from './CategoryCarousel';
import { getDefaultImageForPost as getDefaultImage } from '@/utils/imageUtils';
import { GetAllCategoriesWithPostsQuery, Post } from '@/generated/graphql';
import breakpoints from '@/styles/breakpoints';

const PostsLibrary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${breakpoints.md}px){
    gap: 40px;
  }
`;

interface LibraryProps {
  categoriesData: GetAllCategoriesWithPostsQuery['categories'];
}

export default function Library({ categoriesData }: LibraryProps) {
  const getDefaultImageForPost = (post: Post) => getDefaultImage(post.id);
  const firstPost = categoriesData?.nodes?.[0]?.posts?.nodes?.[0];
  const firstImageUrl = firstPost?.featuredImage?.node?.mediaItemUrl;

  // Create inline base64 placeholder
  const inlineImageStyle = firstImageUrl ? {
    backgroundImage: `url(${firstImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  } : {};

  return (
    <PostsLibrary>
      
      {/* Rest of library */}
      {categoriesData?.nodes
        ?.filter(category => category?.posts?.nodes && category.posts.nodes.length > 0)
        .map((category, index) => (
          <CategoryCarousel
            key={category?.id}
            category={category?.name || ''}
            categorySlug={category?.slug || 'idinsights'}
            posts={category?.posts?.nodes?.filter((post): post is Post => post !== null) || []}
            getDefaultImageForPost={getDefaultImageForPost}
            isFirstCategory={index === 0}
            inlineFirstImage={index === 0}
          />
        ))}
    </PostsLibrary>
  );
};