import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

interface LightningIconProps {
  isOn: boolean;
  size?: {
    base: number;
    md?: number;
  };
}

const IconSVG = styled.svg<LightningIconProps>`
  fill: #01010E;
  transition: filter 0.3s ease;
  
  width: ${props => props.size?.base || 50}px;
  height: ${props => (props.size?.base || 50) * (695/398)}px;

  @media (min-width: ${breakpoints.md}px) {
    width: ${props => props.size?.md || 127}px;
    height: ${props => (props.size?.md || 127) * (695/398)}px;
  }
  
  ${({ isOn }) => isOn ? `
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.33));
  ` : `
    filter: none;
  `}

  path {
    stroke-width: 2px;
    transition: stroke 0.3s ease;
  }
`;

export const LightningIcon: React.FC<LightningIconProps> = ({ isOn, size }) => {
  return (
    <IconSVG 
      isOn={isOn}
      size={size}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 398 695"
    >
      <defs>
        <linearGradient id="lightningStrokeGradientOn" x1="199.5" y1="-16" x2="199.5" y2="706" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.88" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient id="lightningStrokeGradientOff" x1="199.5" y1="-16" x2="199.5" y2="706" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.22" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.13" />
        </linearGradient>
        <filter id="innerShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="6" result="blur"/>
          <feOffset dy="0" dx="0"/>
          <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"/>
          <feFlood flood-color="#000000" flood-opacity="0.13"/>
          <feComposite in2="shadowDiff" operator="in"/>
          <feComposite in2="SourceGraphic" operator="over"/>
        </filter>
      </defs>
      <g filter="url(#innerShadow)">
        <path 
          d="M311.468 6.47693L223.291 287.381L391.953 284.052C396.053 283.971 398.459 288.633 396.018 291.928L99.6671 691.951C97.6806 694.633 93.4657 692.493 94.458 689.307L178.614 419.081H6.78853C2.73577 419.081 0.408896 414.469 2.81754 411.209L304.523 2.94626C307.172 -0.638372 312.803 2.22429 311.468 6.47693Z"
          stroke={isOn ? "url(#lightningStrokeGradientOn)" : "url(#lightningStrokeGradientOff)"}
        />
      </g>
    </IconSVG>
  );
};