// utils/gtm-debug.ts
export const debugGTM = (event: any) => {
  if (process.env.NEXT_PUBLIC_GTM_DEBUG === 'true') {
    console.group('🔍 GTM Event Fired:');
    console.log('Event Name:', event.event);
    console.log('Event Data:', JSON.stringify(event, null, 2));
    console.log('Timestamp:', new Date().toISOString());
    console.log('Page URL:', window.location.href);
    
    // Validate required fields
    if (event.ecommerce?.items) {
      event.ecommerce.items.forEach((item: any, index: number) => {
        if (!item.item_id) {
          console.warn(`⚠️ Missing item_id in item ${index}`);
        }
        if (!item.price) {
          console.warn(`⚠️ Missing price in item ${index}`);
        }
      });
    }
    
    console.groupEnd();
  }
};