// components/BuySection/ProductImage.tsx
import React from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import breakpoints from '@/styles/breakpoints';
import { blurDataURL } from '@/styles/blurDataUrl';

const ImageColumn = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: 50%;
    padding-right: 20px;
  }
`;

const ImageContainerWrapper = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}px) {
    border: none;
    background: none;
  }
`;

const ImageWrapper = styled.div`
  height: 80%;
  width: 80%;
  position: relative;

  @media (min-width: ${breakpoints.md}px) {
    height: 100%;
    width: 100%;
  }
`;

interface ProductImageProps {
  src?: string | null;
  alt?: string | null;
}

export const ProductImage: React.FC<ProductImageProps> = ({ src, alt }) => (
  <ImageColumn>
    <ImageContainerWrapper>
      <ImageContainer>
        <ImageWrapper>
          <Image
            src={src || ''}
            alt={alt || ''}
            fill
            style={{objectFit: "contain"}}
            placeholder='blur'
            blurDataURL={blurDataURL}
          />
        </ImageWrapper>
      </ImageContainer>
    </ImageContainerWrapper>
  </ImageColumn>
);