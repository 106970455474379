import { ContentBlocksFieldsFragment } from "@/generated/graphql";
import styled from "@emotion/styled";
import Section from "../layout/Base/Section";
import Container from "../layout/Base/Container";
import breakpoints from "@/styles/breakpoints";
import Image from "next/image";
import { css } from "@emotion/react";
import Button from "../common/Button";
import useViewport from "@/utils/hooks/useViewport";
import SectionSpacer from "../layout/Base/SectionSpacer";
import { createSafeId } from "./createSafeId";
import { blurDataURL } from "@/styles/blurDataUrl";

interface ContentBlocksProps {
    data: ContentBlocksFieldsFragment | null | undefined;
}

interface LayoutProps {
    layout?: "Media Left" | "Media Right" | "Media Center" | string | null;
    outline?: boolean;
}

const StyledContainer = styled(Container)`
    max-width: 1020px;
`

const Writing = styled.div`
    letter-spacing: normal;

    & h3 {
        margin-bottom: 24px;
        line-height: 140%;
        color: white;

        @media (max-width: ${breakpoints.md}px) {
            /* mobile only */
            font-family: 'PP Neue Montreal', sans-serif;
            line-height: 110%;
            margin-top: 12px;
            margin-bottom: 38px;
        }
    }

    & h4 {
        font-family: 'PP Neue Montreal', sans-serif;
        margin-bottom: -8px;
    }

    & h1 {
        text-align: center;

        @media (max-width: ${breakpoints.md}px) {
            /* mobile only */
            line-height: 120%;
            font-size: 50px;
        }
    }

    & p, & li {
        font-family: 'PP Neue Montreal', sans-serif;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: normal;
        opacity: .9;
        color: #B2B2B2;
        font-weight: regular;
        letter-spacing: -0.01em;

        @media (min-width: ${breakpoints.md}px) {
            font-size: 22px;
            line-height: 140%;
        }
    }

    & b, & strong {
        letter-spacing: 0em;
    }

    & ul, & ol {
        margin-top: -15px;
    }

    & li {
        margin-top: -10px;

        @media (min-width: ${breakpoints.md}px) {
            line-height: 115%;
            margin-top: -12px;
        }
    }
`

const Layout = styled.div<LayoutProps>`
    display: flex;
    flex-direction: column;
    gap: 28px;

    @media (min-width: ${breakpoints.md}px) {
        /* desktop only */
        flex-direction: row;
        justify-content: space-between;
        gap: 56px;
    }

    @media (max-width: ${breakpoints.md}px) {
        /* mobile only */
        border-radius: 16px;
        overflow: hidden;
    }

    ${props => props.outline === true && css`
        //Glosscard outline style:
        position: relative;
        overflow: hidden;
        border-radius: 20px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            border-radius: 20px;
            padding: 1px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.25));
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask-composite: exclude;
            pointer-events: none;
        }
    `}

    ${props => props.layout === "Media Center" && css`
        @media (min-width: ${breakpoints.md}px) {
            margin-top: -100px;
            margin-bottom: -100px;
        }
    `}
`;

const MediaColumn = styled.div<LayoutProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    ${props => props.layout === 'Media Left' && css`
        order: 1;
    `}

    @media (min-width: ${breakpoints.md}px) {
        /* desktop only */
        max-width: 40%; //This controls the balance between image and text on desktop
    }

    @media (max-width: ${breakpoints.md}px) {
        /* mobile only */
        order: 1;
        margin-bottom: -12px;
    }

    ${props => props.layout === 'Media Center' && css`
        @media (min-width: ${breakpoints.md}px) {
            margin: 0 auto;
            max-width: 100%;
        }
    `}
`

const TextColumn = styled.div<LayoutProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${props => props.layout === 'Media Left' && css`
        order: 2;
    `}

    @media (max-width: ${breakpoints.md}px) {
        /* mobile only */
        order: 2;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
`

const BigTitleContainer = styled.div`
    @media (min-width: ${breakpoints.md}px) {
        /* margin-bottom: -120px; */
    }
`

const ImageContainer = styled.div`
    @media (max-width: ${breakpoints.md}px) {
        /* mobile */
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        border-radius: 12px;
        overflow: hidden;

        .responsive-image {
            max-height: 500px;
        }
    }
`;

const TextBox = styled.div`
    
`

const ButtonWrapper = styled.div<{ centered?: boolean, isMega?: boolean }>`
    @media (max-width: ${breakpoints.md}px) {
        /* mobile only */
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10%;
    }

    @media (min-width: ${breakpoints.md}px) {
        /* desktop only */
        margin-top: 6%;
    }

    ${props => props.centered === true && css`
    display: flex;
    justify-content: center;
    width: 100%;

        ${props.isMega === false && css`
            @media (max-width: ${breakpoints.md}px) {
                /* desktop */
                margin-top: -80px;
            }

            @media (min-width: ${breakpoints.md}px) {
                /* mobile */
                margin-top: 12px;
            }
        `}
    `}

    ${props => props.isMega === true && css`
        @media (min-width: ${breakpoints.md}px) {
            margin-bottom: 120px;
        }
    `}
`

const Spacer = styled.div`
    height: 36px;

    @media (min-width: ${breakpoints.md}px) {
        height: 200px;
    }
`

const EmbedContainer = styled.div<{ aspectRatio: number }>`
    position: relative;
    width: 100%;
    padding-top: ${props => (1 / props.aspectRatio) * 100}%;
    
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (min-width: ${breakpoints.md}px) {
        /* desktop only */
        margin: 0 auto;
    }
`;

export default function ContentBlocks({ data }: ContentBlocksProps) {

    if (!data?.contentBlocks) return <></>;

    const { width } = useViewport();
    const isMobile = width > 0 && width < breakpoints.md;

    const getAspectRatio = (html: string) => {
        const match = html.match(/width="(\d+)".+?height="(\d+)"/);
        return match ? parseInt(match[1], 10) / parseInt(match[2], 10) : 16/9;
    };

    return (
        <Writing>

            {data.contentBlocks.map((block, index) => {
                const isMediaTypeEmbed: boolean = typeof block?.mediaType === 'string' 
                    ? block?.mediaType === 'Embed' 
                    : Boolean(block?.mediaType?.includes('Embed'));

                const isMediaTypeNone: boolean = typeof block?.mediaType === 'string' 
                    ? block?.mediaType === 'None' 
                    : Boolean(block?.mediaType?.includes('None'));

                const layoutStyle: LayoutProps = {
                    layout: (block?.heading || block?.text) 
                        ? (block?.layout ?? "Media Left")
                        : "Media Center",
                    outline: isMobile && !!block?.text
                };

                const sectionId: string = block?.heading 
                    ? createSafeId(block.heading)
                    : `section-${index}`;

                // If no body, center a big heading
                const isBigHeading: boolean = !!(block?.heading && !block?.text);

                // If only button, MEGA!
                const isMegaButton: boolean = (!block?.heading && !block?.text && isMediaTypeNone);

                return (
                    <Section key={index} id={sectionId}>
                        <StyledContainer>
                            <Layout outline={layoutStyle.outline} layout={layoutStyle.layout}>

                                {(block?.text || block?.heading || block?.button) && (
                                    <TextColumn layout={layoutStyle.layout}>
                                        <TextBox>
                                            {block?.text && (
                                                <>
                                                    {isMobile && isMediaTypeNone && <div style={{ height: '28px' }}/>}
                                                    {block?.heading && <h3 dangerouslySetInnerHTML={{ __html: block?.heading }}/>}
                                                    <div
                                                        style={{ whiteSpace: "pre-line" }}
                                                        dangerouslySetInnerHTML={{ __html: block?.text }}
                                                    />
                                                </>
                                            )}
                                            
                                            {isBigHeading && block?.heading && (
                                                <>
                                                    <BigTitleContainer>
                                                        {isMobile && <SectionSpacer size={'medium'}/>}
                                                        <h1 dangerouslySetInnerHTML={{ __html: block?.heading }}/>
                                                    </BigTitleContainer>
                                                    {isBigHeading && block.button && <div style={{ height: "100px"}}/>}
                                                </>
                                            )}

                                            {block?.button && (
                                                <ButtonWrapper centered={isMediaTypeNone} isMega={isMegaButton}>
                                                    <Button
                                                        text={block.button.title ?? ''}
                                                        href={block.button.url ?? ''}
                                                        variant={isMegaButton ? 'mega' : isMobile ? 'nav' : undefined}
                                                        target={block.button.target}
                                                    >Text</Button>
                                                </ButtonWrapper>
                                            )}

                                        </TextBox>
                                    </TextColumn>
                                )}

                                {!isMediaTypeNone && (
                                    <MediaColumn layout={layoutStyle.layout}>
                                        {isMediaTypeEmbed ? (
                                            <>
                                                {block?.embeddedMedia && (
                                                    <EmbedContainer aspectRatio={getAspectRatio(block?.embeddedMedia)}>
                                                        <div dangerouslySetInnerHTML={{ __html: block?.embeddedMedia }} />
                                                    </EmbedContainer>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {block?.image?.node.mediaItemUrl && (
                                                    <ImageContainer>
                                                        <Image
                                                            src={block?.image?.node.mediaItemUrl}
                                                            alt={block?.image?.node.altText || ''}
                                                            width={800}
                                                            height={500}
                                                            sizes={`(max-width: ${breakpoints.md}px) 95vw, 40vw`}
                                                            style={{
                                                                width: '100%',
                                                                height: 'auto',
                                                                maxHeight: '600px',
                                                                objectFit: 'contain',
                                                                maxWidth: '100%',
                                                                marginBottom: "-5px",
                                                            }}
                                                            placeholder='blur'
                                                            blurDataURL={blurDataURL}
                                                        />
                                                    </ImageContainer>
                                                )}
                                            </>
                                        )}
                                    </MediaColumn>
                                )}

                            </Layout>
                        </StyledContainer>
                        <Spacer/>
                    </Section>
                )
            })}

        </Writing>
    );
}