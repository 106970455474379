import styled from '@emotion/styled'
import Image from 'next/image';
import DeviceImage from './idvisor-flex_full_on.png';
import breakpoints from '@/styles/breakpoints';
import Section from '@/components/layout/Base/Section';
import Container from "@/components/layout/Base/Container";
import { blurDataUrlSoft } from '@/styles/blurDataUrl';

const StyledSection = styled(Section)`
    overflow: hidden;
    background-color: black;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0px;
    max-width: 100%;
`

const HeroContainer = styled(Container)`
    position: relative;
    height: 90svh;
    max-height: 800px;
`

const Textbox = styled.div`
    padding-top: 48px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    z-index: 1;

    @media (min-width: ${breakpoints.md}px) {
        height: 100%;
        max-width: 35%;
        padding-left: 0px;
        padding-bottom: 15%;
        align-items: flex-start;
        justify-content: center;
    }
`

const Title = styled.h1`
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 72px;
        text-align: start;
    }
`

const Description = styled.p`
    font-size: 18px;
    line-height: 150%;
    color: rgb(var(--token-gray));
    text-align: center;
    max-width: 500px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 20px;
        text-align: start;
    }

    @media (min-width: ${breakpoints.lg}px) {
        font-size: 26px;
    }
`

const ProductImage = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    margin-bottom: 40px;

    @media (min-width: ${breakpoints.md}px) {
        position: absolute;
        top: 5%;
        left: 0;
        right: 0;
        height: 90%;
    }
`

const FlexEasyUseHeroSection = () => {
    return (
        <StyledSection>
            <HeroContainer>
                <Textbox>
                    <Title>Easy to Use for All&nbsp;Staff</Title>
                    <Description>With an intuitive interface and minimal training required, the Flex enables your entire team to check&nbsp;IDs&nbsp;efficiently.</Description>
                </Textbox>
                <ProductImage>
                    <Image
                        src={DeviceImage}
                        alt=''
                        fill={true}
                        sizes={`(max-width: ${breakpoints.md}px) 194px, 325px`}
                        style={{objectFit: "contain"}}
                        placeholder='blur'
                        blurDataURL={blurDataUrlSoft}
                    />
                </ProductImage>
            </HeroContainer>
        </StyledSection>
    )
}

export default FlexEasyUseHeroSection;