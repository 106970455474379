import { Dispatch, SetStateAction } from 'react';
import { flattenMutationResult } from './flattenMutationResult';
import { CartData } from '../types';
import { useRecalcLocalCart } from './useRecalcLocalCart';

type ContextFunction = (...args: any[]) => Promise<any>;

export interface SetData {
    setCartData: Dispatch<SetStateAction<CartData>>;
    setCartLoading: Dispatch<SetStateAction<boolean>>;
    generateClientMutationId: () => string;
}

export function useUpdateLogic({ setCartData, setCartLoading, generateClientMutationId }: SetData) {

    const { recalcLocalCart } = useRecalcLocalCart({ setCartData });

    function withUpdateLogic(fn: ContextFunction, fnName: string): ContextFunction {
        return async (...args: any[]) => {

            //execute mutation
            let mutationResult;
            try {
                setCartLoading(true);
                mutationResult = await fn(...args, generateClientMutationId());
            } catch (error) {
                console.error(`Error in function ${fnName}:`, error);
                throw error; //This gets caught by the component who uses the function!
            } finally {
                setCartLoading(false);
            }

            //process data to fit type
            const cartData = flattenMutationResult(mutationResult);

            //update local state
            setCartData(cartData);

            //recalc Tax
            recalcLocalCart(); //mutation that returns local cart

            return mutationResult;
        };
    }

    return { withUpdateLogic };
}