// components/BuySection/ProductDetails.tsx
import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useAddOns } from '@/providers/AddOnsProvider';
import { AddOnsList } from './AddOnsList';
import { useCart } from '@/providers/CartProvider';
import ProductName from '@/components/common/ProductName';
import { BentoAddonMapping } from './types';
import breakpoints from '@/styles/breakpoints';
import { PriceDisplay } from './PriceDisplay';
import { CheckoutSection } from './CheckoutSection';
import { ProductAddonInput, CartItemInput } from '@/generated/graphql';
import Image from "next/image";
import { sendGTMEventWithDebug } from '@/utils/analytics/gtm-events';


const InfoColumn = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: 50%;
    padding-top: 40px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    padding-top: 60px;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 0;
  }
`;

const TokenWorksTitle = styled.h2`
    color: #FFF;
    font-family: Copperplate, serif;
    font-size: 12.149px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: .1rem;
    margin-bottom: 3px;

    @media (min-width: ${breakpoints.md}px) {
        font-size: 18px;
    }
`;

const TokenWorks = styled(Image)`
  margin-bottom: 10px;
  width: 100px;
    height: auto;

  @media (min-width: ${breakpoints.md}px) {
    width: 157px;
    height: 26.283px;
    margin-bottom: 6px;
  }
`


interface ProductDetailsProps {
    name: string | null;
    price: string | null;
    regularPrice: string | null;
    salePrice: string | null;
    onSale: boolean | null;
    bentoAddonMapping: BentoAddonMapping;
    productId: string | null;
  }
  
  export const ProductDetails: React.FC<ProductDetailsProps> = ({
    name,
    price,
    regularPrice,
    salePrice,
    onSale,
    bentoAddonMapping,
    productId
  }) => {
    const router = useRouter();
    const { totalPrice, state } = useAddOns();
    const { addToCart, isCartReady } = useCart();
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
  
    const handleAddToCart = async () => {
        if (!productId) {
          setError('Product ID is missing');
          return;
        }
    
        try {
          setIsLoading(true);
          setError(null);

          // Convert totalPrice to string with 2 decimal places for consistency
          const formattedTotalPrice = totalPrice.toFixed(2);

          sendGTMEventWithDebug({
            event: 'add_to_cart',
            ecommerce: {
              currency: 'USD',
              value: Number(totalPrice.toFixed(2)), // Convert to number with 2 decimals
              items: [{
                item_id: productId.toString(),
                item_name: name || '',
                price: Number(totalPrice.toFixed(2)),
                currency: 'USD',
                item_brand: 'TokenWorks',
                item_category: 'ID Scanners',
                quantity: 1,
                ...(state.selectedAddOns.size > 0 && {
                  item_addons: Array.from(state.selectedAddOns)
                })
              }],
            }
          });
    
          // Format addons according to the expected structure
          const formattedAddons: ProductAddonInput[] = [{
            fieldName: `${productId}-0`,
            value: Array.from(state.selectedAddOns).map(name => name) // Convert Set to array
          }];
    
          const cartItem: CartItemInput = {
            productId: parseInt(productId, 10),
            quantity: 1,
            addons: formattedAddons
          };
    
          const result = await addToCart(cartItem);
    
          if (result.data) {
            router.push('/cart');
          }
        } catch (err) {
          setError('Failed to add to cart');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };
    

    return (
      <InfoColumn>
        <ProductInfo>
            <TokenWorks
              src="/icons/tokenworks-logo.svg" 
              alt="TokenWorks Logo"
              width={157}
              height={26.283}
              sizes={`
                (max-width: ${breakpoints.sm}px) 100px,
                (max-width: ${breakpoints.md}px) 120px,
                157px
              `}
            />
          <ProductName
            name={name || ''}
            fontSize={{ base: 40, md: 70, lg: 60 }}
            helveticaWeight={400}
            helveticaLetterSpacing="-0.02rem"
            as="h2"
            align='left'
          />
          <PriceDisplay
            onSale={onSale}
            regularPrice={regularPrice}
            salePrice={salePrice}
            price={price}
          />
          <AddOnsList bentoAddonMapping={bentoAddonMapping} />
          <CheckoutSection
            totalPrice={totalPrice}
            isLoading={isLoading}
            isCartReady={isCartReady}
            onAddToCart={handleAddToCart}
            error={error}
          />
        </ProductInfo>
      </InfoColumn>
    );
  };