// components/common/PriceDisplay.tsx
// components/common/PriceDisplay.tsx
import React from 'react';
import styled from '@emotion/styled';

interface SizeProps {
  fontSize?: number;
  marginTop?: number;
  strikethroughHeight?: number;
  strikethroughTop?: string;
}

const PriceContainer = styled.div<SizeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PP Neue Montreal', sans-serif;
  font-size: ${props => props.fontSize || 11}px;
  margin-top: ${props => props.marginTop || 5}px;
`;

const RegularPrice = styled.span<{ isOnSale: boolean }>`
  position: relative;
  color: ${props => props.isOnSale ? 'rgba(255, 255, 255, 0.5)' : '#FFF'};
`;

const SalePriceOverlay = styled.div<SizeProps>`
  position: absolute;
  top: ${props => props.strikethroughTop || '55%'};
  left: -2px;
  right: -2px;
  height: ${props => props.strikethroughHeight || 0.905}px;
  background: #D72422;
  border-radius: 451.961px;
  box-shadow: 0px 0.905px 2.714px 0px #ED0000, 0px -1.357px 2.714px 0.452px rgba(237, 0, 0, 0.33), 0px 0px 0px 0.452px rgba(237, 0, 0, 0.33);
`;

const SalePrice = styled.span`
  color: #D72422;
  margin-left: 8px;
`;

interface PriceDisplayProps extends SizeProps {
  regularPrice: number;
  salePrice?: number;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ 
  regularPrice, 
  salePrice, 
  fontSize, 
  marginTop, 
  strikethroughHeight, 
  strikethroughTop 
}) => {
  const isOnSale = salePrice !== undefined && salePrice < regularPrice;

  if (regularPrice === 0 && (!salePrice || salePrice === 0)) {
    return null;
  }
  
  return (
    <PriceContainer fontSize={fontSize} marginTop={marginTop}>
      <RegularPrice isOnSale={isOnSale}>
        ${regularPrice.toFixed(2)}
        {isOnSale && <SalePriceOverlay strikethroughHeight={strikethroughHeight} strikethroughTop={strikethroughTop} />}
      </RegularPrice>
      {isOnSale && <SalePrice>${salePrice.toFixed(2)}</SalePrice>}
    </PriceContainer>
  );
};

export default PriceDisplay;