// CircleButton.tsx
import React from 'react';
import styled from '@emotion/styled';

const Circle = styled.div<{ disabled?: boolean }>`
    position: relative;
    width: 40px;
    height: 40px;
    * {
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    }
    transition: all 0.2s ease;
    filter: drop-shadow(0px 0px 4px #000);
    opacity: ${props => props.disabled ? 0.5 : 1};

    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        position: absolute;
    }

    svg {
        color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.33)' : 'white'};
    }

    circle {
        fill: #1B1B1B;
        stroke-width: 1px;
        vector-effect: non-scaling-stroke;
        filter: drop-shadow(0px 0px 1.8px rgba(255, 255, 255, 0.13));
    }

    :hover:not(:disabled) {
        .hover-gradient {
            opacity: 1;
        }
    }

    :focus-visible {
        outline: 2px solid rgb(var(--token-blue));
        outline-offset: 2px;
    }
`;

interface CircleButtonProps {
    children: React.ReactNode;
    label: string;
    disabled?: boolean;
    onClick?: () => void;
}

const CircleButton: React.FC<CircleButtonProps> = ({ children, label, disabled, onClick, ...props }) => {
    return (
        <Circle 
            aria-label={label} 
            role='button' 
            disabled={disabled}
            onClick={disabled ? undefined : onClick}
            {...props}
        >
            <svg viewBox="0 0 40 40" width="100%" height="100%">
                <defs>
                    <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#E7E7E7" stopOpacity="0.2178" />
                        <stop offset="100%" stopColor="#E7E7E7" stopOpacity="0.33" />
                    </linearGradient>
                    <linearGradient id="hoverGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#E7E7E7" stopOpacity="0.66" />
                        <stop offset="100%" stopColor="#E7E7E7" stopOpacity="1" />
                    </linearGradient>
                </defs>
                <circle cx="20" cy="20" r="19.5" fill="#1B1B1B" stroke="url(#strokeGradient)" />
                <circle className="hover-gradient" cx="20" cy="20" r="19.5" stroke="url(#hoverGradient)" opacity="0" />
            </svg>
            {children}
        </Circle>
    );
}

export default CircleButton;