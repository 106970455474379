// InTheBoxSection/AddonOverlay.tsx
import React from 'react';
import styled from "@emotion/styled";
import breakpoints from "@/styles/breakpoints";
import Checkbox from '@/components/common/Checkbox';

const GreyOverlay = styled.div<{ show: boolean }>`
    display: ${props => props.show ? 'block' : 'none'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(83, 83, 83, 0.3);
    cursor: pointer;
`

const Content = styled.div<{ show: boolean }>`
    display: ${props => props.show ? 'block' : 'none'};
    position: absolute;
    top: 10px;
    right: 10px;
`

const CheckboxContainer = styled.div`
    width: 32px;
    height: 32px;

    @media (min-width: ${breakpoints.md}px) {
        width: 32px;
        height: 32px;
    }
`

const Price = styled.p<{ show: boolean }>`
    opacity: ${props => props.show ? '1' : '0'};
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-top: 4px;
    transition: opacity 0.3s;



    @media (min-width: ${breakpoints.md}px) {
        font-size: 14px;
        margin-top: 6px;
    }
`

interface AddonOverlayProps {
    addon: boolean;
    name: string;
    checked: boolean;
    onChange: () => void; // Updated type
    isHovered: boolean;
    price?: string;
}

const AddonOverlay: React.FC<AddonOverlayProps> = ({ 
    addon, 
    name, 
    checked, 
    onChange, 
    isHovered, 
    price 
  }) => {
      return (
          <>
              <GreyOverlay show={addon && !checked} />
              {addon && (
                  <Content show={true}>
                      <CheckboxContainer onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                              name={name}
                              checked={checked}
                              onChange={onChange} // Updated to use onChange directly
                          />
                      </CheckboxContainer>
                      <Price show={checked || isHovered}>{price || ''}</Price>
                  </Content>
              )}
          </>
      );
  }
export default AddonOverlay;