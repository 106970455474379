import Head from "next/head";
import { BuyProps } from "./types";
import Script from "next/script";

type PriceSpecification = {
    "@type": "PriceSpecification";
    price: number;
    priceCurrency: string;
    priceType: "https://schema.org/ListPrice";
};

type ProductOffer = {
    "@type": "Offer";
    url: string;
    priceCurrency: string;
    price: number;
    priceValidUntil: string;
    availability: string;
    itemCondition: string;
    priceSpecification?: PriceSpecification;
};

type ProductSchema = {
    "@context": "https://schema.org/";
    "@type": "Product";
    name: string;
    image: string[];
    offers: ProductOffer;
};

export default function ProductSchema({ product }: BuyProps) {
    // console.log('Attempting to render schema with:', product);
    
    const formatPrice = (price: string | null | undefined): number => {
        if (!price) {
            console.log('No price found');
            return 0;
        }
        const cleanedPrice = parseFloat(price.replace(/[^0-9.-]+/g, ""));
        // console.log('Formatted price:', cleanedPrice);
        return isNaN(cleanedPrice) ? 0 : cleanedPrice;
    };

    if (!product.name || !product.price) {
        console.error('Missing required product data:', { name: product.name, price: product.price });
        return null;
    }

    const currentPrice = product.onSale 
        ? formatPrice(product.salePrice)
        : formatPrice(product.price);

    const schema: ProductSchema = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": product.name,
        "image": [
            product.featuredImage?.node.mediaItemUrl
        ].filter(Boolean) as string[],
        "offers": {
            "@type": "Offer",
            "url": typeof window !== 'undefined' ? window.location.href : '',
            "priceCurrency": "USD",
            "price": currentPrice,
            "priceValidUntil": new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition"
        }
    };

    if (product.onSale && product.price) {
        const originalPrice = formatPrice(product.price);
        schema.offers.priceSpecification = {
            "@type": "PriceSpecification",
            "price": originalPrice,
            "priceCurrency": "USD",
            "priceType": "https://schema.org/ListPrice"
        };
    }

    return (
        <Head>
            <script 
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schema)
                }}
            />
        </Head>
    );
}