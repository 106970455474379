// pages/spotlight/components/sections/FormulaSection/FormulaItem.tsx
import React from 'react';
import styled from '@emotion/styled';
import { ShieldIcon } from './icons/ShieldIcon';
import { LightningIcon } from './icons/LightningIcon';
import { PersonIcon } from './icons/PersonIcon';
import breakpoints from '@/styles/breakpoints'


interface FormulaItemProps {
  icon: 'shield' | 'lightning' | 'person';
  isOn: boolean;
  text: string; 
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  max-width: 100%;
  padding-bottom: 24px;
  width: 100%;



  @media (min-width: ${breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 256px;
    padding-bottom: 0;
  }
`;

const IconWrapper = styled.div<{ isOn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 60px;
  margin-right: 20px;
  margin-left: 0;

  @media (min-width: ${breakpoints.md}px) {
    width: auto;
    height: auto;
    margin-right: 0;
  }
`;

const Text = styled.span<{ isOn: boolean }>`
  color: ${props => props.isOn ? 'rgb(var(--token-gray))' : '#3F3F4B'};
  font-family: 'PP Neue Montreal', sans-serif;
  transition: color 0.3s ease;
  font-size: 32px;
  font-weight: 500;
  margin-left: 20px;

  @media (min-width: ${breakpoints.md}px) {
    position: relative; 
    font-size: 42px;
    margin-top: 14px;
    margin-left: 0;


    ${props => props.isOn && `
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        pointer-events: none;
      }
    `}
  }
`;

export const FormulaItem: React.FC<FormulaItemProps> = ({ icon, isOn, text }) => {
    const iconSize = { base: 40, md: undefined }; // undefined for md will use the default size
  
    const IconComponent = {
      shield: () => <ShieldIcon isOn={isOn} size={iconSize} />,
      lightning: () => <LightningIcon isOn={isOn} size={iconSize} />,
      person: () => <PersonIcon isOn={isOn} size={iconSize} />,
    }[icon];
  
    return (
      <ItemWrapper>
        <IconWrapper isOn={isOn}>
          <IconComponent />
        </IconWrapper>
        <Text className='sans' isOn={isOn}>{text}</Text>
      </ItemWrapper>
    );
  };

  export default FormulaItem;