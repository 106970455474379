import { createContext, useContext, useEffect, useState } from "react";
import getShippingSelection, { ShippingSelectionReturn } from "./getShippingSelection";
import { getCartItems, ProcessCartItemsReturn } from "./processCartItems";
import { CartData, emptyCart } from "./types";
import { useMutation } from "@apollo/client";
import { AddItemsToCartDocument, AddItemsToCartMutationResult, UpdateShippingMethodMutationResult, UpdateCartItemQuantityMutationResult, RemoveCartItemMutationResult, AddItemsToCartMutation, CartItemInput, RemoveCartItemDocument, UpdateCartItemQuantityDocument, UpdateShippingMethodDocument, UpdateShippingMethodMutation, RemoveCartItemMutation, UpdateCartItemQuantityMutation, RemoveItemsFromCartDocument, RemoveItemsFromCartMutation } from "@/generated/graphql";
import { useUpdateLogic } from "./useUpdateLogic";
import { useRecalcLocalCart } from "./useUpdateLogic/useRecalcLocalCart";
import { useAuth } from "@faustwp/core";
import { v4 as generateClientMutationId } from "uuid";

interface CartContextType {
    updateShipping: (shippingId: string) => Promise<UpdateShippingMethodMutationResult>;
    handleQuantityChange: (itemKey: string, newQuantity: number) => Promise<UpdateCartItemQuantityMutationResult>;
    handleDeleteItem: (itemKey: string) => Promise<RemoveCartItemMutationResult>;
    addToCart: (item: CartItemInput) => Promise<AddItemsToCartMutationResult>;
    removeItemsFromCart: () => Promise<RemoveCartItemMutationResult>;
    cartData: CartData;
    cartItems: ProcessCartItemsReturn;
    selectedShippingDetails: ShippingSelectionReturn;
    cartLoading: boolean;
    isCartReady: true;
    noShippingNeeded: boolean;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export function CartProvider({ children }: { children: React.ReactNode }) {
    const [cartData, setCartData] = useState<CartData>(emptyCart);
    const [cartLoading, setCartLoading] = useState<boolean>(false);
    const { withUpdateLogic } = useUpdateLogic({ setCartData, setCartLoading, generateClientMutationId });

    //Refetch cart on reload, and on login/logout
    const { isAuthenticated } = useAuth();
    const { recalcLocalCart } = useRecalcLocalCart({ setCartData });
    useEffect(() => {
        const loadCart = async () => {
            setCartLoading(true);
            await recalcLocalCart();
            setCartLoading(false);
        }
        loadCart();
    }, [isAuthenticated])

    const [updateCartItemQuantity] = useMutation<UpdateCartItemQuantityMutation>(UpdateCartItemQuantityDocument);
    const handleQuantityChange = withUpdateLogic(
        async (itemKey: string, newQuantity: number) => {
            return await updateCartItemQuantity({ 
                variables: { key: itemKey, quantity: newQuantity } 
            });
        },
        'updateCartItemQuantity'
    );
  
    const [removeCartItem] = useMutation<RemoveCartItemMutation>(RemoveCartItemDocument);
    const handleDeleteItem = withUpdateLogic(
        async (itemKey: string) => {
        if (window.confirm('Are you sure you want to remove this item from your cart?')) {
            return await removeCartItem({ variables: { key: itemKey } });
        }
        return;
        },
        'removeCartItem'
    );

    const [addToCartFunction] = useMutation<AddItemsToCartMutation>(AddItemsToCartDocument);
    const addToCart = withUpdateLogic(
        async (item: CartItemInput) => {
            return await addToCartFunction({ 
                variables: { items: [item], }
            });
        },
        'addToCart'
    );

    const [updateShippingMethod] = useMutation<UpdateShippingMethodMutation>(UpdateShippingMethodDocument);
    const updateShipping = withUpdateLogic(
        async (shippingId: string) => {
            return await updateShippingMethod({ 
                variables: { shippingMethods: shippingId }
            });
        },
        'updateShipping'
    );

    const [removeItemsFromCartFunction] = useMutation<RemoveItemsFromCartMutation>(RemoveItemsFromCartDocument);
    const removeItemsFromCart = withUpdateLogic(
        async () => {
            return await removeItemsFromCartFunction();
        },
        'removeItemsFromCart'
    );

    const selectedShippingDetails = getShippingSelection(cartData);
    const cartItems = getCartItems(cartData);
    const noShippingNeeded = !(cartData?.cart?.availableShippingMethods?.length || 0);

    const value = {
        cartData,
        cartLoading,
        cartItems,
        selectedShippingDetails,
        handleQuantityChange,
        handleDeleteItem,
        updateShipping,
        addToCart,
        removeItemsFromCart,
        isCartReady: true as const,
        noShippingNeeded
    }
    
    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
}

type UseCartReturn = Omit<CartContextType, 'isCartReady'> & {
    isCartReady: boolean;
}

export function useCart(): UseCartReturn {
    const context = useContext(CartContext);

    return {
        ...context,
        isCartReady: !!context
    } as UseCartReturn;
}