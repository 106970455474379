import Head from "next/head";
import parse from 'html-react-parser';
import { PostSeoFieldsFragment, TaxonomySeoFieldsFragment } from "@/generated/graphql";

interface SeoProps {
    data: PostSeoFieldsFragment | TaxonomySeoFieldsFragment | null | undefined;
}

//For use with PostSeoFieldsFragment
export function Seo({ data }: SeoProps) {

    return ( 
        <Head>
            <title>{data?.title}</title>
            {data?.fullHead && parse(data.fullHead)}
        </Head>
    );
}