// src/components/IndustryItem.tsx
import React, { useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import useViewport from '@/utils/hooks/useViewport';
import useIntersectionObserver from '@/utils/hooks/useIntersectionObserver';
import breakpoints from '@/styles/breakpoints';
import { LgBr } from '@/components/common/Br'; // Importing because it is passed in landing data

interface IndustryItemProps {
  title: React.ReactNode;
  description: React.ReactNode;
  href: string;
  onItemIntersect: (index: number, isIntersecting: boolean) => void;
  index: number;
  isActive: boolean;
}


const ItemWrapper = styled.a`
  width: 100%;
  text-align: center;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;

  * { cursor: pointer; }
  
  &:hover {
    text-decoration: none;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h3`
  line-height: .8;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
`;

const Description = styled.p`
  font-size: 16px;
  color: #86868B;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  margin: 0;
  position: absolute;
  top: 75%;
  left: 0;
  right: 0;
  padding-top: 10px;
`;

const Underline = styled.div`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background: #3A71FF;
  box-shadow: 0px 0px 4px 1px rgba(58, 113, 255, 0.50);
  transition: width 0.2s ease-out;
  z-index: -1;
`;

const BlurEffect = styled.div<{ width: number; height: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  pointer-events: none;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(50% 52.39% at 50% 50%, rgba(58, 113, 255, 0.70) 0%, rgba(215, 36, 34, 0.00) 100%);
    filter: blur(${props => Math.max(props.width, props.height) * 0.1}px);
    border-radius: 50%;
  }
`;

const ArrowSVG = styled.svg`
  position: absolute;
  left: 100%;
  top: 8%;
  transform: translateY(-50%);
  margin-left: 4px;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  &.blink {
    animation: blinkAnimation 1s ease-in-out infinite 0.6s;
  }

  @keyframes blinkAnimation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
`;


const IndustryItem: React.FC<IndustryItemProps> = ({ 
  title, 
  description, 
  href, 
  onItemIntersect, 
  index, 
  isActive 
}) => {
  const itemRef = useRef<HTMLAnchorElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const underlineRef = useRef<HTMLDivElement>(null);
  const [blurDimensions, setBlurDimensions] = useState({ width: 0, height: 0 });
  const { width } = useViewport();
  const isIntersecting = useIntersectionObserver(itemRef, { 
    threshold: 0.8,
    rootMargin: '0px 0px -40% 0px'
  });
  const isMobile = width < breakpoints.md;

  useEffect(() => {
    if (isMobile) {
      onItemIntersect(index, isIntersecting);
    }
  }, [isMobile, isIntersecting, index, onItemIntersect]);

  useEffect(() => {
    if (titleRef.current) {
      const { offsetWidth, offsetHeight } = titleRef.current;
      setBlurDimensions({
        width: offsetWidth * 2,  // Adjust these multipliers as needed
        height: offsetHeight * 2.5
      });
    }
  }, [title]);

  useEffect(() => {
    if (itemRef.current && underlineRef.current) {
      const titleWidth = titleRef.current?.offsetWidth || 0;
      underlineRef.current.style.width = isActive ? `${titleWidth}px` : '0';
    }
  }, [isActive]);

  const handleMouseEnter = () => {
    if (!isMobile) onItemIntersect(index, true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) onItemIntersect(index, false);
  };

  return (
    <Link href={href} passHref legacyBehavior>
      <ItemWrapper
        ref={itemRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <BlurEffect 
          style={{ opacity: isActive ? 1 : 0 }}
          width={blurDimensions.width}
          height={blurDimensions.height}
        />        
        <TitleWrapper>
          <Title ref={titleRef}>{title}</Title>
          <ArrowSVG 
            className={`arrow ${isActive ? 'blink' : ''}`}
            style={{ opacity: isActive ? 1 : 0 }}
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="25" 
            viewBox="0 0 25 25" 
            fill="none"
          >
            <g filter="url(#filter0_dd_5374_40658)">
              <path d="M20.1426 5C20.1426 4.44772 19.6949 4 19.1426 4H10.1426C9.59029 4 9.14258 4.44772 9.14258 5C9.14258 5.55228 9.59029 6 10.1426 6H18.1426V14C18.1426 14.5523 18.5903 15 19.1426 15C19.6949 15 20.1426 14.5523 20.1426 14V5ZM4.29334 18.435C3.90281 18.8256 3.90281 19.4587 4.29334 19.8492C4.68386 20.2398 5.31703 20.2398 5.70755 19.8492L4.29334 18.435ZM18.4355 4.29289L4.29334 18.435L5.70755 19.8492L19.8497 5.70711L18.4355 4.29289Z" fill="#CFCFCF"/>
            </g>
            <defs>
              <filter id="filter0_dd_5374_40658" x="0" y="0" width="24.1426" height="24.1421" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5374_40658"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.228613 0 0 0 0 0.444601 0 0 0 0 1 0 0 0 0.33 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_5374_40658" result="effect2_dropShadow_5374_40658"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5374_40658" result="shape"/>
              </filter>
            </defs>
            </ArrowSVG>
          <Underline ref={underlineRef} />
        </TitleWrapper>
        <Description style={{ opacity: isActive ? 1 : 0 }}>
          {description}
        </Description>
      </ItemWrapper>
    </Link>
  );
};

export default IndustryItem;