//components/layout/NavBar/ScrollLock.tsx
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 98;
`;

interface ScrollLockProps {
  isLocked: boolean;
}

const ScrollLock: React.FC<ScrollLockProps> = ({ isLocked }) => {
  const scrollPosition = useRef(0);
  const router = useRouter();

  useEffect(() => {
    const html = document.documentElement;
    const body = document.body;

    const lockScroll = () => {
      scrollPosition.current = window.pageYOffset;
      const scrollBarWidth = window.innerWidth - html.clientWidth;
      
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition.current}px`;
      body.style.width = '100%';
      body.style.paddingRight = `${scrollBarWidth}px`;
    };

    const unlockScroll = () => {
      body.style.position = '';
      body.style.top = '';
      body.style.width = '';
      body.style.paddingRight = '';
      // Only restore scroll position if we're not navigating
      if (!isNavigating) {
        window.scrollTo(0, scrollPosition.current);
      }
    };

    let isNavigating = false;

    if (isLocked) {
      lockScroll();
    } else {
      unlockScroll();
    }

    const handleRouteChangeStart = () => {
      isNavigating = true;
      if (isLocked) {
        unlockScroll();
      }
    };

    const handleRouteChangeComplete = () => {
      isNavigating = false;
      window.scrollTo(0, 0);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      unlockScroll();
    };
  }, [isLocked, router]);

  if (!isLocked) return null;
  return <Overlay />;
};

export default ScrollLock;