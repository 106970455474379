import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const FooterComponent = dynamic(() => import('./Footer'), {
  loading: () => <div style={{ height: '408px', background: '#01010E', maxWidth: '100%' }} />,
  ssr: false
});

const DynamicFooter: React.FC = () => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    // Delay the footer render
    const timer = setTimeout(() => setShouldRender(true), 0);
    return () => clearTimeout(timer);
  }, []);

  return shouldRender ? <FooterComponent /> : null;
};

export default DynamicFooter;