// src/components/landing/FakeIdSection/SolutionItem.tsx
import React from 'react';
import styled from '@emotion/styled';
import ShieldIcon from '@/components/icons/ShieldIcon';
import Image from 'next/image';


interface SolutionItemProps {
    color: string;
    name: string;
    description: string;
    showBottomBorder: boolean;
    backgroundImage: string;
  }

  const FullWidthContainer = styled.div<{ showBottomBorder: boolean }>`
  min-height: 523px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  border-bottom: ${props => props.showBottomBorder ? '1px solid rgba(255, 255, 255, 0.16)' : 'none'};
  background: #000;
  position: relative;
  overflow: hidden;

  &:hover .background-image {
    opacity: 0.8;
  }
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  object-fit: contain;
`;

const ContentWrapper = styled.div`
  margin: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  position: relative;
  z-index: 2;
`;

const TextContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 26px;
  max-width: 780px;
  padding: 0 10px;
  word-wrap: break-word; 
`;

const Name = styled.h2`
  font-family: "PP Neue Montreal", sans-serif;
  width: 100%;

`;

const Description = styled.h5`
  font-family: "PP Neue Montreal", sans-serif;
  max-width: 780px;
  width: 100%;
  word-wrap: break-word; 
`;

const BigBlur = styled.div`
  width: 473px;
  height: 602px;
  border-radius: 602px;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  filter: blur(60px);
  top: -250px;
  z-index: 1;
  overflow: visible;
`;

const SmallBlur = styled.div`
  width: 187px;
  height: 66px;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  top: 90px;
  border-radius: 30px;
  z-index: 1;
  filter: blur(40px);
  overflow: visible;
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 78px;
  margin-bottom: 64px;
  z-index: 2;
  position: relative;
  overflow: visible;
  background: none;

  svg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: none;
    overflow: visible;

      &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, .33));
    background: none;
    z-index: 1;
    overflow: visible;

  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.13) inset;
    background: none;
    z-index: 1;
    overflow: visible;
  }

  }
`;

const SolutionItem: React.FC<SolutionItemProps> = ({ color, name, description, showBottomBorder, backgroundImage }) => {
    return (
      <FullWidthContainer showBottomBorder={showBottomBorder}>
        <ContentWrapper>
          <BigBlur />
          <SmallBlur />
          <IconWrapper>
            <ShieldIcon color={color} />
          </IconWrapper>
          <TextContainer>
            <Name>{name}</Name>
            <Description>{description}</Description>
          </TextContainer>
        </ContentWrapper>
        <BackgroundImage 
          src={backgroundImage} 
          alt={name} 
          fill
          className="background-image"
        />
      </FullWidthContainer>
    );
  };
  
  export default SolutionItem;