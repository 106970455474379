// src/components/spotlight/sections/BentoSection.tsx

import React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import BentoGrid from '@/components/spotlight/bento/BentoGrid';
import BentoItem from '@/components/spotlight/bento/BentoItem';
import { ProcessedBentoItem as BentoItemType } from '@/utils/spotlightDataProcessor';
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Container";
import PlaceholderAnimation from '@/components/spotlight/bento/PlaceholderAnimation/Animation';

const AnimationComponents: { [key: string]: React.ComponentType<any> } = {
  AntiPassback: dynamic(() => import('@/components/spotlight/bento/AntiPassback/Animation')),
  BatteryLife:  dynamic(() => import('@/components/spotlight/bento/BatteryLife/Animation')),
  ClearAlerts: dynamic(() => import('@/components/spotlight/bento/ClearAlerts/Animation')),
  ClearMobileInterface: dynamic(() => import('@/components/spotlight/bento/ClearMobileInterface/Animation')),
  Cradle: dynamic(() => import('@/components/spotlight/bento/Cradle/Animation')),
  CRM: dynamic(() => import('@/components/spotlight/bento/CRM/Animation')),
  Durable: dynamic(() => import('@/components/spotlight/bento/Durable/Animation')),
  ExpiredIdFlags: dynamic(() => import('@/components/spotlight/bento/ExpiredIdFlags/Animation')),
  ExtendedWarrentyTwo: dynamic(() => import('@/components/spotlight/bento/ExtendedWarrentyTwo/Animation')),
  ExtendedWarrentyThree: dynamic(() => import('@/components/spotlight/bento/ExtendedWarrentyThree/Animation')),
  ExtendedWarrentyFour:dynamic(() => import('@/components/spotlight/bento/ExtendedWarrentyFour/Animation')),
  ExtraBattery: dynamic(() => import('@/components/spotlight/bento/ExtraBattery/Animation')),
  FakeDetection: dynamic(() => import('@/components/spotlight/bento/FakeDetection/Animation')),
  FindMyDevice: dynamic(() => import('@/components/spotlight/bento/FindMyDevice/Animation')),
  FreeUpdates: dynamic(() => import('@/components/spotlight/bento/FreeUpdates/Animation')),
  HapticAlerts: dynamic(() => import('@/components/spotlight/bento/HapticAlerts/Animation')),
  InstantScans: dynamic(() => import('@/components/spotlight/bento/InstantScans/Animation')),
  MultiDeviceSync: dynamic(() => import('@/components/spotlight/bento/MultiDeviceSync/Animation')),
  Passports: dynamic(() => import('@/components/spotlight/bento/Passports/Animation')),
  PhotoCapture: dynamic(() => import('@/components/spotlight/bento/PhotoCapture/Animation')),
  UnderageAlerts: dynamic(() => import('@/components/spotlight/bento/UnderageAlerts/Animation')),
  VIPBanned: dynamic(() => import('@/components/spotlight/bento/VIPBanned/Animation')),
  Placeholder: dynamic(() => import('@/components/spotlight/bento/PlaceholderAnimation/Animation')),
  Stand: dynamic(() => import('@/components/spotlight/bento/Stand/Animation')),
  AvMagStripe: dynamic(() => import('@/components/spotlight/bento/AvMagStripe/Animation')),
  Large7Touchscreen: dynamic(() => import('@/components/spotlight/bento/Large7Touchscreen/Animation')),
};

interface BentoSectionProps {
  items: BentoItemType[];
}

const StyledSection = styled(Section)`
    max-width: 100%;
`

const BentoSection: React.FC<BentoSectionProps> = ({ items }) => {
  return (
  <StyledSection>
      <Container>
          <BentoGrid>
          {items.map((item, index) => {
          const animationName = item.animationName ?? 'Placeholder';
          const AnimationComponent = AnimationComponents[animationName];
          
          if (!AnimationComponent) {
              console.error(`Animation component not found for: ${animationName}`);
              return null; // or return a fallback component
          }
          
          return (
              <BentoItem
                  key={index}
                  data={item}
                  AnimationComponent={AnimationComponent}
              />
              );
          })}
          </BentoGrid>
      </Container>
    </StyledSection>
  );
};

export default BentoSection;