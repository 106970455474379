// @/utils/hooks/useIntersectionObserver.ts
import { useState, useEffect, RefObject } from 'react';

interface IntersectionOptions {
  rootMargin?: string;
  threshold?: number | number[];
}

const useIntersectionObserver = (ref: RefObject<HTMLElement>, options: IntersectionOptions = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, {
      rootMargin: options.rootMargin || '0px',
      threshold: options.threshold ?? 0
    });

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };

  }, [ref, options.rootMargin, options.threshold]);

  return isIntersecting;
}

export default useIntersectionObserver;