//pages/spotlight/components/sections/FaqSection
import React from 'react';
import styled from '@emotion/styled';
import Section from "@/components/layout/Base/Section";
import Container from "@/components/layout/Base/Section";
import breakpoints from "@/styles/breakpoints";
import SectionTitle from "./SectionTitle"
import QAComponent from './QAComponent'; 


const QASection = styled(Section)`
    overflow: hidden;
    width: 100%;
    max-width: 100%;
`

const QAOuterContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
`;



const QAContainer = styled.div`
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: stretch;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.16);
    background: #000;
    margin: 0 auto;
    overflow: hidden; // This will ensure the border-radius is applied correctly
    
`

interface FaqItem {
    question: string;
    answer: string;
  }
  
  interface FaqSectionProps {
    faqData: FaqItem[];
  }
  
const FaqSection: React.FC<FaqSectionProps> = ({ faqData }) => {
    return (
      <QASection>
          <SectionTitle />
          <QAOuterContainer>
          <QAContainer>
          {faqData.map((item, index) => (
            <QAComponent 
              key={index} 
              question={item.question} 
              answer={item.answer}
              isLast={index === faqData.length - 1} // Pass isLast prop
            />
          ))}
        </QAContainer>
        </QAOuterContainer>
      </QASection>
    );
  };
  
export default FaqSection;
