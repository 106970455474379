import { getApolloAuthClient, useAuth } from "@faustwp/core";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { GetCartDocument, GetCartQuery } from "@/generated/graphql";
import { CartData } from "../types";

interface useRecalcLocalCart {
    setCartData: Dispatch<SetStateAction<CartData>>,
}

export function useRecalcLocalCart({ setCartData }: useRecalcLocalCart) {

    //We can still perform operations when isAuthenticated = false, so no need to check here.
    const { isReady } = useAuth();
    const client = getApolloAuthClient();
    const isReadyRef = useRef(isReady);

    useEffect(() => {
        isReadyRef.current = isReady;
    }, [isReady]);

    const [ cartQuery ] = useLazyQuery<GetCartQuery>(GetCartDocument, { client, fetchPolicy: 'network-only' });
    
    const recalcLocalCart = async () => {

        //isReady guard
        while (!isReadyRef.current) {
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        //trigger recalc query
        try {
            //cache bust
            const recalcResult = await cartQuery({
                variables: { cacheBuster: Math.random().toString(36).substring(7) },
                fetchPolicy: 'network-only',
            });

            if (recalcResult.data?.cart) {
                // update local state with refetch result
                setCartData(recalcResult.data);
                return;
            }

            console.log('Cart query completed, but there was no data.');
        } catch (error) {
            console.error('Error in cart recalc:', error);
        }
    }
    
    return { recalcLocalCart };
}