import { getNavDataWithCache } from '@/utils/rendering/nav/navDataCache';
import mockNavData from './mockNavData.json'
import { AppContext } from 'next/app';

export async function getInitialAppProps(appContext: AppContext) {
    //skip if in dev mode
    if (process.env.NODE_ENV === 'development' || process.env.ENABLE_BUILD_SHORTCUTS === 'true') {
        return {
          pageProps: {},
          navData: mockNavData
        };
    }

    const navData = await getNavDataWithCache();

    // Call getInitialProps on the page component if it exists
    const pageProps = appContext.Component.getInitialProps
      ? await appContext.Component.getInitialProps(appContext.ctx)
      : {};
  
    return { pageProps, navData };
}