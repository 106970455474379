//pages/spotlight/components/sections/FormulaSection/icons/ShieldIcon.tsx
import React from 'react';
import styled from '@emotion/styled';
import breakpoints from '@/styles/breakpoints';

interface ShieldIconProps {
  isOn: boolean;
  size?: {
    base: number;
    md?: number;
  };
}

const IconSVG = styled.svg<ShieldIconProps>`
  fill: #01010E;
  transition: filter 0.3s ease;
  
  width: ${props => props.size?.base || 50}px;
  height: ${props => (props.size?.base || 50) * (706/577)}px;

  @media (min-width: ${breakpoints.md}px) {
    width: ${props => props.size?.md || 164}px;
    height: ${props => (props.size?.md || 164) * (706/577)}px;
  }
  
  ${({ isOn }) => isOn ? `
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.33));
  ` : `
    filter: none;
  `}

  path {
    stroke-width: 2.9px;
    transition: stroke 0.3s ease;
  }
`;

export const ShieldIcon: React.FC<ShieldIconProps> = ({ isOn, size }) => {
  return (
    <IconSVG 
      isOn={isOn}
      size={size}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 577 706"
    >
      <defs>
        <linearGradient id="strokeGradientOn" x1="288.5" y1="0" x2="288.5" y2="706" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.88" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient id="strokeGradientOff" x1="288.5" y1="0" x2="288.5" y2="706" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0.16" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0.13" />
        </linearGradient>
        <filter id="innerShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="8.7" result="blur"/>
          <feOffset dy="0" dx="0"/>
          <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"/>
          <feFlood flood-color="#000000" flood-opacity="0.13"/>
          <feComposite in2="shadowDiff" operator="in"/>
          <feComposite in2="SourceGraphic" operator="over"/>
        </filter>
      </defs>
      <g filter="url(#innerShadow)">
        <path 
          d="M288.5 0L0 128.364V320.909C0 499.014 123.093 665.565 288.5 706C453.907 665.565 577 499.014 577 320.909V128.364L288.5 0Z" 
          fill="#01010E"
        />
      </g>
      <path 
        d="M1.45 320.909V129.306L288.5 1.58705L575.55 129.306V320.909C575.55 498.283 453.027 664.098 288.5 704.507C123.973 664.098 1.45 498.283 1.45 320.909Z" 
        stroke={isOn ? "url(#strokeGradientOn)" : "url(#strokeGradientOff)"}
        strokeWidth="2.9"
      />
    </IconSVG>
  );
};