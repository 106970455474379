// CircleArrowButton.tsx

import React from 'react';
import CircleButton from './CircleButton';
import styled from '@emotion/styled';

interface CircleArrowButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
  disabled: boolean;
  ariaLabel: string;
}

const StyledSVG = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CircleArrowButton: React.FC<CircleArrowButtonProps> = ({ direction, onClick, disabled, ariaLabel }) => {
  const svgContent = direction === 'left' ? (
    <path fillRule="evenodd" clipRule="evenodd" d="M18.7511 10.0008C18.7511 9.83503 18.6853 9.67606 18.5681 9.55885C18.4508 9.44164 18.2919 9.37579 18.1261 9.37579H10.7555H3.38487L7.31862 5.44329C7.37673 5.38518 7.42282 5.31619 7.45427 5.24027C7.48572 5.16434 7.50191 5.08297 7.50191 5.00079C7.50191 4.91861 7.48572 4.83723 7.45427 4.76131C7.42282 4.68539 7.37673 4.6164 7.31862 4.55829C7.26051 4.50018 7.19152 4.45408 7.1156 4.42264C7.03967 4.39119 6.9583 4.375 6.87612 4.375C6.79394 4.375 6.71256 4.39119 6.63664 4.42264C6.56071 4.45408 6.49173 4.50018 6.43362 4.55829L1.43362 9.55829C1.37541 9.61635 1.32923 9.68532 1.29773 9.76125C1.26622 9.83718 1.25 9.91858 1.25 10.0008C1.25 10.083 1.26622 10.1644 1.29773 10.2403C1.32923 10.3163 1.37541 10.3852 1.43362 10.4433L6.43362 15.4433C6.49173 15.5014 6.56071 15.5475 6.63664 15.5789C6.71256 15.6104 6.79394 15.6266 6.87612 15.6266C6.9583 15.6266 7.03967 15.6104 7.1156 15.5789C7.19152 15.5475 7.26051 15.5014 7.31862 15.4433C7.37673 15.3852 7.42282 15.3162 7.45427 15.2403C7.48572 15.1643 7.50191 15.083 7.50191 15.0008C7.50191 14.9186 7.48572 14.8372 7.45427 14.7613C7.42282 14.6854 7.37673 14.6164 7.31862 14.5583L3.38487 10.6258H18.1261C18.2919 10.6258 18.4508 10.5599 18.5681 10.4427C18.6853 10.3255 18.7511 10.1665 18.7511 10.0008Z" />
  ) : (
    <path fillRule="evenodd" clipRule="evenodd" d="M1.24888 9.99921C1.24888 10.165 1.31473 10.3239 1.43194 10.4412C1.54915 10.5584 1.70812 10.6242 1.87388 10.6242L9.24451 10.6242L16.6151 10.6242L12.6814 14.5567C12.6233 14.6148 12.5772 14.6838 12.5457 14.7597C12.5143 14.8357 12.4981 14.917 12.4981 14.9992C12.4981 15.0814 12.5143 15.1628 12.5457 15.2387C12.5772 15.3146 12.6233 15.3836 12.6814 15.4417C12.7395 15.4998 12.8085 15.5459 12.8844 15.5774C12.9603 15.6088 13.0417 15.625 13.1239 15.625C13.2061 15.625 13.2874 15.6088 13.3634 15.5774C13.4393 15.5459 13.5083 15.4998 13.5664 15.4417L18.5664 10.4417C18.6246 10.3837 18.6708 10.3147 18.7023 10.2388C18.7338 10.1628 18.75 10.0814 18.75 9.99921C18.75 9.917 18.7338 9.8356 18.7023 9.75967C18.6708 9.68374 18.6246 9.61477 18.5664 9.55671L13.5664 4.55671C13.5083 4.4986 13.4393 4.45251 13.3634 4.42106C13.2874 4.38961 13.2061 4.37342 13.1239 4.37342C13.0417 4.37342 12.9603 4.38961 12.8844 4.42106C12.8085 4.45251 12.7395 4.4986 12.6814 4.55671C12.6233 4.61482 12.5772 4.68381 12.5457 4.75973C12.5143 4.83566 12.4981 4.91703 12.4981 4.99921C12.4981 5.08139 12.5143 5.16277 12.5457 5.23869C12.5772 5.31461 12.6233 5.3836 12.6814 5.44171L16.6151 9.37421L1.87388 9.37421C1.70812 9.37421 1.54915 9.44006 1.43194 9.55727C1.31473 9.67448 1.24888 9.83345 1.24888 9.99921Z" />
  );

  return (
    <CircleButton onClick={onClick} disabled={disabled} label={ariaLabel}>
      <StyledSVG 
        xmlns="http://www.w3.org/2000/svg" 
        width="20" 
        height="20" 
        viewBox="0 0 20 20" 
        fill="currentColor"
      >
        <defs>
          <filter id={`innerShadow_${direction}`} x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="0.5" result="blur"/>
            <feOffset dy="0" dx="0"/>
            <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"/>
            <feFlood floodColor="#000000" floodOpacity="0.55"/>
            <feComposite in2="shadowDiff" operator="in"/>
            <feComposite in2="SourceGraphic" operator="over" result="innerShadow"/>
          </filter>
        </defs>
    <g filter={`url(#innerShadow_${direction})`}>
          {svgContent}
        </g>
      </StyledSVG>
    </CircleButton>
  );
};

export default CircleArrowButton;