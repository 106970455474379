// ./faust.config.js
import { setConfig } from '@faustwp/core';
import templates from './wp-templates';
import possibleTypes from './possibleTypes.json';
import { WooSessionManagerPlugin } from '@/faust-plugins/WooSessionManagerPlugin';

/**
 * @type {import('@faustwp/core').FaustConfig}
 **/
export default setConfig({
  templates,
  plugins: [
    new WooSessionManagerPlugin(),
  ],
  possibleTypes,
  usePersistedQueries: true,
});