// /WooSessionManagerPlugin/createSessionLink/index.ts
import { setContext } from '@apollo/client/link/context';
import { getSessionToken } from './getSessionToken';

export function createSessionLink() {
  return setContext(async (_, { headers }) => {
    const sessionToken = await getSessionToken();

    return {
      headers: {
        ...headers,
        'woocommerce-session': sessionToken ? `Session ${sessionToken}` : '',
      },
    };
  });
}