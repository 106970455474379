// ./wp-templates/category.tsx
import { FaustTemplate } from "@faustwp/core";
import { GetPostsByCategoryDocument, GetPostsByCategoryQuery } from '@/generated/graphql';
import { transformCategoryData } from '@/components/idinsights/categoryTagPages/types';
import CategoryTagTemplate from '@/components/idinsights/categoryTagPages';
import { Seo } from "@/components/layout/Base/Seo"; // Add this import


const Component: FaustTemplate<GetPostsByCategoryQuery> = (props) => {
  if (props.loading) return <>Loading...</>; // Loading state for previews

  if (!props.data) return <div>No data found</div>;
  
  const categoryTagData = transformCategoryData(props.data);

  return (
    <>
      <Seo data={props.data.category?.seo} />
      <CategoryTagTemplate data={categoryTagData}/>
    </>
  );
}


Component.query = GetPostsByCategoryDocument;

Component.variables = (seedQuery, context) => {
  return {
    categoryId: seedQuery?.databaseId,
    first: 50,
    asPreview: context?.asPreview,
  };
};

export default Component;